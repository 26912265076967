
@mixin icon-override($icon) {
	&::before {
		content: $icon;
	}
}

@mixin appearance($val) {
	-webkit-appearance: $val;
	-moz-appearance: $val;
	appearance: $val;
}

@mixin transition($transition...) {
	-moz-transition: $transition;
	-o-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;
}

@mixin focused() {
	outline: $focusOutline;
	outline-offset: $focusOutlineOffset;
	@include shadow($focusShadow);
}

@mixin focused-input() {
	@include focused();

	border-color: $inputFocusBorderColor;
	outline: $stamps-ui-checkbox-box-focus-outline-border;
	outline-offset: 2px;
}

@mixin focused-listitem() {
	outline: $focusOutline;
	outline-offset: $focusOutlineOffset;
	@include shadow($inputListItemFocusShadow);
}

@mixin disabled-input() {
	background-color: $inputDisabledBgColor;
	color: $inputDisabledTextColor;
	cursor: default !important;
	pointer-events: none;
}

@mixin shadow($value) {
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
	box-shadow: $value;
}

@mixin border-radius($val) {
	-moz-border-radius: $val;
	-webkit-border-radius: $val;
	border-radius: $val;
}

@mixin border-radius-right($val) {
	-moz-border-radius-topright: $val;
	-webkit-border-top-right-radius: $val;
	border-top-right-radius: $val;
	-moz-border-radius-bottomright: $val;
	-webkit-border-bottom-right-radius: $val;
	border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
	-moz-border-radius-topleft: $val;
	-webkit-border-top-left-radius: $val;
	border-top-left-radius: $val;
	-moz-border-radius-bottomleft: $val;
	-webkit-border-bottom-left-radius: $val;
	border-bottom-left-radius: $val;
}

@mixin border-radius-top($val) {
	-moz-border-radius-topleft: $val;
	-webkit-border-top-left-radius: $val;
	border-top-left-radius: $val;
	-moz-border-radius-topright: $val;
	-webkit-border-top-right-radius: $val;
	border-top-right-radius: $val;
}

@mixin border-radius-top-right($val) {
	-moz-border-radius-topright: $val;
	-webkit-border-top-right-radius: $val;
	border-top-right-radius: $val;
}

@mixin border-radius-bottom-right($val) {
	-moz-border-radius-bottomright: $val;
	-webkit-border-bottom-right-radius: $val;
	border-bottom-right-radius: $val;
}

@mixin border-radius-bottom($val) {
	-moz-border-radius-bottomleft: $val;
	-webkit-border-bottom-left-radius: $val;
	border-bottom-left-radius: $val;
	-moz-border-radius-bottomright: $val;
	-webkit-border-bottom-right-radius: $val;
	border-bottom-right-radius: $val;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;

	filter: alpha(opacity=$opacity-ie);
}

@mixin placeholder {
	::-webkit-input-placeholder { @content; }
	:-moz-placeholder { @content; }
	::-moz-placeholder { @content; }
	:-ms-input-placeholder { @content; }
}

@mixin rotate($deg) {
	-webkit-transform: rotate($deg);
	-moz-transform: rotate($deg);
	-o-transform: rotate($deg);
	-ms-transform: rotate($deg);
	transform: rotate($deg);
}

@mixin scale($deg) {
	-webkit-transform: scale($deg);
	-moz-transform: scale($deg);
	-o-transform: scale($deg);
	-ms-transform: scale($deg);
	transform: scale($deg);
}

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}
