@mixin ui-steppers {
	.common-ui-steppers {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 10px;

		.step {
			display: flex;
			flex-direction: row;
			width: 100%;

			.step-container {
				display: flex;
				flex-direction: row;
				flex: 1;

				.track {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;

					.checkmark-icon {
						position: absolute;
						top: 12.5%;
						left: 50%;
						transform: translate(-50%, -50%);
						color: $stamps-ui-steppers-bg-color;
						font-size: 9px;
						font-weight: 900;
						z-index: 1;
					}

					.common-steppers-circle {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						margin: 0 auto;

						svg {
							position: absolute;
							top: 0;
							left: 0;
							width: 16px;
							height: 16px;
							fill: $stamps-ui-steppers-color;
							stroke: $stamps-ui-steppers-color;
							stroke-width: 48px;

							circle {
								cx: 50%;
								cy: 50%;
								r: 40%;
							}
						}
					}

					.circle-connector {
						width: 1px;
						flex: 1;
						background-color: $stamps-ui-steppers-color;
						margin: 0 8px;
					}

					.step-body {
						flex: 1;
						margin-top: -2px;
					}
				}
			}

			&:first-child {
				.step-container {
					.track {
						.checkmark-icon {
							display: none;
						}

						.common-steppers-circle {
							svg {
								fill: $stamps-ui-steppers-bg-color;
							}
						}
					}
				}
			}
		}
	}
}
