@mixin carrier-fedex-eula {
	.common-carrier-fedex-eula {
		height: 100%;

		.section-body {
			display: flex;

			.fedex-eula-iframe {
				height: 100%;
				width: 100%;
				flex: 1 0 auto;
			}
		}
	}
}
