
@mixin pdf-preview {
	// PDF Preview
	.common-print.pdf-preview-modal {
		height: 65vh;

		.p-dialog-content {
			padding: 0;
		}

		.print-instructions {
			background-color: #ffffcc;

			.instructions-list {
				padding: 0 15px;
			}

			.header {
				text-align: center;
			}
		}
	}
}
