/*******************************************************************
*                                                                  *
*                    Local style rules add here                    *
*               (This will also override theme styles)             *
*                                                                  *
********************************************************************/

@import './etc/background-image-url';
@import './src/allocate-funds.scss';
@import './src/cancel-account.scss';
@import './src/change-password.scss';
@import './src/change-plan.scss';
@import './src/client-pendo-view.scss';
@import './src/comm-prefs.scss';
@import './src/contact-info.scss';
@import './src/locations.scss';
@import './src/manage-subscription-plan.scss';
@import './src/personal-contact-info.scss';
@import './src/account-verification.scss';
@import './src/refer-a-friend.scss';
@import './src/refundeligibilityreport.scss';
@import './src/regions.scss';
@import './src/ship-from-address.scss';
@import './src/payment.scss';
@import './src/carrier.scss';
@import './src/users.scss';
@import './src/unsubscribe.scss';
@import './src/branded-tracking.scss';
@import './src/win-client-home-page.scss';
@import './src/packing-slips.scss';
@import './src/settings.scss';
@import './src/sms-terms.scss';

.center-view,
.p-dialog-content {
	height: 100%;
	overflow-x: auto;

	.account-footer {
		padding-top: 10px;

		.button-container {
			.common-ui-button {
				padding: 0 10px 0 0;

				.p-button {
					font-size: 13px;
				}
			}
		}
	}

	.account-title {
		@include h3;

		padding-bottom: $stamps-account-content-padding;
		border-bottom: 1px solid #eee;
		margin-bottom: 20px;
	}
}

@include account-allocate-funds();
@include account-cancel-account();
@include account-change-password();
@include account-change-plan();
@include account-client-pendo-view();
@include account-comm-prefs();
@include account-contact-info();
@include account-locations();
@include account-manage-subscription-plan();
@include account-personal-contact-info();
@include account-verification();
@include account-refer-a-friend();
@include account-refundeligibilityreport();
@include account-regions();
@include account-ship-from-address();
@include account-payment();
@include account-carrier();
@include account-users();
@include account-unsubscribe();
@include account-branded-tracking();
@include account-win-client-home-page();
@include account-packing-slips();
@include account-settings();
@include account-sms-terms();
