@mixin tags {
	.common-tags {
		.color-bar {
			width: 15px;
			height: 20px;
			border-radius: 3px;
		}

		.common-grid {
			.ag-theme-common {
				&.ag-grid-container {
					flex: 1 1 auto; // fix IE 11 height issue
				}
			}
		}

		&.tag-cell-renderer {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: flex;

			.color-bar {
				display: inline-block;
				width: 6px;
				height: 15px;
			}

			.color-bar-parent {
				display: inline-block;
				margin-right: 5px;
			}
		}

		&.manage-tags-modal {
			&.p-dialog {
				.p-dialog-content {
					padding: 0;

					.p-toolbar {
						background-color: $stamps-products-toolbar-color;
					}
				}
			}

			// allow the color-bar vertically centered in ag-grid
			.color-cell {
				display: flex;
				align-items: center;
			}
		}

		&.edit-tag-modal {
			.common-ui-element-wrapper {
				.label-container {
					text-align: left;
				}
			}
		}

		&.change-tags-modal {
			width: 300px;

			.search-tags {
				position: relative;

				.common-ui-input-text {
					.p-inputtext {
						padding-right: 30px; // padding space for search icon
					}
				}

				.pi-search {
					position: absolute;
					top: 10px; // make the search icon vertically centered in WrappedInputTexts
					right: 15px; // padding for the search icon in WrappedInputText
					color: #888;
					cursor: pointer;
					font-size: $stamps-icon-button-icon-size;
				}
			}

			.change-tags-modal-cell-renderer {
				display: flex;
				align-items: center;

				.color-bar {
					margin-right: 8px;
				}
			}
		}
	}

	.color-bar-tooltip {
		display: inline-block;
		width: auto;
		background-color: white;
	}
}
