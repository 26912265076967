body {
	.p-component-overlay {
		background-color: $maskBgColor;
	}

	.p-overlaypanel {
		max-width: 320px;
		max-height: 560px;
		background-color: $panelContentBgColor;
		color: $panelContentTextColor;
		padding: 0 20px 0 0;
		border: $stamps-ui-popover-border;
		@include border-radius($borderRadius);
		@include shadow($stamps-ui-popover-box-shadow);

		.p-overlaypanel-content {
			padding: $dialogContentPadding;
			max-width: 288px;
			line-height: $stamps-ui-popover-line-height;
		}

		.p-overlaypanel-close {
			background-color: $overlayPanelCloseIconBgColor;
			color: $overlayPanelCloseIconColor;
			width: 40px;
			height: 40px;
			line-height: 1.538em;
			text-align: center;
			position: absolute;
			top: 0;
			right: 0;
			@include border-radius(50%);
			@include transition(background-color $transitionDuration, box-shadow $transitionDuration);

			> span {
				line-height: inherit;
				font-size: 10px;
			}
		}

		&::after {
			border-color: rgba($panelContentBgColor, 0);
			border-bottom-color: $panelContentBgColor;
		}

		&::before {
			background-color: $panelContentBgColor;
			height: 8px;
			width: 8px;
			top: -5px;
			transform: rotate(45deg);
			border-left: $stamps-ui-popover-border;
			border-top: $stamps-ui-popover-border;
		}

		&.p-overlaypanel-flipped {
			&::after {
				border-top-color: $panelContentBgColor;
			}

			&::before {
				top: auto;
				bottom: -5px;
				border-left: none;
				border-top: none;
				border-right: $stamps-ui-popover-border;
				border-bottom: $stamps-ui-popover-border;
			}
		}
	}

	.p-dialog {
		padding: 0;
		overflow: auto;
		@include border-radius($overlayContainerRadius);
		@include shadow($overlayContainerShadow);

		.p-dialog-titlebar {
			border: $panelHeaderBorder;
			background-color: $panelHeaderBgColor;
			color: $panelHeaderTextColor;
			padding: $dialogHeaderPadding;
			font-weight: $panelHeaderFontWeight;
			border-bottom: 0 none;
			@include border-radius-top($borderRadius);

			.p-dialog-title {
				margin: 0;
				float: none;
				font-weight: $panelHeaderFontWeight;
			}

			.p-dialog-titlebar-icon {
				color: $panelHeaderIconColor;
				border: 0 none;
				padding: 0;
				margin-left: $inlineSpacing;
				@include transition(color $transitionDuration, box-shadow $transitionDuration);

				&:hover {
					color: $panelHeaderIconHoverColor;
				}
			}
		}

		.p-dialog-content {
			background-color: $panelContentBgColor;
			color: $panelContentTextColor;
			padding: $dialogContentPadding;
		}

		.p-dialog-footer {
			border: $panelFooterBorder;
			border-top: 0 none;
			background-color: $panelFooterBgColor;
			color: $panelFooterTextColor;
			padding: $panelFooterPadding;
			margin: 0;
			text-align: right;

			button {
				margin: 0 $inlineSpacing 0 0;
				width: auto;
			}
		}

		&.p-confirm-dialog {
			.p-dialog-content {
				padding: $confirmDialogPadding;

				> span {
					float: none;
					display: inline-block;
					vertical-align: middle;
					line-height: $fontSize;
					margin: 0;

					&.p-icon {
						margin-right: .35em;
						font-size: $fontSize + 2;
					}
				}
			}
		}
	}

	.p-sidebar {
		background-color: $panelContentBgColor;
		color: $panelContentTextColor;
		padding: $panelContentPadding;
		border: $overlayContentBorder;
		@include shadow($overlayContainerShadow);

		.p-sidebar-close {
			color: $textSecondaryColor;

			&:hover {
				color: $textColor;
			}
		}
	}

	.p-tooltip {
		display: block;

		.p-tooltip-text {
			background-color: $tooltipBgColor;
			color: $tooltipTextColor;
			padding: $tooltipPadding;
			@include shadow($overlayContainerShadow);
			@include border-radius($borderRadius);
		}

		&.p-tooltip-right {
			.p-tooltip-arrow {
				border-right-color: $tooltipBgColor;
			}
		}

		&.p-tooltip-left {
			.p-tooltip-arrow {
				border-left-color: $tooltipBgColor;
			}
		}

		&.p-tooltip-top {
			.p-tooltip-arrow {
				border-top-color: $tooltipBgColor;
			}
		}

		&.p-tooltip-bottom {
			.p-tooltip-arrow {
				border-bottom-color: $tooltipBgColor;
			}
		}
	}

	.p-lightbox {
		@include shadow($overlayContainerShadow);

		.p-lightbox-caption {
			border: $panelHeaderBorder;
			background-color: $panelHeaderBgColor;
			color: $panelHeaderTextColor;
			padding: $panelHeaderPadding;
			font-weight: $panelHeaderFontWeight;

			.p-lightbox-caption-text {
				color: $panelHeaderTextColor;
				margin: 0;
			}

			.p-lightbox-close {
				padding: 0;
				color: $panelHeaderIconColor;
				@include transition(color $transitionDuration);

				&:hover {
					color: $panelHeaderIconHoverColor;
				}
			}
		}

		.p-lightbox-content-wrapper {
			overflow: hidden;
			background-color: $panelContentBgColor;
			color: $panelContentTextColor;
			border: 0 none;
			padding: 0;
			@include border-radius(0);

			.p-lightbox-nav-left,
			.p-lightbox-nav-right {
				@include transition(all $transitionDuration);

				font-size: $lightBoxNavIconFontSize;
				color: $lightBoxNavIconColor;
				margin-top: -.5em;

				&:hover {
					@include scale(1.2);
				}
			}

			.p-lightbox-content.p-lightbox-loading ~ a {
				display: none;
			}
		}
	}
}
