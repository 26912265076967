@mixin ui-inline-spinner {
	.common-ui-inline-spinner {
		margin: $stamps-ui-inline-spinner-margin;
		height: 44px;

		.spinner-element {
			height: 44px;

			.circle {
				stroke: $stamps-ui-inline-spinner-color;
				fill: $stamps-ui-inline-spinner-color;
			}
		}
	}
}
