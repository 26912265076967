@mixin ui-media-carousel {

	// **Important**:
	// width and height needs to be provided in the caller
	// based on the carousel images dimensions.

	.common-ui-media-carousel {
		width: 100%;
		height: 100%;
		display: flex;

		.p-carousel {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		.p-carousel-content,
		.p-carousel-container,
		.p-carousel-items-content,
		.p-carousel-items-container {
			padding: 0;
			flex: 1;
			display: flex;
		}

		.p-carousel-content {
			.p-carousel-container {
				position: relative;

				.p-link {
					width: 40px; // override 2.5em
					height: 40px; // override 2.5em
					color: #fff;
					background-color: #222;
					opacity: .3;
					display: none;
					border-radius: 0;

					.pi {
						font-size: 20px;
					}

					&.p-carousel-prev {
						position: absolute;
						left: 0;
						top: calc(50% - 20px); // to vertically centered
					}

					&.p-carousel-next {
						position: absolute;
						right: 0;
						top: calc(50% - 20px); // to vertically centered
					}

					&:hover {
						opacity: .5;
					}
				}

				.p-carousel-item {
					position: absolute;
					top: 0;
					left: 0;

					&.p-carousel-item-active {
						opacity: 1;
					}

					&.p-carousel-item-active-prev {
						opacity: 0;
						transition: opacity 1s ease-in-out;
					}

					&.p-carousel-item-hidden {
						display: none;
					}
				}

				&:hover {
					.p-link {
						display: block;
					}
				}
			}
		}
	}
}
