@mixin carrier-add-fedex-account {
	.common-carrier-add-fedex-account {
		height: 100%;

		.common-carrier-add-carrier-header {
			&.mobile {
				.title {
					padding-top: 12px;
				}
			}
		}

		.common-ui-layout-section {
			.form-container {
				margin: 5px 0;

				.common-ui-element-wrapper {
					&.mobile-wrapper {
						flex-direction: column;
						align-items: normal;
					}
				}

				.row-wrapper {
					display: flex;
					padding: 0;

					.state-input {
						.label-container {
							min-width: 120px;
						}
					}

					.zip-input {
						.label-container {
							width: 50px;
						}
					}

					.input-container {
						.common-ui-input-text {
							flex: 1 0 0;
						}
					}

					&.mobile {
						flex-direction: column;

						.label-container {
							width: 120px;
						}
					}
				}
			}

			.fedex-footer {
				&.mobile {
					flex-direction: column-reverse;

					.content-container {
						text-align: center;
					}
				}
			}
		}
	}
}
