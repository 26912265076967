@mixin account-regions {
	.account-regions {
		height: 100%;
		text-align: left;

		.common-ui-layout-section {
			.section-body {
				padding: 0;
			}
		}

		&.modal {
			height: auto;

			p {
				max-width: 450px;
			}

			.common-ui-element-wrapper {
				padding: 15px 15px 0 0;
			}
		}
	}
}
