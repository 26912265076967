@mixin inbound-details-confirm-inbound {
	.confirm-inbound-modal {
		width: 600px;
		height: 600px;

		.p-dialog-content {
			.modal-content {
				display: flex;
				flex-direction: column;

				.inbound-details-list {
					display: flex;
					flex-direction: column;

					.sub-header {
						color: $stamps-inbound-details-header-text-color;
						font-weight: bold;
					}

					.confirm-modal-label {
						text-align: end;
						font-weight: bold;
					}

					.warehouse-details,
					.product-list,
					.shipfrom,
					.tracking {
						white-space: pre-line;
					}
				}
			}
		}
	}
}
