@mixin account-packing-slips {
	.account-packing-slips-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;

		.settings-content {
			height: 100%;
			width: 100% !important; // override common settings styling
		}
	}
}
