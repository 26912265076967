@mixin account-refundeligibilityreport {
	.account-refundeligibilityreport {
		display: flex;
		flex-direction: column;
		height: 95vh;
		margin-top: 50px;
		padding: 15px;
		text-align: left;
		width: 100vw;

		h1 {
			font-size: 16px;
			font-weight: bold;
			margin: 0 0 16px 0;
		}

		.bottom-section {
			flex-grow: 0;
			flex-shrink: 0;
			margin-top: 10px;

			.update-button {
				margin-top: 10px;
			}
		}

		.common-ui-layout-section {
			flex-grow: 1;
			flex-shrink: 1;
			min-height: 0;

			.section-body {
				padding: 0;
			}
		}

		.confirmation-message {
			color: #008021;
			margin-top: 1em;
		}

		.label-status {
			align-items: center;
			display: flex;
			flex-wrap: nowrap;
			margin-top: -5px;

			&:first-child label {
				margin-right: 12px;
			}
		}
	}
}
