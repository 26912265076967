@mixin global {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: $stamps-font-family;
	font-size: $stamps-font-size;

	p {
		line-height: $stamps-line-height;
	}

	b {
		font-weight: bold;
	}

	a {
		color: $stamps-link-color;
		text-decoration: underline;
		cursor: pointer;

		&:hover {
			color: $stamps-link-hover-color;
			text-decoration: $stamps-link-hover-text-decoration;
		}

		&:focus {
			border-radius: $stamps-link-focus-border-radius;
			outline: $stamps-link-focus-outline;
			text-decoration: $stamps-link-focus-text-decoration;
		}

		&:active {
			text-decoration: $stamps-link-hover-text-decoration;
			color: $stamps-link-active-color;
			outline: $stamps-link-active-outline;
		}
	}

	ul {
		list-style: disc;
		list-style-position: inside;
		margin: initial;
	}

	ol {
		list-style-type: decimal;
		padding-left: 45px;
	}

	li {
		display: list-item;
	}

	i {
		font-style: italic;
	}

	input {
		display: block;
	}

	sup {
		vertical-align: super;
		font-size: smaller;
	}

	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: $stamps-modal-footer-border-top;
		margin: 0 15px;
		padding: 0;
	}

	strong {
		font-weight: bold;
	}

	.p-inputtext {
		width: 100%;
		line-height: $stamps-line-height;
	}

	.p-multiselect {
		width: 100%;
		line-height: $stamps-line-height;
	}

	.primary-header {
		color: $stamps-primary-header-color;
		font-size: $stamps-primary-header-font-size;
		line-height: $stamps-primary-header-line-height;
	}

	.secondary-header {
		color: $stamps-primary-header-color;
		font-size: 17px;
	}

	#printContent {
		display: none;
	}

	.text-secondary {
		color: $stamps-text-secondary-color;
	}

	.common-ui-link {
		.external-link-icon {
			position: relative;
			display: inline-block;
			background: background-image-url('external-link-icon.svg') no-repeat;
			background-size: 13px;
			width: 15px;
			height: 15px;
			vertical-align: bottom;

			&:after {
				content: "\00A0";
				position: absolute;
				text-decoration: underline;
				right: 0;
				left: 0;
				bottom: 0;
				top: 1px;
				letter-spacing: 11px;
			}
		}
	}

	@media print {
		.hide-for-printing {
			display: none;
		}

		#printContent {
			display: block;
		}
	}
}
