@mixin carrier-add-carrier-header {
	.common-carrier-add-carrier-header {
		display: flex;
		padding: 0 15px;

		.ups-logo {
			background: background-image-url($stamps-settings-manage-carrier-add-carrier-ups-logo) no-repeat center;
			width: 46px;
			height: 56px;
			margin-right: 15px;
			margin-top: 15px;
			background-size: $stamps-settings-manage-carrier-add-carrier-header-ups-bg-size;
		}

		.dhl-express-logo {
			background: background-image-url($stamps-settings-manage-carrier-add-carrier-dhl-logo) no-repeat center;
			width: 110px;
			height: 41px;
			margin-right: 15px;
			margin-top: $stamps-settings-manage-carrier-add-carrier-header-dhl-logo-margin-top;
		}

		.fedex-logo {
			background: background-image-url($stamps-settings-manage-carrier-add-carrier-fedex-logo) no-repeat center;
			width: 101px;
			height: 49px;
			margin-right: 15px;
			margin-top: 15px;
		}

		.title {
			flex: 1;
		}

		// to override the global style rule
		.primary-header {
			padding: 15px 0;
			font-size: $stamps-settings-manage-carrier-add-carrier-header-font-size;
			font-weight: $stamps-settings-manage-carrier-add-carrier-header-font-weight;
		}

		&.edit-view-header {
			.primary-header {
				padding-top: 22px;
			}

			.account-number {
				font-weight: bold;
				padding: 30px 0;
			}
		}
	}
}
