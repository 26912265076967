@import 'rateAdvisor/additional-options.scss';
@import 'rateAdvisor/filter.scss';
@import 'rateAdvisor/header.scss';
@import 'rateAdvisor/package-filter.scss';
@import 'rateAdvisor/service-options-included.scss';
@import 'rateAdvisor/services.scss';
@import 'rateAdvisor/shipping-inputs.scss';
@import 'rateAdvisor/slider-filters.scss';
@import 'rateAdvisor/validation-error.scss';

@mixin rate-advisor() {
	.common-rate-advisor {
		height: 100%;
		width: 100%;
		position: relative;
		overflow-x: auto;
		min-width: 860px;
		color: $stamps-rate-advisor-font-color;

		.common-rate-advisor-center-content {
			display: flex;
			height: 100%;
			width: 100%;

			.options-item {
				font-size: 12px;
				padding: 0 0 0 12px;
				display: flex;
				align-items: center;

				.pi-chevron-right,
				.pi-chevron-down {
					font-size: 12px;
					line-height: 20px;
				}

				// remove outline style for all elements
				*:focus {
					outline: none;
				}

				.label-container {
					margin-top: 4px;
				}
			}

			.common-ui-element-wrapper {
				.input-container {
					margin-bottom: 0;
				}

				.label-container {
					width: auto; // override the width rule in ui-element-wrapper mixin
					margin-bottom: 0;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: left;
				}
			}

			// override common style rules for spinner mask
			.common-ui-spinner-mask {
				top: 40px;
				height: calc(100% - 40px);
			}
		}

		&.media-mail-selection-modal {
			width: 600px;
			min-width: 600px;
			height: auto;

			.body {
				background: background-image-url('exclamation-icon.png') no-repeat left;
				background-size: 37px;
				padding-left: 52px;
				background-position: left top;
				min-height: 36px;

				p {
					margin-bottom: 10px;
				}

				.show-again {
					padding-top: 15px;

					.p-checkbox {
						width: 12px;
						height: 12px;
						margin-top: 4px;

						.p-checkbox-box {
							width: 12px;
							height: 12px;

							.p-checkbox-icon {
								font-size: 10px;
								color: #1c9eff;
							}
						}
					}
				}
			}

			.p-dialog-footer {
				padding-left: 67px !important;
			}
		}
	}

	.common-rate-advisor-tootip {
		display: block;

		&.p-tooltip {
			min-width: 250px; // to accommodate some long service names

			.p-tooltip-text {
				box-shadow: -10px 10px 10px -10px #0a0a0a !important;
				border: 1px solid #0a0a0a !important;
				background-color: white;
				font-size: 9pt;
				color: #0a0a0a;
				padding: 10px 15px;

				.title {
					font-weight: bold;
					font-size: 9pt;
					margin-bottom: 5px;
				}

				.service-option-item {
					margin-bottom: 5px;
				}
			}
		}
	}

	@include rate-advisor-additional-options();
	@include rate-advisor-filter();
	@include rate-advisor-header();
	@include rate-advisor-package-filter ();
	@include rate-advisor-service-options-included();
	@include rate-advisor-services();
	@include rate-advisor-shipping-inputs();
	@include rate-advisor-slider-filters();
	@include rate-advisor-validation-error();
}
