@mixin inbound-details-product-details {
	.common-inbound-details-product-details {
		display: flex;
		flex-direction: column;
		margin: 15px 0 15px 0;

		.product-details-header {
			color: $stamps-inbound-details-header-text-color;
			font-weight: bold;
			margin: 0 0 15px 0;
		}

		.product-details-content {
			background-color: $stamps-details-background-color;
			padding: 0 0 20px 20px;
			display: flex;
			flex-direction: column;

			.p-lg-2 {
				width: 12% !important;

				.common-ui-input-number {
					width: 200px;
				}
			}

			.sub-header {
				border-bottom: 1px solid $stamps-inbound-details-border;
				color: $stamps-inbound-details-sub-header-text-color;
				font-weight: bold;
				margin-top: 20px;
				padding-left: .5em;

				.p-lg-2,
				.p-lg-3,
				.p-lg-4,
				.p-lg-5 {
					padding-left: 0;
				}
			}

			.add-product {
				.p-button-label {
					font-weight: bold;
					color: #023a89;
				}

				.add-icon {
					background: background-image-url($stamps-inbound-add-icon) no-repeat center;
					width: 20px !important;
					height: 20px !important;
					left: 0 !important;
					background-position: 0 !important;
					margin-bottom: 5px;
				}
			}

			.delete-product {
				padding-left: 24px;
				background-color: transparent;
				border-color: transparent;
				margin: auto;

				.delete-icon {
					background: background-image-url($stamps-icon-button-delete) no-repeat center;
					background-size: 26px;
					display: block;
					background-color: transparent;
					border-color: transparent;
					margin-bottom: 4px;
				}
			}

			.label-container label {
				color: $stamps-inbound-details-sub-header-text-color;
				font-weight: bold;
			}
		}

		.common-ui-element-wrapper {
			padding: 7px 7px 7px 0;

			.input-container {
				width: 100%;
				display: inline-flex;
			}

			&.barcode {
				.common-ui-dropdown {
					max-width: 160px;
				}
			}
		}

		&.mobile-wrapper {
			.product-details-content,
			.product-item {
				border-bottom: 1px solid $stamps-inbound-details-border;
			}

			.add-product {
				margin-top: 15px;
				align-self: center;
			}

			.delete-product {
				padding-right: 0;
			}

			.quantity .common-ui-input-number {
				width: 80px;
			}

			.barcode .common-ui-dropdown {
				width: 150px;
			}

			.label-container {
				width: 90px;
				text-align: right;
			}
		}
	}
}
