@mixin account-change-password {
	.account-change-password-container {
		height: 100%;
		text-align: left;
		padding: $stamps-account-content-padding;

		.password-form {
			max-width: 640px;

			.common-ui-element-wrapper {
				.label-container {
					width: 170px;
				}

				&.mobile-wrapper {
					.input-container {
						flex: 1;
						width: auto;
					}
				}
			}

			.requirements-container {
				display: flex;
				padding: 5px 0 0 0;

				.requirements {
					width: 170px;
					margin: 0 10px 5px 0;
				}

				li {
					line-height: $stamps-line-height;
				}

				&.mobile-wrapper {
					flex-direction: column;
				}
			}

			.save-password-button {
				margin: 25px 0 15px 180px;

				&.mobile-wrapper {
					padding-left: 0;
				}
			}
		}
	}
}
