@mixin account-manage-subscription-plan {
	.account-manage-subscription-plan {
		height: 100%;
		text-align: left;
		padding: $stamps-account-content-padding;

		.select-plan, .cancel-account {
			.ui-button-secondary:enabled {
				color: $stamps-account-subscription-plan-button-color;
				background-color: $stamps-account-subscription-plan-button-background-color;
				border: 1px solid $stamps-account-subscription-plan-button-border-color;
			}
		}

		.select-plan, .cancel-account {
			align-self: end;

			.ui-button-secondary:enabled:hover {
				color: $stamps-account-subscription-plan-button-hover-color !important;
				background-color: $stamps-account-subscription-plan-button-hover-background-color !important;
				border: 1px solid $stamps-account-subscription-plan-button-hover-border-color !important;
			}
		}

		.common-ui-layout-section {
			.section-body {
				flex: 0 1 auto;
				padding: $stamps-account-plan-padding;

				.plan-tile {
					max-width: 500px;
					background-color: #fff;
					border: 1px solid #ccc;
					border-radius: 5px;
					margin: 10px 0 0 0;
					display: flex;
					flex-direction: column;
					padding: 16px;

					.plan-info {
						padding: 15px;
						display: flex;
						flex-direction: column;

						.plan-details {
							display: flex;

							.plan-name {
								font-weight: bold;
								font-size: 16px;

							}
							.plan-cost {
								flex: 1;
								text-align: right;
								font-weight: bold;

								.plan-mo {
									color: $stamps-modal-body-cost-text-color;
									font-size: $stamps-account-subscription-plan-font-size;
									font-weight: $stamps-account-subscription-plan-font-weight;
								}
							}

							.plan-name-tag {
								color: $primereact-textSecondaryColor;
							}
						}

						.plan-content {
							.plan-sub-header {
								margin: 10px 0px 10px 0px;
								font-weight: bold;
							}

							.plan-sub-header{
								.plan-list {
									padding: 0 0 15px 0;
								}
							}

							.plan-list {
								padding-bottom: 10px;
								.list-item {
									display: flex;
									.icon {
										background: background-image-url('green-check-icon.png') no-repeat;
										height: 24px;
										width: 24px;
										background-position-y: center
									}
								}
							}
						}

						.plan-inprogress {
							text-align: right;
							color: $primereact-textSecondaryColor;
						}
					}

					&.selected {
						border: 1px solid $stamps-account-subscription-plan-button-hover-border-color;

						.current-plan-title {
							background-color: $stamps-account-subscription-plan-background-color;
							color: #fff;
							border-radius: $stamps-account-subscription-plan-border-radius;
							font-size: $stamps-account-subscription-plan-font-size;
							padding: $stamps-account-subscription-plan-padding;
							width: $stamps-account-subscription-plan-width;
						}
					}

					&:hover {
						border: 1px solid $stamps-account-subscription-plan-button-hover-border-color;
						.select-plan,
						.cancel-account {
							.ui-button-secondary {
								color: $stamps-account-subscription-plan-button-hover-color;
								background-color: $stamps-account-subscription-plan-button-hover-background-color;
								border: 1px solid $stamps-account-subscription-plan-button-hover-border-color;
							}
						}
					}

				}

				.subscription-info-text {
					padding: 15px 0px;
				}

				.cancel-subscription {
					.title {
						font-weight: bold;
						padding: 10px 0px;
					}
				}

				.info-banner-container {
					max-width: 500px;
				}
			}
		}
	}
}

