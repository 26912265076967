@mixin ui-input-number() {
	.common-ui-input-number {
		.p-inputnumber {

			// This is added here so a parent like WeightField can give a specific width
			// to its child InputNumber component(s) such as:
			// .common-ui-weight-field .common-ui-input-number { width: 200px; }
			width: 100%;

			.p-inputnumber-input {
				&.p-inputtext {
					border: 1px solid $stamps-ui-input-border-color;
					border-right: none;

					&:enabled:hover {
						border-color: $stamps-ui-input-border-color;
					}

					&:enabled:focus {
						box-shadow: $primereact-focusShadow;
						border-color: $stamps-ui-input-border-focus-color;
					}
				}
			}

			.p-inputnumber-button {
				background-color: $stamps-ui-input-background-color;
				border: 1px solid $stamps-ui-input-border-color;
				border-left: 0;
				color: $stamps-ui-input-number-icon-color;

				.pi {
					font-size: $stamps-ui-input-number-icon-font-size;
					font-weight: $stamps-ui-input-number-icon-weight;
					padding: 1px 0;
				}

				&:enabled:hover {
					background-color: #efefef;
					color: $stamps-icon-button-text-color;
				}

				&:enabled:active {
					background-color: #efefef;
					color: $stamps-icon-button-text-color;
				}
			}

			.p-inputnumber-button-up {
				border-bottom: 0;
				align-items: end;
			}

			.p-inputnumber-button-down {
				border-top: 0;
				align-items: start;
			}

			&.p-inputwrapper-focus {
				.p-inputnumber-button { /* stylelint-disable-line */
					border-color: $stamps-ui-input-border-focus-color;
				}
			}

			&.invalid-component {
				.p-inputnumber-input {
					border-color: $stamps-ui-input-border-invalid-color !important;

					&:enabled:hover {
						border-color: $stamps-ui-input-border-invalid-color !important;
					}

					&:enabled:focus {
						border-color: $stamps-ui-input-border-invalid-color !important;
					}
				}

				.p-inputnumber-button { /* stylelint-disable-line */
					border-color: $stamps-ui-input-border-invalid-color;
				}
			}
		}
	}
}
