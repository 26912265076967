@mixin layout {
	.common-layout {
		padding: 0;
		display: block;
		box-sizing: border-box;

		&.viewport {
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;

			&.should-scroll {
				overflow: auto;
				height: inherit;
			}
		}

		// .west-collapsible-panel
		@include layout-collapsible-panel(west, (
			header-width: $stamps-collapsible-panel-header-width,
			header-text-color: $stamps-collapsible-panel-west-header-text-color,
			background-color: $stamps-collapsible-panel-west-bg-color,
			border-right: $stamps-collapsible-panel-west-border-width solid $stamps-collapsible-panel-west-border-color,
		));

		// .east-collapsible-panel
		@include layout-collapsible-panel(east, (
			header-width: $stamps-collapsible-panel-header-width,
			header-text-color: $stamps-collapsible-panel-east-header-text-color,
			background-color: $stamps-collapsible-panel-east-bg-color,
			border-left: 2px solid $stamps-collapsible-panel-east-border-color,
		));

		@include layout-collapsible-panel-win-client();

		&.top-panel {
			background-color: $stamps-top-panel-background-color;
			border: $stamps-top-panel-border;
		}

		&.center-panel {
			background-color: $stamps-center-panel-background-color;
			text-align: center;
			overflow: auto;
		}
	}
}

@mixin layout-collapsible-panel($panel-position, $panel-params) {
	&.#{$panel-position}-collapsible-panel {
		background-color: map-get($panel-params, 'background-color');
		border-left: map-get($panel-params, 'border-left');
		border-right: map-get($panel-params, 'border-right');

		.collapsible-panel-header {
			left: 0;
			top: 0;
			width: map-get($panel-params, 'header-width');
			height: 100%;
			display: block;

			.collapsible-panel-header-title-wrapper {
				position: absolute;
				top: 50%;
				left: 50%;
				white-space: nowrap;
				transform: translateX(-50%) translateY(-50%) rotate(90deg);
				color: map-get($panel-params, 'header-text-color');
				display: block;
			}

			.collapsible-panel-header-title {
				line-height: 29px;
				user-select: none;
				font-weight: 700;
			}

			.collapse-toggle-button {
				margin: 0 6px 0 0;
				vertical-align: bottom;

				.collapsible-panel-toggle-icon {
					@if ($panel-position == 'west') {
						transform: rotate(-90deg);
						background-position: 0 0;
					} @else if ($panel-position == 'east') {
						transform: rotate(90deg);
						background-position: -24px 0;
					}
				}
			}
		}

		.collapsible-panel-body {
			display: flex;
			flex-direction: column;
			height: 100%;

			.common-menu {
				flex: 1;
			}

			@if ($panel-position == 'west') {
				.collapse-toggle-button {
					padding: 0 $stamps-collapsible-panel-west-toggle-padding-right 0 0;
					display: flex;
					justify-content: flex-end;
					border-top: 1px solid $stamps-collapsible-panel-west-border-color;
					height: 44px;
					align-items: center;

					.collapsible-panel-toggle-icon {
						transform: rotate(180deg);
						background-position: 0 -24px;
					}
				}
			} @else if ($panel-position == 'east') {
				.collapse-toggle-button {
					float: left;
					margin: 6px 0 0 15px;

					.collapsible-panel-toggle-icon {
						transform: rotate(180deg);
						background-position: -24px -24px;
					}
				}
			}
		}

		&.expanded {
			overflow-y: auto;
		}

		&.expanded .collapsible-panel-header {
			display: none;
		}

		&.collapsed .collapsible-panel-body {
			display: none;
		}
	}

	&.#{$panel-position}-collapsible-panel-splitter {
		cursor: col-resize;
		background-color: transparent;
		display: block;

		&.splitter-mask {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 998;
		}

		&.splitter {
			background-color: map-get($panel-params, 'background-color');
			z-index: 999;
			opacity: 0;

			&.hover {
				background-color: darken(map-get($panel-params, 'background-color'), 10%);
			}

			&.active {
				opacity: .6;
			}
		}
	}
}

// override rules of .west-collapsible-panel for win client
@mixin layout-collapsible-panel-win-client {
	// local variables for this mixin
	$bg-color: #fff;
	$bg-color-dark: #eff2f9;
	$border-color-light: #eee;
	$border-color-darker: #ccc;
	$border-color-darkest: #a2a2a2;
	$text-color: #525a5f;

	&.win-client {
		&.west-collapsible-panel {
			background-color: $bg-color;

			.collapsible-panel-header {
				border-right: 1px solid $border-color-darker;

				.collapsible-panel-header-title-wrapper {
					color: $text-color;
				}

				.collapse-toggle-button {
					.collapsible-panel-toggle-icon {
						background-position: -72px 0;
					}
				}
			}

			.collapsible-panel-body {
				.collapse-toggle-button {
					.collapsible-panel-toggle-icon {
						background-position: -72px -24px;
					}
				}
			}
		}

		&.west-collapsible-panel-splitter {
			&.splitter {
				background-color: $border-color-light;
				opacity: 1;

				&.active {
					background-color: $border-color-darkest;
					opacity: .5;
				}
			}
		}

		// override common-menu style rules
		.common-menu {
			.menu-item-text {
				color: $text-color;
			}

			.tools-wrapper {
				.badge {
					color: $text-color;
					background-color: $bg-color-dark;
				}

				.tool {
					color: $text-color;
				}
			}

			.search-item {
				.search-input {
					background-color: $bg-color;

					.search-input-wrapper {
						border: 1px solid $border-color-darkest;
					}
				}
			}

			.badge-item {
				background-color: $bg-color;
				color: $stamps-menu-text-color;

				&.active,
				&:hover {
					background-color: $bg-color-dark;

					.tools-wrapper {
						.badge {
							background-color: $bg-color;
						}
					}
				}
			}

			.group-item {
				.group-item-titlebar {
					border-bottom: 1px solid $border-color-light;
					border-left: 6px solid $bg-color;
					background-color: $bg-color;
				}

				.group-item-content {
					border-bottom: 1px solid $border-color-light;
				}

				&.active {
					.group-item-titlebar {
						border-left: 6px solid $border-color-darkest;
					}
				}
			}
		}
	}
}
