@mixin inline-table-configuration($count, $first-col-size: 1.4fr, $other-col-size: 1fr) {
	.inline-table.columns-#{$count} {
		grid-template-columns: #{$first-col-size} repeat(#{$count - 1}, #{$other-col-size});
	}
}

@mixin ui-inline-table {
	.inline-table {
		display: grid;
		border-radius: $stamps-ui-inline-table-border-radius;
		border: $stamps-ui-inline-table-border-width solid $stamps-ui-inline-table-border-color;

		.header-cell {
			display: flex;
			align-items: center;
			align-self: stretch;
			padding: 0 $stamps-ui-inline-table-padding;
			background: $stamps-ui-inline-table-header-cell-background-color;
			min-height: $stamps-ui-inline-table-min-height;
			border-bottom: $stamps-ui-inline-table-border-width solid $stamps-ui-inline-table-border-color;
		}

		.row-cell {
			display: flex;
			align-items: center;
			align-self: stretch;
			padding: 0 $stamps-ui-inline-table-padding;
			border-bottom: $stamps-ui-inline-table-border-width solid $stamps-ui-inline-table-border-color;
			background: $stamps-ui-inline-table-background-color;
			min-height: $stamps-ui-inline-table-min-height;
			font-weight: $stamps-ui-inline-table-row-cell-font-weight;
		}

		.footer-cell {
			@extend .header-cell;
			border-bottom: none;
		}
	}

	// Generate multiple configurations for the table columns
	@for $i from 1 through 10 {
		@include inline-table-configuration($i);
	}
}
