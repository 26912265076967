@mixin marketplaces() {
	$common-marketplace-list:
		('shipstation' 0),
		('amazonca' 2),
		('amazonuk' 2),
		('amazonfr' 2),
		('amazones' 2),
		('amazonde' 2),
		('amazonmx' 2),
		('amazonit' 2),
		('amazonau' 2),
		('amazonjp' 2),
		('amazon' 2),
		('bigcartel' 4),
		('bigcommerce' 5),
		('channeladvisor' 10),
		('cscart' 14),
		('3dcart' 16),
		('ebay' 18),
		('ebayca' 18),
		('ebayuk' 18),
		('ebayau' 18),
		('etsy' 21),
		('magento' 32),
		('opencart' 39),
		('paypal' 42),
		('prestashop' 45),
		('rakuten' 46),
		('sears' 49),
		('shopify' 53),
		('square' 61),
		('squarespace' 61),
		('volusion' 72),
		('woocommerce' 74),
		('x-cart' 75),
		('yahoo' 76),
		('zencart' 78);

	.common-marketplaces {
		@each $marketplace in $common-marketplace-list {
			$marketplace-name: nth($marketplace, 1);
			$marketplace-icon-index: nth($marketplace, 2);

			&.marketplace-icon-#{$marketplace-name} {
				background: background-image-url('marketplaces/marketplace-sprite.png') no-repeat top left;
				background-position: (16px * ($marketplace-icon-index * -1)) 0;
				height: 19px;
				width: 16px;
				display: inline-block;
				margin-right: 5px;
				margin-top: 1px; // to make the overall height 20px
			}
		}
	}
}
