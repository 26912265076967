@mixin ui-tabview {
	.common-ui-tabview {
		width: 100%;
		height: 100%;
		display: flex;

		.p-tabview {
			flex: 1;
			padding: 0;
			display: flex;
			flex-direction: column;

			.p-tabview-nav-container {
				.p-tabview-nav {
					background: #fff;
					border: 1px solid #dee2e6;
					border-width: 0 0 1px 0;

					li {
						.p-tabview-nav-link {
							border: solid #dee2e6;
							border-width: 0 0 4px 0;
							border-color: transparent transparent #dee2e6 transparent;
							background: #fff;
							color: $stamps-ui-tabview-text-color;
							padding: 15px 30px;
							transition: background-color .2s, border-color .2s, box-shadow .2s;
							margin: 0 0 -4px 0;
						}

						&.p-highlight {
							.p-tabview-nav-link {
								background: $stamps-ui-tabview-active-background-color;
								border-color: $primereact-panelHeaderActiveTextColor;
								color: $stamps-ui-tabview-active-text-color;
								font-weight: 700;
							}
						}
					}

					li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
						background: $stamps-ui-tabview-active-background-color;
						border-color: $stamps-ui-tabview-hover-border-color;
						color: $stamps-ui-tabview-active-text-color;
						font-weight: $stamps-ui-tabview-hover-font-weight;
					}

					.p-tabview-ink-bar {
						z-index: 1;
						display: block;
						position: absolute;
						bottom: 0;
						height: 4px;
						background-color: $stamps-ui-tabview-active-border-color;
						transition: $stamps-ui-tabview-transition;
					}
				}
			}

			.p-tabview-panels {
				flex: 1;
				overflow: auto;
			}
		}
	}
}
