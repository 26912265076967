@mixin carrier-add-ups-account {
	.common-carrier-add-ups-account {
		height: 100%;

		.common-ui-layout-section {
			.section-body {
				display: flex;
				flex-direction: column;
				overflow-x: hidden;

				.subtitle-mobile {
					padding: 0 0 15px 0;
				}

				.footertext-mobile {
					padding: 15px 0 0 0;
					margin-top: auto;
				}

				.radio-container {
					margin: 0 auto;
					padding-left: 15px;
				}

				.note-container {
					max-width: 70%;
    				word-wrap: break-word;
					position: relative;
					left: calc(50% - 300px);
					color: #50575C;
				}

				.auth-ups-container {
					margin: 0 auto;
					padding: 16px;

					.title {
						font-weight: bold;
					}

					.content {
						padding: 16px 0px;
					}

					.label-container {
						width: 240px;
					}

					.common-ui-button {
						padding-top: 10px;
					}
				}
			}
		}
	}
}
