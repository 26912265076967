//main color scheme
$primaryColor: $primereact-primaryColor;
$primaryDarkColor: $primereact-primaryDarkColor;
$primaryDarkerColor: $primereact-primaryDarkerColor;
$primaryTextColor: $primereact-primaryTextColor;

//Global
$fontFamily: $primereact-fontFamily;
$fontSize: $primereact-fontSize;
$textColor: $primereact-textColor;
$textSecondaryColor: $primereact-textSecondaryColor;
$borderRadius: $primereact-borderRadius;
$transitionDuration: $primereact-transitionDuration;
$primeIconFontSize: $primereact-primeIconFontSize;

//anchors
$linkColor: $primereact-linkColor;
$linkHoverColor: $primereact-linkHoverColor;
$linkActiveColor: $primereact-linkActiveColor;

//highlight
$highlightBgColor: $primereact-highlightBgColor;
$highlightColorText: $primereact-highlightColorText;

//focus
$focusOutline: $primereact-focusOutline;
$focusOutlineOffset: $primereact-focusOutlineOffset;
$focusShadow: $primereact-focusShadow;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: $primereact-inputPadding;
$inputBgColor: $primereact-inputBgColor;
$inputBorder: $primereact-inputBorder;
$inputHoverBorderColor: $primereact-inputHoverBorderColor;
$inputHoverForegroundColor: $primereact-inputHoverForegroundColor;
$inputFocusBorderColor: $primereact-inputFocusBorderColor;
$inputErrorBorder: $primereact-inputErrorBorder;
$inputPlaceholderTextColor: $primereact-inputPlaceholderTextColor;
$inputTransition: $primereact-inputTransition;
$inputDisabledBgColor: $primereact-inputDisabledBgColor;
$inputDisabledTextColor: $primereact-inputDisabledTextColor;

//input groups
$inputGroupBorderColor: $primereact-inputGroupBorderColor;
$inputGroupBgColor: $primereact-inputGroupBgColor;
$inputGroupTextColor: $primereact-inputGroupTextColor;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListMinWidth: $primereact-inputListMinWidth;
$inputListBgColor: $primereact-inputListBgColor;
$inputListPadding: $primereact-inputListPadding;
$inputListItemPadding: $primereact-inputListItemPadding;
$inputListItemBgColor: $primereact-inputListItemBgColor;
$inputListItemTextColor: $primereact-inputListItemTextColor;
$inputListItemHoverBgColor: $primereact-inputListItemHoverBgColor;
$inputListItemHoverTextColor: $primereact-inputListItemHoverTextColor;
$inputListItemHighlightBgColor: $primereact-inputListItemHighlightBgColor;
$inputListItemHighlightTextColor: $primereact-inputListItemHighlightTextColor;
$inputListItemBorder: $primereact-inputListItemBorder;
$inputListItemMargin: $primereact-inputListItemMargin;
$inputListItemFocusShadow: $primereact-inputListItemFocusShadow;
$inputListItemTransition: $primereact-inputListItemTransition;
$inputListItemDividerColor: $primereact-inputListItemDividerColor;
$inputListHeaderPaddingTop: $primereact-inputListHeaderPaddingTop;
$inputListHeaderPaddingLeft: $primereact-inputListHeaderPaddingLeft;
$inputListHeaderPaddingRight: $primereact-inputListHeaderPaddingRight;
$inputListHeaderPaddingBottom: $primereact-inputListHeaderPaddingBottom;
$inputListHeaderMargin: $primereact-inputListHeaderMargin;
$inputListHeaderBgColor: $primereact-inputListHeaderBgColor;
$inputListHeaderTextColor: $primereact-inputListHeaderTextColor;
$inputListHeaderBorder: $primereact-inputListHeaderBorder;
$inputListHeaderSearchIconColor: $primereact-inputListHeaderSearchIconColor;
$inputListHeaderCloseIconColor: $primereact-inputListHeaderCloseIconColor;
$inputListHeaderCloseIconHoverColor: $primereact-inputListHeaderCloseIconHoverColor;
$inputListHeaderCloseIconTransition: $primereact-inputListHeaderCloseIconTransition;

//inputs with panels (e.g. password)
$inputContentPanelPadding: $primereact-inputContentPanelPadding;
$inputContentPanelBgColor: $primereact-inputContentPanelBgColor;
$inputContentPanelTextColor: $primereact-inputContentPanelTextColor;

//static positioned input lists like listbox
$inputListBorder: $primereact-inputListBorder;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBorder: $primereact-inputOverlayBorder;
$inputOverlayShadow: $primereact-inputOverlayShadow;

//input dropdowns (e.g. multiselect, dropdown)
$inputDropdownIconColor: $primereact-inputDropdownIconColor;

//button
$buttonTextOnlyPadding: $primereact-buttonTextOnlyPadding;
$buttonWithLeftIconPadding: $primereact-buttonWithLeftIconPadding;
$buttonWithRightIconPadding: $primereact-buttonWithRightIconPadding;
$buttonIconOnlyPadding: $primereact-buttonIconOnlyPadding;
$buttonIconOnlyWidth: $primereact-buttonIconOnlyWidth;
$buttonBgColor: $primereact-buttonBgColor;
$buttonBorder: $primereact-buttonBorder;
$buttonTextColor: $primereact-buttonTextColor;
$buttonHoverBgColor: $primereact-buttonHoverBgColor;
$buttonHoverTextColor: $primereact-buttonHoverTextColor;
$buttonHoverBorderColor: $primereact-buttonHoverBorderColor;
$buttonActiveBgColor: $primereact-buttonActiveBgColor;
$buttonActiveTextColor: $primereact-buttonActiveTextColor;
$buttonActiveBorderColor: $primereact-buttonActiveBorderColor;
$buttonTransition: $primereact-buttonTransition;
$raisedButtonShadow: $primereact-raisedButtonShadow;
$roundedButtonBorderRadius: $primereact-roundedButtonBorderRadius;

$secondaryButtonBgColor: $primereact-secondaryButtonBgColor;
$secondaryButtonBorder: $primereact-secondaryButtonBorder;
$secondaryButtonTextColor: $primereact-secondaryButtonTextColor;
$secondaryButtonHoverBgColor: $primereact-secondaryButtonHoverBgColor;
$secondaryButtonHoverTextColor: $primereact-secondaryButtonHoverTextColor;
$secondaryButtonHoverBorderColor: $primereact-secondaryButtonHoverBorderColor;
$secondaryButtonActiveBgColor: $primereact-secondaryButtonActiveBgColor;
$secondaryButtonActiveTextColor: $primereact-secondaryButtonActiveTextColor;
$secondaryButtonActiveBorderColor: $primereact-secondaryButtonActiveBorderColor;
$secondaryButtonFocusShadow: $primereact-secondaryButtonFocusShadow;

$infoButtonBgColor: $primereact-infoButtonBgColor;
$infoButtonBorder: $primereact-infoButtonBorder;
$infoButtonTextColor: $primereact-infoButtonTextColor;
$infoButtonHoverBgColor: $primereact-infoButtonHoverBgColor;
$infoButtonHoverTextColor: $primereact-infoButtonHoverTextColor;
$infoButtonHoverBorderColor: $primereact-infoButtonHoverBorderColor;
$infoButtonActiveBgColor: $primereact-infoButtonActiveBgColor;
$infoButtonActiveTextColor: $primereact-infoButtonActiveTextColor;
$infoButtonActiveBorderColor: $primereact-infoButtonActiveBorderColor;
$infoButtonFocusShadow: $primereact-infoButtonFocusShadow;

$successButtonBgColor: $primereact-successButtonBgColor;
$successButtonBorder: $primereact-successButtonBorder;
$successButtonTextColor: $primereact-successButtonTextColor;
$successButtonHoverBgColor: $primereact-successButtonHoverBgColor;
$successButtonHoverTextColor: $primereact-successButtonHoverTextColor;
$successButtonHoverBorderColor: $primereact-successButtonHoverBorderColor;
$successButtonActiveBgColor: $primereact-successButtonActiveBgColor;
$successButtonActiveTextColor: $primereact-successButtonActiveTextColor;
$successButtonActiveBorderColor: $primereact-successButtonActiveBorderColor;
$successButtonFocusShadow: $primereact-successButtonFocusShadow;

$warningButtonBgColor: $primereact-warningButtonBgColor;
$warningButtonBorder: $primereact-warningButtonBorder;
$warningButtonTextColor: $primereact-warningButtonTextColor;
$warningButtonHoverBgColor: $primereact-warningButtonHoverBgColor;
$warningButtonHoverTextColor: $primereact-warningButtonHoverTextColor;
$warningButtonHoverBorderColor: $primereact-warningButtonHoverBorderColor;
$warningButtonActiveBgColor: $primereact-warningButtonActiveBgColor;
$warningButtonActiveTextColor: $primereact-warningButtonActiveTextColor;
$warningButtonActiveBorderColor: $primereact-warningButtonActiveBorderColor;
$warningButtonFocusShadow: $primereact-warningButtonFocusShadow;

$dangerButtonBgColor: $primereact-dangerButtonBgColor;
$dangerButtonBorder: $primereact-dangerButtonBorder;
$dangerButtonTextColor: $primereact-dangerButtonTextColor;
$dangerButtonHoverBgColor: $primereact-dangerButtonHoverBgColor;
$dangerButtonHoverTextColor: $primereact-dangerButtonHoverTextColor;
$dangerButtonHoverBorderColor: $primereact-dangerButtonHoverBorderColor;
$dangerButtonActiveBgColor: $primereact-dangerButtonActiveBgColor;
$dangerButtonActiveTextColor: $primereact-dangerButtonActiveTextColor;
$dangerButtonActiveBorderColor: $primereact-dangerButtonActiveBorderColor;
$dangerButtonFocusShadow: $primereact-dangerButtonFocusShadow;

//checkbox
$checkboxWidth: $primereact-checkboxWidth;
$checkboxHeight: $primereact-checkboxHeight;
$checkboxActiveBorderColor: $primereact-checkboxActiveBorderColor;
$checkboxActiveBgColor: $primereact-checkboxActiveBgColor;
$checkboxActiveTextColor: $primereact-checkboxActiveTextColor;
$checkboxActiveHoverBgColor: $primereact-checkboxActiveHoverBgColor;
$checkboxActiveHoverTextColor: $primereact-checkboxActiveHoverTextColor;
$checkboxActiveHoverBorderColor: $primereact-checkboxActiveHoverBorderColor;
$checkboxTransition: $primereact-checkboxTransition;

//radiobutton
$radiobuttonWidth: $primereact-radiobuttonWidth;
$radiobuttonHeight: $primereact-radiobuttonHeight;
$radiobuttonActiveBorderColor: $primereact-radiobuttonActiveBorderColor;
$radiobuttonActiveBgColor: $primereact-radiobuttonActiveBgColor;
$radiobuttonActiveTextColor: $primereact-radiobuttonActiveTextColor;
$radiobuttonActiveHoverBgColor: $primereact-radiobuttonActiveHoverBgColor;
$radiobuttonActiveHoverTextColor: $primereact-radiobuttonActiveHoverTextColor;
$radiobuttonActiveHoverBorderColor: $primereact-radiobuttonActiveHoverBorderColor;
$radiobuttonTransition: $primereact-radiobuttonTransition;

//togglebutton
$toggleButtonBgColor: $primereact-toggleButtonBgColor;
$toggleButtonBorder: $primereact-toggleButtonBorder;
$toggleButtonTextColor: $primereact-toggleButtonTextColor;
$toggleButtonIconColor: $primereact-toggleButtonIconColor;
$toggleButtonHoverBgColor: $primereact-toggleButtonHoverBgColor;
$toggleButtonHoverBorderColor: $primereact-toggleButtonHoverBorderColor;
$toggleButtonHoverTextColor: $primereact-toggleButtonHoverTextColor;
$toggleButtonHoverIconColor: $primereact-toggleButtonHoverIconColor;
$toggleButtonActiveBgColor: $primereact-toggleButtonActiveBgColor;
$toggleButtonActiveBorderColor: $primereact-toggleButtonActiveBorderColor;
$toggleButtonActiveTextColor: $primereact-toggleButtonActiveTextColor;
$toggleButtonActiveIconColor: $primereact-toggleButtonActiveIconColor;
$toggleButtonActiveHoverBgColor: $primereact-toggleButtonActiveHoverBgColor;
$toggleButtonActiveHoverBorderColor: $primereact-toggleButtonActiveHoverBorderColor;
$toggleButtonActiveHoverTextColor: $primereact-toggleButtonActiveHoverTextColor;
$toggleButtonActiveHoverIconColor: $primereact-toggleButtonActiveHoverIconColor;

//inplace
$inplacePadding: $primereact-inplacePadding;
$inplaceHoverBgColor: $primereact-inplaceHoverBgColor;
$inplaceHoverTextColor: $primereact-inplaceHoverTextColor;
$inplaceTransition: $primereact-inplaceTransition;

//rating
$ratingTransition: $primereact-ratingTransition;
$ratingCancelIconColor: $primereact-ratingCancelIconColor;
$ratingCancelHoverIconColor: $primereact-ratingCancelHoverIconColor;
$ratingIconFontSize: $primereact-ratingIconFontSize;
$ratingStarIconColor: $primereact-ratingStarIconColor;
$ratingStarIconHoverColor: $primereact-ratingStarIconHoverColor;

//slider
$sliderBgColor: $primereact-sliderBgColor;
$sliderBorder: $primereact-sliderBorder;
$sliderHeight: $primereact-sliderHeight;
$sliderWidth: $primereact-sliderWidth;
$sliderHandleWidth: $primereact-sliderHandleWidth;
$sliderHandleHeight: $primereact-sliderHandleHeight;
$sliderHandleBgColor: $primereact-sliderHandleBgColor;
$sliderHandleBorder: $primereact-sliderHandleBorder;
$sliderHandleBorderRadius: $primereact-sliderHandleBorderRadius;
$sliderHandleHoverBorder: $primereact-sliderHandleHoverBorder;
$sliderHandleHoverBgColor: $primereact-sliderHandleHoverBgColor;
$sliderHandleTransition: $primereact-sliderHandleTransition;
$sliderRangeBgColor: $primereact-sliderRangeBgColor;

//calendar
$calendarWidth: $primereact-calendarWidth;
$calendarNavIconBgColor: $primereact-calendarNavIconBgColor;
$calendarNavIconColor: $primereact-calendarNavIconColor;
$calendarNavIconHoverColor: $primereact-calendarNavIconHoverColor;
$calendarNavIconTransition: $primereact-calendarNavIconTransition;
$calendarPadding: $primereact-calendarPadding;
$calendarTableMargin: $primereact-calendarTableMargin;
$calendarHeaderCellPadding: $primereact-calendarHeaderCellPadding;
$calendarHeaderTextColor: $primereact-calendarHeaderTextColor;
$calendarCellDatePadding: $primereact-calendarCellDatePadding;
$calendarCellDateHoverBgColor: $primereact-calendarCellDateHoverBgColor;
$calendarCellDateBorderRadius: $primereact-calendarCellDateBorderRadius;
$calendarCellDateSelectedBgColor: $primereact-calendarCellDateSelectedBgColor;
$calendarCellDateSelectedContainerBgColor: $primereact-calendarCellDateSelectedContainerBgColor;
$calendarCellDateSelectedTextColor: $primereact-calendarCellDateSelectedTextColor;
$calendarCellDateTextColor: $primereact-calendarCellDateTextColor;
$calendarCellDateTodayBgColor: $primereact-calendarCellDateTodayBgColor;
$calendarCellDateTodayTextColor: $primereact-calendarCellDateTodayTextColor;
$calendarCellDateTodayBorder: $primereact-calendarCellDateTodayBorder;
$calendarCellDateTodayBorderHover: $primereact-calendarCellDateTodayBorderHover;
$calendarCellDateTodayBorderRadius: $primereact-calendarCellDateTodayBorderRadius;
$calendarCellDisabledBackgroundColor: $primereact-calendarCellDisabledBackgroundColor;
$calendarCellDisabledTextColor: $primereact-calendarCellDisabledTextColor;
$calendarFontWeight: $primereact-calendarFontWeight;
$calendarTimePickerDivider: $primereact-calendarTimePickerDivider;
$calendarTimePickerPadding: $primereact-calendarTimePickerPadding;
$calendarTimePickerIconColor: $primereact-calendarTimePickerIconColor;
$calendarTimePickerIconFontSize: $primereact-calendarTimePickerIconFontSize;
$calendarTimePickerTimeFontSize: $primereact-calendarTimePickerTimeFontSize;
$calendarTimePickerIconHoverColor: $primereact-calendarTimePickerIconHoverColor;
$calendarTitleFontSize: $primereact-calendarTitleFontSize;
$calendarButtonBarDivider: $primereact-calendarButtonBarDivider;
$calendarButtonTextColor: $primereact-calendarButtonTextColor;
$calendarButtonBgColor: $primereact-calendarButtonBgColor;
$calendarMultipleMonthDivider: $primereact-calendarMultipleMonthDivider;

//spinner
$spinnerButtonWidth: $primereact-spinnerButtonWidth;

//input switch
$inputSwitchWidth: $primereact-inputSwitchWidth;
$inputSwitchHeight: $primereact-inputSwitchHeight;
$inputSwitchBorderRadius: $primereact-inputSwitchBorderRadius;
$inputSwitchTransition: $primereact-inputSwitchTransition;
$inputSwitchSliderOffBgColor: $primereact-inputSwitchSliderOffBgColor;
$inputSwitchHandleOffBgColor: $primereact-inputSwitchHandleOffBgColor;
$inputSwitchSliderOffHoverBgColor: $primereact-inputSwitchSliderOffHoverBgColor;
$inputSwitchSliderOnBgColor: $primereact-inputSwitchSliderOnBgColor;
$inputSwitchSliderOnHoverBgColor: $primereact-inputSwitchSliderOnHoverBgColor;
$inputSwitchHandleOnBgColor: $primereact-inputSwitchHandleOnBgColor;

//panel common (e.g. accordion, panel, tabview)
$panelHeaderBorder: $primereact-panelHeaderBorder;
$panelHeaderBgColor: $primereact-panelHeaderBgColor;
$panelHeaderTextColor: $primereact-panelHeaderTextColor;
$panelHeaderIconColor: $primereact-panelHeaderIconColor;
$panelHeaderIconHoverColor: $primereact-panelHeaderIconHoverColor;
$panelHeaderIconTransition: $primereact-panelHeaderIconTransition;
$panelHeaderFontWeight: $primereact-panelHeaderFontWeight;
$panelHeaderPadding: $primereact-panelHeaderPadding;
$panelContentBorder: $primereact-panelContentBorder;
$panelContentBgColor: $primereact-panelContentBgColor;
$panelContentTextColor: $primereact-panelContentTextColor;
$panelContentPadding: $primereact-panelContentPadding;
$panelFooterBorder: $primereact-panelFooterBorder;
$panelFooterBgColor: $primereact-panelFooterBgColor;
$panelFooterTextColor: $primereact-panelFooterTextColor;
$panelFooterPadding: $primereact-panelFooterPadding;
$panelHeaderHoverBgColor: $primereact-panelHeaderHoverBgColor;
$panelHeaderHoverBorder: $primereact-panelHeaderHoverBorder;
$panelHeaderHoverTextColor: $primereact-panelHeaderHoverTextColor;
$panelHeaderHoverIconColor: $primereact-panelHeaderHoverIconColor;
$panelHeaderActiveBgColor: $primereact-panelHeaderActiveBgColor;
$panelHeaderActiveBorder: $primereact-panelHeaderActiveBorder;
$panelHeaderActiveTextColor: $primereact-panelHeaderActiveTextColor;
$panelHeaderActiveIconColor: $primereact-panelHeaderActiveIconColor;
$panelHeaderActiveHoverBgColor: $primereact-panelHeaderActiveHoverBgColor;
$panelHeaderActiveHoverBorder: $primereact-panelHeaderActiveHoverBorder;
$panelHeaderActiveHoverTextColor: $primereact-panelHeaderActiveHoverTextColor;
$panelHeaderActiveHoverIconColor: $primereact-panelHeaderActiveHoverIconColor;
$panelHeaderTransition: $primereact-panelHeaderTransition;

//accordion
$accordionSpacing: $primereact-accordionSpacing;

//tabview
$tabsNavBorder: $primereact-tabsNavBorder;
$tabsNavBgColor: $primereact-tabsNavBgColor;
$tabHeaderSpacing: $primereact-tabHeaderSpacing;

//scrollpanel
$scrollPanelHandleBgColor: $primereact-scrollPanelHandleBgColor;
$scrollPanelTrackBorder: $primereact-scrollPanelTrackBorder;
$scrollPanelTrackBgColor: $primereact-scrollPanelTrackBgColor;

//card
$cardShadow: $primereact-cardShadow;

//paginator
$paginatorBgColor: $primereact-paginatorBgColor;
$paginatorBorder: $primereact-paginatorBorder;
$paginatorPadding: $primereact-paginatorPadding;
$paginatorIconColor: $primereact-paginatorIconColor;
$paginatorElementWidth: $primereact-paginatorElementWidth;
$paginatorElementHeight: $primereact-paginatorElementHeight;
$paginatorElementHoverBgColor: $primereact-paginatorElementHoverBgColor;
$paginatorElementHoverIconColor: $primereact-paginatorElementHoverIconColor;
$paginatorElementBorderRadius: $primereact-paginatorElementBorderRadius;
$paginatorElementMargin: $primereact-paginatorElementMargin;
$paginatorElementPadding: $primereact-paginatorElementPadding;
$paginatorElementBorder: $primereact-paginatorElementBorder;

//table
$tableHeaderCellPadding: $primereact-tableHeaderCellPadding;
$tableHeaderCellBgColor: $primereact-tableHeaderCellBgColor;
$tableHeaderCellTextColor: $primereact-tableHeaderCellTextColor;
$tableHeaderCellFontWeight: $primereact-tableHeaderCellFontWeight;
$tableHeaderCellBorder: $primereact-tableHeaderCellBorder;
$tableHeaderCellHoverBgColor: $primereact-tableHeaderCellHoverBgColor;
$tableHeaderCellHoverTextColor: $primereact-tableHeaderCellHoverTextColor;
$tableHeaderCellIconColor: $primereact-tableHeaderCellIconColor;
$tableHeaderCellHoverIconColor: $primereact-tableHeaderCellHoverIconColor;
$tableBodyRowBgColor: $primereact-tableBodyRowBgColor;
$tableBodyRowTextColor: $primereact-tableBodyRowTextColor;
$tableBodyRowEvenBgColor: $primereact-tableBodyRowEvenBgColor;
$tableBodyRowHoverBgColor: $primereact-tableBodyRowHoverBgColor;
$tableBodyRowHoverTextColor: $primereact-tableBodyRowHoverTextColor;
$tableBodyCellBorder: $primereact-tableBodyCellBorder;
$tableBodyCellPadding: $primereact-tableBodyCellPadding;
$tableFooterCellPadding: $primereact-tableFooterCellPadding;
$tableFooterCellBgColor: $primereact-tableFooterCellBgColor;
$tableFooterCellTextColor: $primereact-tableFooterCellTextColor;
$tableFooterCellFontWeight: $primereact-tableFooterCellFontWeight;
$tableFooterCellBorder: $primereact-tableFooterCellBorder;
$tableResizerHelperBgColor: $primereact-tableResizerHelperBgColor;

//schedule
$scheduleEventBgColor: $primereact-scheduleEventBgColor;
$scheduleEventBorder: $primereact-scheduleEventBorder;
$scheduleEventTextColor: $primereact-scheduleEventTextColor;

//tree
$treeContainerPadding: $primereact-treeContainerPadding;
$treeNodePadding: $primereact-treeNodePadding;
$treeNodeLabelPadding: $primereact-treeNodeLabelPadding;
$treeNodeContentSpacing: $primereact-treeNodeContentSpacing;

//lightbox
$lightBoxNavIconFontSize: $primereact-lightBoxNavIconFontSize;
$lightBoxNavIconColor: $primereact-lightBoxNavIconColor;

//org chart
$organizationChartConnectorColor: $primereact-organizationChartConnectorColor;

//messages
$messagesMargin: $primereact-messagesMargin;
$messagesPadding: $primereact-messagesPadding;
$messagesIconFontSize: $primereact-messagesIconFontSize;
$messageCloseIconFontSize: $primereact-messageCloseIconFontSize;

//message
$messagePadding: $primereact-messagePadding;
$messageMargin: $primereact-messageMargin;
$messageIconFontSize: $primereact-messageIconFontSize;
$messageTextFontSize: $primereact-messageTextFontSize;

//toast
$toastShadow: $primereact-toastShadow;
$toastMessageMargin: $primereact-toastMessageMargin;

//severities
$infoMessageBgColor: $primereact-infoMessageBgColor;
$infoMessageBorder: $primereact-infoMessageBorder;
$infoMessageTextColor: $primereact-infoMessageTextColor;
$infoMessageIconColor: $primereact-infoMessageIconColor;
$successMessageBgColor: $primereact-successMessageBgColor;
$successMessageBorder: $primereact-successMessageBorder;
$successMessageTextColor: $primereact-successMessageTextColor;
$successMessageIconColor: $primereact-successMessageIconColor;
$warnMessageBgColor: $primereact-warnMessageBgColor;
$warnMessageBorder: $primereact-warnMessageBorder;
$warnMessageTextColor: $primereact-warnMessageTextColor;
$warnMessageIconColor: $primereact-warnMessageIconColor;
$errorMessageBgColor: $primereact-errorMessageBgColor;
$errorMessageBorder: $primereact-errorMessageBorder;
$errorMessageTextColor: $primereact-errorMessageTextColor;
$errorMessageIconColor: $primereact-errorMessageIconColor;

//growl
$growlTopLocation: $primereact-growlTopLocation;
$growlIconFontSize: $primereact-growlIconFontSize;
$growlMessageTextMargin: $primereact-growlMessageTextMargin;
$growlMargin: $primereact-growlMargin;
$growlPadding: $primereact-growlPadding;
$growlShadow: $primereact-growlShadow;
$growlOpacity: $primereact-growlOpacity;

//overlays
$overlayContentBorderColor: $primereact-overlayContentBorderColor;
$overlayContentBorder: $primereact-overlayContentBorder;
$overlayContainerShadow: $primereact-overlayContainerShadow;
$overlayContainerRadius: $primereact-overlayContainerRadius;

//dialog
$dialogHeaderPadding: $primereact-dialogHeaderPadding;
$dialogContentPadding: $primereact-dialogContentPadding;
$confirmDialogPadding: $primereact-confirmDialogPadding;

//overlay panel
$overlayPanelCloseIconBgColor: $primereact-overlayPanelCloseIconBgColor;
$overlayPanelCloseIconColor: $primereact-overlayPanelCloseIconColor;
$overlayPanelCloseIconHoverBgColor: $primereact-overlayPanelCloseIconHoverBgColor;
$overlayPanelCloseIconHoverColor: $primereact-overlayPanelCloseIconHoverColor;

//tooltip
$tooltipBgColor: $primereact-tooltipBgColor;
$tooltipTextColor: $primereact-tooltipTextColor;
$tooltipPadding: $primereact-tooltipPadding;

//steps
$stepsItemBgColor: $primereact-stepsItemBgColor;
$stepsItemBorder: $primereact-stepsItemBorder;
$stepsItemNumberColor: $primereact-stepsItemNumberColor;
$stepsItemTextColor: $primereact-stepsItemTextColor;
$stepsItemWidth: $primereact-stepsItemWidth;
$stepsItemHeight: $primereact-stepsItemHeight;

//progressbar
$progressBarHeight: $primereact-progressBarHeight;
$progressBarBorder: $primereact-progressBarBorder;
$progressBarBgColor: $primereact-progressBarBgColor;
$progressBarValueBgColor: $primereact-progressBarValueBgColor;

//menu (e.g. menu, menubar, tieredmenu)
$menuBgColor: $primereact-menuBgColor;
$menuBorder: $primereact-menuBorder;
$menuPadding: $primereact-menuPadding;
$menuTextColor: $primereact-menuTextColor;
$menuitemPadding: $primereact-menuitemPadding;
$menuitemMargin: $primereact-menuitemMargin;
$menuitemTextColor: $primereact-menuitemTextColor;
$menuitemIconColor: $primereact-menuitemIconColor;
$menuitemHoverTextColor: $primereact-menuitemHoverTextColor;
$menuitemHoverIconColor: $primereact-menuitemHoverIconColor;
$menuitemHoverBgColor: $primereact-menuitemHoverBgColor;
$menuitemActiveTextColor: $primereact-menuitemActiveTextColor;
$menuitemActiveIconColor: $primereact-menuitemActiveIconColor;
$menuitemActiveBgColor: $primereact-menuitemActiveBgColor;
$submenuHeaderMargin: $primereact-submenuHeaderMargin;
$submenuPadding: $primereact-submenuPadding;
$overlayMenuBorder: $primereact-overlayMenuBorder;
$overlayMenuBorderRadius: $primereact-overlayMenuBorderRadius;
$overlayMenuMarginTop: $primereact-overlayMenuMarginTop;
$overlayMenuPadding: $primereact-overlayMenuPadding;
$overlayMenuShadow: $primereact-overlayMenuShadow;

//misc
$maskBgColor: $primereact-maskBgColor;
$inlineSpacing: $primereact-inlineSpacing;
$chipsItemMargin: $primereact-chipsItemMargin;
$dataIconColor: $primereact-dataIconColor;
$dataIconHoverColor: $primereact-dataIconHoverColor;

//general
$disabledOpacity: $primereact-disabledOpacity;

/* open-sans-300 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	src: url('~common/src/assets/fonts/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
	src:
		local('Open Sans Light'),
		local('OpenSans-Light'),
		/* IE6-IE8 */
		url('~common/src/assets/fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'),
		/* Super Modern Browsers */
		url('~common/src/assets/fonts/open-sans-v15-latin-300.woff2') format('woff2'),
		/* Modern Browsers */
		url('~common/src/assets/fonts/open-sans-v15-latin-300.woff') format('woff'),
		/* Safari, Android, iOS */
		url('~common/src/assets/fonts/open-sans-v15-latin-300.ttf') format('truetype'),
		/* Legacy iOS */
		url('~common/src/assets/fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg');
}

/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('~common/src/assets/fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
	src:
		local('Open Sans Regular'),
		local('OpenSans-Regular'),
		/* IE6-IE8 */
		url('~common/src/assets/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* Super Modern Browsers */
		url('~common/src/assets/fonts/open-sans-v15-latin-regular.woff2') format('woff2'),
		/* Modern Browsers */
		url('~common/src/assets/fonts/open-sans-v15-latin-regular.woff') format('woff'),
		/* Safari, Android, iOS */
		url('~common/src/assets/fonts/open-sans-v15-latin-regular.ttf') format('truetype'),
		/* Legacy iOS */
		url('~common/src/assets/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg');
}

/* open-sans-700 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('~common/src/assets/fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
	src:
		local('Open Sans Bold'),
		local('OpenSans-Bold'),
		/* IE6-IE8 */
		url('~common/src/assets/fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'),
		/* Super Modern Browsers */
		url('~common/src/assets/fonts/open-sans-v15-latin-700.woff2') format('woff2'),
		/* Modern Browsers */
		url('~common/src/assets/fonts/open-sans-v15-latin-700.woff') format('woff'),
		/* Safari, Android, iOS */
		url('~common/src/assets/fonts/open-sans-v15-latin-700.ttf') format('truetype'),
		/* Legacy iOS */
		url('~common/src/assets/fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg');
}

// primereact designer
@import '~common/src/assets/themes/primereact-designer/_core';
@import '~primereact/resources/primereact.min.css';
