@mixin rate-advisor-services {
	.common-rate-advisor-services {
		position: absolute;
		width: calc(100% - 245px);
		top: 40px;
		left: 245px;
		height: calc(100% - 40px);
		overflow-y: auto;
		overflow-x: hidden;

		// override common-ui-accordion style rules
		.common-ui-accordion {
			border-bottom: 1px solid $stamps-rate-advisor-border-color;

			.p-accordion-tab {
				border-top: 1px solid $stamps-rate-advisor-border-color;
			}

			.p-accordion-header,
			.p-accordion-header:not(.p-disabled).p-highlight,
			.p-accordion-header:not(.p-disabled).p-highlight:hover,
			.p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
				a {
					align-items: center;
					padding: 10px 20px; // 10px 25px 10px 0;

					.p-accordion-header-text {
						width: 100%;
					}
				}
			}

			.p-accordion-content {
				position: relative !important;
				display: flex !important;
				border-top: 1px solid $stamps-rate-advisor-border-color;
				padding: 15px 30px 30px 30px;
			}

			.p-accordion-header:not(.p-disabled).p-highlight:hover,
			.p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
				background-color: #f9f9f9;
			}
		}

		// override common-ui-button style rules
		.common-ui-button {
			&.show-more-btn {
				width: 100%;
				text-align: center;
				font-size: 13px;
				min-width: 585px;

				.p-button {
					.p-button-label {
						color: $stamps-rate-advisor-show-options-button-color;
						padding: 0 25px 3px 0;

						&:hover {
							color: $stamps-rate-advisor-show-options-button-hover-color;
						}
					}
				}
			}
		}

		.sort-tiles {
			background-color: #fff;
			display: flex;
			flex-direction: row;
			padding: 9px 0;
			list-style: none;
			justify-content: center;
			min-width: 585px;

			li {
				cursor: pointer;
				font-size: 14.5px;
				padding: 16px 21px;
				border-bottom: 1px solid #eef2f8;

				sup {
					font-size: 9px;
					font-weight: normal;
				}

				div {
					display: inline-block;
					margin-left: 5px;
				}

				&:hover {
					background-color: $stamps-rate-advisor-services-active-tab-bg-color;
				}

				&.sort-tile-active {
					background-color: $stamps-rate-advisor-services-active-tab-bg-color;
					font-weight: bold;
					box-shadow: 0 5px 0 0 $stamps-rate-advisor-services-active-tab-shadow-color;
				}
			}
		}

		.common-rate-advisor-service-info {
			display: flex;
			flex-direction: row;
			font-size: 13px;
			width: 100%;
			min-width: 600px;

			.title {
				font-weight: bold;
				margin: 0 0 17px 0;
			}

			.description {
				margin-top: 30px;

				&:first-child {
					margin-top: 0;
				}

				.title {
					font-weight: bold;
					margin: 0 0 5px 0;
				}

				p {
					margin-bottom: 5px;
				}
			}

			.right-panel {
				text-align: left;
				padding: 0 0 0 20px;
				flex: 1;
			}

			.left-panel {
				text-align: left;
				flex: 1;
				line-height: 16px;
			}
		}

		/**
		 * The min-width of this element should be: 575px.
		 * - service-icon: 100px;
		 * - service-description: 250px;
		 * - service-eta: 110px;
		 * - service-amount: 115px;
		 * The left and right padding size of its ancestor, <a> element, is 20px,
		 * which makes the mimimum width needed to display the whole content to
		 * be 615px. This matches the min-width of 'common-rate-advisor-services'
		 * element.
		 */
		.common-rate-advisor-service-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			color: $stamps-modal-body-text-color;

			.service-icon {
				width: 100px;
				height: 60px;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;

				&.usps-logo {
					background-image: background-image-url('usps-logo.svg');
				}

				&.ups-logo {
					background-image: background-image-url('ups-logo.svg');
				}

				&.globalpost-logo {
					background-image: background-image-url('globalpost-logo.svg');
				}

				&.dhlexpress-logo {
					background-image: background-image-url('dhl-logo.svg');
				}

				&.fedex-logo {
					background-image: background-image-url('fedex-logo.svg');
				}
			}

			.service-description {
				display: flex;
				flex-direction: column;
				width: 240px;
				padding-left: 10px;

				.service-name {
					@include h4;
				}

				.package-name {
					font-size: 13px;
					line-height: 20px;
				}

				.service-included {
					background: 0 5px background-image-url('checkmark-icon.png') no-repeat;
					font-size: 10.5px;
					height: 17px;
					line-height: 17px;
					display: flex;

					p {
						padding-left: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: #6f6f6f;
					}

					.view-details {
						flex: none;
						display: inline;
						font-size: 10.5px;
						color: #4a76a8;
					}
				}
			}

			.service-eta {
				width: 100px;
				margin-left: 10px;
				text-align: center;
				@include h4;
			}

			.upgrade-required {
				background: left/10px background-image-url('exclamation-point.svg') no-repeat;
				font-size: 10px;
				color: #6f6f6f;
				padding-left: 14px;
				white-space: nowrap;
			}
		}
	}
}
