@mixin rate-advisor-header {
	.common-rate-advisor-header {
		border-bottom: 1px solid lightgray;
		height: 40px;
		width: 100%;
		min-width: 830px;
		display: flex;
		position: absolute;
		align-items: center;
		top: 0;

		.weight-icon {
			margin-left: -16px;
			width: 24px;
			padding-top: 6px;
			height: 24px;
			margin-top: 6px;
			background: background-image-url($stamps-rate-advisor-weight-icon) no-repeat center;
			background-size: 18px 22px !important;

			&:hover {
				background: background-image-url($stamps-rate-advisor-weight-hover-icon) no-repeat center;
			}
		}

		.p-inputnumber-button-group .p-button.p-button-icon-only {
			width: 16px;
		}

		.dimensions-icon {
			width: 24px;
			padding-top: 6px;
			height: 24px;
			margin-top: 6px;
			background: background-image-url($stamps-rate-advisor-dimensions-icon) no-repeat center;
			background-size: 18px 18px !important;

			&:hover {
				background: background-image-url($stamps-rate-advisor-dimensions-hover-icon) no-repeat center;
			}
		}

		.dimensions-empty-info-icon {
			display: inline;
			float: left;
			width: 24px;
			padding-top: 6px;
			height: 24px;
			background: background-image-url($stamps-rate-advisor-dimensions-info-icon) no-repeat center;
			background-size: 18px 18px !important;
			margin-top: 10px;
		}

		.dimensions-empty-info-panel {
			width: 120px;
			margin-left: 5px;
		}

		.dimensions-empty-info-text {
			color: #0F0F10;
			font-size: 10px;
		}

		.common-ui-weight-field {
			margin: 6px 5px 3px 9px;
			width: 200px;
		}

		.common-ui-dimensions-field {
			margin: 6px 5px 3px 2px;
		}

		.p-inputtext {
			padding: 4px;
			font-size: 14px;
		}
	}
}
