@mixin rate-advisor-filter {
	.common-rate-advisor-filter {
		padding: 0 0 0 16px;
		position: absolute;
		top: 46px;
		height: calc(100% - 46px);
		width: 245px;
		border-right: 1px solid $stamps-ui-input-border-color;
		overflow: auto;
		color: #0a0a0a;

		&.hide-vertical-scrollbar {
			height: initial;
		}

		.title {
			font-size: 16px;
			font-weight: bold;
			padding: 36px 0 10px 0;
			border-bottom: 1px solid $stamps-rate-advisor-border-color;
		}

		.common-rate-advisor-carrier-services-filter {
			padding-bottom: 4px;
			border-top: 1px solid #eef2f8;
			border-bottom: 1px solid #eef2f8;
			width: 200px;

			.carrier-title {
				font-size: 10pt;
				font-weight: bold;
				padding: 13px 0 13px 0;
			}

			.ag-theme-common {
				&.ag-grid-container {
					flex: 1 1 auto; // fix IE 11 height issue
				}

				color: #0a0a0a;

				.ag-group-checkbox:not(.ag-invisible) + .ag-group-value:not(:empty) {
					margin-left: 10px;
				}

				.ag-row-group {
					font-size: 13px;
				}

				.ag-row-group-leaf-indent {
					padding: 0;
					margin: 0 0 0 26px;
					font-size: 11px;
				}

				.ag-row-hover {
					background-color: #f9f9f9;
				}

				.ag-group-expanded,
				.ag-group-contracted {
					position: absolute;
					right: 0;
					cursor: pointer;
					padding-right: 0;
				}

				.ag-group-contracted:not(.ag-hidden) ~ .ag-group-checkbox,
				.ag-group-expanded:not(.ag-hidden) ~ .ag-group-checkbox {
					margin-left: 13px;
				}

				.ag-cell {
					padding-left: 0;
				}

				.ag-row-selected:not(.ag-row-hover) {
					background-color: transparent;
				}

				.ag-row.ag-row-last {
					border-bottom-width: 0;
				}

				.ag-selection-checkbox,
				.ag-icon-checkbox-checked,
				.ag-icon-checkbox-unchecked,
				.ag-icon-checkbox-indeterminate {
					height: 12px;
					width: 12px;
					font-size: 14px;
					bottom: 2px;
					right: 1px;
					position: relative;
				}

				.ag-icon-checkbox-indeterminate:before {
					content: "\2212";
					font-size: 14px;
					position: relative;
					top: -5px;
					left: -2px !important;
				}

				.ag-icon-expanded::before {
					content: '\f135';
					font-size: 10px;
					position: relative;
					top: -4px;
					left: 1px;
				}

				.ag-icon-checkbox-checked:before {
					position: relative;
					top: -2px;
					left: -1px;
				}

				.ag-body-horizontal-scroll-viewport {
					overflow-x: hidden;
				}
			}
		}
	}
}
