@mixin ui-grouped-dropdown() {
	.common-ui-grouped-dropdown {
		.p-dropdown {
			.p-dropdown-label {
				width: calc(100% - 2em); // 2em is the default width of the .p-dropdown-trigger element
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.p-dropdown-panel {
				.p-dropdown-item {
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.dropdown-group-item {
					&.p-disabled {
						opacity: 1;
						font-weight: bold;
					}
				}
			}
		}
	}

	.p-dropdown-panel {
		.p-dropdown-items-wrapper {
			.p-dropdown-items {
				.p-dropdown-item-group {
					margin: $stamps-ui-grouped-dropdown-item-group-margin;
					background-color: $stamps-ui-grouped-dropdown-item-group-background;

					&:first-child {
						margin-top: $stamps-ui-grouped-dropdown-item-group-margin-top;
					}

					&:hover {
						background-color: $stamps-ui-grouped-dropdown-item-group-background;
					}
				}
			}
		}
	}
}
