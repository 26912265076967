@mixin upgrade-content() {
	.default-upgrade-content, .orders-products-upgrade-content {
		display: flex;
		width: 100%;
		margin-left: 15px;
   		margin-right: 15px;
		.left-content {
			flex-direction: column;
			display: flex;
			flex: 1;

			.plan-info {
				ul {
					padding-left: 25px;
					list-style-position: outside;
					padding: 10px 0px 15px 25px
				}
			}

			.price-panel {
				display: flex;
				flex-direction: row;
				padding: 16px 0px;
				line-height: 24px;

				.plan-name {
					flex: 1;
				}
			}
		}

		hr {
			margin: 0px
		}
	}

	.orders-products-upgrade-content {
		.plan-features {
			border-radius: 8px;
			border: 1px solid #EAD1FA;

			.common-upgrade-msg  {
				border-radius: 0px;
				border: none;
				background: #F4E8FD;
				margin: 0px;
				padding: 8px 0 8px 0;
			}

			.upgrade-text {
				padding: 4px 15px 4px 0px;
			}

			.plan-features-list {
				padding: 0px 12px 0px 12px;
				margin: 12px 12px 0px 12px;
			}

			.price-panel {
				margin: 0px 12px 0 12px;
				border-top: 0.5px solid #EAD1FA
			}
		}
	}
}
