/********************************************************************
*                                                                   *
*                       AUCTANE DESIGN LANGUAGE                     *
*                                                                   *
********************************************************************/


// Primitive Colors
$adl-grey-white: #FFFFFF;
$adl-grey-025: #F7F7F8;
$adl-grey-050: #F1F2F3;
$adl-grey-100: #E4E5E7;
$adl-grey-200: #CFD1D3;
$adl-grey-300: #AFB3B6;
$adl-grey-400: #8D9196;
$adl-grey-500: #71767A;
$adl-grey-600: #56595D;
$adl-grey-700: #424548;
$adl-grey-800: #27292A;
$adl-grey-900: #0F0F10;
$adl-grey-black: #000000;
$adl-grey-000: rgba(15, 15, 16, 0.6); // #0f0f10 with .6 opacity
$adl-elevation-xs: rgba(39, 41, 42, 0.24);

$adl-blue-050: #EDF6FF;
$adl-blue-100: #DBECFE;
$adl-blue-200: #BFDFFE;
$adl-blue-300: #93CCFD;
$adl-blue-400: #60AFFA;
$adl-blue-500: #3B8EF6;
$adl-blue-600: #256FEB;
$adl-blue-700: #1D5AD8;
$adl-blue-800: #1E49AF;
$adl-blue-900: #1E418A;
$adl-blue-950: #172954;

$adl-cyan-500: #00A0BA;

$adl-green-050: #EBFFF5;
$adl-green-050: #EEFFF5;
$adl-green-100: #D8FDEB;
$adl-green-200: #BEF4D9;
$adl-green-300: #70DBA6;
$adl-green-400: #09AA59;
$adl-green-500: #018945;
$adl-green-600: #007038;
$adl-green-700: #088D4C;
$adl-green-800: #00331A;
$adl-green-900: #001F0F;

$adl-yellow-050: #FFF6EB;
$adl-yellow-100: #FFECD1;
$adl-yellow-200: #FFD599;
$adl-yellow-300: #FFBF66;
$adl-yellow-400: #FFAA33;
$adl-yellow-500: #BD6E00;
$adl-yellow-600: #945600;
$adl-yellow-700: #704100;
$adl-yellow-800: #4D2D00;
$adl-yellow-900: #331E00;

$adl-orange-500: #F69716;

$adl-red-050: #FDF7F7;
$adl-red-100: #F8DEDE;
$adl-red-200: #F1C1C1;
$adl-red-300: #EC7979;
$adl-red-400: #E54D4D;
$adl-red-500: #DF2020;
$adl-red-600: #A91919;
$adl-red-700: #821212;
$adl-red-800: #5D0E0E;
$adl-red-900: #360808;

$adl-purple-050: #F6F2FF;
$adl-purple-100: #EEE8FF;
$adl-purple-200: #DFD4FF;
$adl-purple-300: #CAB1FF;
$adl-purple-400: #B085FF;
$adl-purple-500: #8D30F7;
$adl-purple-600: #7F1EE3;
$adl-purple-700: #6A18BF;
$adl-purple-800: #57169C;
$adl-purple-900: #360B6A;
$adl-purple-950: #11003A; 

// Stamps Brand Colors
$adl-stamps-lightred: #FFDDBB;
$adl-stamps-red: #F91010;
$adl-stamps-darkred: #AA1100;
$adl-stamps-lightpink: #FF99FF;
$adl-stamps-pink: #F733F7;
$adl-stamps-yellow: #FFBF00;
$adl-stamps-darkyellow: #D37700;
$adl-stamps-darkindigo: #11003A;
$adl-stamps-lightblue: #99E0FF;
$adl-stamps-green: #00AA55;
$adl-stamps-darkgreen: #003311;

// Basic UI Colors
$adl-surface-default: $adl-grey-025;
$adl-surface-dark: $adl-grey-900;
$adl-surface-container: $adl-grey-white;
$adl-surface-overlay: $adl-grey-000;

$adl-border-dark: $adl-grey-600;
$adl-border-medium: $adl-grey-300;
$adl-border-light: $adl-grey-100;
$adl-border-focus: $adl-blue-500;
$adl-border-error: $adl-red-600;

$adl-text-primary-dark: $adl-grey-900;
$adl-text-primary-light: $adl-grey-white;
$adl-text-secondary: $adl-grey-600;
$adl-text-out-of-range: $adl-grey-400;
$adl-text-disabled: $adl-grey-400;
$adl-text-link: $adl-blue-500;
$adl-text-error: $adl-red-600;

$adl-icon-primary-dark: $adl-grey-900;
$adl-icon-primary-light: $adl-grey-white;
$adl-icon-secondary: $adl-grey-600;
$adl-icon-disabled: $adl-grey-300;
$adl-icon-link: $adl-blue-500;
$adl-icon-affirm: $adl-green-700;
$adl-icon-warning: $adl-yellow-500;
$adl-icon-error: $adl-red-600;

// Buttons
$adl-button-primary-font-color: $adl-grey-white;
$adl-button-primary-idle-background-color: $adl-stamps-darkindigo;
$adl-button-primary-hover-background-color: #04006B;
$adl-button-primary-active-background-color: #0500A3;
$ald-button-primary-calendar-background-color: $adl-blue-600;
$ald-button-primary-calendar-font-color: $adl-grey-white;

$adl-button-secondary-font-color: $adl-stamps-darkindigo;
$adl-button-secondary-border-color: $adl-stamps-darkindigo;
$adl-button-secondary-idle-background-color: $adl-grey-white;
$adl-button-secondary-hover-background-color: #E4E7FF;
$adl-button-secondary-active-background-color: #A8ABFF;

$adl-button-destructive-idle-background-color: $adl-red-600;
$adl-button-destructive-hover-background-color: $adl-red-700;
$adl-button-destructive-active-background-color: $adl-red-800;

$adl-button-tertiary-font-color: $adl-grey-900;
$adl-button-tertiary-grey-idle-background-color: $adl-grey-100;
$adl-button-tertiary-grey-hover-background-color: $adl-grey-200;
$adl-button-tertiary-grey-active-background-color: $adl-grey-300;
$adl-button-tertiary-white-border-color: $adl-grey-100;
$adl-button-tertiary-white-idle-background-color: $adl-grey-white;
$adl-button-tertiary-white-hover-background-color: $adl-grey-050;
$adl-button-tertiary-white-active-background-color: $adl-grey-100;
$adl-button-tertiary-ghost-idle-background-color: transparent;
$adl-button-tertiary-ghost-hover-background-color: $adl-grey-050;
$adl-button-tertiary-ghost-active-background-color: $adl-grey-100;

$adl-button-disabled-font-color: $adl-grey-400;
$adl-button-disabled-background-color: $adl-grey-050;

// Spacing
$adl-margin-xs: 8px;
$adl-margin-sm: 16px;
$adl-margin-md: 24px;
$adl-margin-lg: 32px;
$adl-margin-xl: 40px;
$adl-padding-xs: 4px;
$adl-padding-sm: 8px;
$adl-padding-md: 12px;
$adl-padding-lg: 16px;
$adl-padding-xl: 24px;
$adl-padding-2xl: 32px;
$adl-border-radius-xs: 4px;
$adl-border-radius-sm: 8px;
$adl-border-radius-md: 16px;
$adl-gap-sm: 12px;
$adl-gap-md: 8px;
$adl-gap: 8px;

// Standard Spacing Variables
$adl-space-var-xxs: 2px;
$adl-space-var-xs: 4px;
$adl-space-var-sm: 8px;
$adl-space-var-md: 12px;
$adl-space-var-lg: 16px;
$adl-space-var-xl: 20px;
$adl-space-var-xxl: 24px;
$adl-space-var-xxxl: 32px;

// Condensed Spacing Variables
$adl-space-var-xxs-condensed: 4px;
$adl-space-var-xs-condensed: 8px;
$adl-space-var-sm-condensed: 12px;
$adl-space-var-md-condensed: 16px;
$adl-space-var-lg-condensed: 20px;
$adl-space-var-xl-condensed: 24px;
$adl-space-var-xxl-condensed: 32px;
$adl-space-var-xxxl-condensed: 40px;

// Expanded Spacing Variables
$adl-space-var-xxs-expanded: 4px;
$adl-space-var-xs-expanded: 8px;
$adl-space-var-sm-expanded: 12px;
$adl-space-var-md-expanded: 16px;
$adl-space-var-lg-expanded: 20px;
$adl-space-var-xl-expanded: 24px;
$adl-space-var-xxl-expanded: 32px;
$adl-space-var-xxxl-expanded: 40px;

$adl-formcontrol-foreground-idle-color: $adl-grey-600;

// Dropdown
$adl-dropdown-hover-background-color: #E5F7FF;
$adl-dropdown-active-background-color: #CCEFFF;

// Layout/Navigation
$adl-border-width: 1px;
$adl-border-leftmenu-width: 4px;
$adl-badge-background-color: $adl-stamps-darkindigo;
$adl-nav-foreground-idle-color: $adl-grey-800;

// Typography
$adl-font-family: 'Figtree', 'Arial', sans-serif;
$adl-font-size-number-input-icon: 6px;
$adl-font-size-xs: 12px;
$adl-font-size-sm: 14px;
$adl-font-size-md: 16px;
$adl-font-size-lg: 20px;
$adl-font-size-xl: 24px;
$adl-font-size-2xl: 32px;
$adl-font-size-4xl: 64px;

$adl-line-height-xs: 16px;
$adl-line-height-sm: 20px;
$adl-line-height-md: 24px;
$adl-line-height-lg: 32px;
$adl-line-height-xl: 40px;
$adl-line-height-2xl: 48px;
$adl-line-height-4xl: 80px;

$adl-font-weight-normal: 400;
$adl-font-weight-bold: 600;
$adl-font-weight-button: 600;

// Icons and Visual Elements
$adl-icon-size-sm: 10px;

// Icon spacing
$adl-icon-margin-sm: 10px;
