@mixin inbound-details-shipping-details {
	.common-inbound-details-shipping-details {
		margin: 15px 0 0 0;
		display: flex;
		flex-direction: column;

		.shipping-details-header {
			color: $stamps-inbound-details-header-text-color;
			display: block;
			font-weight: bold;
			margin: 0 0 15px 0;
		}

		.shipping-content {
			.details {
				margin-bottom: -20px;
			}

			.mobile-wrapper {
				.label-container {
					text-align: right;
				}
			}

			.label-container > label {
				color: $stamps-inbound-details-sub-header-text-color;
				font-weight: bold;
			}
		}

		.show-estimation-tool-btn {
			width: 100%;
			cursor: pointer;
			margin-top: 10px;

			.p-button-label {
				color: $stamps-rate-advisor-show-options-button-color;
				margin: 0 0 0 130px;
				padding-right: 25px;
			}

			.pi-chevron-down,
			.pi-chevron-right {
				color: $stamps-rate-advisor-show-options-button-color;
			}
		}
	}
}
