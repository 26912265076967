@mixin carrier-add-new-carrier {
	.common-carrier-add-new-carrier {
		height: $stamps-settings-manage-carrier-add-carrier-height;

		.common-ui-button {
			.p-button {
				&.ui-button-secondary {
					border: $stamps-settings-manage-carrier-add-carrier-footer-button-border;

					&:hover:not(:disabled) {
						background-color: $stamps-settings-manage-carrier-add-carrier-footer-button-hover-bg-color;
					}

					&:active:not(:disabled) {
						background-color: $stamps-settings-manage-carrier-add-carrier-footer-button-active-bg-color;
					}
				}

				&.ui-button-transparent {
					&:hover:not(:disabled) {
						background-color: #fff;
						border-color: #fff;
					}

					&:active:not(:disabled) {
						background-color: #fff;
						border-color: #fff;
					}
				}

				&.p-button-icon-only {
					height: auto;

					.p-button-icon {
						margin: $stamps-settings-manage-carrier-add-carrier-button-icon-margin;
					}
				}
			}
		}

		.common-ui-layout-section {
			.section-body {
				display: flex;
				flex-direction: column;
				width: $stamps-settings-manage-carrier-add-carrier-section-width;

				.p-button-icon-only {
					height: auto;
				}

				.common-ui-buttongrid {
					border: $stamps-settings-manage-carrier-add-carrier-btn-grid-border;
					border-radius: $stamps-settings-manage-carrier-add-carrier-btn-grid-border-radius;

					.p-grid {
						margin-right: $stamps-settings-manage-carrier-add-carrier-grid-margin-right;
						margin-left: $stamps-settings-manage-carrier-add-carrier-grid-margin-left;
						margin-top: $stamps-settings-manage-carrier-add-carrier-grid-margin-top;

						.p-col-4 {
							width: $stamps-settings-manage-carrier-add-carrier-grid-item-width;

							.grid-item-container {
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								padding: 10px;
								cursor: pointer;
								width: $stamps-settings-manage-carrier-add-carrier-grid-item-container-width;
								height: $stamps-settings-manage-carrier-add-carrier-grid-item-container-height;
								gap: $stamps-settings-manage-carrier-add-carrier-grid-item-container-gap;
								border: $stamps-settings-manage-carrier-add-carrier-grid-item-container-border;
								border-radius: $stamps-settings-manage-carrier-add-carrier-grid-item-container-border-radius;
								background-color: $stamps-settings-manage-carrier-add-carrier-grid-item-container-bg-color;

								&:hover:not(:disabled) {
									border: $stamps-settings-manage-carrier-add-carrier-grid-item-hover;
									transition: all .3s;
								}

								&:active:not(:disabled) {
									border: $stamps-settings-manage-carrier-add-carrier-grid-item-active;
								}

								.ups-logo {
									background: background-image-url($stamps-settings-manage-carrier-add-carrier-ups-logo) no-repeat center;
									height: $stamps-settings-manage-carrier-add-carrier-logo-height;
									width: $stamps-settings-manage-carrier-add-carrier-logo-width;
									background-size: contain;
									position: static;
								}

								.dhl-express-logo {
									background: background-image-url($stamps-settings-manage-carrier-add-carrier-dhl-logo) no-repeat center;
									height: $stamps-settings-manage-carrier-add-carrier-logo-height;
									width: $stamps-settings-manage-carrier-add-carrier-logo-width;
									background-size: contain;
									position: static;
								}

								.fedex-logo {
									background: background-image-url($stamps-settings-manage-carrier-add-carrier-fedex-logo) no-repeat center;
									height: $stamps-settings-manage-carrier-add-carrier-logo-height;
									width: $stamps-settings-manage-carrier-add-carrier-logo-width;
									background-size: contain;
									position: static;
								}

							}
						}

					}
				}
			}
		}
	}
}
