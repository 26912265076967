@mixin ui-badge() {
	.common-ui-badge {
		display: flex;
		padding: $stamps-ui-badge-padding-x $stamps-ui-badge-padding-y;
		justify-content: center;
		align-items: center;
		border-radius: $stamps-ui-badge-border-radius;
		background-color: $stamps-ui-badge-background-color;
		color: $stamps-ui-badge-text-color;
		white-space: nowrap;

		&.complete {
			background-color: $stamps-ui-badge-complete-background-color;
		}
	}
}