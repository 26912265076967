@mixin account-sms-terms {
	.account-sms-terms {
		text-align: left;

		&.modal {
			p {
				padding: 0 0 15px 0;
			}
		}
	}
}
