@mixin ui-element-wrapper() {
	.common-ui-element-wrapper {
		display: flex;
		align-items: center;
		padding: 2px 0;

		.label-container {
			margin-bottom: 5px;
			margin-right: 10px;
			white-space: nowrap;
			width: 120px;
			text-align: right;
			line-height: $stamps-line-height;
			flex: none;
			font-weight: $stamps-ui-input-label-font-weight;
		}

		&.disabled {
			.label-container {
				opacity: $stamps-disabled-opacity;
			}
		}

		.input-container {
			display: flex;
			flex-wrap: nowrap;
			flex: 1 1 auto;
			margin-bottom: 5px;
			min-width: 0;

			// for desktop env where the error icon appears on the right of the field
			.common-ui-error-container {
				display: inline-block;

				.invalid-error-icon {
					width: 20px;
					height: 20px;
					margin: 5px 0 0 5px;
					display: inline-block;
					background: background-image-url($stamps-ui-element-wrapper-error-icon) no-repeat;
					background-position: center;
				}

				// for tablet and phone env where the error icon appears below the field
				&.common-ui-error-text {
					display: block;
					margin-top: 3px;

					.invalid-error-icon {
						vertical-align: middle;
						margin: 0 5px 0 0;
					}

					.error-msg {
						line-height: $stamps-line-height;
						vertical-align: middle;
						color: $stamps-ui-element-wrapper-invalid-text-color;
						white-space: pre-line;
					}
				}
			}
		}

		&.mobile-wrapper {
			.label-container {
				text-align: left;
			}

			.input-container {
				flex-direction: column;

				.common-ui-error-container {
					// for tablet and phone env where the error icon appears below the field
					&.common-ui-error-text {
						flex: 1 1 auto;
					}
				}
			}
		}
	}
}
