@mixin header {
	.common-header {
		display: flex;

		.app-logo {
			width: $stamps-app-logo-icon-width; // same width as left menu width for alignment
			height: 50px;
			background: transparent background-image-url($stamps-app-logo-icon) no-repeat left;
			margin: $stamps-app-logo-icon-margin-right;

			&.phone {
				width: $stamps-app-logo-width-phone;
				margin-left: 15px;
			}
		}

		.p-menubar-button {
			display: none;
		}

		&.store-header {
			.app-store-logo-button {
				width: 240px; // same width as left menu width for alignment
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;

				.p-button {
					width: $stamps-app-store-header-logo-width;
					height: $stamps-app-store-header-logo-height;
					background: transparent background-image-url($stamps-app-store-header-logo-icon) no-repeat center;
				}
			}

			.simple-usps-logo {
				width: 123px;
				height: 50px;
				background: transparent background-image-url('simple-usps-rev.png') no-repeat center;
			}
		}

		.header-badge {
			background-color: #f00;
			background-image: none;
			color: #fff;
			border-radius: 15px;
			margin-left: 5px;
			text-align: center;
			font-size: 12px;
			font-weight: bold;
			line-height: 20px;
			padding: 0 10px;
		}

		.reports-background-image {
			background: background-image-url($stamps-header-reports-upgrade) no-repeat center;
			width: 310px;
			height: 179px;
		}

		.reports-upgrade-list {
			list-style-type: disc;
			padding-inline-start: 40px;
		}

		.header-panel-menu-toggle-button {
			width: 50px;
			height: 50px;
			margin-left: auto;
			cursor: pointer;

			// default hamburger icon
			&.hmenu-default {
				background: background-image-url($stamps-header-hamburger-inactive-icon) no-repeat center;
				background-size: 25px 25px;
			}

			// active hamburger icon
			&.hmenu-active {
				background: transparent background-image-url($stamps-header-hamburger-active-icon) no-repeat center;
				background-size: 25px 25px;
			}
		}

		.header-panel-menu-toggle-link {
			.p-submenu-icon {
				display: none;
			}
		}

		.feature-gate-icon {
			position: relative;
			width: 20px;
			height: 20px;
			top: 3px;
			margin-left: 3px;
			background: background-image-url($stamps-header-feature-gate-icon) no-repeat;
			display: inline-block;

			&:hover {
				background: background-image-url($stamps-header-feature-gate-icon-hover) no-repeat;
			}

			&.submenulist-upgrade {
				background: background-image-url($stamps-header-feature-gate-icon-hover) no-repeat;

				&:hover {
					background: background-image-url($stamps-header-feature-gate-icon) no-repeat;
				}
			}
		}

		/*
		 * Below are to override style rules defined in primereact _menu.scss and
		 * custom rules per the requirement.
		 */

		.p-menu .p-menuitem-link,
		.p-menuitem .p-menuitem-link,
		.p-menubar .p-menuitem-link,
		.p-tieredmenu .p-menuitem-link,
		.p-contextmenu .p-menuitem-link,
		.p-megamenu .p-menuitem-link,
		.p-slidemenu .p-menuitem-link {
			transition: unset;
			font-weight: bold;
			padding: 15px;

			.p-menuitem-text {
				&.p-menuitem-highlighttext {
					font-size: 14px;
				}
			}

			&:focus {
				box-shadow: unset;
			}

			&:hover {
				.feature-gate-icon {
					background: background-image-url($stamps-header-feature-gate-icon-hover) no-repeat;
					&.submenulist-upgrade {
						background: background-image-url($stamps-header-feature-gate-icon) no-repeat;
					}
				}
			}
		}

		.badge-indicator {
			border: 3px solid red;
			margin: -14px 3px 3px;
			border-radius: 50%;
		}

		.p-submenu-list {
			border: $overlayMenuBorder;
			border-radius: $overlayMenuBorderRadius;
			margin-top: $overlayMenuMarginTop;
			min-width: 220px;
			padding: $overlayMenuPadding;
			@include shadow($overlayMenuShadow);

			> .p-menuitem {
				&:not(:first-child) {
					border-top: 1px solid $stamps-header-submenu-item-border-color;
				}

				.p-menuitem-link {
					color: $stamps-header-submenu-item-text-color;
					border-radius: $stamps-header-submenu-item-border-radius;
					padding: $stamps-header-submenu-item-padding;
					transition: unset;

					.p-panelmenu-icon {
						float: right;
						line-height: 20px;
						font-size: $stamps-header-icon-size;
					}

					.p-menuitem-icon {
						color: $stamps-header-submenu-item-text-color;
					}

					.p-menuitem-text {
						color: $stamps-header-submenu-item-text-color;
						font-weight: $stamps-header-submenu-item-font-weight;
						line-height: 19px;
					}

					&:hover {
						color: #fff;
						background-color: $stamps-header-submenu-item-active-bg-color;

						.p-menuitem-text {
							color: $stamps-header-submenu-item-active-text-color;
							font-weight: $stamps-header-submenu-item-active-font-weight;
						}

						.p-panelmenu-icon {
							color: $stamps-header-submenu-item-active-text-color;
						}

						.p-menuitem-icon {
							color: $stamps-header-submenu-item-active-text-color;
						}
					}

					&:focus {
						box-shadow: unset;
					}
				}

				&.p-menuitem-active-parent {
					> .p-menuitem-link {
						color: $stamps-header-submenu-item-active-text-color;
						background-color: $stamps-header-submenu-item-active-bg-color;

						.p-menuitem-text {
							color: $stamps-header-submenu-item-active-text-color;
						}

						.p-menuitem-icon {
							color: $stamps-header-submenu-item-active-text-color;
						}
					}
				}

				.p-submenu-list {
					margin-left: 0;
					border: 0;
					box-shadow: unset;

					> .p-menuitem {
						border-top: 0;
					}
				}
			}
		}

		.p-panelmenu {
			.p-panelmenu-header {
				> a {
					padding: 0;
					background: transparent;

					&:focus {
						box-shadow: unset;
					}
				}

				&.p-highlight {
					> a {
						background: transparent;

						&:hover {
							background: transparent;
						}
					}
				}

				&:not(.p-highlight) {
					> a:hover {
						background: transparent;
					}
				}
			}
		}

		.p-menubar {
			background-color: transparent;
		}

		/* styles for phone view */
		.header-panel-phone {
			position: absolute;
			left: 0;
			font-weight: bold;
			width: 100%;
			z-index: 1000;
			flex: 1;

			.badge-indicator {
				display: none;
			}

			.p-panelmenu {
				.p-panelmenu-panel {
					.p-panelmenu-header {
						> .p-panelmenu-header-link {
							height: 50px;
						}
					}

					> div {
						margin-left: auto;

						// default hamburger icon
						&.hmenu-default {
							background: background-image-url($stamps-header-hamburger-inactive-icon) no-repeat center;
							background-size: 25px 25px;
						}

						// active hamburger icon
						&.hmenu-active {
							background: transparent background-image-url($stamps-header-close-icon) no-repeat center;
							background-size: 25px 25px;
						}
					}
				}

				.p-panelmenu-content {
					background-color: $stamps-menu-right-background-color;
					.p-submenu-list {
						> .p-menuitem {
							&-active-parent {
								border-left: $stamps-menu-item-active-border;
							}

							&:not(:first-child) {
								border-top: 1px solid $stamps-header-submenu-responsive-item-border-color;
							}

							.p-menuitem-link {
								display: flex;
								justify-content: space-between;

								.p-panelmenu-icon {
									font-size: $stamps-pi-menu-font-size;
								}

								.p-submenu-icon {
									font-size: $stamps-pi-menu-font-size;
								}

								.balance-text {
									display: inline-block;
								}

								.dollar-amount-text-positive,
								.dollar-amount-text-negative {
									color: $stamps-primary-button-text-color;
									background-color: $stamps-primary-button-bg-color;
									border-radius: $stamps-menu-badge-border-radius;
									margin-left: 8px;
									padding: $stamps-ui-input-padding;
								}

								.feature-gate-icon {
									background: background-image-url($stamps-header-feature-gate-icon) no-repeat;

									&.submenulist-upgrade {
										background: background-image-url($stamps-header-feature-gate-icon-hover) no-repeat;
									}
								}

								&.p-menuitem-header {
									&.p-menuitem-active {
										background-color: transparent !important;
										color: $stamps-header-submenu-item-active-text-color !important;

										.p-menuitem-text {
											color: $stamps-header-submenu-item-active-text-color !important;
											font-weight: $stamps-header-submenu-item-active-font-weight;
										}

										.feature-gate-icon {
											background: background-image-url($stamps-header-feature-gate-icon-hover) no-repeat;
											&.submenulist-upgrade {
												background: background-image-url($stamps-header-feature-gate-icon) no-repeat;
											}
										}

										color: #fff;
										background-color: $stamps-primary-header-color;
									}
								}
							}

							&:hover {
								.feature-gate-icon {
									background: background-image-url($stamps-header-feature-gate-icon) no-repeat;
									&.submenulist-upgrade {
										background: background-image-url($stamps-header-feature-gate-icon-hover) no-repeat;
									}
								}
							}
						}
					}
				}
			}

			.header-panel-menu-toggle-button {
				// active hamburger icon
				&.hmenu-active {
					background: transparent background-image-url($stamps-header-close-icon) no-repeat center;
					background-size: 25px 25px;
				}
			}
		}

		/* styles for tablet view */
		.header-panel-tablet {
			flex: 1;
			display: flex;

			.p-menubar {
				display: flex;
			}

			.shipstation-upgrade {
				.p-menuitem-link {
					background: transparent background-image-url('shipstation-upgrade.png') no-repeat left 5px center;

					&:hover {
						color: #fff;
						background: transparent background-image-url('shipstation-upgrade-hover.png') no-repeat left 5px center;
					}

					.p-menuitem-text {
						display: block;
						text-align: right;
					}
				}
			}

			.header-panel-tablet-left {
				float: left;
				flex: 1;

				.p-menubar {
					display: flex;

					.p-menubar-root-list {
						> .p-menuitem {
							> .p-menuitem-link {
								border-bottom: $stamps-menu-item-inactive-border;
								font-weight: $stamps-menu-item-inactive-font-weight;
								height: $stamps-menu-item-link-height;
								line-height: $stamps-menu-item-link-height;
								text-transform: $stamps-menu-item-text-transform;

								.p-menuitem-icon,
								.p-submenu-icon {
									font-size: $stamps-pi-menu-font-size;
									margin-left: $stamps-menu-icon-margin;
								}
							}
						}
					}
				}
			}

			.header-panel-tablet-right {
				float: right;
				width: 50px;
				position: relative;
				z-index: 1000; // to bring the sub-menu forward

				.p-panelmenu-content {
					background-color: $stamps-menu-right-background-color;
					position: absolute;
					right: 0;
					width: 220px;

					.p-submenu-list {
						padding: $stamps-menu-right-padding;

						.p-menuitem {
							&-active-parent {
								border-left: $stamps-menu-item-active-border;
							}

							&:not(:first-child) {
								border-top: 1px solid $stamps-header-submenu-responsive-item-border-color;
							}

							.p-menuitem-link {
								display: flex;
								justify-content: space-between;

								&:hover {
									color: $stamps-ui-dropdown-icon-color;
									background-color: $stamps-header-submenu-item-active-bg-color !important;
								}

								.p-submenu-icon {
									font-size: $stamps-pi-menu-font-size;
								}

								.balance-text {
									display: inline-block;
								}

								.dollar-amount-text-positive,
								.dollar-amount-text-negative {
									color: $stamps-primary-button-text-color;
									background-color: $stamps-primary-button-bg-color;
									border-radius: $stamps-menu-badge-border-radius;
									margin-left: 8px;
									padding: $stamps-ui-input-padding;
								}

								&.p-menuitem-active {
									background-color: transparent;
									color: $stamps-header-submenu-item-active-text-color;

									.p-menuitem-text {
										color: $stamps-header-submenu-item-active-text-color !important;
										font-weight: $stamps-header-submenu-item-active-font-weight;
									}
								}
							}
						}
					}
				}
			}
		}

		/* styles for desktop view */
		.header-panel-desktop {
			flex: 1;
			display: flex;

			.header-menu-left {
				flex: 1;

				&.p-menubar {
					.p-menubar-root-list {
						> .p-menuitem {
							height: $stamps-menu-item-height;

							> .p-menuitem-link {
								border-bottom: $stamps-menu-item-inactive-border;
								font-weight: $stamps-menu-item-inactive-font-weight;
								height: $stamps-menu-item-link-height;
								line-height: $stamps-menu-item-link-height;
								text-transform: $stamps-menu-item-text-transform;

								.p-menuitem-icon,
								.p-submenu-icon {
									font-size: $stamps-pi-menu-font-size;
									margin-left: $stamps-menu-icon-margin;
								}
							}

							&.p-menuitem-active-parent {
								> .p-menuitem-link {
									border-bottom: $stamps-menu-item-active-border;
									font-weight: $stamps-menu-item-active-font-weight;
								}

								.p-menuitem-header {
									background-color: $menuitemActiveBgColor;

									.p-menuitem-text {
										color: $menuitemActiveTextColor;
									}

									.p-menuitem-icon,
									.p-submenu-icon {
										color: $menuitemActiveIconColor;
									}
								}
							}
						}
					}
				}
			}

			.header-menu-right {
				padding-right: $stamps-menu-item-right-padding-right;

				&.p-menubar {
					.p-menubar-root-list {
						> .p-menuitem {

							&:hover {
								background-color: $stamps-menu-item-right-edges-background-color !important;
							}

							> .p-menuitem-link {
								border: $stamps-menu-item-right-border;
								border-radius: 4px;
								height: $stamps-menu-item-right-link-height;
								line-height: $stamps-menu-item-right-link-height;
								margin-top: $stamps-menu-item-right-link-margin-top !important;
								text-transform: none;

								.p-submenu-icon {
									font-size: $stamps-pi-menu-font-size;
									margin-left: $stamps-menu-icon-margin;
								}
							}
						}

						.p-menuitem {
							.p-submenu-list {
								right: 0;
							}
						}
					}
				}
			}

			.header-menu-left,
			.header-menu-right {
				&.p-menubar {
					.p-menubar-root-list {
						> .p-menuitem {
							margin-top: $stamps-menu-item-margin-top;

							> .p-menuitem-link {
								padding: $stamps-header-menu-item-padding;
								margin: $stamps-header-menu-item-margin;
							}

							&:hover {
								background-color: $stamps-menu-item-hover-background-color;

								> .p-menuitem-link {
									background-color: $stamps-menu-item-hover-background-color;
								}
							}

							&.user-name-menu-item {
								> .p-menuitem-link {
									.p-menuitem-text {
										font-weight: normal;
									}
								}
							}

							&.user-balance-menu-item {
								margin-right: 10px;

								> .p-menuitem-link {
									height: $stamps-menu-item-right-link-height;
									line-height: $stamps-menu-item-right-link-height;

									.p-menuitem-text {
										font-weight: normal;

										.balance-text {
											display: none;
										}

										.dollar-amount-text {
											font-weight: bold;
										}

										.dollar-amount-text-positive {
											color: #007038;
											font-weight: 600;
										}

										.dollar-amount-text-negative {
											color: #a91919;
											font-weight: 600;
										}
									}
								}
							}
						}
					}

					.p-menubar-custom {
						padding: 0;
					}
				}
			}
		}

		.header-panel-tablet,
		.header-panel-phone {
			.get-supplies {
				.p-menuitem-link {
					background: none;

					&:hover {
						color: #fff;
						background: none;
						background-color: $stamps-header-submenu-getsupplies-active-bg-color !important;
					}
				}
			}

			.help-center {
				.p-menuitem-link {
					background: none;

					&:hover {
						background: none;
					}
				}
			}

			.p-menuitem-active-parent {
				> .p-menuitem-link {
					background-color: transparent !important;
				}
			}
		}

		.get-supplies {
			.p-menuitem-link {
				background: background-image-url($stamps-header-submenu-getsupplies-icon) no-repeat right 12px center;

				&:hover {
					color: #fff;
					background: background-image-url($stamps-header-submenu-getsupplies-icon-hover) no-repeat right 12px center;
					background-color: $stamps-header-submenu-getsupplies-active-bg-color !important;
				}
			}
		}

		.help-center {
			.p-menuitem-link {
				background: background-image-url('open-link-icon.png') no-repeat right 105px center;
				background-size: 12px;

				&:hover {
					color: #0F0F10;
					background: background-image-url($stamps-header-submenu-open-link-icon-hover) no-repeat right 105px center;
					background-color: $stamps-header-submenu-getsupplies-active-bg-color !important;
					background-size: 12px;
				}
			}
		}

		.header-menu-center {
			display: flex;
			margin-right: $adl-padding-sm;

			.print-limit-details {
				display: flex;
				align-items: center;
				gap: $adl-gap;
			}
		}
	}
}
