@mixin ui-fineprint {
    .common-ui-fineprint {
        display: flex;
        align-items: flex-start;
        gap: $stamps-ui-fineprint-gap;
    
        .text {
            flex: 1;
            font-size: $stamps-ui-fineprint-font-size;
            font-weight: $stamps-ui-fineprint-font-weight;
            line-height: $stamps-ui-fineprint-line-height;
        }
    
        i {
            width: 16px;
            height: 16px;
            background-size: contain;
            mask-size: cover;
            background-color: $stamps-ui-fineprint-default-icon-color;
        }
    
        // Colors are not fully declared in Figma. Will need to update as needed. 
        i.affirm {
            mask-image: background-image-url('icons/affirm-outline-off.svg');
        }
    
        i.error {
            mask-image: background-image-url('icons/error-outline-off.svg');
        }
    
        i.featured {
            mask-image: background-image-url('icons/featured-outline-off.svg');
        }
    
        i.help {
            mask-image: background-image-url('icons/help-outline-off.svg');
        }
    
        i.information {
            mask-image: background-image-url('icons/information-outline-off.svg');
            background-color: $stamps-ui-fineprint-information-icon-color;
        }
    
        i.sparkle {
            mask-image: background-image-url('icons/sparkle-outline-off.svg');
        }
    
        i.warning {
            mask-image: background-image-url('icons/warning-outline-off.svg');
        }
    }
}
