@mixin grid() {
	// local variables
	$_toolbar-height: 36px;
	$_toolbar-item-height: 20px;

	%card {
		border: 1px solid $ag-grid-border-color;
	}

	%tab {
		border: 1px solid transparent;
		border-bottom-width: 0;
		margin: $ag-grid-grid-size;
		margin-bottom: 0;
		padding: $ag-grid-grid-size $ag-grid-grid-size * 2;
	}

	%selected-tab {
		background-color: $ag-grid-default-background;
		border-bottom: 2px solid $ag-grid-primary-color;
		border-bottom: 2px solid $ag-grid-default-background;
		border-color: $ag-grid-border-color;
	}

	.common-grid {
		width: 100%;
		height: 100%;

		.common-grid-wrapper {
			height: 100%;
			display: flex;
			flex-direction: column;

			.ag-grid-container {
				flex: 1;
				overflow: hidden;
			}

			.ag-root-wrapper {
				height: 100%;
			}

			.common-grid-pagination {
				padding: $stamps-pagination-bar-padding;
				min-width: 420px;
				text-align: left;
				display: flex;
				flex-wrap: nowrap;
				border-top: $stamps-pagination-bar-border;

				.pagination-toolbar {
					min-width: 160px;

					button {
						&.p-button-icon-only {
							.p-button-icon {
								width: 20px;
								height: $_toolbar-item-height;
								line-height: $_toolbar-item-height;
								font-size: 14px;
							}

							.p-button-label {
								line-height: $_toolbar-item-height;
							}
						}

						&.p-disabled {
							background-color: inherit;
							border-color: transparent;
						}
					}

					.pagination-input {
						width: 78px;
						vertical-align: middle;
						margin-top: 2px;
						padding: $stamps-pagination-input-padding;
					}

					.pagination-text {
						font-size: $stamps-font-size;
						line-height: $_toolbar-item-height;
						padding: 0 10px;
						display: inline-block;
						vertical-align: middle;
						margin-top: 2px;
					}

					.pagination-button {
						vertical-align: middle;

						.p-button-icon-only {
							padding-top: 1px;
						}
					}
				}

				.pagination-spacing {
					flex: 1;
				}

				.pagination-dropdown {
					width: 170px;
					min-width: 170px;
				}

				.common-ui-element-wrapper {
					display: inline-flex;

					.label-container {
						width: auto;
						font-size: $stamps-font-size;
						margin-bottom: 0;
					}

					.input-container {
						margin-bottom: 0;

						.p-inputtext {
							padding: 4px;
							text-align: center;
						}
					}
				}
			}
		}

		.full-width-cell-renderer {
			.common-ui-spinner {
				.spinner-content {
					background-color: #fff;
				}

				.spinner-message {
					display: none;
				}
			}

			.end-message {
				margin-top: 12px;
			}
		}

		.ag-header {
			.ag-react-container {
				width: 100%;
			}
		}

		.ag-theme-common {
			@include ag-grid-theme($ag-grid-params);

			.ag-ltr,
			.ag-rtl {
				.ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
				.ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
				.ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
					border: 1px solid transparent;
					outline: initial;
				}
			}

			.ag-cell {
				text-align: left;
				font-size: $stamps-font-size;

				&.negative-amount {
					color: #008000;
				}

				&.ag-cell-inline-editing {
					padding: 7px;
					height: auto;

					.ag-cell-edit-input {
						padding: 4px;
					}
				}
			}

			.ag-header-cell {
				.ag-icon-menu,
				.ag-icon-desc,
				.ag-icon-asc {
					color: $ag-grid-column-options-icon-color;
				}

				.ag-header-cell-button {
					display: none;
				}

				&:hover {
					.ag-header-cell-button {
						display: block;
					}
				}
			}

			.ag-menu-list {
				padding: 0 4px;
			}

			.ag-menu {
				border-radius: $ag-grid-column-options-menu-border-radius;
				box-shadow: $ag-grid-column-options-menu-box-shadow;

				.ag-icon-tick {
					color: $ag-grid-column-options-icon-color;
				}

				.ag-tab-body {
					padding: 4px;

					.ag-menu-list {
						padding: 0;

						.ag-menu-separator {
							display: $ag-grid-column-options-separator-display;
						}
					}
				}

			}

			// context menu spacing
			.ag-menu-option {
				height: $ag-grid-grid-size * 7;
				height: $ag-grid-column-options-menu-option-height;

				.ag-icon {
					color: $ag-grid-column-options-icon-color;
				}

				.ag-icon-pin {
					rotate: $ag-grid-column-options-icon-pin-rotate;
				}

				.ag-menu-option-text {
					text-align: $ag-grid-column-options-text-align;
				}

				.ag-icon-small-right {
					padding-right: 10px;
				}
			}

			.ag-column-select-panel {
				.ag-column-tool-panel-column-group,
				.ag-column-tool-panel-column,
				.ag-primary-cols-header-panel {
					height: $ag-grid-column-options-tool-panel-height;
					padding: $ag-grid-column-options-tool-panel-item-padding;

					.ag-primary-cols-filter {
						font-family: $stamps-font-family;
						padding: $ag-grid-column-options-search-padding;
						border-radius: $ag-grid-column-options-search-border-radius;
					}
				}

				.ag-primary-cols-header-panel {
					border-top: $ag-grid-column-options-search-border-top;
					height: $ag-grid-column-options-search-height;
				}
			}

			.ag-tab-header {
				border-bottom: 1px solid $ag-grid-border-color;

				.ag-tab {
					margin-bottom: $ag-grid-column-options-tab-margin-bottom;

					.ag-icon-menu,
					.ag-icon-columns {
						color: $ag-grid-column-options-icon-color;
					}
				}

				.ag-tab.ag-tab-selected {
					background-color: $ag-grid-default-background;
					border-bottom-color: $ag-grid-column-options-tab-border-bottom-color;
					border-radius: $ag-grid-column-options-tab-border-radius;
				}
			}

			.ag-tab-body,
			.ag-popup-editor,
			.ag-menu {
				background-color: $ag-grid-default-background;
				color: $ag-grid-foreground-color;
			}

			.ag-overlay {
				pointer-events: auto;

				.ag-overlay-panel {
					pointer-events: auto;

					.ag-overlay-wrapper {
						background-color: transparentize($ag-grid-background-color, .5);

						.ag-overlay-loading-center {
							padding: 0;
							border: 0;
						}
					}
				}
			}

			.ag-icon-checkbox-checked, .ag-icon-checkbox-indeterminate {
				background-color: $ag-grid-icon-checked-background-color;
				color: $ag-grid-icon-checked-color;
			}

			.ag-icon-checkbox-indeterminate {
				padding: $ag-grid-indeterminate-icon-padding;
				width: $ag-grid-indeterminate-icon-width;
				height: $ag-grid-indeterminate-icon-height;
				position: $ag-grid-indeterminate-icon-position;
			}

			.ag-icon-checkbox-checked:before {
				content: $ag-grid-icon-tick;
			}

			.ag-icon-checkbox-indeterminate:before {
				content: $ag-grid-icon-minus;
				position: $ag-grid-indeterminate-icon-content-position;
				left: $ag-grid-indeterminate-icon-content-left;
				top: $ag-grid-indeterminate-icon-content-top;
			}
		}

		// for when auto height is used but there is no row data
		.ag-overlay-no-rows-wrapper.ag-layout-auto-height {
			padding-top: 43px;
		}
	}
}
