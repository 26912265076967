@mixin tutorial-modal-arrow ($dir, $size, $color, $border-color) {
	.tutorial-modal-arrow {
		&.#{$dir} {
			position: absolute;
			width: 0;
			height: 0;
			border: $size solid transparent;

			@if ($dir == 'north') {
				border-bottom-color: $border-color;
			} @else if ($dir == 'west') {
				border-right-color: $border-color;
			}  @else if ($dir == 'east') {
				border-left-color: $border-color;
			} @else if ($dir == 'south') {
				border-top-color: $border-color;
			}

			&::after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				border: $size solid transparent;

				@if ($dir == 'north') {
					top: ($size - 1px) * -1;
					left: $size * -1;
					border-bottom-color: $color;
				} @else if ($dir == 'west') {
					top: $size * -1;
					left: ($size - 1px) * -1;
					border-right-color: $color;
				}  @else if ($dir == 'east') {
					top: $size * -1;
					right: ($size - 1px) * -1;
					border-left-color: $color;
				} @else if ($dir == 'south') {
					bottom: ($size - 1px) * -1;
					left: $size * -1;
					border-top-color: $color;
				}
			}
		}
	}
}
