@mixin ui-dropdown() {
	.common-ui-dropdown {
		flex: 1 1 auto;
		padding-top: 2px;

		// Dev note:
		// This prevents the flexbox child item from growing out of parent.
		// Another solution would be to set overflow: hidden, but we can't
		// do that here as the dropdown menu element will be hidden.
		width: 100%;
		min-width: 0;

		.p-placeholder {
			color: #848484;
			font-style: $stamps-ui-input-placeholder-font-style;
		}

		.p-dropdown {
			// dropdowns should be streched 100% to its immediate parent div.
			width: 100%;

			// override the rule in primereact.min.css, 12.5 em.
			min-width: 0;

			.p-dropdown-label {
				border: none;

				// this overrides the rule in the designer to have height derived at 32px.
				padding: $stamps-ui-input-padding;
				padding-right: 0;
			}

			.p-dropdown-trigger-icon {
				font-size: $stamps-ui-dropdown-icon-size;
				color: $stamps-ui-dropdown-icon-color;

				&:before {
					content: $stamps-ui-dropdown-icon;
				}
			}
		}
	}

	.p-dropdown-panel {
		border-radius: 4px;
		border-color: $stamps-ui-dropdown-list-border-color;
		margin-top: $stamps-ui-dropdown-list-margin-top;

		.p-dropdown-item {
			line-height: $stamps-ui-dropdown-item-line-height;

			&.p-highlight {
				font-weight: $stamps-ui-dropdown-item-active-font-weight;
				border-radius: $stamps-ui-dropdown-list-active-hover-border-radius;
			}

			&:hover {
				border-radius: $stamps-ui-dropdown-list-active-hover-border-radius;
			}

			&.p-disabled {
				color: $stamps-ui-dropdown-list-disabled-font-color;
			}
		}
	}
}
