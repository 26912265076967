@mixin branded-tracking() {
	.common-branded-tracking {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;

		.common-ui-checkbox {
			margin-top: 20px;
		}

		.preview-and-editor-wrapper {
			display: flex;
			overflow: hidden;
			flex: 1;
		}

		.branded-tracking-viewport {
			flex: 1;
			overflow: auto;
			border: 1px solid #ccc;
			background-color: #929292;

			.header {
				background: $stamps-primary-button-text-color;
			}

			.controls {
				width: 50%;
			}

			.branded-tracking-preview {
				background-color: #929292;
				padding-top: 30px;
				padding-bottom: 20px;
				overflow: hidden;
			}
		}

		.branded-tracking-viewport-toggle {
			.tab-button {
				margin-left: 5px;

				// change Icon button dimensions from default 24px to 30px
				.p-button-icon-only {
					width: 30px;
					height: 30px;

					.p-button-icon {
						width: 28px; // 2px less of button width because of the border width
						height: 28px; // 2px less of button icon because of the border width
						margin-top: -14px;
						margin-left: -14px;
						background-size: contain;
					}
				}

				.mobile-tab-icon {
					background:
						transparent
						background-image-url('branded-tracking/branded-tracking-mobile.png')
						no-repeat
						center;
					background-size: contain;
				}

				.desktop-tab-icon {
					background:
						transparent
						background-image-url('branded-tracking/branded-tracking-desktop.png')
						no-repeat center;
					background-size: contain;
				}

				.tablet-tab-icon {
					background:
						transparent
						background-image-url('branded-tracking/branded-tracking-tab.png')
						no-repeat
						center;
					background-size: contain;
				}

				&.active .p-button {
					border-color: $stamps-branded-tracking-tab-button-active-border-color;
					background: $stamps-branded-tracking-tab-button-active-background-color;
				}
			}

			.tabs-container {
				display: flex;
				justify-content: center;
				background: $stamps-primary-button-text-color;
				align-items: center;
				padding: 5px 30px 5px 0;
			}

			@media (max-width: 1000px) {
				.preview-section {
					display: none;
				}

				.preview-modal {
					display: block;
				}
			}
			@media (min-width: 1001px) {
				.preview-section {
					display: block;
				}

				.preview-modal {
					display: none;
				}
			}
		}

		.branded-tracking-editor {
			width: 450px;
			position: relative;

			.common-ui-element-wrapper {
				align-items: flex-start; //override default center to start
				flex-direction: column; //override default row to column

				.label-container {
					text-align: left; // override default right to left
				}

				.input-container {
					width: 100%;
				}

				.p-disabled {
					&.p-inputtext {
						background: $stamps-secondary-color;
					}

					.p-fileupload-choose {
						input[type=file] {
							cursor: default !important;
						}
					}
				}

				&.links-tab-input-wrapper {
					.label-container {
						@include heading-label;

						label {
							display: inline-flex;

							i {
								height: 20px;
								width: 20px;
								background-image: background-image-url('branded-tracking/branded-tracking-tablet-social-links.png');
								background-repeat: no-repeat;
								background-position: -11px -8px;
								display: inline;

								&::before {
									display: none;
								}

								&.pi-twitter {
									background-position: -65px -6px;

									//To hide the empty icon symbol
									&::before {
										display: none;
									}
								}

								&.instagram {
									background-position: -225px -6px;

									&::before {
										display: none;
									}
								}

								&.pinterest {
									background-position: -171px -8px;

									&::before {
										display: none;
									}
								}

								&.youtube {
									background-position: -121px -4px;

									&::before {
										display: none;
									}
								}

								&.linkedin {
									background-position: -280px -6px;

									&::before {
										display: none;
									}
								}
							}
						}
					}
				}

				&.return-policy-input-wrapper {
					.label-container {
						font-size: 12px;
						font-weight: bold;
						margin-bottom: 3px;
					}
				}

				&.contact-input-wrapper {
					.label-container {
						@include heading-label;
					}
				}
			}

			.p-tabview-panels {
				border-bottom: 0;
				margin-bottom: 50px;
			}

			.branded-tracking-design-tab-panel {
				padding: 5px;

				.pick-theme {
					display: flex;
					flex-direction: column;

					h2 {
						margin-bottom: $stamps-branded-tracking-theme-picker-header-margin;
						@include heading-label;
					}

					> div {
						display: flex;
						margin-bottom: 20px;
						justify-content: flex-start;

						.common-ui-radiobutton {
							padding: $stamps-branded-tracking-theme-picker-padding;

							label {
								background-image:
									background-image-url(
										'branded-tracking/branded-tracking-themes.png'
									);
								background-repeat: no-repeat;
								background-position: -16px -15px;
								display: inline-block;
								height: 25px;
								width: 81px;
								text-indent: -5000px;
								border: 1px solid #eef0f2;
								margin-left: 6px;
							}

							&:nth-child(2) label {
								background-position: -227px -15px;
							}

							&:nth-child(3) label {
								background-position: -122px -15px;
							}
						}
					}
				}

				section.custom-header {
					margin: 10px 0;
					position: relative;

					h2 {
						margin-bottom: $stamps-branded-tracking-file-upload-header-margin;
						@include heading-label;
					}

					.file-upload-container {
						display: flex;
						flex-direction: row;
						align-items: center;

						.logo-name {
							flex-grow: 1;
							margin-right: 10px;

							.input-container {
								margin-bottom: 0;
							}
						}

						.common-ui-fileupload {
							.p-button.p-disabled {
								padding: 2px 12px;
							}

							.p-button-icon-left {
								margin-right: 16px;
							}
						}
					}
				}

				.common-ui-checkbox {
					margin: 10px 0;
				}

				.sms-opt-in-note {
					font-size: 12px;
					font-style: italic;
				}
			}

			.branded-tracking-buttons {
				position: absolute;
				bottom: 0;
				border-top: 1px solid #e0e1e2;
				margin-top: auto;
				padding: 10px 20px;
				width: 100%;
				background: #fff;

				.common-ui-button {
					margin-right: 10px;
				}
			}

			.branded-tracking-links-tab-panel {
				padding-bottom: 15px;

				// common styles for links tabs
				h2 {
					@include h4;
					margin-bottom: $stamps-branded-tracking-section-margin-bottom;
				}

				section {
					margin-bottom: $stamps-branded-tracking-section-margin-bottom;
				}

				.p-grid {
					gap: $stamps-branded-tracking-section-margin-bottom 0;

					.right-col {
						padding-left: 10px;
					}
				}

				.common-ui-button {
					position: fixed;
					bottom: 0;
					background: #fff;
					width: 370px;
					padding: 20px;
				}

				.contact-section {
					margin-bottom: 40px;
				}
			}

			.branded-tracking-stores-tab-panel {
				.header-section {
					@include heading-label;
					margin: 5px 0 15px;
				}

				.common-ui-checkbox {
					margin-top: $stamps-branded-tracking-stores-checkbox-margin-top;
				}

				.description-section {
					margin: 40px 0 70px;
					@include paragraph;
				}

				.no-stores-section {
					margin-bottom: 30px;
					line-height: 1.5;
					font-size: 13px;
					font-style: italic;
				}

				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
					position: relative;

					li {
						margin-bottom: 10px;

						&.disabled-list {
							opacity: .25;
						}

						.store-icon {
							margin-right: 8px;
						}

						> div {
							display: flex;
							align-items: center;
						}
					}

					label {
						display: inline-flex;
						align-items: center;
						cursor: pointer;
					}
				}
			}

			.branded-tracking-emails-tab-panel {
				.common-ui-checkbox {
					margin-top: 0;
				}

				section {
					margin-bottom: $stamps-branded-tracking-section-margin-bottom;
				}

				.email-status-container {
					padding-bottom: 4px;
					margin-bottom: 8px;

					.shipping-status-heading-container {
						display: flex;
						padding-bottom: 10px;
						margin-bottom: 4px;

						h2 {
							@include heading-label;

							&.status {
								width: 280px;
							}
						}
					}

					.common-ui-checkbox {
						display: flex;
						flex-direction: row-reverse;
						justify-content: flex-end;

						.p-checkbox-label {
							width: 280px;
						}
					}
				}
			}
		}

		.branded-tracking-header {
			border: none;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			padding: $stamps-branded-tracking-header-padding;

			h1 {
				@include h3;
				margin-bottom: $stamps-branded-tracking-header-margin-bottom;
			}

			.learning-center {
				padding: 15px 15px 15px 25px;
				background:
					transparent
					background-image-url('branded-tracking/branded-tracking-learning-center.png')
					no-repeat
					0 center;
				color: #677893;
				font-weight: 600;
				outline: 0;
			}

			p {
				@include paragraph;
			}
		}
	}

	.common-branded-tracking,
	.common-branded-tracking-preview-modal {
		// .branded-tracking-view-desktop
		@include branded-tracking-view-factory(desktop, (
			background-image: $stamps-branded-tracking-browser-frame,
			nature-frame-width: 2804px,
			nature-frame-height: 2196px,
			nature-frame-top-inset: 168px,
			nature-frame-left-inset: 3px,
			nature-frame-right-inset: 3px,
			nature-frame-bottom-inset: 3px,
			scale: .22
		));

		// .branded-tracking-view-tablet
		@include branded-tracking-view-factory(tablet, (
			background-image: $stamps-branded-tracking-tablet-frame,
			nature-frame-width: 2294px,
			nature-frame-height: 3300px,
			nature-frame-top-inset: 415px,
			nature-frame-left-inset: 124px,
			nature-frame-right-inset: 124px,
			nature-frame-bottom-inset: 280px,
			scale: .22
		));

		// .branded-tracking-view-mobile
		@include branded-tracking-view-factory(mobile, (
			background-image: $stamps-branded-tracking-iphonex-frame,
			nature-frame-width: 550px,
			nature-frame-height: 1092px,
			nature-frame-top-inset: 154px,
			nature-frame-left-inset: 38px,
			nature-frame-right-inset: 40px,
			nature-frame-bottom-inset: 135px,
			scale: .72,
		));
	}

	.common-branded-tracking-modal {
		min-width: 1200px;
		overflow: auto; // fix IE11 layout issue

		.preview-and-editor-wrapper {
			flex: 1 1 auto; // fix IE11 layout issue
		}
	}

	.store-disabled-tooltip {
		margin-right: 20px; // do not overlap vertical scrollbar
		margin-top: 3px;
		max-width: 330px !important; // do not overlap preview section
	}
}
