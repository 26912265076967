@mixin feedback() {
	.common-feedback {
		&.p-dialog.modal {
			max-width: 393px;
			width: 100%;
		}

		.body {
			height: 100%;

			.input-container {
				font-weight: bold;
				flex: 1 1 auto;

				.experience-container {
					margin: 15px 0;

					.radio-buttons {
						display: flex;
						justify-content: space-around;
						align-items: baseline;
						text-align: center;

						> div {
							flex: 1 1 100%;
						}

						.common-ui-radiobutton {
							display: flex;
							flex-direction: column-reverse;
							align-items: center;
							padding: 0 0 15px 0;

							.p-radiobutton-label {
								margin: 0 0 10px 0;
							}
						}
					}
				}

				.common-ui-element-wrapper {
					flex-direction: column;
					align-items: inherit;
					padding-bottom: 15px;
				}
			}
		}
	}
}
