@mixin ui-checkbox() {
	.common-ui-checkbox {
		padding: 5px 0;
		display: flex;

		.p-checkbox {
			flex: none; // prevent from shrinking
			width: $stamps-ui-checkbox-box-size;

			.p-checkbox-box {
				width: $stamps-ui-checkbox-box-size;
				height: $stamps-ui-checkbox-box-size;

				.p-checkbox-icon {
					font-size: $stamps-ui-checkbox-check-size;
				}

				&.p-highlight {
					background-color: $stamps-ui-checkbox-box-checked-background-color;
					color: $stamps-ui-checkbox-box-checked-color;
					border-color: $stamps-ui-checkbox-box-checked-border-color;

					&:not(.p-disabled):hover {
						border-color: $stamps-ui-checkbox-box-checked-hover-border-color;
						background-color: $stamps-ui-checkbox-box-checked-background-color;
						color: $stamps-ui-checkbox-box-checked-color;
					}
				}

				&.p-disabled {
					background-color: $stamps-ui-checkbox-box-disabled-background-color;
					border-color: $stamps-ui-checkbox-box-disabled-border-color;
					color: $stamps-ui-checkbox-box-disabled-border-color;
				}
			}
		}

		.p-checkbox-label {
			line-height: $stamps-line-height;
			display: inline-flex;
			cursor: pointer;
			margin: $stamps-ui-checkbox-label-margin;
		}
	}
}
