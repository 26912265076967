@mixin menu {
	.common-menu {
		font-family: $stamps-menu-font-family;

		.menu-item-text {
			font-size: $stamps-menu-text-font-size;
			font-weight: $stamps-menu-text-font-weight;
			color: $stamps-menu-text-color;
			line-height: $stamps-menu-text-line-height;
			text-align: left;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			.feature-gate-icon {
				position: relative;
				width: 20px;
				height: 14px;
				margin-left: 3px;
				background: background-image-url($stamps-header-feature-gate-icon) no-repeat;
				display: inline-block;

				&:hover {
					background: background-image-url($stamps-header-feature-gate-icon-hover) no-repeat;
				}
			}
		}

		.tools-wrapper {
			position: absolute;
			top: calc(50% - #{$stamps-menu-badge-height} / 2);
			height: $stamps-menu-badge-height;
			overflow: hidden;

			&.left {
				left: $stamps-menu-item-padding;
			}

			&.right {
				right: $stamps-menu-item-padding;
			}

			.badge {
				float: left;
				width: $stamps-menu-badge-width;
				height: $stamps-menu-badge-height;
				line-height: $stamps-menu-badge-height;
				font-size: $stamps-menu-badge-font-size;
				color: $stamps-menu-badge-text-color;
				background-color: $stamps-menu-badge-background-color;
				border-radius: $stamps-menu-badge-border-radius;
				text-align: center;
				vertical-align: middle;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-left: $stamps-menu-tool-margin;
				font-weight: $stamps-menu-title-text-font-weight;
			}

			.tool {
				float: left;
				height: $stamps-menu-badge-height;
				color: $stamps-menu-tool-color;
				line-height: normal;
				vertical-align: middle;
				overflow: hidden;
				display: none;
				cursor: pointer;

				&.visible {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.pi {
					font-size: $stamps-menu-icon-caret-size;
					margin-left: $stamps-menu-icon-margin-left;
					line-height: $stamps-menu-badge-height;
					color: $stamps-menu-icon-color;

					&.pi-plus,
					&.pi-cog,
					&.pi-trash,
					&.pi-pencil {
						height: $stamps-menu-icon-plus-cog-size;
						width: $stamps-menu-icon-plus-cog-size;
					}

					&.pi-sort-down-fill,
					&.pi-sort-up-fill {
						margin-left: 12px;
					}
				}
			}

			.tag {
				float: left;
				width: $stamps-menu-tag-width;
				height: $stamps-menu-tag-height;
				border-radius: $stamps-menu-tag-border-radius;

				// make sure tag height is smaller than badge height
				margin-top:
					max(
						$stamps-menu-badge-height - $stamps-menu-tag-height,
						0
					) / 2;
			}
		}

		.search-item {
			.search-input {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: $stamps-menu-search-padding;
				background-color: $stamps-menu-item-background-color;
				height: $stamps-ui-toolbar-height;
				border-bottom: 1px solid $stamps-menu-item-border-color;

				.search-input-wrapper {
					position: relative;
					border: 1px solid $stamps-menu-search-wrapper-border-color;
					border-radius: $stamps-menu-search-border-radius;
					background-color: $stamps-menu-search-background-color;
					padding:
						$stamps-menu-search-input-top-bottom-padding
						(
							$stamps-menu-search-icon-size +
							$stamps-menu-search-input-left-right-padding * 2
						)
						$stamps-menu-search-input-top-bottom-padding $stamps-menu-search-input-left-right-padding;

					&.with-advanced-search {
						padding-right:
							(
								($stamps-menu-search-icon-size * 2) +
								($stamps-menu-search-input-left-right-padding * 2) + 5
							);
					}

					::placeholder {
						font-style: $stamps-ui-input-placeholder-font-style;
					}
				}

				input {
					width: 100%;
					color: $stamps-menu-search-text-color;
					font-size: $stamps-menu-search-text-font-size;
					border: 1px solid $stamps-menu-search-border-color;
					padding: $stamps-menu-search-input-inner-padding;
					font-family: $stamps-menu-search-font-family;
					line-height: $stamps-menu-search-line-height;

					&:focus {
						border-color: $stamps-menu-search-focus-border-color;
						outline: none;
					}
				}

				.search-trigger-wrapper {
					position: absolute;
					top: 50%;
					right: 0;
					height: $stamps-menu-search-icon-size;
					margin-top: $stamps-menu-search-icon-size / -2;
					display: flex;

					justify-content: center;
					align-items: center;
					margin-right: $stamps-menu-search-input-left-right-padding;

					.pi {
						cursor: pointer;
						color: $stamps-menu-search-text-color;
						font-size: $stamps-menu-search-icon-size;

						&.pi-angle-down {
							margin-right: $stamps-menu-search-input-left-right-padding;
							font-size: $stamps-menu-search-caret-size;
						}
					}
				}
			}
		}

		.badge-item {
			position: relative;
			width: 100%;
			background-color: $stamps-menu-item-background-color;
			color: $stamps-menu-text-color;
			border-bottom: 1px solid $stamps-menu-item-border-color;
			outline: 0;

			.menu-item-text {
				padding: $stamps-menu-item-padding;
			}

			&.clickable {
				cursor: pointer;
			}

			&.disabled {
				cursor: default;
			}

			&.badge-item-badge-only {
				.menu-item-text {
					padding-right:
						$stamps-menu-item-padding +
						$stamps-menu-badge-width + $stamps-menu-tool-margin;
				}

				&.active {
					border-left: $stamps-menu-group-item-highlight-border-width solid
						$stamps-menu-group-item-highlight-border-color;
				}
			}

			&.badge-item-tools-only {
				.menu-item-text {
					padding-right:
						$stamps-menu-item-padding +
						$stamps-menu-tool-size * 2 + $stamps-menu-tool-margin;
				}
			}

			&.badge-item-badge-and-tools {
				.menu-item-text {
					padding-right:
						$stamps-menu-item-padding +
						$stamps-menu-badge-width + $stamps-menu-tool-size * 2 +
						$stamps-menu-tool-margin;
				}
			}

			&.badge-item-x-icon {
				.tools-wrapper {
					.x-icon {
						.pi {
							margin-left: 0;
							color: $stamps-menu-x-icon-color;
						}
					}
				}

				.menu-item-text {
					padding-left:
						$stamps-menu-item-padding +
						$stamps-menu-x-icon-size + $stamps-menu-tool-margin;
				}

				&.active {
					border-left: $stamps-menu-group-item-highlight-border-width solid
						$stamps-menu-group-item-highlight-border-color;
				}
			}

			&.badge-item-tag {
				.menu-item-text {
					padding-left:
						$stamps-menu-item-padding +
						$stamps-menu-tag-width + $stamps-menu-tool-margin;
				}
			}

			&.active {
				background-color: $stamps-menu-item-highlight-background-color;

				.menu-item-text {
					font-weight: $stamps-menu-title-text-font-weight;
				}
			}

			&:hover {
				background-color: $stamps-menu-item-highlight-background-color;

				.menu-item-text {
					font-weight: $stamps-menu-title-text-font-weight;
				}

				.tool {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		.group-item {
			.group-item-titlebar {
				position: relative;
				text-align: left;
				outline: 0;
				border-bottom: 1px solid $stamps-menu-item-border-color;
				border-left: $stamps-menu-group-item-highlight-border-width solid $stamps-menu-item-background-color;
				background-color: $stamps-menu-item-background-color;
				padding:
					$stamps-menu-item-padding 35px
					$stamps-menu-item-padding 7px;

				.menu-item-text {
					font-weight: $stamps-menu-title-text-font-weight;
				}

				&.group-item-titlebar-with-tools {
					padding-right:
						$stamps-menu-item-padding +
						$stamps-menu-tool-size * 2 +
						$stamps-menu-toggle-icon-size + $stamps-menu-tool-margin;
				}

				&:hover {
					.tool {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}

			.group-item-content {
				border-bottom: 1px solid $stamps-menu-item-border-color;

				.badge-item {
					border-bottom: 0 none;

					.menu-item-text {
						font-size: $stamps-menu-badge-item-font-size;

						// do not set paddings using shorthand padding;
						// do not override padding-right
						padding-top: $stamps-menu-item-padding - 2px;
						padding-bottom: $stamps-menu-item-padding - 2px;
						padding-left: $stamps-menu-item-padding * 2;
					}

					&.active {
						.menu-item-active-icon {
							position: absolute;
							left: -3px;
							top: 0;
							bottom: 0;
							margin-top: auto;
							margin-bottom: auto;
							background-color: $stamps-menu-group-item-highlight-border-color;
							width: 6px;
							height: 6px;
							transform: rotate(45deg);
						}
					}
				}
			}

			.group-item-titlebar, .badge-item {
				&:hover {
					.pi-cog, .pi-plus, .pi-trash, .pi-pencil {
						background: $stamps-menu-icon-background;
						background-size: auto;
					}

					.pi-cog {
						background-position: 0 -112px;

						&:before {
							content: $stamps-menu-icon-cog-content;
						}
					}

					.pi-plus {
						background-position: 0px -240px;

						&:before {
							content: $stamps-menu-icon-plus-content;
						}
					}

					.pi-trash {
						background-position: 0px -144px;

						&:before {
							content: $stamps-menu-icon-trash-content;
						}
					}

					.pi-pencil {
						background-position: 0px -128px;

						&:before {
							content: $stamps-menu-icon-pencil-content;
						}
					}
				}
			}

			&.active {
				.group-item-titlebar {
					border-left: $stamps-menu-group-item-highlight-border-width solid
						$stamps-menu-group-item-highlight-border-color;
				}

				.badge-item {
					border-left: $stamps-menu-group-item-highlight-content-border-width solid
						$stamps-menu-badge-item-highlight-border-color;
				}
			}

			&.collapsed {
				.group-item-content {
					display: none;
				}
			}

			&.expanded {
				.group-item-titlebar {
					border-bottom: $stamps-menu-item-group-item-expanded-border-width solid $stamps-menu-group-item-border-color;
				}
			}

			&.clickable {
				cursor: pointer;
			}
		}
	}
}
