@mixin ui-dimensions-field() {
	.common-ui-dimensions-field-wrapper {
		padding: 0; // override to avoid double padding

		> .input-container {
			margin-bottom: 0; // override to avoid double padding
		}
	}

	.common-ui-dimensions-field {
		display: flex;
		flex-wrap: nowrap;

		.common-ui-element-wrapper {
			.input-container {
				align-items: center; // make label vertically centered

				.label-container {
					width: auto; // to reset
					text-align: left;
					margin-left: 5px; // spacing between the label (e.g. L") and the input field
					margin-bottom: 0; // override to correctly center the label
				}
			}
		}
	}
}
