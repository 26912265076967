
// Common\src\assets\themes\base-theme\etc\branded-tracking-view-factory.scss
@mixin branded-tracking-view-factory($view, $view-params) {
	// parameters
	$nature-frame-width: map-get($map: $view-params, $key:  'nature-frame-width') or 0;
	$nature-frame-height: map-get($map: $view-params, $key:  'nature-frame-height') or 0;
	$nature-frame-top-inset: map-get($map: $view-params, $key:  'nature-frame-top-inset') or 0;
	$nature-frame-left-inset: map-get($map: $view-params, $key:  'nature-frame-left-inset') or 0;
	$nature-frame-right-inset: map-get($map: $view-params, $key: 'nature-frame-right-inset') or 0;
	$nature-frame-bottom-inset: map-get($map: $view-params, $key: 'nature-frame-bottom-inset') or 0;
	$scale: map-get($map: $view-params, $key: 'scale') or 1;
	$backgroundImage: map-get($view-params, 'background-image');

	$iframeWidth: ($nature-frame-width - $nature-frame-left-inset - $nature-frame-right-inset);
	$iframeHeight: ($nature-frame-height - $nature-frame-top-inset - $nature-frame-bottom-inset);
	$iframeRatio: $iframeHeight / $iframeWidth;
	$gutter: 20px; // space around device frame

	.branded-tracking-#{$view}-view {
		position: relative;
		width: ceil($nature-frame-width * $scale);
		height: ceil($nature-frame-height * $scale);
		margin: 0 auto;

		.device-frame {
			position: relative;
			width: 100%;
			height: 100%;
			background: transparent background-image-url($backgroundImage) no-repeat center;
			background-size: contain;
			margin: $gutter 0;
			padding:
				ceil($nature-frame-top-inset * $scale)
				ceil($nature-frame-right-inset * $scale)
				ceil($nature-frame-bottom-inset * $scale)
				ceil($nature-frame-left-inset * $scale);
			overflow: hidden;
		}

		iframe {
			// set min-width to make sure that the iframe content stays true to desktop layout
			// see https://stamps.atlassian.net/browse/BT-153
			@if ($view == 'desktop') {
				min-width: 1200px;
				min-height: ceil(1200px * $iframeRatio);
				transform-origin: left top;
				transform: scale($iframeWidth * $scale / 1200px);
			} @else {
				height: 100%;
				width: 100%;
			}
		}
	}
}
