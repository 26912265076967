@mixin payment() {
	.common-payment {
		&.add-funds-modal {
			width: 400px;

			.common-ui-button {
				&.link {
					display: block;
					margin-bottom: $stamps-payment-button-margin;

					.p-button-label {
						color: $stamps-payment-button-text-color;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}

			.free-account-credit {
				display: inline-block;
				margin: $stamps-payment-link-margin;
			}

			.info-banner-container {
				padding: $stamps-payment-info-banner-space;
			}

			.add-other-amount {
				display: flex;
				align-items: center;
				margin-bottom: 10px;

				.label-container {
					margin-bottom: 0;
					text-align: left;
					width: auto;
				}

				.input-container {
					margin-bottom: 0;
				}
			}
		}

		&.account-balance-limit-modal {
			width: 350px;
			line-height: $stamps-line-height;
		}

		&.auto-fund-account-modal {
			.auto-fund-account {
				// remove the padding when AutoFundAccountWrapper is embedded in a modal
				padding: 0;
			}
		}

		&.auto-fund-account {
			width: 350px;
			padding: 10px;

			.label-container {
				width: 170px;
				text-align: left;
			}

			.input-container {
				width: 50px;
				flex: none;
			}

			.error-area {
				height: 60px;

				.error-icon {
					background: background-image-url($stamps-modal-error-icon) no-repeat left center;
					background-size: 26px;
					padding-left: 35px;
					height: 40px;
				}
			}
		}

		&.confirm-transaction-modal {
			width: 350px;
			line-height: $stamps-line-height;
		}

		&.past-due-service-fees-modal {
			width: 350px;
		}

		&.processing-purchase-modal {
			width: 350px;

			.p-dialog-content {
				text-align: center;

				.p-progressbar {
					height: 10px;
					margin-top: 15px;
				}
			}
		}
	}
}
