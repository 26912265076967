@mixin account-settings {
	.account-settings-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;

		.settings-content {
			height: 100%;
			width: 100% !important; // override common settings styling
		}
	}
}
