@mixin account-ship-from-address {
	.account-ship-from-address {
		text-align: left;
		padding: $stamps-account-content-padding;
		min-width: 240px;

		.header-text {
			margin-bottom: 20px;
		}

		.main-contact {
			max-width: 400px;

			.label-container {
				flex: none; // prevent from label shrinking
			}

			.common-ui-dropdown {
				.p-placeholder {
					color: $primereact-textColor;
					font-style: normal;
				}
			}

			p {
				padding: 2px 0;
				margin: 0 0 5px 130px;
				white-space: nowrap;
			}
		}

		.save-button {
			margin: 25px 0 15px 130px;

			&.mobile-wrapper {
				margin-left: 0;
			}
		}
	}
}
