@mixin authentication() {
	.common-authentication {
		&.authentication-signin {
			background: background-image-url($stamps-signin-background-image) no-repeat bottom;
			background-color: $stamps-signin-background-color;
			background-size: cover;
			height: $stamps-signin-container-height;
			left: $stamps-signin-container-left;
			padding: $stamps-signin-container-padding;

			&.mobile {
				background: #fff;

				#customerNotification {
					bottom: 90px; // position it to be above `privacy-footer`
				}
			}

			.link-separator {
				cursor: default;
				padding: 0 5px;
				visibility: $stamps-signin-link-separator-visibility;
			}

			.primary-header, .signin-primary-header {
				font-size: $stamps-signin-header-font-size;
				font-weight: $stamps-signin-header-font-weight;
				padding: $stamps-signin-header-padding;
				text-align: $stamps-signin-header-text-align;

				&.tablet {
					font-size: 35px;
				}
			}

			.signin-primary-header {
				color: $stamps-signin-primary-header-color;
			}

			.authentication-signin-wrapper {
				flex-direction: column;
				overflow: auto;
				display: flex;
				text-align: center;
				height: calc(100% - 40px); // fix IE11 scrolling issue

				&.mobile {
					height: unset;
				}

				.brand-logo {
					background: background-image-url($stamps-signin-brand-logo) no-repeat center;
					height: 28px;
				}

				.stamps-indigo-logo {
					background: background-image-url($stamps-signin-indigo-brand-logo) no-repeat center;
					height: 28px;
					background-size: contain;
				}

				.form-container {
					flex: 1;

					.form-wrapper {
						background-color: #fff;
						border: $stamps-signin-form-border;
						border-radius: $stamps-signin-form-border-radius;
						display: inline-block;
						width: $stamps-signin-form-max-width;
						min-height: $stamps-signin-form-min-height;
						padding: $stamps-signin-form-padding;
						position: $stamps-signin-container-position;
						top: $stamps-signin-container-top;
						transform: $stamps-signin-container-transform;

						&.mobile {
							width: 100%;
							padding: $stamps-signin-form-padding-mobile;
							border: none;
							position: inherit;
							transform: none;
							margin-top: $stamps-signin-mobile-form-wrapper-margin-top;
						}
					}
				}
			}

			.form {
				width: $stamps-signin-form-width;
				display: flex;
    			flex-direction: column;

				.content {
					padding: 0px 0px $stamps-signin-header-padding;
					text-align: left;
				}

				.common-ui-element-wrapper {
					display: $stamps-signin-element-display;
					width: 100%;
					padding: 0;
					text-align: $stamps-signin-header-text-align;

					.common-ui-input-text {
						flex: 1 1 auto;
					}

					.input-container {
						flex-direction: column;
					}

					.common-ui-error-container, .common-ui-error-text {
						line-height: 0;
						text-align: left;
						flex: 1 1 auto;
					}

					.show-password {
						margin-top: $stamps-signin-hide-password-icon-margin-top;
					}

					&.mobile {
						width: 100%;
					}

					.label-container {
						text-align: left;
					}


				}

				.row-container {
					display: flex;
					line-height: 24px;
					margin-bottom: $stamps-signin-row-container-forgot-flows-margin-bottom;
					padding: $stamps-signin-row-container-padding;
					flex-direction: column;

					&:last-child {
						margin-bottom: 0;
					}

					&.mobile {
						width: 100%;
					}

					&.centered {
						justify-content: center;
					}

					.common-ui-button {
						width: $stamps-signin-button-width;

						.p-button {
							height: 40px;
							width: inherit;
						}
					}
				}
			}

			/**
			* DEV NOTES FOR WEBAPPS-27501
			* ---
			* The style rules below are used to display customer notifications,
			* which are currently used in the legacy and new React applications
			*/
			#customerNotification {
				position: absolute;
				bottom: 40px;
				left: 0;
				line-height: $stamps-line-height;
				width: 100%;
				padding: 20px 75px 20px 30px;
				color: #fff;
				background-color: $stamps-primary-color;
				opacity: .95;
				display: none;

				#messageHeader {
					font-size: 26px;
					line-height: 24px;
				}

				#innerAlert {
					font-size: 14px;
					margin: 10px 0 0 0;
				}

				.notificationClose {
					position: absolute;
					top: 20px;
					right: 30px;
					width: 16px;
					height: 16px;

					.pi {
						&.pi-times {
							font-size: $stamps-icon-button-icon-size;
							color: #fff;
						}
					}
				}
			}

			.privacy-footer {
				/* Layout */
				display: flex;
				align-items: center;
				justify-content: center;
				height: $stamps-signin-footer-height;

				/* Color */
				background-color: $stamps-signin-footer-background-color;
				color: $stamps-signin-footer-color;
				opacity: .8;

				/* Typography */
				font-size: $stamps-signin-link-font-size;
				line-height: $stamps-signin-footer-line-height;

				/* Spacing */
				margin-top: $stamps-signin-footer-margin-top;

				&.mobile {
					opacity: 1;
					display: flex;
					flex-direction: column;
					border-top: $stamps-signin-privacy-links-mobile-border-top;
				}

				.privacy-links {
					cursor: pointer;

					&.tablet {
						font-size: 18px;
					}

					&.mobile {
						text-decoration: underline;
						margin-top: $stamps-signin-privacy-links-margin-top;

						&:last-of-type {
							margin-bottom: $stamps-signin-privacy-links-margin-top;
						}
					}
				}
			}
		}

		&.authentication-signin-form {
			display: flex;
			justify-content: center;

			.signup-row {
				display: flex;
				justify-content: flex-end;

				.signup-link,
				.visit-website-link:not(.mobile) {
					color: #333;
					line-height: $stamps-line-height;
				}

				&.tablet {
					.signup-link {
						font-size: 18px;
					}
				}

				&.mobile {
					justify-content: center;
				}
			}

			&.in-web-store .form {
				margin: 0 !important;
			}

			.form {
				.common-ui-element-wrapper {
					.show-password {
						margin-top: $stamps-signin-hide-password-icon-margin-top;
					}
				}

				hr {
					margin-bottom: $stamps-signin-row-container-margin-bottom;
					width: 100%;
				}
				&.mobile hr {
					margin: $stamps-signin-form-mobile-hr-margin;
				}

				&.tablet {
					.p-inputtext {
						line-height: 38px;
						font-size: 18px;
					}
				}

				&.mobile {
					width: 100%;
					margin: 0;
				}

				.row-container {
					margin-bottom: $stamps-signin-row-container-margin-bottom;

					.forgot-link {
						color: $stamps-signin-link-color;
						padding: $stamps-signin-forgot-link-padding;
						align-self: flex-start;
						line-height: 24px;
						white-space: $stamps-signin-forgot-link-white-space;

						&.tablet {
							font-size: 18px;
							padding-top: 10px;
						}

						&.mobile {
							padding: 0;
						}
					}

					.remember-username-label {
						line-height: $stamps-signin-form-checkbox-label-line-height;

						&.tablet {
							font-size: 18px;
						}
					}

					.reset-links {
						font-size: $stamps-signin-reset-links-font-size;
					}

					.signin-primary-button button {
						background-color: $stamps-signin-primary-button-bg-color;
						color: $stamps-signin-primary-button-text-color;

						&:hover {
							background-color: $stamps-signin-primary-button-hover-bg-color;
						}

						&:active {
							background-color: $stamps-signin-primary-button-active-bg-color;
						}
					}

					.signin-secondary-button button {
						background-color: $stamps-signin-secondary-button-bg-color;
						border-color: $stamps-signin-secondary-button-border-color;
						color: $stamps-signin-secondary-button-text-color;

						&:hover {
							background-color: $stamps-signin-secondary-button-hover-bg-color !important;
							border-color: $stamps-signin-secondary-button-hover-border-color !important;
						}

						&:active {
							background-color: $stamps-signin-secondary-button-active-bg-color !important;
							border-color: $stamps-signin-secondary-button-active-border-color !important;
						}
					}

					&.mobile .reset-links {
						@include paragraph;
					}

					&.getting-started-row.mobile {
						margin-bottom: 24px;
					}

					&.centered {

						.learning-center, .track-a-package, .visit-website {
							display: inline-block;
							color: #1C14FF;
						}
					}

					&:last-child {
						padding-bottom: 10px;
					}

				}
			}

			.tablet {
				.p-button {
					width: 200px;
					height: 50px;
					font-size: 18px;
				}
			}
		}

		&.authentication-sso-form {
			width: 400px;
			text-align: left;

			.sso-signin {
				display: block;
				text-align: center;
				padding: 20px 0 10px;
			}
		}

		&.authentication-header-signin {
			margin: 0 15px;
			padding: 10px 0;
			font-size: 12px;

			.link {
				color: #2159a8;
			}

			.p-grid {
				margin: 0;

				.error-message {
					color: $stamps-ui-element-wrapper-invalid-text-color;
					padding: 10px 0;
				}

				.common-ui-checkbox {
					margin: 0 0 5px 0;

					.p-checkbox,
					.p-checkbox-box {
						height: 12px;
						width: 12px;

						.p-checkbox-icon {
							font-size: 12px;
						}
					}
				}

				.p-checkbox-label {
					margin-left: 8px;
				}
			}

			.signin-row {
				margin-bottom: 10px;

				.p-button {
					padding: 0;

					.p-button-label {
						padding: 2px 10px;
					}
				}

				.header-registration {
					display: inline-block;
					font-weight: 700;
					margin: 0 0 0 12px;
				}
			}

			.forgot-row {
				color: #0a0a0a;
				line-height: 14px;
				font-weight: 700;
				text-align: left;

				.forgot-modal {
					padding-left: 2px;
				}
			}

			.authentication-sso-form {
				width: 220px;
			}
		}

		&.modal {
			width: 400px;

			.common-ui-element-wrapper {
				width: 100%;
				flex-direction: column;
				align-items: stretch;
				display: $stamps-signin-element-display;
				margin-top: 10px;

				.label-container {
					text-align: left;

					label {
						font-weight: bold;
					}
				}
			}

			.security-questions {
				margin-top: 20px;

				.security-question {
					margin-top: 10px;

					p {
						font-weight: bold;
					}

					.common-ui-element-wrapper {
						margin-top: 0;
					}
				}
			}

			&.auth-reset-password {
				ul {
					padding: 10px 0 0 40px;
					list-style-position: outside;

					li {
						line-height: 24px;
					}
				}
			}
		}

		&.auth-forgot-password-view {
			display: flex;
    		justify-content: center;

			.username-link {
				text-align: right;
			}

			.security-question {
				font-weight: bold;
				margin-bottom: 4px;
   				text-align: left;
			}
		}

		&.auth-forgot-username-view {
			display: flex;
    		justify-content: center;
		}

		&.auth-reset-password-view {
			display: flex;
			justify-content: center;

			.form {
				.password-input {
					margin-bottom: 0;
				}
			}

			.password-list {
				border: $stamps-signin-reset-validation-form-border;
				border-radius: $stamps-signin-form-border-radius;
				margin-top: $stamps-signin-reset-validation-form-margin-top;
				padding:  $stamps-signin-reset-validation-form-padding;

				.validation-header {
					font-weight: $stamps-signin-header-font-weight;
				}

				ul {
					li {
						padding-top: $stamps-signin-reset-validation-form-list-padding;
						list-style: none;

						.affirm {
							background: background-image-url('affirm.svg') no-repeat;
							display: inline-flex;
							width: 16px;
							height: 16px;
							vertical-align: middle;
							margin-right: 8px;

							&.disabled {
								background: background-image-url('affirm-disabled.svg') no-repeat;
							}
						}
					}
				}
			}
		}

		&.auth-login-error-view {
			display: flex;
			justify-content: center;

			.error {
				padding: 0px 0px 24px 0;
    			text-align: center;
			}
			.error-icon {
				padding-left: 15px;
				background: background-image-url($stamps-ui-element-wrapper-error-icon) no-repeat center;
			}

			.error-message {
				padding-left: 8px;
				color: $stamps-ui-element-wrapper-invalid-text-color;
			}

			.contact-support {
				padding: 0px 0px 24px 0;
				font-size: 12px;
			}
		}

		&.auth0-custom-error-view {
			display: flex;
			justify-content: center;

			.error-header {
				font-size: $stamps-signin-header-font-size;
				font-weight: $stamps-signin-header-font-weight;
				padding: $stamps-signin-header-padding;
				text-align: $stamps-signin-header-text-align;

				&.tablet {
					font-size: 35px;
				}
			}

			.generic-error-section {
				padding: 16px;
				border: 1px solid #F1C1C1;
				border-radius: 8px;
				background-color: #FDF7F7;

				.exclamation-icon {
					background: background-image-url('exclamation-icon.svg') no-repeat center;
					background-size: contain;
					height: 40px;
					width: 40px;
					display: inline-block;
				}

				.generic-error-label {
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;
					margin-top: 16px;
				}

				.generic-error-content {
					margin-top: 8px;
				}
			}

			.common-ui-collapsible-card {
				margin: 24px 0;
			}

			.error-context {
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
			}

			.tracking-id {
				margin-top: 8px;
				color: $stamps-ui-collapsible-card-secondary-text-color;

				.tracking-id-label {
					@include heading-label;
				}
			}

			.signin-primary-button button {
				background-color: $stamps-signin-primary-button-bg-color;
				color: $stamps-signin-primary-button-text-color;
				border: 1px solid $stamps-signin-primary-button-bg-color;

				&:hover {
					background-color: $stamps-signin-primary-button-hover-bg-color !important;
				}

				&:active {
					background-color: $stamps-signin-primary-button-active-bg-color !important;
				}
			}

			.contact-support {
				margin-top: 16px;
			}
		}
	}

}
