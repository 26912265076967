@mixin rate-advisor-shipping-inputs {
	.common-rate-advisor-shipping-inputs {
		margin: 0 10px;
		display: flex;

		.arrow-icon {
			background: background-image-url('Arrow.svg') no-repeat center;
			width: 22px;
			height: 25px;
			vertical-align: middle;
			background-size: 14px 20px;
		}
	}
}
