@mixin carrier-manage-carrier {
	.common-carrier-manage-carrier {
		height: 100%;

		.common-ui-toolbar {
			.p-toolbar {
				background-color: $stamps-settings-manage-carrier-toolbar-background-color;
			}
		}

		.header {
			padding: 15px 15px 0;
		}

		.common-grid {
			// hide horizontal scrollbar
			.ag-center-cols-viewport {
				overflow-x: hidden;
			}

			.manage-carrier-row {
				&.ag-cell {
					padding-left: $stamps-settings-manage-carrier-grid-row-pad-left;
				}

				&.carrier-logo {
					background-repeat: no-repeat;
					background-position: $stamps-settings-manage-carrier-grid-logo-bg-pos;
					background-size: $stamps-settings-manage-carrier-grid-logo-bg-size;
					margin-left: calc(
						$stamps-settings-manage-carrier-grid-logo-bg-pos +
						($stamps-settings-manage-carrier-grid-logo-bg-pos -
						$stamps-settings-manage-carrier-grid-logo-size-sm) / 2
					);
					// Adjusting margin based on icon size difference
				}

				&.usps-logo {
					background-image: background-image-url('usps-logo.svg');
					background-position: $stamps-settings-manage-carrier-grid-logo-usps-bg-pos;

				}

				&.ups-logo {
					background-image: background-image-url('ups-logo.svg');
					background-position: $stamps-settings-manage-carrier-grid-logo-ups-bg-pos;
					margin-left: calc(
						$stamps-settings-manage-carrier-grid-logo-bg-pos +
						($stamps-settings-manage-carrier-grid-logo-bg-pos -
						$stamps-settings-manage-carrier-grid-logo-size-med) / 2
					);
					// Adjusting margin based on bigger icon size
				}

				&.globalpost-logo {
					background-image: background-image-url('globalpost-logo.svg');
					margin-left: calc(
						$stamps-settings-manage-carrier-grid-logo-bg-pos +
						($stamps-settings-manage-carrier-grid-logo-bg-pos -
						$stamps-settings-manage-carrier-grid-logo-size-med) / 2
					);
					// Adjusting margin based on bigger icon size
				}

				&.dhl-express-logo {
					background-image: background-image-url('dhl-logo.svg');
					background-position: $stamps-settings-manage-carrier-grid-logo-dhl-bg-pos;
				}

				&.fedex-logo {
					background-image: background-image-url('fedex-logo.svg');
				}
			}

		}
	}
}
