@mixin terms() {
	.terms-modal {
		overflow: auto; // fix modal position issue in IE11
	}

	.common-terms {
		&.legal-list {
			padding: 15px;

			.terms-title {
				font-size: 21px;
				margin: 2px 0 17px;
				font-weight: bold;
			}
		}

		&.terms-page {
			background-color: #fff;
			text-align: left;
			height: 100%;
			padding: 15px;
			overflow: auto;

			.accept-button {
				margin: 15px 15px 0 0;
			}

			.action-row {
				display: flex;

				.success-text {
					color: #34a835;
				}

				p {
					margin-top: 16px;
				}
			}

			// add base css
			h1 {
				display: block;
				font-size: 28px;
				margin: 19px 0;
				font-weight: bold;
			}

			h2 {
				display: block;
				font-size: 21px;
				margin: 14px 0;
				font-weight: bold;
			}

			h4 {
				font-weight: bold;
			}

			p {
				display: block;
				margin: 14px 0;
				padding: 5px 0;
			}

			ul {
				display: block;
				list-style-type: disc;
				margin: 14px 0;
				padding-inline-start: 40px;
				list-style-position: outside;

				ul {
					list-style-type: circle;
				}
			}

			ol {
				display: block;
				list-style-type: decimal;
				margin: 14px 0;
				padding-inline-start: 40px;

				ul {
					list-style-type: circle;
				}
			}

			li {
				margin: 10px 0;
			}

			hr {
				margin: 6px 0;
			}

			table,
			th,
			td {
				border: 1px solid grey;
				padding: 5px;
				line-height: 20px;
			}

			table {
				display: table;
				box-sizing: border-box;
				border-spacing: 2px;

				thead {
					display: table-header-group;
					vertical-align: middle;

					th {
						display: table-cell;
						vertical-align: inherit;
						font-weight: bold;
						text-align: center;
					}
				}

				tbody {
					display: table-row-group;
					vertical-align: middle;

					tr {
						display: table-row;
						vertical-align: middle;
					}

					td {
						display: table-cell;
						vertical-align: middle;
					}
				}
			}

			.wrapper {
				font-size: 13px;

				p {
					line-height: 1;
					margin: 0;
					padding: 5px 0 0 0;
				}

				ol {
					padding: 0 0 0 20px;
				}
			}
		}

		&.terms-dialog {
			max-width: 600px;
		}
	}
}
