@mixin rate-advisor-slider-filters {
	.common-rate-advisor-slider-filters {
		.slider-container {
			border: 1px solid #eef2f8;
			border-width: 1px 0 1px 0;
		}

		.slider-label {
			color: #0a0a0a;
			padding-top: 13px;
			font-size: 10pt;

			span {
				font-weight: bold;
			}
		}
	}
}
