@mixin ui-multiselect() {
	.common-ui-mulitiselect {
		flex: 1 1 auto;

		.p-multiselect {
			width: 100%;

			.tag-color {
				width: 6px;
				height: 15px;
				border-radius: 2px;
			}

			// default styles for name in menu options and selected options
			.name {
				padding: 0 5px;
			}

			// default styles for selected option template
			.p-multiselect-label-container {
				.p-multiselect-label {
					display: flex;
					flex-wrap: wrap;
					line-height: $stamps-line-height;

					.multiselect-selected-item-template {
						display: inline-flex;
						align-items: center;
						background-color: $stamps-ui-multiselect-selected-item-background;
						padding: 0 5px;
						margin: 2px 4px 2px 0;
					}
				}
			}

			.p-multiselect-trigger-icon {
				font-size: $stamps-ui-dropdown-icon-size;
				color: $stamps-ui-dropdown-icon-color;

				&:before {
					content: $stamps-ui-dropdown-icon;
				}
			}
		}
	}

	.p-multiselect-panel {
		// hide the header bar (when filter value is true) in menu options
		.p-multiselect-header {
			display: none;
		}

		// default styles for color bar in menu options and selected options
		.tag-color {
			width: 6px;
			height: 15px;
			border-radius: 2px;
		}

		// default styles for name in menu options and selected options
		.name {
			padding: 0 5px;
		}

		// default styles for menu options
		.p-multiselect-items-wrapper {
			.p-multiselect-items {
				.p-multiselect-item {

					// hide menu option's checkbox
					.p-checkbox {
						display: none;
					}

					.multiselect-item-template {
						display: inline-flex;
						align-items: center;
					}
				}
			}
		}
	}
}
