@mixin ui-toolbar {
	.common-ui-toolbar {
		.p-toolbar {
			display: flex;
			background-color: $stamps-ui-toolbar-bg-color;
			border-bottom: $stamps-ui-toolbar-border;
			padding: $stamps-ui-toolbar-padding;
			min-height: $stamps-ui-toolbar-height;
			@include border-radius(0);

			.p-toolbar-group-left {
				flex: 1 0 auto;
			}

			.p-toolbar-group-left,
			.p-toolbar-group-right {
				.toolbar-list {
					display: flex;
					padding: $stamps-ui-toolbar-list-padding;
					list-style-type: none;

					.toolbar-item {
						&:hover:not(:disabled) {
							background-color: $stamps-ui-toolbar-item-hover-bg-color;
						}

						.p-show-bage .p-button-text {
							float: left;
						}

						&.showBadgeItem {
							position: relative;

							.badge-icon {
								position: absolute;
								float: right;
								right: 27px;
								top: $stamps-ui-toolbar-badge-icon-top;
								line-height: 22px;
								font-size: 11px;
								border-radius: 15px;
								text-align: center;
								vertical-align: middle;
								text-overflow: ellipsis;
								overflow: hidden;
								margin-left: 6px;
								background-color: #f00;
								background-image: none;
								color: #fff;
								font-weight: bold;
								width: 22px;
							}

							.p-button-label {
								width: calc(150px + 34px);
								text-align: left;
							}
						}

						.export-btn,
						.import-btn,
						.feedback-btn {
							.ui-button-icon {
								padding: $stamps-ui-toolbar-icon-button-padding;
								height: $stamps-ui-toolbar-icon-button-height;
							}
						}
					}
				}
			}

			.toolbar-button {
				border-radius: 4px;

				.p-button {
					padding: $stamps-ui-toolbar-button-padding;
				}

				.p-button-label {
					padding: 0;
				}
			}

			.toolbar-split-button {
				border-radius: $stamps-splitbutton-menuitem-border-radius;
			}

			.toolbar-button,
			.toolbar-split-button {
				margin: $stamps-ui-toolbar-button-margin;

				.p-splitbutton {
					.p-splitbutton-menubutton.p-button-icon-only {
						margin-right: 7px;
					}

					.p-splitbutton-defaultbutton {
						padding-right: 0;

						.p-button-label {
							padding-right: 0;
						}
					}
				}

				.p-button,
				.p-splitbutton {
					display: flex;

					&:not(.p-button-icon-only) {
						height: $stamps-ui-toolbar-button-height;
					}

					.edit-icon {
						background: background-image-url($stamps-ui-toolbar-edit-icon) no-repeat center;
						background-size: 24px;
						height: 24px;
						width: 24px;
						display: $stamps-ui-toolbar-edit-icon-display;
					}

					&:hover:not(:disabled) {
						.p-button-label,
						.p-button-icon-only {
							color: $stamps-ui-toolbar-item-hover-text-color;
						}

						.edit-icon {
							background: background-image-url($stamps-ui-toolbar-edit-hover-icon) no-repeat center;
							background-size: 24px;
						}
					}
				}

				&.split-edit-btn {
					.p-splitbutton {
						border-radius: 4px;
					}

					.ui-splitbutton-icon {
						.p-button-icon {
							position: static;
							margin: 0;
						}
					}
				}
			}

			.search-input {
				padding: $stamps-ui-toolbar-search-padding;
				position: relative;
				width: 200px;
				float: left;
				margin-right: 4px;

				.ui-toolbar-search-input {
					position: relative;
					padding:
						$stamps-menu-search-input-top-bottom-padding
						($stamps-menu-search-icon-size + $stamps-menu-search-input-left-right-padding * 2)
						$stamps-menu-search-input-top-bottom-padding
						$stamps-menu-search-input-left-right-padding;

					input {
						width: 100%;
						font-size: $stamps-menu-search-text-font-size;
						font-family: $stamps-menu-search-font-family;
						line-height: $stamps-menu-search-line-height;

						&:focus {
							border-color: $stamps-menu-search-focus-border-color;
							outline: none;
						}
					}
				}

				.pi {
					position: absolute;
					top: 50%;
					right: 10px;
					transform: translateY(-50%);
					color: $stamps-menu-search-text-color;
					font-size: $stamps-menu-search-icon-size;
				}
			}
		}
	}
}
