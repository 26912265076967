@import 'upgrade-content.scss';

@mixin subscription-plan() {
	.subscription-plan {
		&.modal {
			height: auto;

			p {
				max-width: 600px;
			}

		}

		&.plan-change-modal {
			.section1 {
				padding: 0 0 10px 0;
			}
		}

		&.plan-changed-succesful-modal {
			.plan-change-info {
				ul {
					list-style-position: outside;
					padding: 0px 0 0 30px;
					line-height: 20px;
				}
				.section2 {
					padding: 10px 0 10px 0;
				}

				.footer {
					padding: 10px 0 10px 0;
					display: flex;
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;

					.info-icon {
						width: 40px;
						height: 20px;
						margin: 10px 10px 10px 0px;
						display: inline-block;
						background: background-image-url('info-icon.png') no-repeat;
					}

					.info-text {
						display: inline;
					}

					a {
						color: #007EE5;
					}
				}
			}

			.footer {
				display: flex;
				align-items: center;
				padding-top: 10px;

				.message-icon {
					background: background-image-url('icon-instance-logo.svg') no-repeat;
					height: 25px;
					width: 25px;
					padding-left: 30px;
				}

				.footer-text{
					font-size: 12px;
				}
			}
		}

		&.upgrade-plan-modal {
			line-height: 20px;

			.body {
				display: flex;
				@include upgrade-content();
			}

			&.fee-details .body {
				flex-direction: column;
			}
		}
	}
}

