@mixin icon-mask($width: 16px, $height: 16px, $color: currentColor) {
    width: $width;
    height: $height;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: $color;
}

@mixin icon-mask-type($type) {
    @if $type == 'affirm' {
        mask-image: background-image-url($stamps-ui-affirm-icon);
        background-color: $stamps-ui-affirm-color;
    } @else if $type == 'error' {
        mask-image: background-image-url($stamps-ui-error-icon);
    } @else if $type == 'featured' {
        mask-image: background-image-url($stamps-ui-featured-icon);
    } @else if $type == 'help' {
        mask-image: background-image-url($stamps-ui-help-icon);
    } @else if $type == 'information' {
        mask-image: background-image-url($stamps-ui-information-icon);
    } @else if $type == 'information-outline' {
        mask-image: background-image-url($stamps-ui-information-outline-icon);
    } @else if $type == 'sparkle' {
        mask-image: background-image-url($stamps-ui-sparkle-icon);
    } @else if $type == 'warning' {
        mask-image: background-image-url($stamps-ui-warning-icon);
    } @else if $type == 'upgrade' {
        mask-image: background-image-url($stamps-ui-upgrade-icon);
    } @else {
        @error "Invalid icon type. Must be one of: affirm, error, featured, help, information, information-no-outline, sparkle, warning, upgrade";
    }
}