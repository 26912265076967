@mixin ui-radio-button() {
	.common-ui-radiobutton {
		padding: 5px 0;
		display: flex;

		.p-radiobutton-label {
			line-height: $stamps-line-height;
			margin: $stamps-ui-radiobutton-label-margin;
		}

		.p-radiobutton-disabled {
			opacity: $stamps-ui-disabled-opacity;
		}

		.p-radiobutton {
			.p-radiobutton-box {
				&.p-disabled {
					background-color: $stamps-ui-radiobutton-disabled-background-color;

					.p-radiobutton-icon {
						background-color: $stamps-ui-radiobutton-disabled-icon-color;
					}
				}

				&:not(.p-disabled).p-highlight {
					border-color: $stamps-ui-radiobutton-selected-border-color;
				}
			}
		}
	}
}
