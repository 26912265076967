@mixin print-modal {
	.common-print-modal.modal {
		overflow: auto; // fix the modal layout issue in IE11

		hr {
			width: 100%;
			margin: 10px auto;
		}

		.label-preview {
			display: flex;
			flex-direction: row;

			.field-label {
				width: 120px;
				text-align: right;
				flex: none;
				margin-right: 10px;
				font-weight: $stamps-ui-input-label-font-weight;
				line-height: $stamps-line-height;
				padding: $stamps-ui-input-label-spacing;
			}

			.label-preview-wrapper {
				width: 80%;
				flex: 1;
			}
		}

		.quantity-display {
			width: 120px;
			line-height: 20px;
			text-align: left;
		}

		.quantity-input {
			width: 250px;
			margin-top: 10px;
		}

		// override
		.p-dialog-content {
			display: flex;
			flex-direction: column;
			padding: 0;

			.dialog-body {
				width: 100%;
				flex: 1 1 auto; // fix IE 11 height issue
				overflow: auto;
				padding: 15px;
			}

			.p-dialog-footer {
				height: 60px;
				padding: 15px;
				display: flex;
				align-items: center;

				.total-cost-text {
					flex: 1;
					text-align: left;
					font-weight: $stamps-ui-input-label-font-weight;
					font-size: $stamps-modal-total-cost-font-size;
				}
			}
		}

		.label {
			padding-top: 10px;
		}

		.pi-sync {
			cursor: pointer;
			margin: 9px 0 0 17px;
		}
	}
}
