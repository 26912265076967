@mixin cleanse-address() {
	.common-cleanse-address {
		&.multi-select-modal {
			p {
				margin-top: 10px;
			}

			table {
				width: 100%;
				border: 1px solid #ddd;
				margin-top: 20px;

				th {
					border: 1px solid #ddd;
					font-weight: bold;
					padding: 5px;
					text-align: left;
				}

				td {
					padding: 5px;
				}
			}

			select {
				display: inline-block;

				&.suffix-select {
					margin-left: 5px;
				}
			}
		}
	}
}
