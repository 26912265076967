@mixin inbound-details-edit-product-details {
	.common-inbound-details-edit-product-details {
		display: flex;
		flex-direction: column;
		margin: 15px 0 15px 0;

		.product-details-header {
			color: $stamps-inbound-details-header-text-color;
			font-weight: bold;
			margin: 0 0 15px 0;
		}

		.product-details-content {
			background-color: $stamps-details-background-color;
			padding: 0 0 20px 20px;
			display: flex;
			flex-direction: column;

			.p-lg-2 {
				width: 12% !important;
			}

			.sub-header {
				border-bottom: 1px solid $stamps-inbound-details-border;
				color: $stamps-inbound-details-sub-header-text-color;
				font-weight: bold;
				margin-top: 20px;

				.p-lg-2,
				.p-lg-3,
				.p-lg-4 {
					padding-left: 0;
				}
			}

			.product-item {
				padding-top: 10px;
			}

			.read-only-label-container {
				.read-only-label {
					color: $stamps-inbound-details-sub-header-text-color;
					font-weight: bold;
				}
			}
		}

		&.mobile-wrapper {
			.product-details-content,
			.product-item {
				border-bottom: 1px solid $stamps-inbound-details-border;

				.read-only-label-container {
					display: flex;
					line-height: 20px;
				}
			}
		}
	}
}
