@mixin ui-footer() {
	.common-ui-layout-footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 15px;

		.button-container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.common-ui-button {
				padding: 5px 0 5px 10px;
			}

			.common-ui-button:first-of-type {
				padding-left: 0;
			}

			.common-ui-button:last-of-type {
				padding-right: 10px;
			}
		}

		.content-container {
			overflow: auto;
			font-size: $stamps-font-size;
			line-height: $stamps-line-height;
			flex: 1;
		}
	}
}
