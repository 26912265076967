@mixin account-verification {
	.account-verification {
		height: 100%;
		display: flex;
		flex-direction: column;

		.account-verification-with-header {
			color: $stamps-primary-header-color;
			font-size: 30px;
			padding: 15px;
			line-height: 60px;
			font-weight: normal;
		}
	}
}
