@mixin account-cancel-account {
	.account-cancel-account-container {
		height: 100%;
		text-align: left;
		padding: 20px;

		ul {
			padding: 0 0 10px 0;
		}

		li {
			margin-top: 5px;
		}

		p {
			padding: 0 0 10px 0;

			&.address-block {
				padding: 0;
			}

			&.refund-info-text {
				padding: 36px 0 0 0;
			}
		}

		// per Product's requirement, 32px space top and bottom
		.primary-header {
			padding-top: calc(32px - 20px);
			padding-bottom: 32px;
		}

		.account-cancellation-questions {
			display: flex;
			flex-direction: column;

			.cancellation-section {
				flex: 1 1 auto;
				max-width: 480px;

				p {
					padding: 0 0 5px 0;
				}

				.common-ui-element-wrapper.dropdown {
					margin-bottom: 15px;
				}
			}
		}

		.confirmation-container {
			max-width: 530px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			align-items: flex-start;

			.header {
				display: flex;
				gap: 10px;
				align-items: center;
			}

			p {
				padding: 0 !important;
			}

			.success-icon {
				width: 32px;
				height: 32px;
				background: background-image-url('icon-success.svg');
				background-size: contain;
				background-repeat: no-repeat;
			}
		}

		.cancel-account-downgrade {
			.cancel-button .common-ui-button {
				float: right;
			}

			.downgrade-offers {
				.downgrade-plan-option {
					.content-container {
						background-color: #fff;
						border-radius: 10px;
						display: flex;
						flex-direction: column;
						height: 100%;
						padding: 20px;
					}

					.secondary-header {
						text-align: center;
						margin-bottom: 16px;
					}

					.content {
						flex: 1;
					}

					.button-container {
						margin-top: 20px;
						text-align: center;
					}
				}
			}

			.downgrade-confirmed.paygo {
				max-width: 530px;
				display: flex;
				flex-direction: column;
				gap: 10px;
				align-items: flex-start;

				.header {
					display: flex;
					gap: 10px;
					align-items: center;
				}

				p {
					padding: 0 !important;
				}

				.success-icon {
					width: 32px;
					height: 32px;
					background: background-image-url('icon-success.svg');
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
		}

		.cancel-account-click-to-call {
			width: 100%;

			.click-to-call-data {
				width: 100%;
				display: flex;
				margin-top: 10px;

				.left {
					width: 475px;
					background-color: #e2e2e2;
					padding: 20px;

					// row of input and the button
					.call-me-now-row {
						display: flex;
						align-items: center; // align input and button vertically centered

						.common-ui-input-text {
							margin-right: 20px;
						}

						.call-me-now-button {
							width: 120px;

							.p-button-label {
								background-color: #f96a08;
							}
						}
					}

					.fine-print {
						font-size: 10px;
						margin-top: 10px;
					}
				}

				.right {
					flex: 1;
					padding: 20px;
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;
				}
			}
		}
	}
	.confirm-cancel-account {
		.sub-header {
			padding-bottom: $stamps-cancel-account-confirm-sub-header-padding-bottom;
		}
	}
}
