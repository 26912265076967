@mixin ui-tracking-field() {
	.common-ui-tracking-field {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;

		// add dropdown panel style rules
		@include dropdown-panel-factory('tracking');

		.common-ui-element-wrapper {
			.input-container {
				align-items: center; // make label vertically centered

				.label-container {
					width: auto; // to reset
					text-align: left;
					margin-left: 5px; // spacing between the label (e.g. Service") and the input field
					margin-bottom: 0; // override to correctly center the label
				}
			}
		}
	}

	.common-ui-tracking-field-tooltip {
		padding: 5px;

		.title {
			font-weight: bold;
		}

		.description {
			.title {
				font-weight: normal;
				margin-top: 20px;
			}
		}
	}

	.tracking-item {
		display: flex;
		justify-content: space-between;
	}
}
