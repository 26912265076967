@mixin dropdown-panel-factory ($ui) {
	.#{$ui}-dropdown-panel {
		.dropdown-item,
		.dropdown-group-item {
			padding: 4px 7px; // adjust the padding so the overall height stays at 28px
		}

		.#{$ui}-item,
		.#{$ui}-group-item {
			display: table;
			border-collapse: separate;
			table-layout: fixed;
			width: 100%;

			.table-cell {
				height: 20px;
				display: table-cell;

				&.ellipsis {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				span {
					height: 20px;
					line-height: 20px;
					vertical-align: middle;
				}
			}

			.#{$ui}-icon {
				width: 32px;
				padding-right: 5px;
			}

			.#{$ui}-title {
				width: auto;
			}

			.#{$ui}-cost {
				width: 60px;
				padding-left: 5px;
				text-align: right;
			}
		}
	}
}
