/**
 * The following css rules are used for Browser based label printing aka Gif Priting.
 *
 * This corresponding css file is included into the DOM based on iframe, non-iframe printing.
 * In case of iframe the css file is included into the scope of the iframe only.
 * In case of non-iframe (currently only for edge browser) printing, the css
 * file is included into the head section of the main page.
 *
 * Dev Note: the corresponding gifPrint.css file is compiled from gifPrint.scss file using scss compiler
 */

// Paper Dimensions

$widthDefault:	8.5;
$heightDefault:	11;

$widthE10:		4.125;
$heightE10:		9.5;

$widthE9:		3.875;
$heightE9:		8.875;

$widthEA9:		5.75;
$heightEA9:		8.75;

$widthE6:		3.625;
$heightE6:		6.5;

$widthEA2:		4.375;
$heightEA2:		5.75;

$widthE7:		3.875;
$heightE7:		7.5;

$widthE11:		4.5;
$heightE11:		10.375;

$widthE12:		4.75;
$heightE12:		11;

/**
 * macros to generate height and width attributes in point values based on the scale
 * of a standard 8.5 x 11 sheet
 */

@mixin size($percentage) {
	height: unquote(round(($heightDefault * 72 * $percentage) / 100) + 'pt');
	width: unquote(round(($widthDefault * 72 * $percentage) / 100) + 'pt');
}

@mixin height($percentage) {
	height: unquote(round(($heightDefault * 72 * $percentage) / 100) + 'pt');
}

@mixin width($percentage) {
	width: unquote(round(($widthDefault * 72 * $percentage) / 100) + 'pt');
}

@mixin envelopeSize($percentage, $width, $height) {
	height: unquote(round(($height * 72 * $percentage) / 100) + 'pt') !important;
	width: unquote(round(($width * 72 * $percentage) / 100) + 'pt') !important;
}

@mixin envelopeHeight($percentage, $height) {
	height: unquote(round(($height * 72 * $percentage) / 100) + 'pt') !important;
}

@mixin envelopeWidth($percentage, $width) {
	width: unquote(round(($width * 72 * $percentage) / 100) + 'pt') !important;
}

@mixin printengine {
	@media print {

		/**
		* 	plain paper, SDC-1200, NetStamps
		*/
		img {
			&.macosx {
				&.chrome {
					@include size(98);
					margin-top: 5pt;
					margin-left: 15pt;

					&.netStamps {
						@include height(94.5);
						@include width(88.4);
						margin-top: -1pt;
						margin-left: 34pt;

						&.Alternate {
							@include width(88.2);
							margin-top: -10pt;
							margin-left: 24pt;
						}

						&.PromoPaper20NetStamps {
							margin-left: 32pt;
						}

						&.ReturnAddress {
							margin-top: -4pt;
						}
					}
				}
				&.firefox  {
					@include size(100);
					margin-top: 10pt;
					margin-left: 8pt;

					&.netStamps {
						height: 97%;
						width: 92%;
						margin-left: 22pt;
						margin-top: -9pt;

						&.Alternate {
							height: 97.2%;
							margin-left: 20pt;
						}

						&.PhotoLandscape {
							height: 97.2%;
							margin-top: -12pt;
							margin-left: 26pt;
						}

						&.PromoPaper20NetStamps {
							width: 91.9%;
							margin-left: 24pt;
						}

						&.ReturnAddress {
							margin-left: 27pt;
						}
					}
				}
				&.safari {
					@include size(80);
					margin-top: 33pt;
					margin-left: 0pt;

					&.netStamps {
						@include height(89);
						@include width(84);
						margin-top: -20pt;
						margin-left: 10pt;

						&.Alternate {
							@include height(88.8);
							@include width(83.8);
							margin-top: -16pt;
							margin-left: 3pt;
						}

						&.PhotoLandscape {
							@include width(83);
							margin-left: 17pt;
						}

						&.PromoPaper20NetStamps {
							@include height(89.2);
							@include width(83.8);
							margin-left: 15pt;
						}

						&.ReturnAddress {
							margin-top: -22pt;
							margin-left: 20pt;
						}
					}
				}
			}
			&.windows {
				&.chrome {
					@include size(96);
					margin-top: 10pt;
					margin-left: 15pt;

					&.netStamps {
						@include height(94.5);
						@include width(88.4);
						margin-top: -1pt;
						margin-left: 34pt;

						&.Alternate {
							margin-top: -10pt;
							margin-left: 24pt;
						}
					}
				}
				&.edge {
					/**
					* Edge doesn't print all the pages consistently while scaling the label images with point units.
					* The preview would look good but the actual prints might have some missing labels.
					*
					* So the image dimensions are in percentage which work consistently.
					*/
					height: 92%;
					width: 92%;
					margin-top: 13pt;
					margin-left: 20pt;

					&.netStamps {
						height: 90%;
						width: 89.5%;
						margin-top: 0pt;
						margin-left: 30pt;
					}

					&.PhotoLandscape {
						margin-left: 35pt;
					}
				}
				&.firefox {
					@include size(100);
					margin-top: 10pt;
					margin-left: 0pt;

					&.netStamps {
						height: 100%;
						width: 96.5%;
						margin-left: 10pt;
						margin-top: -23pt;

						&.Alternate {
							height: 97%;
							width: 91.5%;
							margin-left: 30pt;
							margin-top: -7pt;
						}
					}

					&.PhotoLandscape {
						height: 96.5%;
						width: 91.5%;
						margin-left: 27pt;
						margin-top: -6pt;
					}
				}
				&.ie {
					@include size(95);
					margin-top: 17pt;
					margin-left: 12pt;

					&.netStamps {
						height: 95%;
						width: 90%;
						margin-top: -5pt;
						margin-left: 25pt;
					}

					&.PhotoLandscape {
						margin-left: 31pt;
					}

					&.PostageTools {
						/**
						* WEBAPPS-11600: IE - Container labels printing to small and in middle of page
						* Interim fix to print container labels properly in History
						*/
						height: 92%;
						width: 92%;
						margin-top: 13pt;
						margin-left: 20pt;
					}
				}
			}
		}

		/* certified mail labels and envelopes */

		img.windows.chrome, img.macosx.chrome {
			&.mailingLabelCM3610 {
				@include size(100);
				margin-left: 0pt;
				margin-top: -6pt;
			}
			&.mailingLabelCM3710 {
				@include size(99);
				margin-left: -5pt;
				margin-top: 0pt;
			}
			&.mailingLabelCM3910 {
				@include size(100);
				margin-left: 0pt;
				margin-top: 0pt;
			}
			&.mailingLabelCM3930 {
				@include size(100);
				margin-left: 0pt;
				margin-top: 0pt;
			}
			&.envelopeCM3810 {
				@include size(100);
				margin-left: 0pt;
				margin-top: 0pt;
			}
			&.envelopeCM3830 {
				@include size(100);
				margin-left: 0pt;
				margin-top: 0pt;
			}
			&.envelope10 {
				@include envelopeSize(120, $widthE10, $heightE10);
				margin-left: -10px;
				margin-top: -20px;
			}
			&.envelope9 {
				@include envelopeSize(120, $widthE9, $heightE9);
				margin-left: -10px;
				margin-top: -20px;
			}
			&.envelopeA9 {
				@include envelopeSize(120, $widthEA9, $heightEA9);
				margin-left: -10px;
				margin-top: -20px;
			}
			&.envelope6 {
				@include envelopeSize(120, $widthE6, $heightE6);
				margin-left: -10px;
				margin-top: -20px;
			}
			&.envelopeA2 {
				@include envelopeSize(120, $widthEA2, $heightEA2);
				margin-left: -10px;
				margin-top: -20px;
			}
			&.envelope7 {
				@include envelopeSize(120, $widthE7, $heightE7);
				margin-left: -10px;
				margin-top: -20px;
			}
			&.envelope11 {
				@include envelopeSize(120, $widthE11, $heightE11);
				margin-left: -10px;
				margin-top: -20px;
			}
			&.envelope12 {
				@include envelopeSize(120, $widthE12, $heightE12);
				margin-left: -10px;
				margin-top: -20px;
			}
		}

		img.windows.ie {
			&.mailingLabelCM3610 {
				@include size(100);
				margin-left: 0pt;
				margin-top: -9pt;
			}
			&.mailingLabelCM3710 {
				@include size(99.6);
				margin-left: -5pt;
				margin-top: -8pt;
			}
			&.mailingLabelCM3910 {
				@include size(100);
				margin-left: 0pt;
				margin-top: 0pt;
			}
			&.mailingLabelCM3930 {
				@include size(100);
				margin-left: 0pt;
				margin-top: 0pt;
			}
			&.envelopeCM3810 {
				@include size(100);
				margin-left: 1pt;
				margin-top: 0pt;
			}
			&.envelopeCM3830 {
				@include size(100);
				margin-left: 5pt;
				margin-top: 0pt;
			}
			&.envelope10 {
				@include envelopeSize(100, $widthE10, $heightE10);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope9 {
				@include envelopeSize(100, $widthE9, $heightE9);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelopeA9 {
				@include envelopeSize(100, $widthEA9, $heightEA9);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope6 {
				@include envelopeSize(100, $widthE6, $heightE6);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelopeA2 {
				@include envelopeSize(100, $widthEA2, $heightEA2);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope7 {
				@include envelopeSize(100, $widthE7, $heightE7);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope11 {
				@include envelopeSize(100, $widthE11, $heightE11);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope12 {
				@include envelopeSize(100, $widthE12, $heightE12);
				margin-left: 0;
				margin-top: 0;
			}
		}

		img.windows.edge {
			&.mailingLabelCM3610 {
				@include size(100);
				margin-left: 0pt;
				margin-top: -8pt;
			}
			&.mailingLabelCM3710:not(.batch) {
				@include height(103);
				@include width(99);
				margin-left: 0pt;
				margin-top: -12pt;
			}
			&.mailingLabelCM3710.batch {
				@include height(100);
				@include width(99);
				margin-left: 0pt;
				margin-top: -12pt;
			}
			&.mailingLabelCM3910 {
				@include size(98);
				margin-left: 0pt;
				margin-top: -10pt;
			}
			&.mailingLabelCM3930 {
				@include size(100);
				margin-left: 6pt;
				margin-top: 0pt;
			}
			&.envelopeCM3810 {
				@include size(103);
				margin-left: 1pt;
				margin-top: 2pt;
			}
			&.envelopeCM3830 {
				@include size(100);
				margin-left: 0pt;
				margin-top: -5pt;
			}
			&.envelope10 {
				@include envelopeSize(100, $widthE10, $heightE10);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope9 {
				@include envelopeSize(100, $widthE9, $heightE9);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelopeA9 {
				@include envelopeSize(100, $widthEA9, $heightEA9);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope6 {
				@include envelopeSize(100, $widthE6, $heightE6);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelopeA2 {
				@include envelopeSize(100, $widthEA2, $heightEA2);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope7 {
				@include envelopeSize(100, $widthE7, $heightE7);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope11 {
				@include envelopeSize(100, $widthE11, $heightE11);
				margin-left: 0;
				margin-top: 0;
			}
			&.envelope12 {
				@include envelopeSize(100, $widthE12, $heightE12);
				margin-left: 0;
				margin-top: 0;
			}
		}

		img.windows.firefox, img.macosx.firefox {
			&.mailingLabelCM3610 {
				@include size(100);
				margin-top: -29pt;
				margin-left: -10pt;
				&.batch {
					margin-left: -16pt;
					margin-top: -13pt;
				}
			}
			&.mailingLabelCM3710 {
				@include size(100);
				margin-top: -23pt;
				margin-left: -28pt;
			}
			&.mailingLabelCM3910 {
				@include size(100);
				margin-top: -23pt;
				margin-left: -22pt;
			}
			&.mailingLabelCM3930 {
				@include size(100);
				margin-top: -23pt;
				margin-left: -22pt;
			}
			&.envelopeCM3810 {
				@include size(100);
				margin-top: -23pt;
				margin-left: -16pt;
			}
			&.envelopeCM3830 {
				@include height(103.3);
				@include width(100);
				margin-top: -13pt;
				margin-left: 0pt;
			}
			&.envelope10 {
				@include envelopeSize(100, $widthE10, $heightE10);
				margin-left: -10px;
				margin-top: 0;
			}
			&.envelope9 {
				@include envelopeSize(100, $widthE9, $heightE9);
				margin-left: -10px;
				margin-top: 0;
			}
			&.envelopeA9 {
				@include envelopeSize(100, $widthEA9, $heightEA9);
				margin-left: -10px;
				margin-top: 0;
			}
			&.envelope6 {
				@include envelopeSize(100, $widthE6, $heightE6);
				margin-left: -10px;
				margin-top: 0;
			}
			&.envelopeA2 {
				@include envelopeSize(100, $widthEA2, $heightEA2);
				margin-left: -10px;
				margin-top: 0;
			}
			&.envelope7 {
				@include envelopeSize(100, $widthE7, $heightE7);
				margin-left: -10px;
				margin-top: 0;
			}
			&.envelope11 {
				@include envelopeSize(100, $widthE11, $heightE11);
				margin-left: -10px;
				margin-top: 0;
			}
			&.envelope12 {
				@include envelopeSize(100, $widthE12, $heightE12);
				margin-left: -10px;
				margin-top: 0;
			}
		}

		img.windows.firefox {
			&.mailingLabelCM3710.batch {
				height: 830pt;
				width: 630pt;
				margin-left: -2pt;
				margin-top: -12pt;
			}
		}

		img.macosx.firefox {
			&.envelopeCM3830 {
				@include height(103.3);
				@include width(100);
				margin-top: -18pt;
				margin-left: 0pt;
			}
			&.mailingLabelCM3710.batch {
				height: 800pt;
				width: 594pt;
				margin-left: 5pt;
				margin-top: -12pt;
			}
		}

		img.macosx.safari {
			&.mailingLabelCM3610 {
				@include size(100);
				margin: -27pt -10pt;
			}
			&.mailingLabelCM3710 {
				@include size(98.85);
				margin-top: -14.7pt;
				margin-left: -19pt;
			}
			&.mailingLabelCM3910 {
				@include size(100);
				margin: -18pt -16pt;
			}
			&.mailingLabelCM3930 {
				@include size(100);
				margin: -18pt -16pt;
			}
			&.envelopeCM3810 {
				@include size(93);
				margin-left: -13pt;
				margin-top: -18pt;
			}
			&.envelopeCM3830 {
				@include size(95);
				margin-left: 0pt;
				margin-top: -50pt;
			}
			&.envelope10 {
				@include envelopeWidth(89, $widthE10);
				@include envelopeHeight(100, $heightE10);
				margin-left: -16px;
				margin-top: 0;
			}
			&.envelope9 {
				@include envelopeWidth(89, $widthE10);
				@include envelopeHeight(100, $heightE10);
				margin-left: -16px;
				margin-top: 0;
			}
			&.envelopeA9 {
				@include envelopeWidth(89, $widthE10);
				@include envelopeHeight(100, $heightE10);
				margin-left: -16px;
				margin-top: 0;
			}
			&.envelope6 {
				@include envelopeWidth(89, $widthE10);
				@include envelopeHeight(100, $heightE10);
				margin-left: -16px;
				margin-top: 0;
			}
			&.envelopeA2 {
				@include envelopeWidth(89, $widthE10);
				@include envelopeHeight(100, $heightE10);
				margin-left: -16px;
				margin-top: 0;
			}
			&.envelope7 {
				@include envelopeWidth(89, $widthE10);
				@include envelopeHeight(100, $heightE10);
				margin-left: -16px;
				margin-top: 0;
			}
			&.envelope11 {
				@include envelopeWidth(89, $widthE10);
				@include envelopeHeight(100, $heightE10);
				margin-left: -16px;
				margin-top: 0;
			}
			&.envelope12 {
				@include envelopeWidth(89, $widthE10);
				@include envelopeHeight(100, $heightE10);
				margin-left: -16px;
				margin-top: 0;
			}
		}

		//******* The Following CSS is specifically for macosx Safari Batch Prints *******

		//CSS adjustments to the div container for the image. Modifying dimensions will affect how much space is taken up on a single page
		.image-container {
			overflow: hidden !important;

			&.mailingLabelCM3610 {
				height: 732.5pt;
			}
			&.mailingLabelCM3710 {
				height: 732.7pt;
				@include width(94);
			}
			&.mailingLabelCM3910 {
				height: 732.5pt;
			}
			&.mailingLabelCM3930 {
				height: 732.5pt;
			}
			&.envelopeCM3810 {
				@include width(90);
				height: 732.5pt;
				margin-left: -8pt;
				margin-top: 0pt;
			}
			&.envelopeCM3830 {
				@include width(90);
				height: 687.6pt;
				margin-left: -8pt;
				margin-top: 0pt;
			}
		}

		//CSS adjustments to image itself. Modifying dimensions will affect how the img is positioned within the div container
		img.macosx.safari.batch {
			&.mailingLabelCM3610 {
				@include height(103);
				@include width(100);
			}
			&.mailingLabelCM3710 {
				@include size(98.85);
				margin-top: -15pt;
				margin-left: -15pt;
			}
			&.mailingLabelCM3910 {
				height: 763.5pt;
				@include width(94);
				margin: -14pt -12pt;
			}
			&.mailingLabelCM3930 {
				height: 763.5pt;
				@include width(94);
				margin: -18pt -6pt;
			}
			&.envelopeCM3810 {
				@include size(98);
				margin-left: -8pt;
				margin-top: -12pt;
			}
			&.envelopeCM3830 {
				@include height(94);
				@include width(93);
				margin-left: -13pt;
				margin-top: -38pt;
			}
		}
	}
}
