@mixin account-verification-successful-verification {
	.common-account-verification-successful-verification {
		padding-left: 15px;

		.verification-header-and-image {
			display: flex;
			width: fit-content;
			align-items: center;
			padding: 0;

			.phone-icon {
				background-image: background-image-url($stamps-verify-phone-icon);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 95px;
				height: 150px;
				width: 95px;
			}

			h1 {
				color: $stamps-primary-header-color;
				font-size: 30px;
				line-height: 60px;
				font-weight: normal;
			}
		}

		.action-bar {
			margin-top: 40px;
		}
	}
}
