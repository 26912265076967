@mixin rate-advisor-service-options-included {
	.common-rate-advisor-service-options-included {
		display: flex;
		flex-direction: column;

		.service-option-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-bottom: 8px;

			.service-option-icon {
				width: 16px;
				height: 16px;
				flex: none;
				margin: 0 12px 0 0;

				&.icon-coverage {
					background: background-image-url('rateadvisor/includes/shield.svg') no-repeat center;
				}

				&.icon-free-pickup {
					background: background-image-url('rateadvisor/includes/truck.svg') no-repeat center;
				}

				&.icon-guaranteed-delivery-date {
					background: background-image-url('rateadvisor/includes/calendar.svg') no-repeat center;
				}

				&.icon-guaranteed-delivery-time {
					background: background-image-url('rateadvisor/includes/clock.svg') no-repeat center;
				}

				&.icon-saturday-delivery {
					background: background-image-url('rateadvisor/includes/calendar.svg') no-repeat center;
				}

				&.icon-sunday-holiday-delivery {
					background: background-image-url('rateadvisor/includes/calendar.svg') no-repeat center;
				}

				&.icon-tracking {
					background: background-image-url('rateadvisor/includes/location.svg') no-repeat center;
				}
			}

			.service-option-text {
				font-size: 10pt;
				font-family: $stamps-font-family;
			}

			&.small {
				padding-left: 10px;

				.service-option-icon {
					width: 30px;
					height: 30px;
					margin: 0 10px 0 0;

					&.icon-coverage {
						background: background-image-url('rateadvisor/includes/shield.svg') no-repeat center;
					}

					&.icon-free-pickup {
						background: background-image-url('rateadvisor/includes/truck.svg') no-repeat center;
					}

					&.icon-guaranteed-delivery-date {
						background: background-image-url('rateadvisor/includes/calendar.svg') no-repeat center;
					}

					&.icon-guaranteed-delivery-time {
						background: background-image-url('rateadvisor/includes/clock.svg') no-repeat center;
					}

					&.icon-saturday-delivery {
						background: background-image-url('rateadvisor/includes/calendar.svg') no-repeat center;
					}

					&.icon-sunday-holiday-delivery {
						background: background-image-url('rateadvisor/includes/calendar.svg') no-repeat center;
					}

					&.icon-tracking {
						background: background-image-url('rateadvisor/includes/location.svg') no-repeat center;
					}
				}

				.service-option-text {
					font-size: 12px;
					padding: 0;
				}
			}
		}
	}
}
