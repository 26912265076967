@mixin account-refer-a-friend() {
	.account-refer-a-friend {
		height: 100%;
		text-align: left;
		max-width: 640px;

		.section-body {
			flex: initial;
			padding-bottom: 0;
		}

		&.account-terms-modal {
			display: flex;
			flex-direction: column;
			max-height: 500px;

			.p-dialog-content {
				flex: 1;
				overflow: auto;

				h1 {
					font-size: 16px;
					font-weight: bold;
					margin: 0 0 16px 0;
				}

				p {
					margin: 0 0 10px;
				}

				li {
					margin: 0 0 8px 0;
					line-height: $stamps-line-height;
				}
			}
		}

		.body {
			max-width: 900px;
			height: 100%;
			display: flex;
			flex-direction: column;

			.common-ui-save-confirmation {
				padding: 0;
			}

			.primary-header {
				@include h3;
				padding: $stamps-account-refer-friend-heading-padding;
			}

			.secondary-header {
				margin: 20px 0 10px 0;
				font-weight: 600;
			}

			.item-list {
				flex: 1;
				display: flex;
				flex-direction: column;

				.item-block {
					margin-bottom: $stamps-account-refer-friend-promo-section-margin-bottom;
				}

				.item {
					flex-shrink: 0;
					padding: $stamps-account-refer-friend-promo-section-item-padding;

					.item-letter {
						font-weight: bold;
						font-size: $stamps-account-refer-friend-promo-letter-text-size;
						padding: 0 10px 0 0;
					}

					.promo-code-text {
						font-weight: bold;
						font-size: $stamps-account-refer-friend-promo-letter-text-size;
					}

					.share-text {
						display: inline-block;
					}

					.ui-button-icon {
						padding: 0 7px;
					}

					.p-button-label {
						color: #fff;
						font-weight: 600;
					}

					.facebook-wrapper {
						display: inline-block;
						margin: 0 0 0 15px;
					}

					.social-button {
						margin-right: 8px;
					}

					.email-wrapper {
						padding-top: 7px;

						p {
							margin: 0 0 5px 132px;
						}

						.common-ui-element-wrapper {
							align-items: start; // make label vertically top of the textarea

							.label-container {
								margin-right: 20px;
								white-space: normal;
								flex: none; // prevent label from shrinking
								padding-top: (32px - 20px) /2; // make label vertically centered to an text input
							}

							.input-container {
								flex-wrap: nowrap; // make error icon stick to the right side of the input
							}
						}
					}
				}

				.share-buttons, .promo-code {
					padding: 12px 0 0 33px;
				}

				.promo-code {
					display: flex;
					align-items: center;
					flex-shrink: 0;

					.promo-text {
						font-weight: $stamps-account-refer-friend-promo-code-font-weight;
					}

					.friend-code {
						color: $stamps-account-refer-friend-promo-code-color;
						font-size: $stamps-account-refer-friend-promo-code-font-size;
						font-weight: 600;
						line-height: $stamps-account-refer-friend-promo-code-line-height;
						padding: $stamps-account-refer-friend-promo-code-padding;
						border: $stamps-account-refer-friend-promo-code-border;
						border-radius: $stamps-account-refer-friend-promo-code-radius;
						display: inline;
					}

					.copy-icon {
						background: background-image-url('copy-icon.svg') no-repeat right;
						background-size: 17px;
						background-position: calc(100% - 10px) center;
					}

					.copied-text {
						margin-left: 10px;
					}
				}

				.terms {
					margin: auto 0 0 0;
				}
			}
		}
	}
}
