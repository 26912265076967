@mixin print-label-preview {
	.common-print-label-preview {
		width: 100%;
		user-select: none;

		.thumbnails-container {
			background-color: $stamps-print-labelpreview-thumbnail-container-color;
			padding: 10px;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;

			.row {
				width: 100%;
				flex: 1 1 auto; // fix IE 11 height issue
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}
		}

		.tip {
			margin-top: 10px;
		}

		.roll-sheet {
			.thumbnails-container {
				padding: 0;
				justify-content: flex-start;
				align-items: start;
			}
		}

		.shipping-label-sheet {
			background: background-image-url('print/background-top-page.png') no-repeat;
			background-position: right center;
			background-size: 19px 178px;
			border: $stamps-print-labelpreview-border solid $stamps-print-labelpreview-border-color;
			box-shadow: $stamps-print-labelpreview-box-shadow;
			border-radius: $stamps-print-labelpreview-box-radius;

			.thumbnails-container {
				padding: 10px 20px 10px 10px;

				.common-print-thumbnail {
					margin-right: 10px;

					&.printed {
						background: none;
						background-color: $stamps-print-labelpreview-printed-thumbnail-color;
					}
				}
			}
		}

		.netstamps-sheet {
			height: 344px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: $stamps-print-labelpreview-background-color;
			padding: $stamps-print-labelpreview-printed-padding;
			border-radius: $stamps-print-labelpreview-box-radius;
			margin: $stamps-print-labelpreview-printed-thumbnail-gap 0;

			.thumbnails-container {
				width: 260px;
				height: 344px;
				border: 1px solid $stamps-print-labelpreview-border-color;
			}

			&.default {
				width: 100%;
				background-image: background-image-url('print/netstamps-placeholder.png');
			}

			&.promotional {
				background-image: background-image-url('print/promotional-sheet.jpg');

				.thumbnails-container {
					padding-top: 200px;
				}
			}

			&.promo-paper-20 {
				background-image: background-image-url('print/promo-paper-20-sheet.jpg');

				.thumbnails-container {
					padding-top: 200px;
				}
			}

			@for $idx from 1 through 3 {
				&.roll-type-s-or-d-#{$idx} {
					width: 100%;
					background-image: background-image-url('print/roll-type-s-or-d-#{$idx}.png');
				}
			}
		}

		.envelope-sheet {
			.thumbnails-container {
				padding: 0;
			}
		}

		.certified-mail-sheet {
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 344px;

			&.cm-3610 {
				background-image: background-image-url('print/cm-3610-background.png');
			}

			&.cm-3710 {
				background-image: background-image-url('print/cm-3710-background.png');
			}

			&.cm-3910 {
				background-image: background-image-url('print/cm-3910-background.png');
			}

			&.cm-3930 {
				background-image: background-image-url('print/cm-3930-background.png');
			}

			&.cm-3810 {
				background-image: background-image-url('print/envelope-cm-3810-background.png');
			}

			&.cm-3830 {
				background-image: background-image-url('print/envelope-cm-3830-background.png');
			}
		}
	}
}
