@mixin emails {
	.common-emails {
		position: relative;
		text-align: left;
		padding: $stamps-account-content-padding;

		.intro-text {
			padding: 10px 0;
			max-width: 640px;
		}

		.input-container {
			width: 100%;
			max-width: 640px;
		}

		.common-ui-element-wrapper {
			align-items: inherit;

			.label-container {
				width: 130px;
				margin-top: 5px;
				flex: none; // prevent label width from shrinking
			}
		}

		.p-grid {
			margin-top: 20px;

			.common-ui-button {
				margin-right: 10px;
			}
		}

		.button-container {
			padding-left: 140px;
			display: flex;

			.email-button {
				padding: 0 10px 0 0;

				.p-button {
					width: 80px;
				}
			}
		}
	}

	&.preview-modal {
		height: auto;

		.email-address {
			padding-top: 15px;
		}
	}
}
