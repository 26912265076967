@mixin upgrade-msg() {
	.common-upgrade-msg {
		border-radius: 12px;
		display: flex;
		border: 1px #EAD1FA solid;
		overflow: hidden;
		margin: 10px 0 20px 0px;

		.upgrade-icon-container {
			padding: 2px 8px 2px 12px;
			background: #F4E8FD;
			align-items: center;
			justify-content: center;
			display: flex;
			width: 40px;

			.upgrade-icon {
				background-image: background-image-url($stamps-header-feature-banner-icon);
				background-repeat: no-repeat;
				width: 20px;
				height: 20px;
				line-height: 24px;
				background-position: $stamps-header-feature-banner-icon-background-position;
			}
		}
		.upgrade-text {
			padding: 4px 15px 4px 12px;
			font-size: 12px;
			font-weight: 400;
			line-height: 24px;
		}
	}
}
