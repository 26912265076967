@mixin ui-spinner {
	.common-ui-spinner {
		.spinner-content {
			padding: $stamps-ui-spinner-padding;
			border: $stamps-ui-spinner-border;
			border-radius: $stamps-ui-spinner-border-radius;
			background-color: $stamps-ui-spinner-bg-color;
			width: $stamps-ui-spinner-width;
			display: flex;
			flex-direction: column;
			align-items: center;

			.spinner-message {
				font-size: 14px;
				color: $stamps-ui-spinner-text-color;
				font-weight: $stamps-ui-spinner-font-weight;
			}

			.spinner-image {
				width: $stamps-ui-spinner-size;
				height: $stamps-ui-spinner-size;
				margin-bottom: $stamps-ui-spinner-image-margin-bottom;
				background-image: background-image-url('spinner.svg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		&.common-ui-spinner-mask {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			text-align: center;
			align-items: center;
			background-image: none;
			background-color: $stamps-ui-spinner-mask-background-color;
			z-index: 997;
		}
	}
}
