@mixin ui-save-confirmation() {
	.common-ui-save-confirmation {
		padding: 0 0 0 15px;
		color: $stamps-ui-save-confirmation-success-color;
		font-size: 16px;

		&.failed {
			color: $stamps-ui-save-confirmation-error-color;
		}

		.check-icon {
			display: inline-block;
			background: background-image-url('timeline-checked-icon.svg') no-repeat center;
			width: 20px;
			height: 20px;
			vertical-align: middle;
			margin: 0 5px 0 0;
		}

		p {
			display: inline-block;
			vertical-align: middle;
		}
	}
}
