@mixin account-verification-user-configuration {
	.common-account-verification-user-configuration {
		height: 100%;
		overflow: auto;
		padding: 15px;

		p {
			margin-bottom: 10px;
		}

		.error-icon {
			padding-left: 15px;
			background: background-image-url($stamps-ui-element-wrapper-error-icon) no-repeat center;
		}

		.error-message {
			padding-left: 8px;
			color: $stamps-ui-element-wrapper-invalid-text-color;
		}

		.action-bar {
			position: relative;
			width: 100%;
			margin-top: 20px;

			.common-ui-button {
				background-position: center 25%;
				background-repeat: no-repeat;
				background-size: 35px 35px;
				width: 160px;


				.ui-button-icon {
					padding-top: 60px;
					border: 1px solid $stamps-verify-button-border-color;
					height: 104px;
					font-weight: bold;
					color: $stamps-verify-text-color;
					cursor: pointer;
					width: 100%;
					text-align: center;

					.p-button-label {
						display: inline-block;
					}
				}

				&.email,
				&.text,
				&.call {
					.ui-button-icon {
						&:hover,
						&:active {
							background-color: transparent;
							color: $stamps-verify-button-hover-color;
							border-color: $stamps-verify-button-hover-color;

							.p-button-label {
								color: $stamps-verify-button-hover-color;
							}
						}
					}
				}

				// email icon
				&.email {
					background-image: background-image-url($stamps-verify-email-me-icon);
					background-size: 55px;
					margin-right: 25px;

					&:hover {
						background-color: $stamps-verify-button-active-color;
					}

					&.active {
						background-color: $stamps-verify-button-active-color;
					}
				}

				// text icon
				&.text {
					background-image: background-image-url($stamps-verify-text-me-icon);
					background-size: 55px;
					margin-right: 25px;

					&:hover {
						background-color: $stamps-verify-button-active-color;
					}

					&.active {
						background-color: $stamps-verify-button-active-color;
					}
				}

				//call icon
				&.call {
					background-image: background-image-url($stamps-verify-call-me-icon);
					background-size: 55px;

					&:hover {
						background-color: $stamps-verify-button-active-color;
					}

					&.active {
						background-color: $stamps-verify-button-active-color;
					}
				}
			}
		}

		.help-text {
			margin-top: 20px;
		}
	}
}
