@mixin ui-buttongrid() {
	.common-ui-buttongrid {
		height: 100%;
		padding: 10px;
		border: 1px solid $stamps-ui-input-border-color;

		.grid-item-container {
			border: 3px solid #e5e5e5;
			border-radius: 5px;
			padding: 10px;
			cursor: pointer;
			width: 100%;

			button {
				width: 100%;
				display: flex;
				justify-content: center;
				outline: none;

				.p-button-label {
					display: none;
				}
			}

			&:hover {
				border: 3px solid #2159a8;
				transition: all .3s;
			}
		}
	}
}
