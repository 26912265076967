@mixin account-payment {
	.account-payment {
		.common-ui-element-wrapper,
		.card-number {
			.p-dropdown-label {
				text-align: left !important;
				width: calc(100% - 2px);
			}

			.p-dropdown-trigger {
				right: 2px;
			}

			.label-container {
				min-width: 130px;
			}
		}

		.common-ui-element-wrapper,
		.card-number,
		.expiration {
			width: 470px;

			.cvv {
				.ui-button-icon {
					&:enabled:hover {
						background-color: transparent;
						border-color: transparent;
					}
				}
			}

			&.mobile-wrapper {
				width: 100%;
			}
		}

		.new-billing-address {
			.zip-code {
				width: 280px;

				&.mobile-wrapper {
					width: 100%;
				}
			}
		}

		&.payment-settings {
			height: 100%;
			padding: $stamps-account-content-padding;

			.sub-title {
				text-align: left;
				padding: 10px 0;
			}

			.common-ui-toolbar {
				height: 48px;
				background: #e9e9e9;
			}

			.account-title {
				text-align: left;
			}

			.common-ui-save-confirmation {
				display: inline-block;
				vertical-align: middle;
				margin: -5px;

				.label {
					display: inline-block;
					vertical-align: bottom;
					padding-top: 0;
				}
			}

			.edit-info {
				margin-left: 27%;
			}

			.info-payment {
				display: flex;
			}

			.info-payment-service-fees {
				display: flex;
				flex-flow: wrap;
				position: relative;
			}

			.resubmission-error-text {
				text-align: start;
				margin: 5px 0px 5px 140px;
				color: $stamps-ui-element-wrapper-invalid-text-color;
			}

			.edit-payment-section {
				text-align: right;
				margin-right: 10px;
				min-width: 130px;

				a {
					line-height: 1.45;
				}
			}

			.info-card {
				text-align: left;
				margin-bottom: 20px;
				flex: 1;

				li {
					list-style-type: none;
					line-height: 1.45;
				}
			}

			.corp-payment-settings {
				display: flex;
				padding: 2px 0;

				.corp-radiobutton-label {
					margin-top: 3px;
					margin-right: 20px;
					white-space: nowrap;
					width: 130px;
					min-width: 130px;
					text-align: right;
					line-height: $stamps-line-height;
				}

				.radio-buttons {
					.common-ui-radiobutton {
						display: flex;
						flex-flow: row nowrap;
						flex: 1;
						margin-bottom: 5px;

						.p-radiobutton-label {
							text-align: left;
						}
					}

					.info-payment .edit-payment-section {
						min-width: 0;
						margin-right: 20px;
						margin-left: -45px;
					}
				}
			}

			.group-btn-payment {
				text-align: left !important;
				margin-top: 18px;
				padding-left: 140px;
			}

			.saveButton {
				margin-right: 15px;
			}
		}

		&.payment-methods {
			height: 100%;
			display: flex;
			flex-direction: column;

			.payment-methods-grid {
				height: calc(100% - 42px);
			}

			.ag-center-cols-container {
				min-width: 100%;
			}
		}

		&.manage-payment {
			display: flex;
			flex-direction: column;

			&.mobile-wrapper {
				padding: 10px;
			}

			.heading {
				font-size: 18px;
				font-weight: bold;
				border-bottom: 1px solid #eee;
				padding-bottom: 20px;
			}

			.expiration {
				display: flex;

				.month {
					width: calc(120px + 100px);
					margin-right: 10px;
				}

				.year {
					width: 80px;

					&.mobile-wrapper {
						width: auto;
						flex: 1;
					}
				}

				.cvv {
					position: relative;

					// override the common style rules
					.common-ui-element-wrapper {
						width: 160px;

						.label-container {
							width: 30px;
							min-width: 30px;
							margin-left: 20px;
						}

						.input-container {
							.common-ui-input-text {
								min-width: 100px;
							}
						}
					}

					.cvv-tooltip-btn {
						cursor: pointer;
						position: absolute;
						outline: none;
						right: 6px;
						top: 8px;
						height: 18px;
						width: 18px;
						background: background-image-url($stamps-ui-question-mark-icon) no-repeat;
						background-size: 18px;

						&:hover {
							background: background-image-url($stamps-ui-question-mark-icon-hover) no-repeat;
							background-size: 18px;
						}
					}
				}
			}

			.p-placeholder {
				font-style: $stamps-ui-input-placeholder-font-style;
				color: #ccc;
			}

			.main-add-payment {
				display: flex;
				flex-direction: column;
				padding: 20px 40px;
			}

			.manage-payment-footer {
				display: flex;
				flex-direction: row-reverse;
				padding: 10px 20px;
			}

			.ui-button-primary {
				width: 68px;
			}

			.card-number {
				position: relative;

				.credit-cards {
					position: absolute;
					right: 0;
					top: 6px;
					height: 24px;

					span {
						list-style-type: none;
						display: inline-block;
						margin-right: 5px;
						width: 24px;
						height: inherit;
					}

					.visa-card {
						background: background-image-url('visacard.png') no-repeat;
					}

					.master-card {
						background: background-image-url('mastercard.png') no-repeat;
					}

					.discover-card {
						background: background-image-url('discovercard.png') no-repeat;
					}

					.american-express-card {
						background: background-image-url('american-express.png') no-repeat;
					}
				}

				// prevent credit cards icons from blocking the error icon
				&.invalid:not(.mobile-wrapper) {
					.credit-cards {
						right: 25px;
					}
				}
			}
		}

		&.cvv-tooltip {
			.p-tooltip-text {
				display: flex;
				flex-direction: column;
				max-width: 300px;
			}

			p {
				margin-bottom: 5px;
			}

			.cvv-images {
				display: flex;
				justify-content: center;

				.cvv-image {
					height: 80px;
					width: 128px;

					&.all-cards {
						background: background-image-url('CVV.svg') no-repeat;
						margin-right: 10px;
						background-size: cover;
					}

					&.amex {
						background: background-image-url('CVV_Amex.svg') no-repeat;
						background-size: cover;
					}
				}
			}
		}

		&.delete-payment-modal {
			.warning-icon {
				font-size: 40px;
				vertical-align: middle;
			}
		}
	}
}
