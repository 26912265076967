@mixin account-locations {
	$general-settings-header-color: #565e64;
	$settings-menu-item-highlight-background-color: #fff;
	$settings-menu-item-highlight-border-color: #2158aa;
	$settings-menu-border-color: #868789;
	$settings-menu-group-item-border-color: #ccc;

	.account-locations {
		height: 100%;
		text-align: left;

		// permissions modal
		&.permissions-modal {
			overflow: auto; // fix IE modal position issue

			.dollar-amount-field {
				padding-left: 10px;

				&.is-mobile {
					padding-left: 130px;
				}

				.common-ui-input-number {
					width: 100%;

					.p-inputnumber-input {
						width: calc(100% - 24px); // fix input overflow issue in Firefox
					}
				}
			}

			height: auto;
		}

		// regions modal
		&.regions-modal {
			overflow: auto;
			height: auto;
		}

		// regions modal
		&.complete-modal {
			overflow: auto;
			height: auto;
		}

		// autofund modal
		&.autofund-modal {
			overflow: auto;
			height: auto;

			.common-ui-element-wrapper {
				.label-container {
					flex: 1;
					text-align: left;
					width: auto;
				}

				.input-container {
					flex: none;
				}
			}
		}

		&.addCarrier-modal {
			overflow: auto;
			height: auto;
		}

		// request new locations modal
		&.request-new-locations-modal {
			overflow: auto;
			height: auto;

			.upload-container {
				display: flex;
				padding-top: 15px;
				align-items: center;

				.common-ui-fileupload {
					padding: 0 15px 0 0;
				}
			}

			.request-info {
				padding: 10px;
				text-align: center;
			}

			.success-message {
				p {
					padding: 10px 0;
				}
			}
		}

		// settings modal
		&.p-dialog.location-settings-modal {
			height: auto;
			min-width: calc(240px + 920px);

			.p-dialog-content {
				display: flex;
				flex-direction: row;
				overflow: hidden;
				padding: 0;

				.location-general-settings {
					padding: {
						left: 10px;
						right: 10px;
					}

					.dollar-amount-field {
						max-width: 210px;
					}

					.header {
						border-bottom: 1px solid $settings-menu-group-item-border-color;
						margin-bottom: 10px;
						padding: {
							bottom: 7px;
							top: 12px;
						}

						h1 {
							color: $general-settings-header-color;

							font: {
								size: 17px;
								weight: bold;
							}
						}
					}

					.label-container {
						max-width: 60px;
					}

					.save-button {
						margin-top: 20px;
					}

					.verticalTextAlign {
						padding-top: 14px;
					}
				}

				.settings-menu {
					background-color: $settings-menu-item-background-color;
					border-right: 1px solid $stamps-menu-item-border-color;
					min-height: 600px;
					width: 240px;
					flex: none;
					padding: 0;

					.common-menu {
						border-left: $settings-menu-border-left;
						border-bottom: 1px solid $stamps-menu-item-border-color;

						.badge-item {
							background-color: $settings-menu-item-background-color;
							padding-left: $settings-menu-padding-left;
							border-bottom: $settings-menu-border-bottom;
							border-left: $settings-menu-badge-border-left;

							&.active {
								border-left: $settings-menu-badge-active-border-left;

								.menu-item-active-icon {
									display: $settings-menu-badge-active-icon-display;
									position: absolute;
									left: -3px;
									top: 0;
									bottom: 0;
									margin-top: auto;
									margin-bottom: auto;
									background-color: $stamps-menu-group-item-highlight-border-color;
									width: 6px;
									height: 6px;
									transform: rotate(45deg);
								}
							}

							.menu-item-text {
								color: $settings-menu-text-color;
							}
						}
					}
				}

				.settings-content {
					padding: 0;
					flex: 1;
					overflow: auto;

					.account-users.account-users-wrapper.show-details {
						width: auto;
						min-width: auto;
					}

					.account-contact-info {
						position: initial;
					}
				}
			}
		}

		// ag-grid
		.locations-grid {
		}
	}
}
