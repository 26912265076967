@mixin ui-calendar() {
	.common-ui-calendar {
		.p-calendar.p-calendar-w-btn {
			margin-right: 30px; // the size of the calendar icon

			.p-datepicker-trigger.p-button {
				width: 31px; // 1px is for the right-border and 30px is the icon size
				background-color: $stamps-transparent-button-bg-color;
				border-color: $stamps-ui-input-border-color;
				border-left: 0;
				padding: 0;

				.p-button-icon.calendar-icon {
					width: 100%;
					height: 100%;
					background: background-image-url($stamps-ui-calendar-date-trigger-icon);
					background-repeat: no-repeat;
					background-position: 0;
				}

				.p-button-label {
					padding: 0;
				}
			}

			.p-inputtext {
				padding-right: 0;
			}

			// override the behavior of primereact calendar component
			&.p-inputwrapper-focus {
				.p-inputtext,
				.p-datepicker-trigger.p-button {
					border-color: $stamps-ui-input-hover-border-color;
				}
			}

			&.invalid-component {
				.p-inputtext,
				.p-datepicker-trigger.p-button {
					border-color: $stamps-ui-input-border-invalid-color;
				}
			}

			&:not(.p-disabled):hover {
				.p-inputtext,
				.p-datepicker-trigger.p-button {
					border-color: $stamps-ui-input-hover-border-color;

					.p-button-icon.calendar-icon {
						background-position: center;
					}
				}
			}

			&.invalid-component:not(.p-disabled):hover {
				.p-inputtext,
				.p-datepicker-trigger.p-button {
					border-color: $stamps-ui-input-border-invalid-color;
				}
			}
		}
	}
}
