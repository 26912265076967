@mixin button-factory ($button-type, $button-params) {
	.p-splitbutton.ui-splitbutton-#{$button-type} {
		height: 26px;
	}

	.p-button.ui-button-#{$button-type},
	.p-splitbutton.ui-splitbutton-#{$button-type} .p-button {
		color: map-get($button-params, 'text-color');
		background-color: map-get($button-params, 'background-color');
		border: map-get($button-params, 'border');

		&:enabled:not(.p-splitbutton):not(.p-splitbutton-menubutton):not(.p-splitbutton-defaultbutton):hover {
			color: map-get($button-params, 'hover-text-color');
			background-color: map-get($button-params, 'hover-background-color');
			border-color: map-get($button-params, 'hover-border-color');
		}

		&:enabled:not(.p-splitbutton):not(.p-splitbutton-menubutton):not(.p-splitbutton-defaultbutton):active {
			color: map-get($button-params, 'active-text-color');
			background-color: map-get($button-params, 'active-background-color');
			border-color: map-get($button-params, 'active-border-color');
		}

		// conditional logic for Icon type
		@if ($button-type == 'icon') {
			$icon-size: map-get($button-params, 'icon-size');
			$padding: map-get($button-params, 'padding');

			&.p-button-icon-only .p-button-icon,
			&.p-button-label-icon-left .p-button-icon,
			&.p-button-label-icon-right .p-button-icon {
				position: absolute;
				top: 50%;
				margin-top: $icon-size / 2 * -1;
				width: $icon-size;
				height: $icon-size;
				line-height: $icon-size;
			}

			&.p-button-icon-only {
				.p-button-icon {
					left: 50%;
					margin-left: $icon-size / 2 * -1;
				}
			}

			&.p-button-label-icon-left {
				.p-button-icon {
					left: $padding;
				}
			}

			&.p-button-label-icon-right {
				.p-button-icon {
					right: $padding;
				}
			}
		}
	}

	.p-splitbutton.ui-splitbutton-#{$button-type} {
		color: map-get($button-params, 'text-color');
		background-color: map-get($button-params, 'background-color');
		border: map-get($button-params, 'border');

		.p-splitbutton-menubutton, .p-splitbutton-defaultbutton {
			color: inherit;
			background-color: inherit;
			border: inherit;
		}

		&:hover {
			color: map-get($button-params, 'hover-text-color');
			background-color: map-get($button-params, 'hover-background-color');
			border-color: map-get($button-params, 'hover-border-color');
		}

		&:active{
			color: map-get($button-params, 'active-text-color');
			background-color: map-get($button-params, 'active-background-color');
			border-color: map-get($button-params, 'active-border-color');
		}

	}
}
