@mixin rate-advisor-additional-options() {
	.common-rate-advisor-additional-options {
		border-bottom: 1px solid #eef2f8;
		width: 200px;

		.options-title {
			font-size: 10pt;
			font-weight: bold;
			padding-top: 27px;
			padding-bottom: 19px;
		}

		.options-radio-buttons {
			padding-left: 36px;
		}

		.options-button {
			cursor: pointer;
			padding: 2px 0 36px 8px;
			font-size: 12px;
			color: $stamps-rate-advisor-show-options-button-color;

			&:hover {
				color: $stamps-rate-advisor-show-options-button-hover-color;
			}

			.pi-chevron-down {
				top: 3px;
			}

			.pi-chevron-down,
			.pi-chevron-up {
				left: 2px;
				font-size: 12px;
				position: relative;
			}

			&:focus {
				outline: none;
			}
		}
	}
}
