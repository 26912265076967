@mixin account-allocate-funds {
	.account-allocate-funds {

		// This wrapper is to prevent modals' styling get affected
		.allocate-funds-wrapper {
			position: absolute;
			height: 100%;
			width: 100%;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			text-align: left;
			padding: 0;
		}

		.body {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			overflow: auto;
			padding: $stamps-account-content-padding;
		}

		// per WEBAPPS-25573, checkbox and continue button
		// should always be visible when page is scrollable
		.footer {
			margin: 15px 0;
			height: 130px;
			flex-shrink: 0;
			padding: 0 15px;
		}

		.amount-input {
			margin-top: 9px;
			text-align: right;
			width: 70px;
		}

		.header-text {
			margin-bottom: 20px;
			text-align: left;
		}

		.left {
			float: left;
			margin-right: 6px;
		}

		.save-button {
			margin-top: 12px;
		}

		.primary-header {
			border-bottom: 1px solid #ddd;
			color: #000;
			font-size: 18px;
			font-weight: 900;
			margin-bottom: 20px;
			padding: 0 0 11px 0;
			text-align: left;
		}

		// per WEBAPPS-25573, meter list should be scrollable
		.common-grid {
			max-width: 530px; // WEBAPPS-29081, location name can display up to 40 characters.

			.ag-theme-common {
				&.ag-grid-container {
					flex: 1 1 auto;
				}
			}
		}

		ul {
			list-style-position: outside; // fix the issue of IE11 renders bullets
		}

		.common-ui-checkbox {
			max-width: 400px;
		}

		.common-ui-element-wrapper {
			max-width: 400px;

			&.mobile-wrapper {
				.label-container {
					text-align: right;
					width: 130px;
					flex: none; // prevent label from shrinking
				}
			}

			&.billing-address {
				align-items: start;
			}
		}

		.grid-container {
			min-height: 225px; // per WEBAPPS-25573, the height to show the minimum 4 meters including header
			overflow: hidden;
			margin: 12px 0;
			flex: 1;

			.common-grid {
				.ag-theme-common {
					overflow: auto;

					.ag-header,
					.ag-row-even,
					.ag-row-odd {
						background-color: $stamps-center-panel-background-color;
					}

					.ag-overlay .ag-overlay-panel .ag-overlay-wrapper {
						background-color: rgba(246, 247, 247, .5);
					}

					&.ag-grid-container {
						background-color: $stamps-center-panel-background-color;
					}
				}

				// override input-nummber style rules
				.fund-amount-cell-renderer {
					padding: 0;
					display: inline-flex;

					.input-container {
						margin-bottom: 0;

						// fix an issue of input number in ag-grid cell on FireFox
						.common-ui-input-number {
							width: 100%;

							.p-inputnumber-input {
								width: calc(100% - 24px);
							}
						}

						.common-ui-error-container {
							.invalid-error-icon {
								margin: 10px 0 0 5px;
							}
						}
					}

					&.mobile-wrapper {
						.common-ui-error-container {
							display: inline-flex;

							.invalid-error-icon {
								flex: none;
								margin: 0 5px 0 0;
							}
						}
					}
				}
			}
		}

		&.review-transaction-modal {
			overflow: auto; // fix the modal layout issue in IE11

			.body-text {
				margin-bottom: 12px;
			}

			.field-label {
				font-weight: bold;
				text-align: right;
			}
		}

		&.transaction-confirmation-modal {
			overflow: auto; // fix the modal layout issue in IE11

			.transaction-confirmation-content {
				p,
				ol li {
					margin-bottom: 10px;
				}

				.common-grid {
					max-width: 530px; // WEBAPPS-29081, location name can display up to 40 characters.

					.ag-theme-common {
						.ag-header,
						.ag-row-even,
						.ag-row-odd {
							background-color: #fff;
						}

						&.ag-grid-container {
							background-color: #fff;
						}
					}
				}

				.subtitle {
					margin-bottom: 20px;
				}

				.display-info {
					display: flex;
					flex-direction: row;
					margin-top: 5px;

					.display-label {
						width: 170px;
						display: inline-block;
						text-align: right;
						line-height: 20px;
					}

					.display-data {
						margin-left: 10px;
						line-height: 20px;
					}
				}
			}

			#printTrigger {
				display: none;
			}
		}
	}
}
