@mixin ui-tristatecheckbox() {
	.common-ui-tristatecheckbox {
		display: inline-block;
		vertical-align: middle;

		.p-checkbox {
			display: flex;
			align-items: center;
			width: $stamps-ui-checkbox-box-size;

			.p-checkbox-box {
				width: $stamps-ui-checkbox-box-size;
				height: $stamps-ui-checkbox-box-size;
				background-color: $stamps-ui-checkbox-box-checked-background-color;
				color: $stamps-ui-checkbox-box-checked-color;

				&.p-highlight {
					// tristate checkbox doesn't distinguish between checked states, so use aria-checked to do so instead
					&[aria-checked='false'] {
						background-color: #fff;

						&:not(.p-disabled):hover {
							background-color: #fff;
						}
					}

					&:not(.p-disabled):hover {
						border-color: $stamps-ui-checkbox-box-checked-hover-border-color;
						background-color: $stamps-ui-checkbox-box-checked-background-color;
						color: $stamps-ui-checkbox-box-checked-color;
					}
				}

				.p-checkbox-icon {
					font-size: $stamps-ui-checkbox-check-size;
					font-family: 'primeicons'; /* stylelint-disable-line */
				}

				.p-checkbox-icon::before {
					// default 'undetermined' state icon
					content: '\e90f';
				}

				.p-checkbox-icon.pi-check::before {
					// override 'checked' state icon
					content: '\e909';
				}

				.p-checkbox-icon.pi-times::before {
					// override 'uncheck' state icon
					content: '';
				}
			}
		}

	}
}
