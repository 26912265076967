@mixin cost-codes() {
	.common-cost-codes {
		height: 100%;
		text-align: left;
		display: flex;
		flex-direction: column;

		&.p-dialog {
			width: 400px;
			height: auto; // override height:100%

			.common-ui-element-wrapper {
				margin: 15px 0 0 0;

				.input-container {
					flex-wrap: nowrap;
				}

				.label-container {
					width: auto;
				}
			}

			.common-grid {
				.ag-theme-common {
					&.ag-grid-container {
						flex: 1 1 auto; // fix IE 11 height issue
					}
				}
			}

			&.add-cost-codes-modal {
				.upload-container {
					display: flex;
					padding: 15px 0 0 0;
					align-items: center;
				}

				.common-ui-fileupload {
					padding: 0 15px 0 0;
				}
			}

			&.import-cost-codes-modal {
				.upload-container {
					display: flex;
					padding: 15px 0 0 0;
					align-items: center;

					.common-ui-fileupload {
						padding: 0 15px 0 0;
					}
				}
			}

			&.manage-cost-codes-modal {
				height: 480px;

				&.p-dialog {
					.p-dialog-content {
						padding: 0;
						flex: 1;
					}
				}
			}
		}
	}
}
