@mixin ui-drag-and-drop-file-upload {
	.common-ui-drag-and-drop-file-upload {
		width: 100%;
		height: 100%;

		.drop-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			border: 1px dashed $stamps-ui-drag-drop-upload-border;
			border-radius: $stamps-ui-drag-drop-upload-border-radius;
			padding: 12px;
			height: 240px;

			.icon-container {
				width: $stamps-ui-drag-drop-upload-icon-size;
				height: $stamps-ui-drag-drop-upload-icon-size;
				margin: auto;
				overflow: hidden;

				.upload-icon {
					display: block;
					background: background-image-url($stamps-ui-drag-drop-upload-icon) no-repeat;
					background-position: $stamps-ui-drag-drop-upload-background-position;
					background-size: $stamps-ui-drag-drop-upload-icon-background-size;
					width: 100%;
					height: 100%;
				}
			}

			.drop-area-text {
				text-align: center;
				color: $stamps-ui-drag-drop-upload-text-color;
				font-weight: 600;
			}

			.supported-formats {
				font-size: 12px;
				line-height: 16px;
			}

			.import-contacts-upload-button {
				width: 100%;

				.p-fileupload-buttonbar {
					padding: 0;

					.p-button {
						margin-right: 0px;
						color: $stamps-primary-button-text-color;
						background-color: $stamps-primary-button-bg-color;
						width: 100%;

						.p-button-label {
							padding: 8px 16px;
						}

						.p-button-icon {
							display: none;
						}

						&:hover {
							background-color: $stamps-primary-button-hover-bg-color;
						}

						&:active {
							background-color: $stamps-primary-button-active-bg-color;
						}
					}
				}
			}

			.p-fileupload-content {
				display: none;
			}
		}

		.successful-upload {
			display: flex;
			flex-direction: column;
			align-items: center;

			.success-icon {
				width: 64px;
				height: 64px;
				background: background-image-url($stamps-ui-drag-drop-upload-success-icon) no-repeat;
			}

			.success-message {
				color: $stamps-ui-drag-drop-upload-text-color;
				font-weight: 600;
			}
		}
	}
}
