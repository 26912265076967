@mixin account-change-plan {
	.account-change-plan {
		height: 100%;
		text-align: left;
		padding: $stamps-account-content-padding;

		.gbb-button {
			&.select-plan,
			&.cancel-account {
				align-self: end;
				.ui-button-secondary:enabled {
					color: $stamps-account-subscription-plan-button-color;
					background-color: $stamps-account-subscription-plan-button-background-color;
					border: 1px solid $stamps-account-subscription-plan-button-border-color;
				}

				.ui-button-secondary:enabled:hover {
					color: $stamps-account-subscription-plan-button-hover-color !important;
					background-color: $stamps-account-subscription-plan-button-hover-background-color !important;
					border: 1px solid $stamps-account-subscription-plan-button-hover-border-color !important;
				}
			}
		}

		.common-ui-layout-section {
			.section-body {
				flex: 0 1 auto;
				padding: $stamps-account-plan-padding;

				.account-plan-tile {
					display: flex;
					position: relative;
					max-width: $stamps-account-plan-width;
					background-color: #fff;
					border: $stamps-account-plan-tile-border-size solid #ccc;
					border-radius: 5px;
					margin: 25px 0 0 0;
					cursor: pointer;
					padding: 16px;

					.plan-name {
						flex: 1 1 calc(100% / 3);
						font-weight: bold;
						margin: auto 15px;

						&.mobile-wrapper {
							margin: 0;
							padding: 0 0 5px 0;
						}
					}

					.plan-info {
						flex: 1 1 calc(100% / 3 * 2);

						.plan-cost {
							padding: 0 0 15px 0;

							.details {
								margin: 0 0 0 15px;
							}

							.plan-mo {
								color: $stamps-modal-body-cost-text-color;
								font-size: $stamps-account-subscription-plan-font-size;
								font-weight: $stamps-account-subscription-plan-font-weight;
							}
						}
					}


					.plan-type {
						position: absolute;
						top: 0;
						right: 0;
						font-weight: $stamps-account-subscription-plan-font-weight;


					}
					&:first-child {
						margin: 10px 0 0 0;
					}

					&:hover {
						border: $stamps-account-plan-border-size solid $stamps-account-subscription-plan-border-color;

						.plan-type {
							padding: 8px 8px 0 0;
						}
					}

					&.selected {
						border: $stamps-account-plan-border-size solid $stamps-account-subscription-plan-border-color;
						border-left-width: $stamps-account-plan-border-left-width;
						margin-bottom: -2px;
						padding: 13px 13px 15px 7px;

						.plan-type {
							padding: $stamps-account-subscription-plan-text-padding;
							background-color: $stamps-account-subscription-plan-background-color;
							color: $stamps-account-subscription-plan-color;
							border-radius: $stamps-account-subscription-plan-border-radius;
							font-size: $stamps-account-subscription-plan-font-size;
							padding: $stamps-account-subscription-plan-padding;
							width: $stamps-account-subscription-plan-width;
							line-height: 16px;
						}
					}

					&.mobile-wrapper {
						flex-direction: column;
					}
				}

				.gbb-account-plan-tile {
					max-width: $stamps-account-plan-width;
					background-color: #fff;
					border: 1px solid #ccc;
					border-radius: 5px;
					margin: 10px 0 0 0;
					display: flex;
					flex-direction: column;

					.plan-info {
						padding: 15px;
						display: flex;
						flex-direction: column;

						.plan-details {
							display: flex;

							.plan-name {
								font-weight: bold;
								font-size: 16px;

							}

							.plan-cost {
								flex: 1;
								text-align: right;
								font-weight: bold;

								.plan-mo {
									color: $stamps-modal-body-cost-text-color;
									font-size: $stamps-account-subscription-plan-font-size;
									font-weight: $stamps-account-subscription-plan-font-weight;
								}
							}

							.plan-name-tag {
								color: $primereact-textSecondaryColor;
							}
						}

						.plan-content {
							.plan-sub-header {
								margin: 10px 0px 10px 0px;
								font-weight: bold;
							}

							.plan-sub-header{
								.plan-list {
									padding: 0 0 15px 0;
								}
							}

							.plan-list {
								padding-bottom: 10px;
								.list-item {
									display: flex;
									.icon {
										background: background-image-url('green-check-icon.png') no-repeat;
										height: 24px;
										width: 24px;
										background-position-y: center
									}
								}
							}
						}

						.plan-inprogress {
							text-align: right;
							color: $primereact-textSecondaryColor;
						}
					}

					.legacy-plan-info {
						padding: 15px;
						display: flex;
						flex-direction: column;

						.plan-details {
							.plan-header {
								display: flex;
							}

							.plan-name {
								font-weight: bold;
								font-size: 16px;

							}

							.plan-cost {
								flex: 1;
								text-align: right;
								font-weight: bold;

								.plan-mo {
									color: $stamps-modal-body-cost-text-color;
									font-size: $stamps-account-subscription-plan-font-size;
									font-weight: $stamps-account-subscription-plan-font-weight;
								}
							}

							.common-ui-info-banner {
								margin: 10px 0 10px 0;
								border: $stamps-ui-infobanner-warning-light-border;
							}
						}
					}

					&.selected {
						border: 1px solid $stamps-account-subscription-plan-button-hover-border-color;

						.current-plan-title {
							background-color: $stamps-account-subscription-plan-background-color;
							color: #fff;
							border-radius: 0 0 5px 0;
							font-size: $stamps-account-subscription-plan-font-size;
							padding: $stamps-account-subscription-plan-padding;
							width: $stamps-account-subscription-plan-width;
						}
					}

					&:hover {
						border: 1px solid $stamps-account-subscription-plan-button-hover-border-color;
						.select-plan,
						.cancel-account {
							.ui-button-secondary {
								color: $stamps-account-subscription-plan-button-hover-color !important;
								background-color: $stamps-account-subscription-plan-button-hover-background-color !important;
								border: 1px solid $stamps-account-subscription-plan-button-hover-border-color !important;
							}
						}
					}

				}

				.tax-exemption {
					font-size: 13px;
					font-style: italic;
					padding: 25px 0 0 0;
				}

				.change-plan-button {
					margin: 15px 0px;
				}

				.subscription-info-text {
					padding: 15px 0px;
				}

				.cancel-subscription {
					.title {
						font-weight: bold;
						padding: 10px 0px;
					}
				}

				.info-banner-container {
					max-width: 640px;
				}
			}

			.account-footer {
				padding-left: 25px;
			}
		}

		&.modal {
			height: auto;

			p {
				max-width: 600px;
			}
		}

		&.plan-changed-modal {
			p {
				padding: 0 0 15px 0;
			}
		}

		&.gbb-plan-change-modal {
			.section1 {
				padding: 0 0 10px 0;
			}
		}

		&.gbb-plan-changed-succesful-modal {
			.plan-change-info {
				ul {
					list-style-position: outside;
					padding: 0px 0 0 30px;
				}
				.section2 {
					padding: 10px 0 10px 0;
				}

				.footer {
					padding: 10px 0 10px 0;
					display: flex;
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;

					.info-icon {
						width: 40px;
						height: 20px;
						margin: 10px 10px 10px 0px;
						display: inline-block;
						background: background-image-url('info-icon.png') no-repeat;
					}

					.info-text {
						display: inline;
					}

					a {
						color: #007EE5;
					}
				}
			}

			.footer {
				display: flex;
				align-items: center;
				padding-top: 10px;

				.message-icon {
					background: background-image-url('icon-instance-logo.svg') no-repeat;
					height: 25px;
					width: 25px;
					padding-left: 30px;
				}

				.footer-text{
					font-size: 12px;
				}
			}
		}

		&.plan-details-modal {
			.body {
				.title {
					font-size: 18px;
					font-weight: bold;
					padding-bottom: 10px;
				}

				.key-features {
					font-size: 15px;
					padding-bottom: 9px;
				}

				.text {
					font-size: 14px;
					margin-left: 10px;
				}
			}
		}
	}
}

