@mixin ui-section() {
	.common-ui-layout-section {
		display: flex;
		flex-direction: column;
		height: 100%;

		.section-body {
			flex: 1;
			overflow: auto;
			font-size: 14px;
			line-height: 1;
			padding: 15px;

			&.no-padding {
				padding: 0;
			}
		}

		.section-title {
			font-weight: bold;
			font-size: 16px;
			margin: 0;
			padding: 20px 0;
		}
	}
}
