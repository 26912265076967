@mixin ui-service-field() {
	// local variable
	$services: (
		'postcard':'service/service_postcard.svg',
		'letter': 'service/service_letter.svg',
		'envelope': 'service/service_envelope.svg',
		'large-envelope': 'service/service_envelope.svg',
		'package': 'service/service_package.svg',
		'large-package': 'service/service_package.svg',
		'usps-small-flat-rate-box': 'service/service_package.svg',
		'usps-medium-flat-rate-box': 'service/service_package.svg',
		'usps-medium-flat-rate-box-pme': 'service/service_package.svg',
		'usps-large-flat-rate-box': 'service/service_package.svg',
		'usps-flat-rate-envelope': 'service/service_envelope.svg',
		'usps-flat-rate-envelope-pme': 'service/service_envelope.svg',
		'usps-padded-flat-rate-envelope': 'service/service_envelope.svg',
		'usps-padded-flat-rate-envelope-pme': 'service/service_envelope.svg',
		'usps-legal-flat-rate-envelope': 'service/service_envelope.svg',
		'usps-legal-flat-rate-envelope-pme': 'service/service_envelope.svg',
		'ups-letter': 'service/service_letter.svg',
		'ups-pak': 'service/service_tube.svg',
		'ups-tube': 'service/service_tube.svg',
		'ups-express-box-small': 'service/service_package.svg',
		'ups-express-box-medium': 'service/service_package.svg',
		'ups-express-box-large': 'service/service_package.svg',
		'fedex-envelope': 'service/service_envelope.svg',
		'fedex-pak': 'service/service_tube.svg',
		'fedex-tube': 'service/service_tube.svg',
		'fedex-one-rate-envelope': 'service/service_envelope.svg',
		'fedex-one-rate-pak': 'service/service_tube.svg',
		'fedex-one-rate-tube': 'service/service_tube.svg',
		'fedex-one-rate-small-box': 'service/service_package.svg',
		'fedex-one-rate-medium-box': 'service/service_package.svg',
		'fedex-one-rate-large-box': 'service/service_package.svg',
		'fedex-one-rate-extra-large-box': 'service/service_package.svg',
		'fedex-10kg-box': 'service/service_package.svg',
		'fedex-25kg-box': 'service/service_package.svg',
		'express-envelope': 'service/service_envelope.svg',
	);

	.common-ui-service-field {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;

		.common-ui-element-wrapper {
			.input-container {
				align-items: center; // make label vertically centered

				.label-container {
					width: auto; // to reset
					text-align: left;
					margin-left: 5px; // spacing between the label (e.g. Service") and the input field
					margin-bottom: 0; // override to correctly center the label
				}
			}
		}
	}

	.common-ui-service-field-tooltip {
		padding: 5px;

		.title {
			font-weight: bold;
		}

		.description {
			.title {
				font-weight: normal;
				margin-top: 20px;
			}
		}
	}

	// add dropdown panel style rules
	@include dropdown-panel-factory('service');

	// add service icons style rules
	.service-dropdown-panel {
		.p-dropdown-item-group {
			font-weight: bold;
		}

		.service-group-item,
		.service-item {
			display: flex;
		}

		.service-title {
			flex: auto;
		}

		.service-icon {
			@each $name, $path in $services {
				span.#{$name} {
					width: 100%;
					height: 100%;
					background-image: background-image-url($path);
					background-repeat: no-repeat;
					background-position: center;
					display: inline-block;
				}
			}
		}

		.p-progress-spinner {
			width: 20px;
			height: 20px;
			vertical-align: middle;
			display: inline-block;
			margin-left: 5px;
		}
	}
}
