@mixin account-contact-info {
	.account-contact-info {
		position: relative;
		padding: $stamps-account-content-padding;
		overflow: auto;
		text-align: left;

		.account-contact-info-body {
			display: flex;
			flex-direction: row;

			&.mobile-wrapper {
				flex-direction: column;
			}

			.main-contact-wrapper,
			.business-contact-wrapper {
				max-width: 480px;
				min-width: 360px;
				flex: 1 1 auto;

				&.mobile-wrapper {
					min-width: 0;
				}
			}

			.business-contact-wrapper {
				padding: 0 20px;

				&.mobile-wrapper {
					padding: 25px 0 0 0;
				}
			}
		}

		.header-and-checkbox {
			margin-bottom: 10px;
			padding-left: 130px;

			.header-text {
				margin-right: 15px;
				display: inline-block;
			}

			.common-ui-checkbox {
				display: inline-block;
				padding: 0;
			}

			&.mobile-wrapper {
				padding-left: 0;
			}
		}

		.label-container {
			flex: none; // prevent from shirking
		}

		.business-address-checkbox {
			margin-left: 130px;

			&.mobile-wrapper {
				margin-left: 0;
			}
		}

		.business-address-info {
			display: inline-flex;
			align-items: center;

			.business-address-icon {
				padding: 5px;
				font-size: 11px;

				&:hover {
					cursor: pointer;
				}
			}

			.p-checkbox {
				align-content: center;
			}
		}

		.save-button {
			margin: 25px 0 15px 130px;

			&.mobile-wrapper {
				margin-left: 0;
			}
		}
	}
}
