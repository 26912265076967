@mixin ui-tooltip {
	.p-tooltip {
		// tooltip content layout & style
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		max-width:  $stamps-ui-tooltip-max-width;
		min-height: $stamps-ui-tooltip-min-height;
		max-height: $stamps-ui-tooltip-max-height;
		padding: $stamps-ui-tooltip-padding;
		gap: $stamps-ui-tooltip-padding-gap;
		border-radius: $stamps-ui-tooltip-border-radius;

		.p-tooltip-text {
			// tooltip description layout & typography
			max-width: $stamps-ui-tooltip-text-max-width;
			color: $stamps-ui-tooltip-text-color;
			font-size: $stamps-ui-tooltip-text-font-size;
			font-style: $stamps-ui-tooltip-text-font-style;
			font-weight: $stamps-ui-tooltip-text-font-weight;
			line-height: $stamps-ui-tooltip-text-line-height;
		}
	}
}
