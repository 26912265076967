@mixin account-comm-prefs {
	.account-comm-prefs {
		height: 100%;
		text-align: left;
		padding: $stamps-account-content-padding;

		.header-text,
		.common-ui-checkbox {
			padding: 0 0 15px 0;
		}

		.comm-prefs-body {
			max-width: 640px;
		}

		.common-ui-button {
			margin: 25px 0 15px 0;
		}
	}
}
