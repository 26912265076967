@mixin ui-address-field() {
	.common-ui-address-field {
		display: flex;
		flex-direction: column;
		width: 100%;

		.common-ui-button {
			margin-bottom: 10px;

			.p-button-label {
				padding: 0;
				color: $stamps-primary-header-color;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
