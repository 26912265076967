@mixin ui-list {
    .common-ui-list {
        .list {
			display: flex;
			flex-direction: column;
			gap: $stamps-ui-list-item-gap;
			list-style: none;

			.item {
					position: relative;
					padding-left: calc($stamps-ui-list-icon-width + 4px); 

					&::before {
						content: '';
						position: absolute;
						left: 0;
						top: $stamps-ui-list-icon-top;
						@include icon-mask();
						mask-image: background-image-url($stamps-ui-list-icon); // default bullet icon
					}

					&.error {
						&::before {
							@include icon-mask-type('error');
						}
					}
					&.featured {
						&::before {
							@include icon-mask-type('featured');
						}
					}
					&.help {
						&::before {
							@include icon-mask-type('help');
						}
					}
					&.information {
						&::before {
							@include icon-mask-type('information');
						}
					}
					&.sparkle {
						&::before {
							@include icon-mask-type('sparkle');
						}
					}
					&.warning {
						&::before {
							@include icon-mask-type('warning');
						}
					}
					&.affirm {
						&::before {
							@include icon-mask-type('affirm');
						}
					}
			}
        }
    }
}
