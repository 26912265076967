@mixin upgrade-plan-comparison() {
	.upgrade-plan-comparison {
		display: flex;
		flex-direction: column;
		gap: $upgrade-plan-comparison-gap;

		&.no-table {
			min-height: 400px;
		}
	}
}
