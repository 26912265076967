@import 'inboundsDetails/product-details.scss';
@import 'inboundsDetails/edit-product-details.scss';
@import 'inboundsDetails/inbound-details.scss';
@import 'inboundsDetails/instructions.scss';
@import 'inboundsDetails/tracking-info.scss';
@import 'inboundsDetails/shipping-details.scss';
@import 'inboundsDetails/shipping-estimation.scss';
@import 'inboundsDetails/confirm-inbound.scss';

@mixin inbounds-details {
	// TODO: Below styles need to be updated later based on product requirements
	.common-create-inbound {
		display: flex;
		vertical-align: middle;
		flex-direction: column;
		position: absolute;
		height: 100%;
		width: 100%;

		.inbound-details-header {
			font-weight: bold;
			font-size: 16px;
			margin: 15px 15px 15px 15px;

			.instructions-checkbox {
				font-weight: normal;
				display: inline;
				float: right;
			}
		}

		.inbound-status-wrapper {
			margin: 0 15px 25px 15px;

			.status {
				font-weight: bold;

				.status-label {
					color: $stamps-inbound-details-header-text-color;
				}
			}

			.date-updated {
				font-style: italic;
			}
		}

		.inbound-details-content {
			flex: 1 1 auto;
			overflow-y: scroll;
			margin: 0 0 0 15px;
			padding-right: 15px;
		}

		&.mobile {
			padding: 15px 0 15px 0;

			.invalid-error-icon {
				margin: 0 4px 0 18px !important;
			}

			.terms-checkbox {
				display: inline-block !important;
			}

			.terms-error-container {
				display: inline-flex !important;
			}
		}

		.read-only-label-container {
			display: flex;
			padding: 2px 0;

			.read-only-label {
				margin-bottom: 5px;
				margin-right: 10px;
				width: 120px;
				text-align: right;
				line-height: 20px;
				color: $stamps-inbound-details-sub-header-text-color;
				font-weight: bold;
			}

			.read-only-text {
				flex: 1 1 auto;
				margin-bottom: 5px;
				text-align: left;
				line-height: 20px;
				overflow-wrap: anywhere;
			}
		}

		.inbound-details-footer {
			margin: 15px 0 15px 0;
			padding: 5px 10px 0 20px;
			border-top: 1px solid $stamps-inbound-details-border;
			display: flex;
			flex-direction: column;

			.common-ui-checkbox {
				margin-top: 0;
			}

			.common-ui-button {
				.p-button {
					width: 100px;
				}
			}

			.common-ui-button:last-of-type {
				margin-left: 10px;
			}

			.footer-buttons-wrapper {
				margin-top: 6px;

				.save-draft-button {
					margin-left: 10px;

					.p-button {
						width: 120px;
					}
				}

				.discard-changes-button {
					.p-button {
						width: 130px;
					}
				}
			}

			.terms-error-container {
				display: inline-block;

				.tooltip-error {
					.p-tooltip-text {
						border: 1px solid $stamps-ui-tooltip-invalid-border-color;
					}
				}

				.invalid-error-icon {
					width: 16px;
					height: 16px;
					margin: 0 0 0 5px;
					display: inline-block;
					background: background-image-url($stamps-ui-element-wrapper-error-icon) no-repeat;
					background-position: center;
				}

				.error-msg {
					font-size: 14px;
					line-height: 14px;
					vertical-align: middle;
					color: $stamps-ui-element-wrapper-invalid-text-color;
					white-space: pre-line;
				}
			}
		}

		.inbound-details-footer > .terms-checkbox,
		.inbound-details-header > .instructions-checkbox {
			display: inline-flex;

			.common-ui-checkbox {
				margin-top: 0;
				padding-top: 0;
				font-size: 12px;

				.p-checkbox,
				.p-checkbox-box {
					width: 14px;
					height: 14px;

					.p-checkbox-icon {
						font-size: 13px;
					}
				}

				.checkbox-error {
					.p-checkbox-box {
						border-color: $stamps-ui-element-wrapper-invalid-text-color;
					}
				}

				.footer-text {
					line-height: 14px;
				}
			}
		}
	}

	@include inbound-details-product-details();
	@include inbound-details-inbound-details();
	@include inbound-details-instructions();
	@include inbounds-details-tracking-info();
	@include inbound-details-shipping-details();
	@include inbound-details-shipping-details-estimation();
	@include inbound-details-confirm-inbound();
	@include inbound-details-edit-product-details();
}
