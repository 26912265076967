body {
	.p-inputtext {
		font-size: $fontSize;
		color: $textColor;
		background: $inputBgColor;
		padding: $inputPadding;
		border: $inputBorder;
		@include transition($inputTransition);
		@include appearance(none);
		@include border-radius($borderRadius);

		&:enabled:hover {
			border-color: $inputHoverBorderColor;
			background: $inputHoverForegroundColor;
		}

		&:enabled:focus {
			@include focused-input();
		}
	}

	.p-checkbox {
		display: inline-block;
		vertical-align: middle;
		margin: 0;
		width: $checkboxWidth;
		height: $checkboxHeight;

		.p-checkbox-box {
			border: $inputBorder;
			background-color: $inputBgColor;
			width: $checkboxWidth;
			height: $checkboxHeight;
			text-align: center;
			@include border-radius($borderRadius);
			@include transition($checkboxTransition);

			&:not(.p-disabled):hover {
				border-color: $inputHoverBorderColor;
			}

			&:not(.p-disabled).p-focus {
				@include focused-input();
			}

			&.p-highlight {
				border-color: $checkboxActiveBorderColor;
				background-color: $checkboxActiveBgColor;
				color: $checkboxActiveTextColor;

				&:not(.p-disabled):hover {
					border-color: $checkboxActiveHoverBorderColor;
					background-color: $checkboxActiveHoverBgColor;
					color: $checkboxActiveHoverTextColor;
				}
			}

			.p-checkbox-icon {
				overflow: hidden;
				position: relative;
				font-size: $fontSize + 4;
			}
		}
	}

	.p-checkbox-label {
		margin: 0 0 0 $inlineSpacing;
	}

	.p-radiobutton {
		display: inline-block;
		vertical-align: middle;
		margin: 0;
		width: $radiobuttonWidth;
		height: $radiobuttonHeight;

		.p-radiobutton-box {
			border: $inputBorder;
			background-color: $inputBgColor;
			width: $radiobuttonWidth;
			height: $radiobuttonHeight;
			text-align: center;
			position: relative;
			@include transition($radiobuttonTransition);
			@include border-radius(50%);

			&:not(.p-disabled):not(.p-highlight):hover {
				border-color: $inputHoverBorderColor;
			}

			&:not(.p-disabled).p-focus {
				@include focused-input();
			}

			&.p-highlight {
				border-color: $radiobuttonActiveBorderColor;
				background-color: $radiobuttonActiveBgColor;
				color: $radiobuttonActiveTextColor;

				.p-radiobutton-icon {
					background-color: $radiobuttonActiveTextColor;
				}

				&:not(.p-disabled):hover {
					border-color: $radiobuttonActiveHoverBorderColor;
					background-color: $radiobuttonActiveHoverBgColor;
					color: $radiobuttonActiveHoverTextColor;
				}
			}

			.p-radiobutton-icon {
				background: transparent;
				width: $radiobuttonWidth / 2;
				height: $radiobuttonHeight / 2;
				display: inline-block;
				position: absolute;
				@include transition(background-color $transitionDuration);
				@include border-radius(50%);

				&::before {
					display: none;
				}
			}
		}
	}

	.p-radiobutton-label {
		margin: 0 0 0 .5em;
	}

	.p-inputswitch {
		width: $inputSwitchWidth;
		height: $inputSwitchHeight;

		.p-inputswitch-slider {
			@include transition($inputSwitchTransition);

			border-radius: $inputSwitchBorderRadius;
			background: $inputSwitchSliderOffBgColor;

			&::before {
				background-color: $inputSwitchHandleOffBgColor;
				height: 1.25em;
				width: 1.25em;
				left: .25em;
				bottom: .25em;
				border-radius: 50%;
				-webkit-transition: $transitionDuration;
				transition: $transitionDuration;
			}
		}

		&.p-inputswitch-checked {
			.p-inputswitch-slider::before {
				-webkit-transform: translateX(1.25em);
				-ms-transform: translateX(1.25em);
				transform: translateX(1.25em);
			}
		}

		&.p-inputswitch-focus {
			.p-inputswitch-slider {
				@include focused();
			}
		}

		&:not(.p-disabled):hover {
			.p-inputswitch-slider {
				background-color: $inputSwitchSliderOffHoverBgColor;
			}
		}

		&.p-inputswitch-checked {
			.p-inputswitch-slider {
				background-color: $inputSwitchSliderOnBgColor;
			}

			&:not(.p-disabled):hover {
				.p-inputswitch-slider {
					background-color: $inputSwitchSliderOnHoverBgColor;
				}
			}
		}
	}

	.p-autocomplete {
		.p-autocomplete-input {
			padding: $inputPadding;
		}

		&.p-autocomplete-multiple {
			.p-autocomplete-multiple-container {
				padding: $inputPadding / 2 $inputPadding;

				&:not(.p-disabled):hover {
					border-color: $inputHoverBorderColor;
				}

				&:not(.p-disabled).p-focus {
					@include focused-input();
				}

				.p-autocomplete-input-token {
					margin: 0;
					padding: $inputPadding / 2 0;
					color: $textColor;

					input {
						font-family: $fontFamily;
						font-size: $fontSize;
						padding: 0;
						margin: 0;
					}
				}

				.p-autocomplete-token {
					font-size: $fontSize;
					padding: $inputPadding / 2 $inputPadding;
					margin: $chipsItemMargin;
					background: $highlightBgColor;
					color: $highlightColorText;
					@include border-radius($borderRadius);
				}
			}
		}
	}

	.p-autocomplete-panel {
		padding: 0;
		border: $inputOverlayBorder;
		background-color: $inputListBgColor;
		@include shadow($inputOverlayShadow);

		.p-autocomplete-items {
			padding: $inputListPadding;

			.p-autocomplete-list-item {
				margin: $inputListItemMargin;
				padding: $inputListItemPadding;
				border: $inputListItemBorder;
				color: $inputListItemTextColor;
				background-color: $inputListItemBgColor;
				@include transition($inputListItemTransition);
				@include border-radius(0);

				&:hover {
					color: $inputListItemHoverTextColor;
					background-color: $inputListItemHoverBgColor;
				}

				&.p-highlight {
					color: $inputListItemHighlightTextColor;
					background-color: $inputListItemHighlightBgColor;
				}
			}

			.p-autocomplete-group {
				padding: $inputListItemPadding;
				background-color: $inputListItemDividerColor;
				color: $textColor;
			}
		}
	}

	.p-fluid {
		.p-autocomplete {
			.p-autocomplete-dropdown.p-button {
				width: $buttonIconOnlyWidth;
			}

			&.p-autocomplete-multiple.p-autocomplete-dd {
				.p-autocomplete-multiple-container {
					border-right: 0 none;
					width: calc(100% - #{$buttonIconOnlyWidth});
				}
			}

			&.p-autocomplete-dd {
				.p-inputtext {
					border-right: 0 none;
					width: calc(100% - #{$buttonIconOnlyWidth});
				}
			}
		}
	}

	.p-chips {
		> ul.p-inputtext {
			padding: $inputPadding / 2 $inputPadding;
			display: inline-block;

			&:not(.p-disabled):hover {
				border-color: $inputHoverBorderColor;
			}

			&:not(.p-disabled).p-focus {
				@include focused-input();
			}

			.p-chips-input-token {
				padding: $inputPadding / 2 0;

				input {
					font-family: $fontFamily;
					font-size: $fontSize;
					padding: 0;
					margin: 0;
					color: $textColor;

					&:hover {
						border: 0 none;
					}

					&:focus {
						@include shadow(none);

						outline: 0 none;
						border: 0 none;
					}
				}
			}

			.p-chips-token {
				font-size: $fontSize;
				padding: $inputPadding / 2 $inputPadding;
				margin: $chipsItemMargin;
				background: $highlightBgColor;
				color: $highlightColorText;
				@include border-radius($borderRadius);
			}
		}
	}

	.p-dropdown {
		background: $inputBgColor;
		border: $inputBorder;
		@include transition($inputTransition);
		@include border-radius($borderRadius);

		&:not(.p-disabled):hover {
			border-color: $inputHoverBorderColor;
		}

		&:not(.p-disabled).p-focus {
			@include focused-input();
		}

		.p-dropdown-label {
			padding-right: 2em;

			&:focus {
				outline: 0 none;
				@include shadow(none);
			}
		}

		.p-dropdown-trigger {
			background-color: $inputBgColor;
			width: 2em;
			line-height: 2em;
			text-align: center;
			padding: 0;
			color: $inputDropdownIconColor;
			@include border-radius($borderRadius);
		}

		.p-dropdown-clear-icon {
			color: $inputDropdownIconColor;
		}
	}

	.p-dropdown-panel {
		padding: 0;
		border: $inputOverlayBorder;
		background-color: $inputListBgColor;
		@include shadow($inputOverlayShadow);

		.p-dropdown-filter-container {
			padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
			border-bottom: $inputListHeaderBorder;
			color: $inputListHeaderTextColor;
			background-color: $inputListHeaderBgColor;
			margin: $inputListHeaderMargin;

			.p-dropdown-filter {
				width: 100%;
				padding-right: 2em;
			}

			.p-dropdown-filter-icon {
				top: 50%;
				margin-top: -.5em;
				right: 2em;
				color: $inputListHeaderSearchIconColor;
				font-size: $stamps-menu-search-icon-size;
			}
		}

		.p-dropdown-items {
			padding: $inputListPadding;

			.p-dropdown-item,
			.p-dropdown-item-group {
				margin: $inputListItemMargin;
				padding: $inputListItemPadding;
				border: $inputListItemBorder;
				color: $inputListItemTextColor;
				background-color: $inputListItemBgColor;
				@include transition($inputListItemTransition);
				@include border-radius(0);

				&.p-highlight {
					color: $inputListItemHighlightTextColor;
					background-color: $inputListItemHighlightBgColor;
				}

				&:not(.p-highlight):not(.p-disabled):not(.p-dropdown-item-group):hover {
					color: $inputListItemHoverTextColor;
					background-color: $inputListItemHoverBgColor;
				}
			}
		}
	}

	.p-multiselect {
		background: $inputBgColor;
		border: $inputBorder;
		@include transition($inputTransition);
		@include border-radius($borderRadius);

		&:not(.p-disabled):hover {
			border-color: $inputHoverBorderColor;
		}

		&:not(.p-disabled).p-focus {
			@include focused-input();
		}

		.p-multiselect-label {
			padding: $inputPadding;
			padding-right: 2em;
			font-weight: 400;
			color: $textColor;
		}

		.p-multiselect-trigger {
			background-color: $inputBgColor;
			width: 2em;
			line-height: 2em;
			text-align: center;
			padding: 0;
			color: $inputDropdownIconColor;
			@include border-radius($borderRadius);
		}
	}

	.p-multiselect-panel {
		padding: 0;
		border: $inputOverlayBorder;
		background-color: $inputListBgColor;
		@include shadow($inputOverlayShadow);

		.p-multiselect-header {
			padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
			border-bottom: $inputListHeaderBorder;
			color: $inputListHeaderTextColor;
			background-color: $inputListHeaderBgColor;
			margin: $inputListHeaderMargin;

			.p-multiselect-filter-container {
				float: none;
				width: 70%;
				display: inline-block;
				vertical-align: middle;
				margin-left: 0;

				.p-inputtext {
					padding: $inputPadding;
					padding-right: 2em;
				}

				.p-multiselect-filter-icon {
					color: $inputListHeaderSearchIconColor;
					top: 50%;
					margin-top: -.5em;
					right: .5em;
					left: auto;
				}
			}

			.p-checkbox {
				margin-right: $inlineSpacing;
				float: none;
				vertical-align: middle;
			}

			.p-multiselect-close {
				color: $inputListHeaderCloseIconColor;
				top: 50%;
				margin-top: -.5em;
				@include transition($inputListHeaderCloseIconTransition);
			}
		}

		.p-multiselect-items {
			padding: $inputListPadding;

			.p-multiselect-item {
				margin: $inputListItemMargin;
				padding: $inputListItemPadding;
				border: $inputListItemBorder;
				color: $inputListItemTextColor;
				background-color: $inputListItemBgColor;
				@include transition($inputListItemTransition);
				@include border-radius(0);

				&:not(.p-highlight):not(.p-disabled):hover {
					color: $inputListItemHoverTextColor;
					background-color: $inputListItemHoverBgColor;
				}

				&:focus {
					@include focused-listitem();
				}

				&.p-highlight {
					color: $inputListItemHighlightTextColor;
					background-color: $inputListItemHighlightBgColor;
				}

				.p-checkbox {
					position: static;
					display: inline-block;
					vertical-align: middle;
					margin: 0 $inlineSpacing 0 0;
				}

				label {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}

	.p-listbox {
		padding: 0;
		min-width: $inputListMinWidth;
		background: $inputListBgColor;
		border: $inputListBorder;

		.p-checkbox {
			margin: 0 $inlineSpacing 0 0;
		}

		.p-listbox-header {
			padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
			border-bottom: $inputListHeaderBorder;
			color: $inputListHeaderTextColor;
			background-color: $inputListHeaderBgColor;
			margin: $inputListHeaderMargin;
			@include border-radius-top($borderRadius);

			.p-listbox-filter-container {
				input {
					padding: $inputPadding;
					padding-right: 2em;
				}

				.p-listbox-filter-icon {
					top: 50%;
					left: auto;
					margin-top: -.5em;
					right: .5em;
					color: $inputListHeaderSearchIconColor;
				}
			}
		}

		.p-listbox-list {
			background-color: $inputListBgColor;

			.p-listbox-item {
				margin: $inputListItemMargin;
				padding: $inputListItemPadding;
				border: $inputListItemBorder;
				color: $inputListItemTextColor;
				background-color: $inputListItemBgColor;
				@include transition($inputListItemTransition);
				@include border-radius(0);

				&.p-highlight {
					color: $inputListItemHighlightTextColor;
					background-color: $inputListItemHighlightBgColor;
				}

				&:focus {
					@include focused-listitem();
				}

				.p-checkbox {
					position: static;
					display: inline-block;
					vertical-align: middle;
					margin: 0 $inlineSpacing 0 0;
				}

				label {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		&:not(.p-disabled) {
			.p-listbox-item {
				&:not(.p-highlight):not(.p-disabled):hover {
					color: $inputListItemHoverTextColor;
					background-color: $inputListItemHoverBgColor;
				}
			}
		}

		&.p-disabled {
			.p-checkbox-box {
				&:not(.p-disabled):not(.p-highlight):hover {
					border: $inputBorder;
				}
			}
		}

		.p-listbox-footer {
			padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
			border-top: $inputListHeaderBorder;
			color: $inputListHeaderTextColor;
			background-color: $inputListHeaderBgColor;
		}
	}

	.p-editor-container {
		.p-editor-toolbar {
			border: $panelHeaderBorder;
			background-color: $panelHeaderBgColor;
		}

		.p-editor-content {
			border: $panelContentBorder;

			.ql-editor {
				background-color: $inputBgColor;
				color: $textColor;
			}
		}

		.ql-picker.ql-expanded {
			.ql-picker-label {
				color: $panelHeaderTextColor;
			}
		}

		.ql-stroke {
			stroke: $panelHeaderTextColor;
		}

		.ql-picker-label {
			color: $panelHeaderTextColor;
		}

		.ql-snow.ql-toolbar button:hover,
		.ql-snow .ql-toolbar button:hover,
		.ql-snow.ql-toolbar button.ql-active,
		.ql-snow .ql-toolbar button.ql-active,
		.ql-snow.ql-toolbar .ql-picker-label:hover,
		.ql-snow .ql-toolbar .ql-picker-label:hover,
		.ql-snow.ql-toolbar .ql-picker-label.ql-active,
		.ql-snow .ql-toolbar .ql-picker-label.ql-active,
		.ql-snow.ql-toolbar .ql-picker-item:hover,
		.ql-snow .ql-toolbar .ql-picker-item:hover,
		.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
		.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
			color: $highlightBgColor;
		}

		.ql-snow.ql-toolbar button:hover .ql-stroke,
		.ql-snow .ql-toolbar button:hover .ql-stroke,
		.ql-snow.ql-toolbar button.ql-active .ql-stroke,
		.ql-snow .ql-toolbar button.ql-active .ql-stroke,
		.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
		.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
		.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
		.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
		.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
		.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
		.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
		.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
		.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
		.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
		.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
		.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
		.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
		.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
		.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
		.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
		.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
		.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
		.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
		.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
			stroke: $highlightBgColor;
		}
	}

	.p-rating {
		.p-rating-icon {
			font-size: $ratingIconFontSize;
			text-align: center;
			display: inline-block;
			color: $ratingStarIconColor;
			@include border-radius($borderRadius);
			@include transition($ratingTransition);

			&.p-rating-cancel {
				color: $ratingCancelIconColor;
			}

			&:focus {
				@include focused();
			}
		}

		&:not(.p-disabled):not(.p-rating-readonly) {
			.p-rating-icon {
				&:hover {
					color: $ratingStarIconHoverColor;
				}

				&.p-rating-cancel {
					&:hover {
						color: $ratingCancelHoverIconColor;
					}
				}
			}
		}
	}

	.p-spinner {
		.p-spinner-input {
			padding-right: $spinnerButtonWidth + $inputPadding;
		}

		.p-spinner-button {
			width: $spinnerButtonWidth;

			.p-spinner-button-icon {
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -.5em;
				margin-left: -.5em;
				width: 1em;
			}

			&.p-spinner-button {
				@include border-radius(0);

				&.p-spinner-button-up {
					@include border-radius-top-right($borderRadius);
				}

				&.p-spinner-button-down {
					@include border-radius-bottom-right($borderRadius);
				}

				&:focus {
					z-index: 1;
				}
			}
		}
	}

	.p-fluid {
		.p-spinner {
			.p-spinner-input {
				padding-right: $spinnerButtonWidth + $inputPadding;
			}

			.p-spinner-button {
				width: $spinnerButtonWidth;

				.p-spinner-button-icon {
					left: 50%;
				}
			}
		}
	}

	.p-slider {
		background-color: $sliderBgColor;
		border: $sliderBorder;

		&.p-slider-horizontal {
			height: $sliderHeight;

			.p-slider-handle {
				top: 50%;
				margin-top: -1 * $sliderHandleHeight / 2;
			}
		}

		&.p-slider-vertical {
			width: $sliderWidth;

			.p-slider-handle {
				left: 50%;
				margin-left: -1 * $sliderHandleWidth / 2;
			}
		}

		.p-slider-handle {
			height: $sliderHandleHeight;
			width: $sliderHandleWidth;
			background-color: $sliderHandleBgColor;
			border: $sliderHandleBorder;
			@include border-radius($sliderHandleBorderRadius);
			@include transition($sliderHandleTransition);

			&:focus {
				@include focused();
			}
		}

		.p-slider-range {
			background-color: $sliderRangeBgColor;
		}

		&:not(.p-disabled) {
			.p-slider-handle:hover {
				background-color: $sliderHandleHoverBgColor;
				border: $sliderHandleHoverBorder;
			}
		}
	}

	.p-datepicker {
		padding: $calendarPadding;
		min-width: $calendarWidth;
		background-color: $inputContentPanelBgColor;
		color: $inputContentPanelTextColor;
		border: $inputListBorder;
		@include border-radius($borderRadius);

		&:not(.p-datepicker-inline) {
			border: $inputOverlayBorder;
			@include shadow($inputOverlayShadow);
		}

		&:not(.p-disabled) {
			.p-datepicker-header {
				.p-datepicker-prev,
				.p-datepicker-next {
					&:hover {
						color: $calendarNavIconHoverColor;
					}
				}
			}

			table {
				td {
					span:not(.p-highlight):not(.p-disabled):hover {
						background-color: $calendarCellDateHoverBgColor;
					}
				}
			}

			.p-monthpicker {
				.p-monthpicker-month:not(.p-highlight):hover {
					background-color: $calendarCellDateHoverBgColor;
				}
			}
		}

		.p-datepicker-header {
			padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
			background-color: $inputListHeaderBgColor;
			color: $inputListHeaderTextColor;
			@include border-radius(0);

			.p-datepicker-prev,
			.p-datepicker-next {
				cursor: pointer;
				top: 0;
				color: $calendarNavIconColor;
				background-color: $calendarNavIconBgColor;
				border: none;
				@include transition($calendarNavIconTransition);
			}

			.p-datepicker-title {
				margin: 0;
				padding: 0;
				line-height: 1;

				.p-link {
					font: {
						size: $calendarTitleFontSize;
						weight: $calendarFontWeight;
					}
				}

				select {
					margin-top: -.35em;
					margin-bottom: 0;
				}
			}

			.p-datepicker-month {
				padding-right: 5px;
			}
		}

		table {
			font-size: $fontSize;
			margin: $calendarTableMargin;

			th {
				padding: $calendarHeaderCellPadding;

				> span {
					color: $calendarHeaderTextColor;
				}
			}

			td {
				padding: $calendarCellDatePadding;

				@at-root {
					&:has(> span.p-highlight) {
						background-color: $calendarCellDateSelectedContainerBgColor;
					}
				}

				> span {
					display: block;
					text-align: center;
					color: $calendarCellDateTextColor;
					cursor: pointer;
					font-weight: $calendarFontWeight;
					padding: $calendarCellDatePadding;
					@include border-radius($calendarCellDateBorderRadius);

					&.p-highlight {
						color: $calendarCellDateSelectedTextColor;
						background-color: $calendarCellDateSelectedBgColor;
					}
				}

				&.p-datepicker-today {
					> span {
						background-color: $calendarCellDateTodayBgColor;
						border: $calendarCellDateTodayBorder;
						border-radius: $calendarCellDateTodayBorderRadius;
						color: $calendarCellDateTodayTextColor;

						&:hover {
							border: $calendarCellDateTodayBorderHover;
						}

						&.p-highlight {
							color: $calendarCellDateSelectedTextColor;
							background-color: $calendarCellDateSelectedBgColor;
						}
					}
				}

				&.p-datepicker-other-month {
					background-color: $calendarCellDisabledBackgroundColor;

					> span {
						color: $calendarCellDisabledTextColor;
					}
				}
			}
		}

		.p-datepicker-buttonbar {
			border-top: $calendarButtonBarDivider;
		}

		.p-datepicker-buttonbar {
			.p-button {
				color: $stamps-primary-button-text-color;
				background-color: $stamps-primary-button-bg-color;
				padding: 5px 10px;

				&:hover {
					color: $stamps-primary-button-text-color !important;
					background-color: $stamps-primary-button-hover-bg-color !important;
				}

				&:active {
					background-color: $stamps-primary-button-active-bg-color !important;
				}

				&:last-child {
					display: none;
				}
			}

			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 10px;
		}

		.p-timepicker {
			border: 0 none;
			border-top: $calendarTimePickerDivider;
			padding: $calendarTimePickerPadding;

			button {
				color: $calendarTimePickerIconColor;
				font-size: $calendarTimePickerIconFontSize;
				@include transition($calendarNavIconTransition);

				&:hover {
					color: $calendarTimePickerIconHoverColor;
				}
			}

			span {
				font-size: $calendarTimePickerTimeFontSize;
			}
		}

		.p-monthpicker {
			.p-monthpicker-month {
				color: $textColor;

				&.p-highlight {
					color: $calendarCellDateSelectedTextColor;
					background-color: $calendarCellDateSelectedBgColor;
				}
			}
		}

		&.p-datepicker-timeonly {
			padding: 0;

			.p-timepicker {
				border-top: 0 none;
			}
		}

		&.p-datepicker-multiple-month {
			.p-datepicker-group {
				border-right: $calendarMultipleMonthDivider;
				padding-right: $calendarPadding;
				padding-left: $calendarPadding;
				padding-top: 0;
				padding-bottom: 0;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
					border-right: 0 none;
				}
			}
		}
	}

	.p-calendar.p-calendar-w-btn {
		.p-inputtext {
			@include border-radius-right(0);

			border-right: 0 none;

			&:enabled:hover:not(.p-error),
			&:enabled:focus:not(.p-error) {
				border-right: 0 none;
			}
		}

		.p-datepicker-trigger.p-button {
			width: $buttonIconOnlyWidth;
			@include border-radius-left(0);
		}
	}

	.p-fluid {
		.p-calendar.p-calendar-w-btn {
			.p-inputtext {
				width: calc(100% - #{$buttonIconOnlyWidth});
			}
		}
	}

	.p-fileupload {
		.p-fileupload-buttonbar {
			background-color: $panelHeaderBgColor;
			padding: $panelHeaderPadding;
			border: $panelHeaderBorder;
			color: $panelHeaderTextColor;
			border-bottom: 0 none;
			@include border-radius-top($borderRadius);

			.p-button {
				margin-right: 8px;

				&.p-fileupload-choose.p-focus {
					@include focused();
				}
			}
		}

		.p-fileupload-content {
			background-color: $panelContentBgColor;
			padding: $panelContentPadding;
			border: $panelContentBorder;
			color: $panelContentTextColor;
			@include border-radius-bottom($borderRadius);
		}

		.p-progressbar {
			top: 0;
		}
	}

	.p-fileupload-choose {
		&:not(.p-disabled):hover {
			background-color: $buttonHoverBgColor;
			color: $buttonHoverTextColor;
			border-color: $buttonHoverBorderColor;
		}

		&:not(.p-disabled):active {
			background-color: $buttonActiveBgColor;
			color: $buttonActiveTextColor;
			border-color: $buttonActiveBorderColor;
		}
	}

	.p-password-panel {
		padding: $inputContentPanelPadding;
		background-color: $inputContentPanelBgColor;
		color: $inputContentPanelTextColor;
		border: $inputOverlayBorder;
		@include shadow($inputOverlayShadow);
	}

	.p-inputgroup {
		.p-inputgroup-addon {
			border-color: $inputGroupBorderColor;
			background-color: $inputGroupBgColor;
			color: $inputGroupTextColor;
			padding: $inputPadding;
			min-width: 2em;

			&:first-child {
				@include border-radius-left($borderRadius);
			}

			&:last-child {
				@include border-radius-right($borderRadius);
			}

			&.p-inputgroup-addon-checkbox {
				position: relative;

				.p-checkbox {
					position: absolute;
					top: 50%;
					left: 50%;
					margin-left: -1 * $checkboxWidth / 2;
					margin-top: -1 * $checkboxHeight / 2;
				}
			}

			&.p-inputgroup-addon-radiobutton {
				position: relative;

				.p-radiobutton {
					position: absolute;
					top: 50%;
					left: 50%;
					margin-left: -1 * $radiobuttonWidth / 2;
					margin-top: -1 * $radiobuttonHeight / 2;
				}
			}
		}

		.p-button {
			&:first-child {
				@include border-radius-left($borderRadius);
			}

			&:last-child {
				@include border-radius-right($borderRadius);
			}
		}

		.p-inputtext:focus {
			z-index: 1;
		}
	}

	.p-fluid {
		.p-inputgroup {
			.p-button {
				width: auto;

				&.p-button-icon-only {
					width: $buttonIconOnlyWidth;
				}
			}
		}
	}

	::-webkit-input-placeholder { color: $inputPlaceholderTextColor; }
	:-moz-placeholder { color: $inputPlaceholderTextColor; }
	::-moz-placeholder { color: $inputPlaceholderTextColor; }
	:-ms-input-placeholder { color: $inputPlaceholderTextColor; }

	/* Validations */
	.p-inputtext.p-error,
	.p-dropdown.p-error,
	.p-autocomplete.p-error > .p-inputtext,
	.p-calendar.p-error > .p-inputtext,
	.p-chips.p-error > .p-inputtext,
	.p-checkbox.p-error > .p-checkbox-box,
	.p-radiobutton.p-error > .p-radiobutton-box,
	.p-inputswitch.p-error,
	.p-listbox.p-error,
	.p-multiselect.p-error,
	.p-spinner.p-error > .p-inputtext,
	.p-selectbutton.p-error > .p-button,
	.p-togglebutton.p-error > .p-button {
		border: $inputErrorBorder;
	}
}
