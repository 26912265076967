@mixin ui-button() {
	// local variable for all icon in tools-medium.png spritesheet
	$tools-medium-list:
		(add 1),
		(export 5),
		(feedback, 3),
		(help, 4),
		(import 2),
		(more 6),
		(print, 7),
		(settings, 8),
		(favorites, 9),
		(reset, 10),
		(refund, 11),
		(refresh 12);

	.common-ui-button {
		display: inline-block;

		// '.ui-button-primary'
		@include button-factory(primary, (
			text-color: $stamps-primary-button-text-color,
			background-color: $stamps-primary-button-bg-color,
			border: 1px solid $stamps-primary-button-bg-color,
			hover-text-color: $stamps-primary-button-text-color,
			hover-background-color: $stamps-primary-button-hover-bg-color,
			hover-border-color: $stamps-primary-button-hover-bg-color,
			active-text-color: $stamps-primary-button-text-color,
			active-background-color: $stamps-primary-button-active-bg-color,
			active-border-color: $stamps-primary-button-active-bg-color
		));

		// '.ui-button-secondary'
		@include button-factory(secondary, (
			text-color: $stamps-secondary-button-text-color,
			background-color: $stamps-secondary-button-bg-color,
			border: 1px solid $stamps-secondary-button-border-color,
			hover-text-color: $stamps-secondary-button-text-color,
			hover-background-color: $stamps-secondary-button-hover-bg-color,
			hover-border-color: $stamps-secondary-button-hover-border-color,
			active-text-color: $stamps-secondary-button-text-color,
			active-background-color: $stamps-secondary-button-active-bg-color,
			active-border-color: $stamps-secondary-button-active-border-color
		));

		// '.ui-button-icon'
		@include button-factory(icon, (
			text-color: $stamps-icon-button-text-color,
			background-color: $stamps-icon-button-bg-color,
			border: 1px solid $stamps-icon-button-bg-color,
			padding: 6px,
			icon-size: 24px,
			hover-text-color: $stamps-icon-button-text-color,
			hover-background-color: $stamps-icon-button-hover-bg-color,
			hover-border-color: $stamps-icon-button-hover-bg-color,
			active-text-color: $stamps-icon-button-text-color,
			active-background-color: $stamps-icon-button-active-bg-color,
			active-border-color: $stamps-icon-button-active-bg-color,
		));

		// '.ui-button-tertiary-grey'
		@include button-factory(tertiary-grey, (
			text-color: $stamps-tertiary-button-grey-text-color,
			background-color: $stamps-tertiary-button-grey-idle-background-color,
			border: 1px solid $stamps-tertiary-button-grey-idle-background-color,
			hover-text-color: $stamps-tertiary-button-grey-text-color,
			hover-background-color: $stamps-tertiary-button-grey-hover-background-color,
			hover-border-color: $stamps-tertiary-button-grey-hover-background-color,
			active-text-color: $stamps-tertiary-button-grey-text-color,
			active-background-color: $stamps-tertiary-button-grey-active-background-color,
			active-border-color: $stamps-tertiary-button-grey-active-background-color
		));

		// '.ui-button-tertiary-white'
		@include button-factory(tertiary-white, (
			text-color: $stamps-tertiary-button-white-text-color,
			background-color: $stamps-tertiary-button-white-idle-background-color,
			border: 1px solid $stamps-tertiary-button-white-hover-background-color,
			hover-text-color: $stamps-tertiary-button-white-text-color,
			hover-background-color: $stamps-tertiary-button-white-hover-background-color,
			hover-border-color: $stamps-tertiary-button-white-hover-background-color,
			active-text-color: $stamps-tertiary-button-white-text-color,
			active-background-color: $stamps-tertiary-button-white-active-background-color,
			active-border-color: $stamps-tertiary-button-white-active-background-color
		));

		// '.ui-button-tertiary-ghost'
		@include button-factory(tertiary-ghost, (
			text-color: $stamps-tertiary-button-ghost-text-color,
			background-color: $stamps-tertiary-button-ghost-idle-background-color,
			border: 1px solid $stamps-tertiary-button-ghost-idle-background-color,
			hover-text-color: $stamps-tertiary-button-ghost-text-color,
			hover-background-color: $stamps-tertiary-button-ghost-hover-background-color,
			hover-border-color: $stamps-tertiary-button-ghost-hover-background-color,
			active-text-color: $stamps-tertiary-button-ghost-text-color,
			active-background-color: $stamps-tertiary-button-ghost-active-background-color,
			active-border-color: $stamps-tertiary-button-ghost-active-background-color
		));

		// '.ui-button-upgrade'
		@include button-factory(upgrade, (
			background-color: $stamps-upgrade-button-idle-background-color,
			hover-background-color: $stamps-upgrade-button-hover-background-color,
			border: 1px solid $stamps-upgrade-button-idle-background-color,
		));

		.p-button {
			transition: unset;

			.p-button-label {
				padding: $stamps-default-button-padding;
				line-height: $stamps-default-button-line-height;
				white-space: nowrap;
				margin: auto;
			}

			&:focus {
				box-shadow: unset;
			}

			&.ui-button-icon {
				padding: $stamps-ui-toolbar-button-padding;

				.p-button-label {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.p-button-icon {
				height: $primereact-buttonIconOnlyHeight;
				width: $primereact-buttonIconOnlyWidth;
				margin: auto;
				margin-right: 6px;
			}

			.p-button-icon.p-button-icon-left.pi {
				margin-right: calc(-1 * $stamps-default-button-padding-left + 2px);
				margin-left: $stamps-default-button-padding-left;

				&.upgrade {
					@include icon-mask($color: $adl-grey-white);
					@include icon-mask-type('upgrade');
				}
			}
		}

		.ui-button-icon,
		.p-button-icon {
			.p-button-icon {
				height: $primereact-buttonIconOnlyHeight;
				width: $primereact-buttonIconOnlyWidth;
				margin: auto;
				margin-right: 6px;
			}

			.collapsible-panel-toggle-icon {
				background:
					transparent
					background-image-url($stamps-collapsible-panel-collapse-expand-icon)
					no-repeat
					0 0;
			}
		}

		.p-button-icon-only {
			padding: 0;
			width: 30px;
			height: 30px;
			background-color: transparent;
			border-color: transparent;

			.p-button-icon {
				margin: auto;
				padding: 0;
			}

			.p-button-label {
				display: none;
			}
		}

		&.split-button-menu {
			opacity: 1;

			.p-menu-list {
				padding: $stamps-splitbutton-menulist-padding;

				.p-menuitem {
					margin: $stamps-splitbutton-menuitem-margin;

					.p-menuitem-link {
						padding: $stamps-splitbutton-menuitem-padding;
						border-radius: $stamps-splitbutton-menuitem-border-radius;

						.p-menuitem-text {
							color: $stamps-splitbutton-text-color;
							line-height: $stamps-splitbutton-menuitem-line-height
						}

						&:hover {
							background-color: $stamps-splitbutton-menuitem-hover-background-color;
						}

						&:active {
							background-color: $stamps-splitbutton-menuitem-active-background-color;
						}

						&.p-disabled {
							.p-menuitem-text {
								color: $stamps-splitbutton-menuitem-disabled-text-color;
							}
						}

						&:focus {
							box-shadow: $stamps-splitbutton-menuitem-focus-box-shadow;
						}
					}

					.upgrade-icon {
						background: background-image-url($stamps-header-feature-banner-icon) no-repeat;
						order: 2;
						height: 19px;
						width: 20px;
					}
				}
			}
		}

		// currently p-menu style rules are design for Header (Content Service).
		// Override the style rules for splitbutton
		&.split-button {
			.p-menu {
				background-color: $stamps-splitbutton-bg-color;

				.p-menuitem-link {
					color: $stamps-splitbutton-text-color;
					text-align: left;

					.p-menuitem-text {
						color: $stamps-splitbutton-text-color;
					}

					.p-menuitem-icon {
						color: $stamps-splitbutton-text-color;
					}

					&:hover {
						background-color: $stamps-splitbutton-hover-bg-color;

						.p-menuitem-text {
							color: $stamps-splitbutton-hover-text-color;
						}

						.p-menuitem-icon {
							color: $stamps-splitbutton-hover-text-color;
						}
					}
				}
			}

			.p-splitbutton {
				align-items: center;

				.p-button-label-only {
					.p-button-label {
						padding-right: 0;
					}
				}

				.p-button-icon-only,
				.p-splitbutton-defaultbutton {
					.p-button-label {
						padding: $stamps-default-button-padding;
					}

					.more-icon {
						background-position: 0 -120px;
						background-size: $stamps-button-more-icon-background-size;
					}

					// hover detection must be on the button element to avoid flickering
					&:hover:not(:disabled) {
						.more-icon {
							background-position: -24px -120px;
						}
					}
				}

				.p-splitbutton-menubutton.p-button-icon-only {
					width: 16px;

					.pi {
						font-size: 7px;
						font-weight: bold;

						&.pi-chevron-down {
							background: background-image-url($stamps-splitbutton-menuitem-icon) no-repeat;
							background-position: center;
							height: 16px;
							width: 16px;

							&:before {
								content: '',
							}
						}
					}
				}
			}
		}

		// override disabled button style
		.p-disabled.p-button {
			background-color: $stamps-ui-disabled-button-background-color;
			border-color: $stamps-ui-disabled-button-border-color;
			color: $stamps-ui-disabled-button-font-color;
		}

		// styling for upgrade icon for feature gating
		.p-button {
			.upgrade-icon {
				background-image: background-image-url($stamps-header-feature-banner-icon);
				background-size: 20px;
				background-position: 5px 0px;
				background-repeat: no-repeat;
				order: 2;
			}

			&:hover:not(:disabled) {
				.upgrade-icon {
					background-position: 5px 0px;
				}
			}
		}

		&.upgrade-icon-btn {
			opacity: 0.5;
			color: #333;
			background-color: transparent;
			border: 1px solid transparent;
		}

		// build all button icons
		@each $button in $tools-medium-list {
			$icon-name: nth($button, 1);
			$icon-index: nth($button, 2) - 1;

			.p-button {
				.#{$icon-name}-icon {
					background-image: background-image-url($stamps-ui-button-icon-background-image);
					background-position: 0 $stamps-ui-button-icon-size * ($icon-index * -1);
					background-repeat: no-repeat;
					background-size: $stamps-ui-button-icon-background-size;
					height: $stamps-ui-button-icon-size;
					width: $stamps-ui-button-icon-size;
				}

				&:hover:not(:disabled) {
					.#{$icon-name}-icon {
						background-position: -$stamps-ui-button-icon-size $stamps-ui-button-icon-size * ($icon-index * -1);
					}
				}
			}
		}

		.additional-button-icon {
			&.upgrade-icon {
				height: 20px;
				width: 20px;
				background-position: 0px;
			}

			&:hover:not(:disabled) {
				.upgrade-icon {
					background-position: 0px;
				}
			}
		}
	}

	button {
		padding-block: $stamps-button-padding-block;
		padding-inline: $stamps-button-padding-inline;
	}
}
