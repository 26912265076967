@mixin ui-collapsible-card {
	.common-ui-collapsible-card {
		padding: $stamps-ui-collapsible-card-padding;
		border: 1px solid $stamps-ui-collapsible-card-border-color;
		border-radius: $stamps-ui-collapsible-card-border-radius;

		.p-panel-header {
			.p-panel-title {
				@include heading-label;
			}

			.pi {
				color: $stamps-ui-collapsible-card-header-icon-color;
    			font-size: 10px;
			}
		}

		.p-panel-content {
			padding: 8px 0 0 0;
			text-align: left;
		}
	}
}
