@mixin rate-advisor-package-filter {
	.common-rate-advisor-package-filter {
		border-bottom: 1px solid #eef2f8;
		width: 200px;

		.package-filter-title {
			display: table;
			width: 100%;
			font-size: 10pt;
			font-weight: bold;
			padding-top: 19px;
			padding-bottom: 19px;
			cursor: pointer;

			&:hover {
				background-color: #f9f9f9;
			}

			&:focus {
				outline: none;
			}
		}

		.filter-panel-expander {
			display: table-cell;
			text-align: right;
		}

		.options-button {
			cursor: pointer;
			padding: 2px 0 19px 8px;
			font-size: 12px;
			color: $stamps-rate-advisor-show-options-button-color;

			&:hover {
				color: $stamps-rate-advisor-show-options-button-hover-color;
			}

			.pi-chevron-down,
			.pi-chevron-up {
				left: 2px;
				font-size: 12px;
				position: relative;
			}

			.pi-chevron-down {
				top: 3px;
			}

			&:focus {
				outline: none;
			}
		}
	}
}
