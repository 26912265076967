@mixin ui-legal-text() {
	.common-ui-legal-text {
		display: flex;
		flex-direction: column;
		height: 100%;
		margin: 0 15px;

		.print-button-container {
			text-align: right;
			margin-top: 0;
		}

		.text-container {
			flex: 1;
			margin: 0;
			padding: 15px;
			border: 1px solid #a0a0a0;
			overflow-x: auto;
		}
	}
}
