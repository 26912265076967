@mixin inbound-details-instructions {
	.common-inbound-details-instructions {
		border: 1px solid $stamps-inbound-details-header-text-color;
		padding: 12px;

		.title {
			color: $stamps-inbound-details-sub-header-text-color;
			font-weight: bold;
			margin: 0 0 10px 0;
		}

		.helper-text {
			&.height-60 {
				height: 60px !important;
			}

			&.height-80 {
				height: 80px !important;
			}

			&.height-100 {
				height: 100px !important;
			}

			&.height-120 {
				height: 120px !important;
			}

			&.height-140 {
				height: 140px !important;
			}

			&.height-160 {
				height: 160px !important;
			}

			&.height-180 {
				height: 180px !important;
			}

			&.height-200 {
				height: 200px !important;
			}
		}

		&.product,
		&.shipping {
			.helper-text {
				max-height: 120px;
				min-height: 60px;
			}
		}

		&.inbound {
			.helper-text {
				max-height: 180px;
				min-height: 80px;
			}
		}
	}
}
