@mixin marketing {
	.common-marketing {
		&.marketing-banner {
			overflow: hidden;
			position: relative;
			display: flex;

			.image-button-container {
				display: flex;
				flex: 1;
				justify-content: center;
				overflow: hidden;

				.image-container {
					overflow: hidden;

					.banner-image {
						vertical-align: middle;
						display: inline-block;
					}
				}

				.primary-button {
					margin: 0 0 0 10px;
					line-height: 56px;
					vertical-align: middle;
				}
			}

			.close-button {
				width: 40px;
				display: inline-flex;
				align-items: center;
				padding: 0 10px 0;

				.close-svg {
					cursor: pointer;
				}
			}
		}

		&.marketing-modal {
			height: auto;

			&.p-dialog.modal {
				max-width: 100%;

				.p-dialog-content {
					padding: 0;
				}

				.p-dialog-footer {
					.ui-button-primary {
						margin: 0 10px 0 0;
					}

					&.hide-footer {
						display: none;
					}
				}
			}
		}
	}
}
