@mixin inbound-details-inbound-details {
	.common-inbound-details-inbound-details {
		margin: 15px 0 15px 0;
		display: flex;
		flex-direction: column;

		.inbound-details-header {
			color: $stamps-inbound-details-header-text-color;
			display: block;
			font-weight: bold;
			font-size: 14px;
			margin: 0 0 15px 0;
		}

		.label-container {
			min-width: 90px;
			width: inherit !important;

			label {
				color: $stamps-inbound-details-sub-header-text-color;
				font-weight: bold;
				float: right;
			}
		}

		.inbound-details-content {
			display: inline-block;
			overflow-y: inherit;
			padding-right: 22px;
			margin: 0;

			.details-column-full {
				display: inline-block;
				width: 50%;
				vertical-align: top;
			}

			.details-column-resized {
				.label-container,
				.read-only-label {
					width: 120px !important;
				}
			}

			.processing {
				label {
					line-height: 13px;
				}
			}

			.shipping-method {
				padding-top: 6px;
			}

			.packing-method {
				padding-top: 5px;
				padding-bottom: 8px;
				width: 310px;

				&.processing {
					padding-bottom: 8px;
				}

				.packing-title {
					display: inline-flex;
					height: 40px;
					vertical-align: top;
					padding-top: 7px;
					padding-left: 6px;
					color: $stamps-inbound-details-sub-header-text-color;
					font-weight: bold;

					&.read-only-label-container {
						padding-top: 0;
					}
				}

				.package-buttons {
					display: inline;

					.packing-button {
						border: solid #848484 1px;
						border-radius: 2px;
						padding: 8px 0 4px 0;
						background-color: white;
						width: 24%;
						margin-left: 12px;
						cursor: pointer;

						&.uneditable {
							cursor: auto;
							margin-left: 0;
						}

						&.invalid-component {
							border-color: #c30 !important;
							width: 19.5%;
						}

						&.selected {
							border: solid $primereact-highlightBgColor 2px;
						}

						.packing-image {
							display: inline-block;
							width: 40px;
							height: 30px;
							vertical-align: middle;

							&.pallet {
								background: background-image-url($stamps-pallet-background-image) no-repeat center;
							}

							&.boxes {
								background: background-image-url($stamps-boxes-background-image) no-repeat center;
							}
						}

						.package-text {
							padding-top: 4px;
							font-size: 12px;

							&.selected {
								color: $primereact-highlightBgColor;
							}
						}
					}

					.packing-button:disabled {
						cursor: auto;

						.pallet {
							opacity: .5;
						}
					}
				}

				.packing-error-container {
					display: inline-block;

					.invalid-error-icon {
						width: 20px;
						height: 20px;
						margin: 0 0 10px 5px;
						display: inline-block;
						background: background-image-url($stamps-ui-element-wrapper-error-icon) no-repeat;
						background-position: center;
					}

					// for tablet and phone env where the error icon appears below the field
					&.packing-error-text {
						display: block;
						margin-top: 3px;

						.packing-filler {
							display: inline-flex;
							width: 120px;
						}

						.invalid-error-icon {
							vertical-align: middle;
							margin: 0 5px 0 0;
							margin-left: 12px;
						}

						.error-msg {
							line-height: $stamps-line-height;
							vertical-align: middle;
							color: $stamps-ui-element-wrapper-invalid-text-color;
							white-space: pre-line;
						}
					}
				}
			}

			.read-only-label-container {
				height: 41px;

				.read-only-label {
					padding-top: 7px;
					min-width: 90px;
					width: inherit;
				}

				.read-only-text {
					padding-top: 7px;
				}
			}
		}
	}
}
