@mixin account-win-client-home-page {
	.account-win-client-home-page {
		height: inherit;
		background-color: $stamps-winclienthome-bg-color;

		.container {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(3, auto);
			grid-gap: $stamps-winclient-container-gap;
			padding: $stamps-winclient-container-padding;
			width: 760px;
			height: 401px;

			.cell {
				background-size: cover;
				background-repeat: no-repeat;
			}

			.link {
				width: 100%;
				display: block;
				height: 100%;

				.link-text {
					// Accessible only to screen readers
					position: absolute;
					width: 1px;
					height: 1px;
					padding: 0;
					margin: -1px;
					overflow: hidden;
					clip: rect(0, 0, 0, 0);
					border: 0;
				}
			}

			/* Top-left cell spans 2 columns and 2 rows */
			.cell:nth-of-type(1) {
				grid-column: 1 / 3;
				grid-row: 1 / 3;
				background-image: background-image-url('winclient/featured-premium-netstamps@2x.png');
				height: auto;
			}

			.cell:nth-of-type(2) {
				grid-column: 3 / 4;
				grid-row: 1 / 2;
				background-image: background-image-url('winclient/shipping-supplies@2x.png');
			}

			.cell:nth-of-type(3) {
				grid-column: 3 / 4;
				grid-row: 2 / 3;
				background-image: background-image-url('winclient/thermal-shipping-rolls@2x.png');
			}

			.cell:nth-of-type(4) {
				grid-column: 1 / 2;
				grid-row: 3 / 4;
				background-image: background-image-url('winclient/postage-wizard@2x.png');
			}

			.cell:nth-of-type(5) {
				grid-column: 2 / 3;
				grid-row: 3 / 4;
				background-image: background-image-url('winclient/contact-support@2x.png');
			}

			.cell:nth-of-type(6) {
				grid-column: 3 / 4;
				grid-row: 3 / 4;
				background-image: background-image-url('winclient/free-usps-supplies@2x.png');
			}

			.cell:nth-of-type(2), .cell:nth-of-type(3), .cell:nth-of-type(6) {
				width: 196px;
				height: 147px;
			}

			.cell:nth-of-type(4), .cell:nth-of-type(5) {
				width: 302px;
				height: 147px;
			}
		}
	}
}
