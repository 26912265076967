@mixin account-personal-contact-info {
	.account-personal-contact-info {
		height: 100%;
		text-align: left;
		padding: $stamps-account-content-padding;

		.common-ui-layout-section {
			.section-body {
				flex: 0 1 auto;

				.common-ui-element-wrapper {
					.input-container {
						flex: none;
						width: 330px;
					}

					&.phone {
						padding-bottom: 0;

						.input-container {
							margin-bottom: 0;
						}
					}

					&.mobile-wrapper {
						.input-container {
							flex: 1;
							width: auto;
						}
					}
				}
			}

			.account-footer {
				// section-body padding + label-container width + label-container margin-right
				padding-left: 15px + 120px + 10px;

				&.mobile-wrapper {
					padding-left: 15px;
				}
			}
		}
	}
}
