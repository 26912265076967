@mixin ui-collapsible-input {
	.common-ui-collapsible-input {
		padding: 2px 0;

		.collapsible-input-wrapper {
			display: flex;
			width: 100%;
			margin-bottom: 5px;

			.collapsible-input-label {
				width: 130px;
				text-decoration: none;
				outline: none;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				@include heading-label;

				.pi {
					font-size: 18px;
				}
			}

			.common-ui-input-text {
				flex: 1;
			}
		}

		.collapsible-hidden-wrapper {
			padding-left: 25px;
			padding-top: 2px;

			&.collapsed {
				display: none;
			}
		}
	}
}
