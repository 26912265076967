@mixin ui-file-upload {
	.common-ui-fileupload {
		.p-button {
			transition: unset;
			padding: 0;

			&.p-fileupload-choose:not(.p-disabled) {
				color: $stamps-file-upload-button-font-color;
				border-color: $stamps-file-upload-button-border-color;
				background-color: $stamps-file-upload-button-idle-background-color;
				padding: $stamps-default-button-padding;

				.pi-plus {
					background: background-image-url('tools-medium.svg') no-repeat;
					margin-right: 12px;
					height: 16px;
					width: 16px;
					background-size: 120px 288px;
					background-position: -4px -101px;

					&:before {
						content: '';
					}
				}

				&:hover {
					background-color: $stamps-file-upload-button-hover-background-color;
				}

				&:active {
					background-color: $stamps-file-upload-button-active-background-color;;
				}
			}

			.p-button-icon {
				display: $stamps-file-upload-button-icon-display;
			}

			.p-button-label {
				line-height: $stamps-default-button-line-height;
				white-space: nowrap;
			}

			&:focus {
				box-shadow: unset;
			}
		}
	}
}
