@mixin ui-info-banner {
	.common-ui-info-banner {
		padding: $stamps-ui-infobanner-padding;
		font-size: $stamps-ui-infobanner-font-size;
		line-height: $stamps-ui-infobanner-line-height;
		border-radius: $stamps-ui-infobanner-border-radius;
		display: flex;

		.info-content {
			flex: 1;
		}

		[class^="icon-"] {
			display: inline-flex;
			width: 16px;
			height: 16px;
			margin-top: 2px;
			margin-right: 8px;
		}

		&.warning {
			background: $stamps-ui-infobanner-warning-bg;
			border: $stamps-ui-infobanner-warning-border-color;

			.icon-warning {
				background: background-image-url('warning.svg') no-repeat;
			}
		}

		&.affirm {
			background: $stamps-ui-infobanner-affirm-background;
			border: $stamps-ui-infobanner-affirm-border-color;
			.icon-affirm {
				background: background-image-url('affirm.svg') no-repeat;
			}
		}

		&.inform {
			background: $stamps-ui-infobanner-inform-background;
			border: $stamps-ui-infobanner-inform-border-color;
			.icon-inform {
				background: background-image-url('information-blue.svg') no-repeat;
			}
		}

		&.error {
			background: $stamps-ui-infobanner-error-background;
			border: $stamps-ui-infobanner-error-border-color;
			.icon-error {
				background: background-image-url('error.svg') no-repeat;
			}
		}
	}
};
