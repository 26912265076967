@mixin ui-colorpicker() {
	.p-colorpicker-panel {
		background-color: #323232;
		border-color: #191919;
		display: block !important;
	}

	.common-ui-colorpicker {
		.p-inputtext {
			width: 15px;
			height: 20px;

			&:enabled:hover {
				border-color: $stamps-ui-input-border-focus-color;
			}

			&:enabled:focus {
				@include focused-input();
			}
		}
	}
}
