@mixin account-client-pendo-view {
	.account-client-pendo-view {
		display: flex;
		justify-content: right;
		overflow: auto;
		background-color: #f0f0f0;
		height: 100%;

		.endicia-logo {
			background: background-image-url('endicia-signin-logo.svg') no-repeat center;
			background-size: 44px auto;
			width: 44px;
			height: 20px;
			margin: 5px 10px;
		}
	}
}
