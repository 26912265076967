@mixin inbound-details-shipping-details-estimation {
	.common-inbound-details-shipping-details-estimation {
		margin: 0 0 15px 0;
		display: flex;
		flex-direction: column;
		background-color: #eef2f8;

		.shipping-estimation-content {
			padding: 20px 20px 15px 20px;

			.boxes-content {
				border-bottom: 1px solid #babec3;

				.sub-header {
					border-bottom: 1px solid #babec3;
					color: $stamps-inbound-details-sub-header-text-color;
					font-weight: bold;
				}

				.box-item {
					.box-number {
						align-self: center;
						font-weight: bold;
						color: $stamps-inbound-details-sub-header-text-color;
						font-size: 16px;

						&.mobile {
							display: inline-flex;
						}
					}

					.delete-box-item {
						.delete-icon {
							background: background-image-url($stamps-icon-button-delete) no-repeat center;
							background-size: 26px;
						}
					}

					.clone-box-item {
						margin-right: 5px;

						.clone-icon {
							background: background-image-url($stamps-icon-button-clone) no-repeat center;
							background-size: 26px;
						}
					}
				}

				.add-box-button {
					margin-bottom: 10px;

					.p-button-label {
						font-weight: bold;
						color: #023a89;
						padding-left: 5px;
					}

					.add-icon {
						background: background-image-url($stamps-inbound-add-icon) no-repeat center;
						width: 20px !important;
						height: 20px !important;
						left: 0 !important;
						background-position: 0 !important;
					}
				}
			}
		}

		&.mobile-wrapper {
			padding-bottom: 0;
			padding-right: 0;

			.weight,
			.dimensions {
				padding-left: 0;
				padding-bottom: 0;
				display: inline-table;

				.weight-input,
				.dimensions-input {
					.input-container {
						width: 88px;

						.common-ui-input-number {
							width: 50px;
						}
					}
				}
			}

			.buttons-container {
				padding-bottom: 0;
				padding-right: 0;
				display: inline-flex;
			}

			.label-container {
				width: 45px;
				text-align: right;
			}

			.shipping-estimation-content {
				padding: 20px 10px 15px 20px;

				.box-item {
					border-bottom: 1px solid #babec3;
					margin-left: 1px;
					width: 103%;
				}
			}
		}

		.estimated-cost-wrapper {
			align-self: flex-end;

			.estimated-cost {
				margin: 0 10px 10px 0;
				background: background-image-url($stamps-icon-button-info) no-repeat;
				padding-left: 20px;
				font-weight: bold;
				color: $stamps-inbound-details-sub-header-text-color;
			}
		}

		.invalid-error-icon {
			margin: 8px 0 0 0 !important;
		}

		.invalid {
			.p-inputnumber-input,
			.p-inputnumber-button {
				border-color: #c30 !important;
			}
		}
	}
}
