@mixin ui-typography {
	.typography {
		// &.H1 {
		// 	@include h1;
		// }
		// &.H2 {
		// 	@include h2;
		// }
		&.H3 {
			@include h3;
		}
		&.H4 {
			@include h4;
		}
		// &.H5 {
		// 	@include h5;
		// }
		// &.H6 {
		// 	@include h6;
		// }
		&.paragraph {
			@include paragraph;
		}
		&.bold {
			font-weight: 600;
		}
	}
}
