@import 'ui/button.scss';
@import 'ui/spinner.scss';
@import 'ui/inline-spinner.scss';
@import 'ui/legal-text.scss';
@import 'ui/element-wrapper.scss';
@import 'ui/tooltip.scss';
@import 'ui/field/addressfield.scss';
@import 'ui/field/dimensionsfield.scss';
@import 'ui/field/print-on-field.scss';
@import 'ui/field/service-field.scss';
@import 'ui/field/tracking-field.scss';
@import 'ui/field/weightfield.scss';
@import 'ui/layout/buttongrid.scss';
@import 'ui/layout/section.scss';
@import 'ui/layout/footer.scss';
@import 'ui/multiselect.scss';
@import 'ui/toolbar.scss';
@import 'ui/calendar.scss';
@import 'ui/checkbox.scss';
@import 'ui/colorpicker.scss';
@import 'ui/dropdown.scss';
@import 'ui/grouped-dropdown.scss';
@import 'ui/radio-button.scss';
@import 'ui/input-number.scss';
@import 'ui/file-upload.scss';
@import 'ui/drag-and-drop-file-upload.scss';
@import 'ui/save-confirmation.scss';
@import 'ui/data-display.scss';
@import 'ui/slider.scss';
@import 'ui/accordion.scss';
@import 'ui/tabview.scss';
@import 'ui/carousel.scss';
@import 'ui/collapsible-input.scss';
@import 'ui/steppers.scss';
@import 'ui/tristatecheckbox.scss';
@import 'ui/info-banner.scss';
@import 'ui/disabled-overlay.scss';
@import 'ui/fineprint.scss';
@import 'ui/list.scss';
@import 'ui/typography.scss';
@import 'ui/inline-table.scss';
@import 'ui/collapsible-card.scss';
@import 'ui/badge.scss';
@import 'ui/icons.scss';
@import 'ui/popover.scss';

@mixin ui() {
	.common-ui-layout-action-row {
		display: flex;
		padding: 14px 0;

		.common-ui-button:first-of-type {
			flex: 1;
		}
	}

	.common-ui-input-text,
	.common-ui-textarea {
		flex: 1 1 auto;

		::placeholder {
			font-style: $stamps-ui-input-placeholder-font-style;
		}

		.common-ui-input-icon-right {
			display: inline-block;
			position: relative;
			width: 100%;
		}

		.show-password {
			position: absolute;
			display: inline-block;
			cursor: pointer;
			width: 44px;
			height: 100%;
			margin-top: -30px;
			right: 0px;
			background: background-image-url($stamps-signin-show-password-icon) no-repeat center;
			background-size: 16px 16px !important;
		}

		.hide-password {
			position: absolute;
			display: inline-block;
			cursor: pointer;
			width: 44px;
			height: 100%;
			margin-top: -30px;
			right: 0px;
			background: background-image-url($stamps-signin-hide-password-icon) no-repeat center;
			background-size: 16px 16px !important;
		}
	}

	.common-ui-input-text,
	.common-ui-dropdown,
	.common-ui-textarea {
		.p-inputtext,
		.p-dropdown {
			&.p-disabled {
				@include disabled-input();

				.p-dropdown-label,
				.p-dropdown-trigger {
					@include disabled-input();
				}
			}
		}
	}

	// Utility classes
	.invalid-component,
	.invalid-component:not(.p-disabled):hover {
		border-color: $stamps-ui-input-border-invalid-color;
	}

	.no-text-decoration {
		text-decoration: none;
	}

	.bold-font {
		font-weight: bold;
	}

	.disabled-text,
	.disabled-text a {
		color: $stamps-disabled-font-color;
	}

	.social-button {
		.p-button {
			width: 77px;
			height: 24px;
			border: 0px;
		}

		.ui-button-facebook {
			background: background-image-url('facebook-icon.svg') no-repeat center;

			&:hover {
				background: background-image-url('facebook-icon-hover.svg') no-repeat center;
			}

			&:active {
				background: background-image-url('facebook-icon-active.svg') no-repeat center;
			}
		}

		.ui-button-twitter {
			background: background-image-url('twitter-icon.svg') no-repeat center;

			&:hover {
				background: background-image-url('twitter-icon-hover.svg') no-repeat center;
			}
		}
	}

	@include ui-button();
	@include ui-element-wrapper();
	@include ui-spinner();
	@include ui-inline-spinner();
	@include ui-legal-text();
	@include ui-tooltip();
	@include ui-address-field();
	@include ui-dimensions-field();
	@include ui-print-on-field();
	@include ui-service-field();
	@include ui-tracking-field();
	@include ui-weight-field();
	@include ui-buttongrid();
	@include ui-section();
	@include ui-footer();
	@include ui-multiselect();
	@include ui-media-carousel();
	@include ui-toolbar();
	@include ui-calendar();
	@include ui-checkbox();
	@include ui-colorpicker();
	@include ui-dropdown();
	@include ui-grouped-dropdown();
	@include ui-radio-button();
	@include ui-file-upload();
	@include ui-drag-and-drop-file-upload();
	@include ui-save-confirmation();
	@include ui-data-display();
	@include ui-tabview();
	@include ui-input-number();
	@include ui-slider();
	@include ui-accordion();
	@include ui-collapsible-input();
	@include ui-steppers();
	@include ui-tristatecheckbox();
	@include ui-info-banner();
	@include ui-disabled-overlay();
	@include ui-fineprint();
	@include ui-list();
	@include ui-typography();
	@include ui-inline-table();
	@include ui-collapsible-card();
	@include ui-badge();
	@include ui-popover();
}
