@mixin print-thumbnail {
	$netstamps-static-list:(
		'traditional':'print/traditional',
		'promotional': 'print/traditional',
		'standard': 'print/standard',
		'alternate': 'print/alternate',
		'themed': 'print/themed',
		'promo-paper-20': 'print/photo',
		'photo-landscape': 'print/photo',
		'photo-landscape-hs':'print/photo',
		'photo-portrait': 'print/photo',
		'return-address': 'print/return-address'
	);

	$envelope-aspect-ratio:
		('6', 1.8, 55.77%), // 1300 x 725
		('7', 1.94, 51.67%), // 1500 x 775
		('9', 2.29, 43.66%), // 1775 x 775
		('10', 2.3, 43.42%), // 1900 x 825
		('11', 2.3, 43.37%), // 2075 x 900
		('12', 2.32, 43.18%), // 2200 x 950
		('a2', 1.31, 76.09%), // 1150 x 875
		('a9', 1.52, 65.71%); // 1750 x 1150

	.common-print-thumbnail {
		position: relative;
		border: 1px solid $stamps-print-labelpreview-border-color;
		overflow: hidden;
		display: flex;
		align-items: center;

		&.selectable:hover {
			border: 1px solid $stamps-print-labelpreview-border-hover-color;
		}

		.thumbnail-image {
			position: absolute;
			top: -1px;
			left: -1px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}

		&.thumbnail-shippingLabel,
		&.thumbnail-roll {
			.shipping-label-static {
				background-image: background-image-url('print/carrier-agnostic-sample.png');
			}
		}

		&.thumbnail-envelope {
			width: 100%;
			padding-top: 43.2%;
			aspect-ratio: 2.3; /* stylelint-disable-line */

			@each $envelope in $envelope-aspect-ratio {
				$envelope-type: nth($envelope, 1);
				$aspect-ratio: nth($envelope, 2);
				$percentage: nth($envelope, 3);

				&.thumbnail-#{$envelope-type} {
					padding-top: $percentage;
					aspect-ratio: $aspect-ratio; /* stylelint-disable-line */
				}
			}

			.thumbnail-image {
				width: 100%;
				height: 100%;
				background-size: contain;

				&.envelope-static {
					background-image: background-image-url('print/envelope-sample.gif');
				}
			}
		}

		&.thumbnail-netstamps {
			@each $ns-type, $ns-path in $netstamps-static-list {
				&.thumbnail-#{$ns-type}.available {
					.netstamps-static {
						background-image: background-image-url('#{$ns-path}-available.png');
					}
				}

				&.thumbnail-#{$ns-type}.printed {
					.netstamps-static {
						background-image: background-image-url('#{$ns-path}-printed.png');
					}
				}

				&.thumbnail-#{$ns-type}.selected {
					.netstamps-static {
						background-image: background-image-url('#{$ns-path}-selected.png');
					}
				}
			}
		}

		.p-progress-spinner {
			width: 36px;
			height: 36px;
		}
	}
}
