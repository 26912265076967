@mixin account-verification-enter-verification {
	.common-account-verification-enter-verification {
		padding: $stamps-modal-body-padding;

		p {
			margin-bottom: 10px;
		}

		.verify-input-container {
			width: 350px;
		}

		.error-icon {
			padding-left: 15px;
			background: background-image-url($stamps-ui-element-wrapper-error-icon) no-repeat center;
		}

		.error-message {
			padding-left: 8px;
			color: $stamps-ui-element-wrapper-invalid-text-color;
		}

		.action-bar {
			margin-top: 30px;

			.common-ui-button {
				&:first-of-type {
					padding-right: 15px;
				}
			}
		}
	}
}
