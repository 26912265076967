@mixin account-users {
	.account-users {
		.common-ui-element-wrapper {
			.input-container {
				.common-ui-error-container {
					display: flex;
					align-items: center;
				}
			}
		}

		.common-ui-element-wrapper,
		.common-ui-data-display {
			.label-container {
				width: 160px;
			}
		}

		.common-ui-button:not(:first-child):not(.pagination-button) {
			margin-left: 10px;
		}

		&.p-dialog.modal {
			max-width: 550px;
		}

		&.account-users-filters-modal {
			width: inherit;

			.p-dialog-content {
				overflow: hidden;
			}

			.filters-body {
				min-height: 100px;
				max-height: 400px;
				overflow: auto;

				.search-filter {
					border-bottom: $stamps-account-users-border;

					.input-search-container {
						position: relative;

						.p-inputtext {
							padding-right: 25px;
						}

						.pi.pi-search {
							position: absolute;
							color: #888;
							right: 5px;
							top: 8px;
							font-size: 18px;
						}
					}
				}

				.p-accordion-header,
				.p-accordion-header:not(.p-disabled).p-highlight,
				.p-accordion-header:not(.p-disabled).p-highlight:hover,
				.p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
					a {
						display: flex;
						justify-content: space-between;
						background-color: transparent;
						border-radius: 0;

						.p-accordion-header-text {
							order: 1;
							color: #2159a8;
						}

						.p-accordion-toggle-icon {
							order: 2;
							color: #2159a8;
						}

						:hover {
							background-color: transparent;
							color: #2159a8;
						}
					}
				}

				.p-accordion-content {
					border: none;
				}

				.filter-checkbox {
					margin-bottom: 5px;
				}

				.filter-checkbox:nth-child(2) {
					margin-top: 10px;
				}
			}
		}

		.icon-email {
			background-image: background-image-url('email.svg');
		}

		.icon-document {
			background-image: background-image-url('document.svg');
		}

		&.account-users-wrapper {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;

			&.show-details {
				min-width: 900px; /* grid has min-width: 420px and details has min:width: 480px */
				overflow: hidden; /* fix scroll bar  */
				position: relative;
			}

			.grid-and-details-wrapper {
				flex: 1;
				display: flex;
				flex-direction: row;
				height: 100%; /* fix scroll bar */
				overflow: hidden;

				.account-users-users-grid {
					flex: 1;
					overflow: auto; /* fix scroll bar */
				}

				.account-users-user-details {
					overflow: auto; /* fix scroll bar */
					width: 500px;
					min-width: 500px;
					text-align: left;
					background-color: $stamps-account-users-details-bg-color;
					border-left: $stamps-account-users-border;
					display: flex;
					flex-direction: column;

					.p-inputnumber-input {
						width: 75px;
						text-align: right;
					}

					.user-details-heading {
						display: flex;
						flex-flow: row nowrap;
						padding: 0 12px;
						height: 43px;
						align-items: center;
						border-bottom: $stamps-account-users-border;
						background-color: $stamps-account-users-details-heading-bg-color;
					}

					.user-details-container {
						flex: 1;
						overflow: auto;
						padding: 10px;

						.non-editable-field {
							.label-container {
								font-weight: bold;
							}
						}
					}

					.user-details-footer {
						padding: 8px 12px 9px;
						border-top: $stamps-account-users-border;
						background: $stamps-account-users-details-footer-bg-color;
					}
				}

				.account-users-multi-users-edit {
					padding: 10px;
				}

				.account-users-single-user-edit {
					.username,
					.status {
						display: flex;
						justify-content: space-between;

						.link-button {
							display: inline-flex;
							font-weight: bold;
							line-height: 20px;
							margin-top: 2px;

							.pi {
								font-size: 16px;
								line-height: 20px;
							}

							&.disabled {
								pointer-events: none;
								opacity: .7;
							}
						}
					}

					.wrapped-field.phone {
						.common-ui-input-text {
							flex: none;
							flex-basis: 180px;
						}
					}

					.user-caps-checkbox {
						margin-top: 10px;
					}

					.per-print-limit {
						display: flex;

						.input-per-print-value {
							.input-container {
								position: relative;

								.common-ui-error-container {
									position: absolute;
									right: -20px;
									transform: translateY(25%);
								}
							}
						}
					}
				}
			}
		}

		&.account-users-activation-view,
		&.account-users-activation-confirmation {
			width: 100%;
			height: 100%;
			text-align: left;
			padding: 20px 0 0 20px;

			.activation-heading {
				font-size: 42px;
				padding-bottom: 15px;
				font-weight: bold;
			}

			.activation-content {
				font-size: 18px;
				margin-bottom: 30px;
			}

			.continue-button {
				&.common-ui-button {
					margin-left: 170px;
				}
			}
		}

		&.account-users-activation-view {
			.term-conditions {
				display: flex;
			}
		}

		&.account-users-activation-confirmation {
			.activation-subheading {
				color: $stamps-primary-color;
				font-size: 26px;
				margin-bottom: 15px;
			}

			.p-button-danger {
				width: 60%;

				span {
					font-size: 18px;
					text-transform: uppercase;
				}
			}

			.signin-online {
				background: background-image-url('Stamps.com-Online.png') no-repeat;
				display: inline-block;
				width: 400px;
				height: 261px;
				margin-bottom: 40px;
			}

			.stamps-for-windows {
				background: background-image-url('Stamps.com-For-Windows.png') no-repeat;
				display: inline-block;
				width: 400px;
				height: 261px;
			}
		}
	}
}
