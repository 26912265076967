@mixin rate-advisor-validation-error {
	.common-rate-advisor-validation-error {
		text-align: center;
		color: $stamps-rate-advisor-validation-error;
		width: calc(100% - 245px);
		min-width: 585px;
		position: absolute;
		top: 86px;
		left: 245px;

		.no-data-error-container,
		.generic-error-container {
			.content {
				font-size: 18px;
				margin-bottom: 20px;
			}
		}

		.no-data-error-container {
			text-align: left;
			display: inline-block;

			.sub-content-error {
				text-align: justify;
				margin: 0 0 30px 20px;

				li {
					padding: 8px;
				}
			}
		}

		.generic-error-container {
			.sub-content-error {
				margin-bottom: 30px;
			}
		}
	}
}
