@mixin ui-data-display() {
	.common-ui-data-display {
		display: flex;
		align-items: center;
		padding: 2px 0;

		.label-container {
			margin-bottom: 5px;
			margin-right: 10px;
			white-space: nowrap;
			width: 120px;
			text-align: right;
			line-height: $stamps-line-height;
		}

		.data-display-container {
			display: flex;
			flex-wrap: wrap;
			flex: 1;
			margin-bottom: 5px;
		}
	}
}
