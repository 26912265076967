@mixin learning-center() {
	.common-article-external-link {
		display: flex;
	}

	.common-article-external-link .external-link-nav {
		background: background-image-url('external-link-nav.svg') no-repeat;
		width: 12px;
		margin-top: 3px;
		margin-left: 4px;
	}
}
