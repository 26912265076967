@mixin inbounds-details-tracking-info {
	.common-inbound-details-tracking-info {
		display: flex;
		flex-direction: column;
		margin: 15px 0 15px 0;

		.tracking-info-header {
			color: $stamps-inbound-details-header-text-color;
			font-weight: bold;
			margin: 0 0 15px 0;
		}

		.tracking-info-content {
			background-color: $stamps-details-background-color;
			padding: 20px 20px;
			display: flex;
			flex-direction: column;

			.sub-header {
				border-bottom: 1px solid $stamps-inbound-details-border;
				color: $stamps-inbound-details-sub-header-text-color;
				font-weight: bold;

				.p-lg-3,
				.p-lg-9 {
					padding-left: 0;
				}
			}

			.delete-tracking {
				margin: auto;

				.delete-icon {
					background: background-image-url($stamps-icon-button-delete) no-repeat center;
					background-size: 26px;
					margin: auto;
					margin-bottom: 5px;
				}
			}

			.add-tracking {
				.p-button-label {
					font-weight: bold;
					color: #023a89;
				}

				.add-icon {
					background: background-image-url($stamps-inbound-add-icon) no-repeat center;
					width: 20px !important;
					height: 20px !important;
					left: 0 !important;
					background-position: 0 !important;
				}
			}

			.label-container label {
				color: $stamps-inbound-details-sub-header-text-color;
				font-weight: bold;
			}
		}

		.common-ui-element-wrapper {
			padding: 7px 7px 7px 0;
		}

		&.mobile-wrapper {
			.tracking-info-content {
				padding-right: 0;
				padding-top: 0;
			}

			.tracking-item {
				border-bottom: 1px solid $stamps-inbound-details-border;

				.label-container {
					min-width: 120px;
					text-align: right;
				}
			}

			.add-tracking {
				margin-top: 15px;
				align-self: center;

				.p-button-label {
					font-weight: bold;
					color: #023a89;
				}
			}

			.delete-tracking {
				padding-right: 0;
			}

			.carrier .common-ui-dropdown {
				width: 130px;
			}
		}
	}
}
