@import 'upgrade-content.scss';

@mixin settings() {
	.common-settings {
		hr {
			margin: 12px 0;
		}

		.p-col-12 {
			display: flex;
			align-items: center;

			&.mobile-view {
				flex-direction: column;
				align-items: flex-start;

				.common-ui-checkbox {
					display: flex;
				}
			}
		}

		.common-ui-element-wrapper {
			.label-container {
				width: auto;
				margin-top: 5px;
				flex: none;
			}

			.input-container {
				margin: 0;  // override margin-rule in element-wrapper.scss for aligning items vertically centered
			}

			&.mobile-view {
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
			}
		}

		.p-checkbox-label {
			color: $stamps-settings-label-text-color;
		}

		.settings-list {
			flex: 1;
			min-height: 200px;
		}

		.settings-search {
			position: relative;

			.common-ui-input-text {
				.p-inputtext {
					padding-right: 30px; // padding space for search icon
				}
			}

			.search-icon {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 15px;
				color: $settings-mail-search-icon-color;
				cursor: pointer;
				font-size: $stamps-settings-search-icon-size;
			}
		}

		.settings-section-container {
			margin: $stamps-settings-section-margin;
			max-width: 640px;
			padding: $stamps-settings-section-padding;

			&.custom-message {

				.settings-section-form-container {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.settings-label {
						font-weight: $stamps-settings-header-font-weight;
						padding: 0;
						text-align: left;
					}
				}
			}
		}

		.settings-inline {
			padding: 5px;
		}

		.settings-label {
			color: $stamps-settings-label-text-color;
			line-height: 20px;
			text-align: right;
			padding: 5px;
			width: 130px;
			display: inline-block;
			flex: none;
			margin-right: 5px;
			font-weight: $stamps-ui-input-label-font-weight;

			&.mobile-view {
				font-weight: bold;
				text-align: left;
				padding: 0;
			}
		}

		&.main-view {
			display: flex;
			padding: 0;
			overflow: hidden;
			width: 100%;
			height: 100%;

			.settings-left-menu {
				width: 186px;
				background-color: $stamps-settings-leftmenu-background;
				border-right: 2px solid $stamps-settings-splitter-color;
				overflow-y: auto;

				.group-item {
					.group-item-titlebar {
						padding-left: 18px;
						background-color: $stamps-settings-leftmenu-background;
						border-left: none;
						border-bottom-color: $stamps-settings-leftmenu-border-color;

						.tool {
							color: $stamps-settings-leftmenu-caret-icon-color;
						}

						&:focus {
							outline: none;
						}
					}

					.group-item-content {
						border-bottom: none;

						.badge-item {
							padding-left: 6px;
							background-color: $stamps-settings-leftmenu-background;
							border-bottom: 1px solid $stamps-settings-leftmenu-border-color;

							&.active,
							&:hover {
								background-color: #fff;
								padding-left: 0;
								border-left: 6px solid $stamps-settings-leftmenu-active-border-color;
							}

							&:focus {
								outline: none;
							}
						}
					}

					.menu-item-text {
						color: $stamps-settings-leftmenu-text-color;
					}

					&.active.collapsed {
						border-left: 6px solid $stamps-settings-leftmenu-active-border-color;

						.group-item-titlebar {
							padding-left: 12px;
						}
					}
				}
			}

			.settings-content {
				width: calc(100% - 186px);
				overflow: auto;

				// header row in the setting content
				.settings-content-header {
					color: $stamps-settings-header-text-color;
					font-size: $stamps-settings-header-font-size;
					font-weight: $stamps-settings-header-font-weight;
					padding-bottom: $stamps-settings-header-padding-bottom;
					width: 100%;

					> p {
						padding: 10px 0 0 0;
						line-height: $stamps-settings-header-line-height;
					}
				}

				.settings-body {
					padding: 0 16px;
					display: flex;
					flex-direction: column;
					height: 100%
				}

				// footer row in the setting content
				.settings-content-footer {
					margin-top: auto;
					padding: 16px;
					border-top: $stamps-settings-footer-border-top;
				}

				.settings-button {
					padding: 5px;

					.p-button {
						min-width: 75px;
					}
				}
			}

			// style rules for Common/GeneralView component
			.settings-common-general-view {
				display: flex;
				flex-direction: column;

				.common-ui-element-wrapper {
					.common-ui-dropdown {
						width: $stamps-settings-general-dropdown-width;
					}
				}

				.p-col-12 {
					&.mobile-view {
						.label-container {
							margin: 0;
						}

						.input-container {
							width: 100%;

							.p-dropdown {
								width: 100%;
							}
						}
					}
				}
			}

			.settings-mail-refund-assist-view {
				display: flex;
				flex-direction: column;

				p {
					padding-bottom: 10px;
				}

				.settings-body {
					padding-bottom: 16px;
				}

				.refund-assist-container {
					border: 1px solid #afb3b6;
					border-radius: 4px;
					padding: 10px;
					.sub-heading {
						font-size: 20px;
					}

					.p-col-12 {
						display: block;
					}

					.disclaimer-refund-asssit {
						border: 1px solid #002ccc;
						background-color: #e5ebff;
						padding: 5px;

						.info-icon {
							display: inline;
							float: left;
							width: 16px;
							height: 16px;
							background: background-image-url(
									$stamps-rate-advisor-dimensions-info-icon
								)
								no-repeat center;
							background-size: 16px 16px !important;
							margin: 4px;
						}

						.info-text {
							display: flex;
						}
					}
					.p-accordion-header-link {
						padding: 20px 0px 5px 0px !important;
						justify-content: start !important;

						.p-accordion-toggle-icon {
							color: #0f0f10;
							padding: 5px;
							font-size: 10px;
						}
					}
					.p-accordion-header {
						padding-bottom: 5px;
					}

					.p-accordion-content {
						padding: 5px;
						ul {
							line-height: 20px;
						}
					}

					.refund-enable-button {
						padding-top: 15px;
					}
				}

				.refund-assist-disable {
					.p-col-12 {
						display: block;
					}

					.disclaimer {
						background-color: #f1f2f3;
						padding: 8px;
					}

					.p-button-label {
						color: #0f0f10;
					}
				}

				.common-ui-info-banner {
					margin: 10px 0;
					background-color: #E5EBFF;
					border: 1px solid #002CCC;
				}

				.common-article-external-link {
					margin: 10px 0;

					.external-link-nav {
						margin-top: 0;
					}
				}
				.refund-assist-footer {
					display: flex !important;
					flex-direction: row;
					align-items: flex-end;

					.common-article-external-link {
						margin-left: 10px;
					}
				}
			}

			.settings-common-carriers-view {
				height: 100%;

				.ag-center-cols-viewport {
					overflow-x: hidden;
				}
			}

			.settings-common-serviceoptions-view {
				display: flex;
				flex-direction: column;
				height: 100%;

				// local variable
				$services: (
					'postcard': $stamps-settings-service-options-postcard-icon,
					'letter': $stamps-settings-service-options-letter-icon,
					'envelope': $stamps-settings-service-options-envelope-icon,
					'large-envelope': $stamps-settings-service-options-large-envelope-icon,
					'package': $stamps-settings-service-options-package-icon,
					'large-package': $stamps-settings-service-options-large-package-icon,
					'usps-small-flat-rate-box': $stamps-settings-service-options-usps-small-flat-rate-box-icon,
					'usps-medium-flat-rate-box': $stamps-settings-service-options-usps-medium-flat-rate-box-icon,
					'usps-medium-flat-rate-box-pme': $stamps-settings-service-options-usps-medium-flat-rate-box-pme-icon,
					'usps-large-flat-rate-box': $stamps-settings-service-options-usps-large-flat-rate-box-icon,
					'usps-flat-rate-envelope': $stamps-settings-service-options-usps-flat-rate-envelope-icon,
					'usps-flat-rate-envelope-pme': $stamps-settings-service-options-usps-flat-rate-envelope-pme-icon,
					'usps-padded-flat-rate-envelope': $stamps-settings-service-options-usps-padded-flat-rate-envelope-icon,
					'usps-padded-flat-rate-envelope-pme': $stamps-settings-service-options-usps-padded-flat-rate-envelope-pme-icon,
					'usps-legal-flat-rate-envelope': $stamps-settings-service-options-usps-legal-flat-rate-envelope-icon,
					'usps-legal-flat-rate-envelope-pme': $stamps-settings-service-options-usps-legal-flat-rate-envelope-pme-icon,
					'ups-letter': $stamps-settings-service-options-ups-letter-icon,
					'ups-pak': $stamps-settings-service-options-ups-pak-icon,
					'ups-tube': $stamps-settings-service-options-ups-tube-icon,
					'ups-express-box-small': $stamps-settings-service-options-ups-express-box-small-icon,
					'ups-express-box-medium': $stamps-settings-service-options-ups-express-box-medium-icon,
					'ups-express-box-large': $stamps-settings-service-options-ups-express-box-large-icon,
					'fedex-envelope': $stamps-settings-service-options-fedex-envelope-icon,
					'fedex-pak': $stamps-settings-service-options-fedex-pak-icon,
					'fedex-tube': $stamps-settings-service-options-fedex-tube-icon,
					'fedex-one-rate-envelope': $stamps-settings-service-options-fedex-one-rate-envelope-icon,
					'fedex-one-rate-pak': $stamps-settings-service-options-fedex-one-rate-pak-icon,
					'fedex-one-rate-tube': $stamps-settings-service-options-fedex-one-rate-tube-icon,
					'fedex-one-rate-small-box': $stamps-settings-service-options-fedex-one-rate-small-box-icon,
					'fedex-one-rate-medium-box': $stamps-settings-service-options-fedex-one-rate-medium-box-icon,
					'fedex-one-rate-large-box': $stamps-settings-service-options-fedex-one-rate-large-box-icon,
					'fedex-one-rate-extra-large-box': $stamps-settings-service-options-fedex-one-rate-extra-large-box-icon,
					'fedex-10kg-box': $stamps-settings-service-options-fedex-10kg-box-icon,
					'fedex-25kg-box': $stamps-settings-service-options-fedex-25kg-box-icon,
					'express-envelope': $stamps-settings-service-options-express-envelope-icon,
				);

				.p-grid {
					flex: 1;
					flex-wrap: nowrap;
				}

				// fix width issue on IE11
				/* stylelint-disable */
				.p-grid {
					-ms-flex: 1 0 auto;
				}
				/* stylelint-enable */

				.service-options-row {
					@each $name, $path in $services {
						&.#{$name}-logo {
							.ag-group-value {
								background-image: background-image-url($path);
								background-repeat: no-repeat;
								background-position: 10px;
								background-size: 20px;
								margin: 0;
								padding-left: $stamps-settings-service-options-row-padding-left;
							}
						}
					}
				}

				.service-group-row {
					font-weight: bold;
				}

				.ag-center-cols-viewport {
					overflow-x: hidden;
				}

				.ag-group-expanded,
				.ag-group-contracted {
					position: absolute;
					right: 0;
					margin: 0 20px 0 0;
					cursor: pointer;
				}

				.ag-cell {
					padding-left: 0;
				}

				.ag-row-selected:not(.ag-row-hover) {
					background-color: transparent;
				}

				.ag-body-horizontal-scroll-viewport {
					overflow-x: hidden;
				}
			}

			// style rules for LabelCustomizationView component
			.settings-common-labelcustomization-view {
				display: flex;
				flex-direction: column;
				height: 100%;

				h1 {
					font-size: 30px;
				}

				.common-ui-button {
					padding: 5px 10px 5px 0;
				}

				.title {
					margin-bottom: 15px;
				}

				.content-left {
					p {
						margin-bottom: 20px;
					}
				}

				.mask-content {
					opacity: 0.5;
					pointer-events: none
				}

				.content-right {
					.label-customization-sample {
						background: background-image-url($stamps-settings-label-customization-image) no-repeat center;
						background-size: contain;
						min-height: 320px;
					}
				}

				.saved-images {
					img {
						border: 1px solid #a2a2a2;
						height: 88px;
						width: 88px;
					}
				}

				.return-label-instructions {
					.common-ui-element-wrapper {
						align-items: flex-start;
						flex: 1 1 auto;
					}

					.common-ui-dropdown {
						margin: 2px 0 0 10px;
						flex: none;
						width: auto;

						.p-dropdown {
							border: 1px solid $stamps-secondary-button-bg-color;
						}

						.p-dropdown-label {
							padding: 5px 30px 5px 10px;
							background-color: $stamps-secondary-button-bg-color;
							font-style: normal;
							color: #333;
						}

						.p-dropdown-trigger {
							background-color: $stamps-secondary-button-bg-color;
						}

						.p-dropdown-items {
							width: 200px;
						}

						&:hover {
							.p-dropdown-label,
							.p-dropdown-trigger {
								background-color: $stamps-secondary-button-hover-bg-color;
							}
						}
					}

					.settings-row {
						align-items: flex-start;
					}

					.settings-hint {
						color: $stamps-secondary-button-text-color;
						font-style: italic;
					}
				}
			}

			// style rules for Mail/GeneralView component
			.settings-mail-general-view {
				display: flex;
				flex-direction: column;

				/*
					you need this rule if height is specified in JavaScript, and using `p-align-end` can correctly
					place 'Done' button at the bottom of the dialog
				*/
				height: 100%;

				.p-grid {
					flex-wrap: nowrap; // fix rows layout issue on IE11
				}

				.print-options-container {
					&.p-col-12 {
						align-items: flex-start;
					}

					.print-options {
						flex: 1;
					}

					.common-ui-checkbox {
						width: 100%;
					}
				}

				.common-ui-button {
					padding-right: 5px;
				}

				.settings-reset-fields {
					display: flex;
					align-items: center;
				}
			}

			// style rules for PrintOnOptionsView component
			.settings-mail-print-on-options-view {
				display: flex;
				flex-direction: column;
				height: 100%;

				.p-grid {
					flex: 1;
					flex-wrap: nowrap;
					width: $stamps-settings-content-body-width;
				}

				// fix width issue on IE11
				/* stylelint-disable */
				.p-grid {
					-ms-flex: 1 0 auto;
				}

				.ag-row-selected, .ag-row-hover {
					background-color: $ag-grid-order-setting-background-color;
				}

				.ag-root-wrapper {
					border: 1px solid $ag-grid-order-setting-border-color;
					border-radius: 8px;
				}

				.ag-header {
					border-bottom: none;
				}

				.certified-mail-print-option {
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					align-self: flex-start;
					color: $stamps-settings-print-on-disabled-color;

					.label {
						padding-left: 5px;
					}

					.upgrade-icon {
						background-image: background-image-url($stamps-header-feature-banner-icon);
						background-repeat: no-repeat;
						width: 16px;
						height: 16px;
						margin-bottom: 12px;
						margin-left: 5px;
					}
				}
			}

			// style rules for OrdersMarketingView component
			.settings-orders-orders-marketing-view {
				font-size: 16px;
				padding: 15px;

				.p-col-12 {
					justify-content: center;
				}

				ul {
					width: 400px;
					padding-bottom: 15px;
				}

				li {
					text-align: left;
					margin-top: 15px;
				}

				.marketing-text-large {
					font-size: 52px;
					color: $stamps-primary-color;
					line-height: 66px;
				}
			}

			// style rules for Orders/InternationalView component
			.settings-orders-international-view {
				display: flex;
				flex-direction: column;
				height: 100%;

				.p-grid {
					width: $stamps-settings-content-body-width;
				}

				.p-col-9,
				.p-col-10 {
					display: flex;

					.settings-label {
						width: 160px;
					}

					.p-inputnumber {
						width: 100%;
					}

					.itn-note {
						padding-left: 160px;

						p {
							border: $settings-order-international-note-border;
							background-color: $settings-order-international-note-background-color;
							padding: $settings-order-international-note-padding;
						}
					}
				}
			}

			// style rules for Orders/LabelMessagesView component
			.settings-orders-label-messages-view {
				.settings-body {
					max-width: 768px;
				}

				.choice-row {
					flex-direction: column;
					align-items: flex-start;
					gap: $stamps-settings-label-messages-choice-row-gap;
				}
			}

			.settings-orders-packing-slips-view {
				display: flex;
				flex-direction: column;
				height: 100%;

				.body-text-row {
					align-items: flex-start;
				}

				.packing-slips-input-container {
					width: 50%;
					display: block;

					.body-text-counter {
						text-align: right;
						color: #c9c9c9;
						display: none;
					}

					// only show the text counter for the body text field when it's focused
					&:focus-within {
						.body-text-counter {
							display: block;
						}
					}
				}
			}

			.settings-orders-upgrade-view {
				display: flex;
				flex-direction: column;
				padding: 0 15px;
				height: 100%;
				line-height: 20px;

				.settings-content {
					margin-left: 15px;
					margin-right: 15px;

					.sub-header {
						margin-bottom: 15px;
					}

					@include upgrade-content();

					.plan-features {
						width: 50%;
					}

					.common-ui-button {
						margin-top: 15px;
					}
				}
			}
		}

		&.p-dialog {
			overflow-y: auto; // fix vertical scrolling issue on IE11

			&.settings-modal {
				.p-dialog-titlebar {
					flex: none;
				}

				.p-dialog-content {
					padding: 0;
					overflow: hidden;
					display: flex;
					flex-grow: 1;

					.main-view {
						flex-grow: 1;
						height: auto;
						width: auto;
					}
				}
			}

			&.carrier-statements-modal {
				.carrier-terms-container {
					padding: 15px 15px 0 15px;

					li {
						margin: 10px 0;
					}
				}
			}

			&.meter-contact-info-modal {
				min-width: 480px;
			}

			&.ship-notification-modal {
				max-width: 600px;

				// undo common Settings styles
				.common-emails {
					padding: 0;

					.p-col-12 {
						display: block;
						align-items: flex-start;

						.label-container {
							width: 130px;
							margin: 5px 10px 5px 0;
						}
					}
				}
			}

			&.upload-image-modal {

				.upload-image-modal-container {
					display: flex;
					justify-content: center;
				}

				.crop-image {
					display: inline-block;
				}

				.crop-element img {
					max-width: 280px; // change width to max-width to prevent filling up the height in IE11
				}

				.error-message {
					color: #f00;
				}

				#file {
					display: none;
				}

				.hidden-canvas {
					display: none;
				}

				.select-image-btn button {
					background: background-image-url($stamps-settings-upload-image-background) no-repeat center;
					background-color: #fff !important;
					background-position: $stamps-settings-upload-image-background-position;
					border: 2px dashed #ccc !important;
					border-radius: $stamps-settings-upload-image-border-radius;
					cursor: pointer;
					height: 150px;
					width: 280px;

					&:hover {
						border: 1px solid $stamps-settings-upload-image-border-hover-color !important;
					}

					span {
						display: $stamps-settings-upload-image-text-display;
						color: #11003A;
						position: relative;
						top: 35px;
					}
				}
			}

			&.reset-fields-modal {
				width: 600px;

				.p-grid {
					margin: 0;
				}

				.common-ui-element-wrapper {
					margin: 5px 10px;
				}

				.common-ui-input-number {
					.p-inputtext {
						width: 60px;
					}
				}

				.settings-row {
					padding-left: 10px;
				}
			}

			&.preview-label-image-modal {
				.p-dialog-content {
					min-width: 140px;
					min-height: 140px;
					display: flex;
				}

				.preview-label-image-modal-container {
					display: flex;
					justify-content: center;
					width: 100%;
				}
			}

			&.preview-sample-packing-slip-modal {
				.preview-sample-image {
					border: 2px solid #000;
					width: 460px;
				}
			}
		}
	}
}
