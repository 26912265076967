@mixin ui-print-on-field() {
	.common-ui-print-on-field {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;

		// add dropdown panel style rules
		@include dropdown-panel-factory('print-on');

		.print-on-dropdown-panel {
			.print-on-icon {
				span {
					width: 100%;
					background-repeat: no-repeat;
					background-position: center;
					display: inline-block;

					&.netstamps {
						background-image: background-image-url('print-on/print-on-netstamps.png');
					}

					&.shipping-label {
						background-image: background-image-url('print-on/print-on-shipping-label.png');
					}

					&.envelope {
						background-image: background-image-url('print-on/print-on-letter.png');
					}

					&.certified {
						background-image: background-image-url('print-on/print-on-certified-mail.png');
					}

					&.roll {
						background-image: background-image-url('print-on/print-on-roll.png');
					}
				}
			}
		}
	}
}
