@mixin account-unsubscribe {
	.account-unsubscribe {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		.common-header {
			display: flex;
			height: 50px;
			background-color: $stamps-top-panel-background-color;
		}

		.common-ui-save-confirmation {
			display: flex;
			padding: 0;
			color: #333;

			.check-icon {
				flex-shrink: 0;
			}
		}

		.body {
			text-align: left;
			padding: 15px;
			flex: 1 1 auto;
			overflow: auto;
		}

		.title {
			display: flex;
			align-items: center;
			font-size: 18px;
			font-weight: bold;
			border-bottom: 1px solid #eee;
			height: 43px;
			margin-bottom: 20px;
		}
	}
}
