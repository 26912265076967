@mixin carrier-ups-eula {
	.common-carrier-ups-eula {
		height: 100%;
		max-width: 700px;

		.common-ui-layout-section {
			ol {
				padding-left: 0;
			}

			li {
				margin: 12px 0 12px 35px;
			}

			.ups-technology-agreement-link .ups-promotional-and-technology-agreement-link {
				margin-left: 5px;
			}
		}
	}
}
