@import './../../adl-theme/var/adl.scss';

// Global
$stamps-font-family: $adl-font-family;
$stamps-font-size: 14px;
$stamps-icon-font-size: 16px;
$stamps-line-height: 20px;
$stamps-primary-color: #565e64;
$stamps-primary-header-color: #333;
$stamps-signin-primary-header-color: #333;
$stamps-secondary-color: #dadee3;
$stamps-app-logo-width: 97px;
$stamps-app-logo-width-phone: 97px;
$stamps-app-logo-height: 28px;
$stamps-app-logo-icon: null;
$stamps-app-store-header-logo-icon: 'stamps-store-logo.png';
$stamps-app-logo-icon-width: 160px;
$stamps-app-logo-icon-margin-right: 0 64px 0 $adl-margin-sm;
$stamps-app-store-header-logo-width: 151px;
$stamps-app-store-header-logo-height: 30px;
$stamps-disabled-opacity: .4;
$stamps-disabled-font-color: $adl-text-disabled;
$stamps-text-secondary-color: #0a0a0a;
$stamps-link-color: #2159a8;
$stamps-link-hover-color: #1c9eff;
$stamps-link-hover-decoration: underline;
$stamps-link-active-color: #1c9eff;
$stamps-link-active-outline: inherit;
$stamps-link-hover-text-decoration: underline;
$stamps-link-focus-border-radius: none;
$stamps-link-focus-outline: none;
$stamps-link-focus-text-decoration: underline;

// Icons
$stamps-ui-affirm-icon: '~icons/affirm-outline-off.svg';
$stamps-ui-affirm-color: $adl-green-700;
$stamps-ui-error-icon: '~icons/error-outline-off.svg';
$stamps-ui-featured-icon: '~icons/featured-outline-off.svg';
$stamps-ui-help-icon: '~icons/help-outline-off.svg';
$stamps-ui-information-icon: '~icons/information-outline-off.svg';
$stamps-ui-information-outline-icon: '~icons/information.svg';
$stamps-ui-sparkle-icon: '~icons/sparkle-outline-off.svg';
$stamps-ui-warning-icon: '~icons/warning-outline-off.svg';
$stamps-ui-upgrade-icon: '~icons/upgrade-outline-off.svg';

$stamps-primary-header-font-size: 20px;
$stamps-primary-header-line-height: 30px;
$stamps-account-content-padding: 15px;

$stamps-account-plan-tile-border-size: 2px;
$stamps-account-plan-padding: 15px 15px 15px 25px;
$stamps-account-plan-width: 800px;
$stamps-account-plan-border-size: 4px;
$stamps-account-plan-border-left-width: 10px;
$stamps-account-subscription-plan-border-color: $stamps-primary-color;
$stamps-account-subscription-plan-color: $stamps-primary-color;
$stamps-account-subscription-plan-font-weight: bold;
$stamps-account-subscription-plan-text-padding: 8px 8px 0 0;
$stamps-account-subscription-plan-background-color: $stamps-primary-color;
$stamps-account-subscription-plan-border-radius: none;
$stamps-account-subscription-plan-padding: 8px 10px 8px;
$stamps-account-subscription-plan-font-size: 14px;
$stamps-account-subscription-plan-width: 100%;
$stamps-account-subscription-plan-button-color: #fff;
$stamps-account-subscription-plan-button-background-color: #2159a8;
$stamps-account-subscription-plan-button-border-color: #2159a8;

$stamps-account-subscription-plan-button-hover-color: #fff;
$stamps-account-subscription-plan-button-hover-background-color: #2159a8;
$stamps-account-subscription-plan-button-hover-border-color: #2159a8;

$stamps-account-refer-friend-heading-padding: 32px 0;
$stamps-account-refer-friend-promo-code-color: #de1429;
$stamps-account-refer-friend-promo-code-font-size: 30px;
$stamps-account-refer-friend-promo-code-font-weight: 400;
$stamps-account-refer-friend-promo-code-line-height: 30px;
$stamps-account-refer-friend-promo-code-padding: 0 0 0 5px;
$stamps-account-refer-friend-promo-code-border: none;
$stamps-account-refer-friend-promo-code-radius: none;
$stamps-account-refer-friend-promo-letter-text-size: 15px;
$stamps-account-refer-friend-promo-section-border: none;
$stamps-account-refer-friend-promo-section-border-radius: 0;
$stamps-account-refer-friend-promo-section-margin-bottom: 0;
$stamps-account-refer-friend-promo-section-item-padding: 10px 0;

$stamps-cancel-account-confirm-sub-header-padding-bottom: 10px;

// Typography
@mixin h1 {
	font-size: 24px;
	font-weight: bold;
	line-height: 24px;
}
@mixin h2 {
	font-size: 20px;
	font-weight: bold;
	line-height: 20px;
}
@mixin h3 {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
}
@mixin h4 {
	font-size: 14px;
	font-weight: bold;
	line-height: 14px;
}
@mixin h5 {
	font-size: 12px;
	font-weight: bold;
	line-height: 12px;
}
@mixin h6 {
	font-size: 10px;
	font-weight: bold;
	line-height: 10px;
}
@mixin heading-label {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
}
@mixin paragraph {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
}

// Button
$stamps-default-button-padding: 5px 10px;
$stamps-default-button-padding-left: 10px;
$stamps-default-button-font-weight: 400;
$stamps-primary-button-text-color: #fff;
$stamps-default-button-line-height: $stamps-line-height;
$stamps-primary-button-bg-color: $stamps-primary-color;
$stamps-primary-button-hover-bg-color: darken($stamps-primary-color, 10%);
$stamps-primary-button-active-bg-color: darken($stamps-primary-color, 20%);
$stamps-secondary-button-text-color: #333;
$stamps-secondary-button-bg-color: $stamps-secondary-color;
$stamps-secondary-button-border-color: $stamps-secondary-button-bg-color;
$stamps-secondary-button-hover-bg-color: darken($stamps-secondary-color, 10%);
$stamps-secondary-button-hover-border-color: darken($stamps-secondary-color, 10%);
$stamps-secondary-button-active-bg-color: darken($stamps-secondary-color, 20%);
$stamps-secondary-button-active-border-color: darken($stamps-secondary-color, 20%);
$stamps-transparent-button-text-color: #333;
$stamps-transparent-button-bg-color: transparent;
$stamps-transparent-button-hover-bg-color: transparent;
$stamps-transparent-button-active-bg-color: transparent;
$stamps-tertiary-button-grey-text-color: #0F0F10;
$stamps-tertiary-button-grey-line-height: $stamps-line-height;
$stamps-tertiary-button-grey-bg-color: $stamps-primary-color;
$stamps-tertiary-button-grey-hover-bg-color: darken($stamps-primary-color, 10%);
$stamps-tertiary-button-grey-active-bg-color: darken($stamps-primary-color, 20%);
$stamps-tertiary-button-grey-idle-background-color: transparent;
$stamps-tertiary-button-grey-hover-background-color: transparent;
$stamps-tertiary-button-grey-active-background-color: transparent;
$stamps-tertiary-button-white-text-color: #0F0F10;
$stamps-tertiary-button-white-line-height: $stamps-line-height;
$stamps-tertiary-button-white-bg-color: $stamps-primary-color;
$stamps-tertiary-button-white-hover-bg-color: darken($stamps-primary-color, 10%);
$stamps-tertiary-button-white-active-bg-color: darken($stamps-primary-color, 20%);
$stamps-tertiary-button-white-border-color: transparent;
$stamps-tertiary-button-white-idle-background-color: transparent;
$stamps-tertiary-button-white-hover-background-color: transparent;
$stamps-tertiary-button-white-active-background-color: transparent;
$stamps-tertiary-button-ghost-text-color: #0F0F10;
$stamps-tertiary-button-ghost-line-height: $stamps-line-height;
$stamps-tertiary-button-ghost-bg-color: $stamps-primary-color;
$stamps-tertiary-button-ghost-hover-bg-color: darken($stamps-primary-color, 10%);
$stamps-tertiary-button-ghost-active-bg-color: darken($stamps-primary-color, 20%);
$stamps-tertiary-button-ghost-border-color: transparent;
$stamps-tertiary-button-ghost-idle-background-color: transparent;
$stamps-tertiary-button-ghost-hover-background-color: transparent;
$stamps-tertiary-button-ghost-active-background-color: transparent;
$stamps-upgrade-button-idle-background-color: $adl-purple-500;
$stamps-upgrade-button-hover-background-color: $adl-purple-600;
$stamps-icon-button-icon-size: 24px;
$stamps-icon-button-padding: 6px;
$stamps-icon-button-text-color: #333;
$stamps-icon-button-bg-color: transparent;
$stamps-icon-button-hover-bg-color: transparent;
$stamps-icon-button-active-bg-color: transparent;
$stamps-ui-button-icon-background-size: 48px;
$stamps-ui-button-icon-size: 24px;
$stamps-ui-button-icon-background-image: 'tools-medium.png';
$stamps-button-padding-block: 1px;
$stamps-button-padding-inline: 6px;
$stamps-splitbutton-text-color: #000;
$stamps-splitbutton-bg-color: #fff;
$stamps-splitbutton-hover-text-color: #000;
$stamps-splitbutton-hover-bg-color: #eee;
$stamps-splitbutton-menuitem-icon: 'caret-down.svg';
$stamps-splitbutton-menuitem-padding: 0.714em 0.857em;
$stamps-splitbutton-menuitem-active-background-color: inherit;
$stamps-splitbutton-menuitem-hover-background-color: #e9e9e9;
$stamps-splitbutton-menuitem-disabled-text-color: $stamps-splitbutton-text-color;
$stamps-splitbutton-menulist-padding: 0;
$stamps-splitbutton-menuitem-margin: 0;
$stamps-splitbutton-menuitem-border-radius: 0;
$stamps-splitbutton-menuitem-line-height: inherit;
$stamps-splitbutton-menuitem-focus-box-shadow: inset 0 0 0 0.2em #8dcdff;
$stamps-primary-hover-background-image: 'stamps-button.png';
$stamps-icon-button-delete: 'icon-delete.png';
$stamps-icon-button-clone: 'icon-clone.png';
$stamps-icon-button-info: 'info-icon.png';
$stamps-ui-disabled-button-background-color: none; // none is an invalid color, but by doing this we can default to the original color palette for each button type
$stamps-ui-disabled-button-border-color: none;
$stamps-ui-disabled-button-font-color: none;
$stamps-file-upload-button-font-color: #fff;
$stamps-file-upload-button-border-color: unset;
$stamps-file-upload-button-icon: none;
$stamps-file-upload-button-icon-display: none;
$stamps-file-upload-button-idle-background-color: $stamps-primary-color;
$stamps-file-upload-button-hover-background-color: darken($stamps-primary-color, 10%);
$stamps-file-upload-button-active-background-color: darken($stamps-primary-color, 20%);
$stamps-button-more-icon-background-size: 48px;

// Header
$stamps-header-close-icon: 'close-icon.png';
$stamps-header-hamburger-active-icon: 'hamburger-active-icon.png';
$stamps-header-hamburger-inactive-icon: 'hamburger-inactive-icon.png';
$stamps-header-reports-upgrade: 'reports-upgrade.png';
$stamps-header-icon-size: 12px;
$stamps-header-menu-item-margin: $adl-margin-sm 0 0 0;
$stamps-header-menu-item-padding: 0 $adl-padding-lg;
$stamps-header-menu-item-text-color: $adl-grey-800;
$stamps-header-menu-item-active-text-color: $adl-grey-800;
$stamps-header-menu-item-active-bg-color: $adl-surface-container;
$stamps-header-submenu-item-border-radius: 0;
$stamps-header-submenu-item-padding: 13px;
$stamps-header-submenu-item-font-size: 13px;
$stamps-header-submenu-item-font-weight: $adl-font-weight-normal;
$stamps-header-submenu-item-text-color: $adl-grey-900;
$stamps-header-submenu-item-active-bg-color: $adl-dropdown-active-background-color;
$stamps-header-submenu-item-active-font-weight: $adl-font-weight-bold;
$stamps-header-submenu-item-active-text-color: $adl-grey-900;
$stamps-header-submenu-item-border-color: #e3e3e3;
$stamps-header-submenu-getsupplies-active-bg-color: $stamps-header-submenu-item-active-bg-color;
$stamps-header-submenu-getsupplies-icon: 'shopping-cart-icon.svg';
$stamps-header-submenu-getsupplies-icon-hover: 'shopping-cart-icon-hover.svg';
$stamps-header-submenu-open-link-icon-hover: 'open-link-icon-hover.png';
$stamps-header-submenu-responsive-item-border-color: #e3e3e3;
$stamps-header-feature-banner-icon: 'feature-banner-icon.png';
$stamps-header-feature-banner-icon-background-position: inherit;
$stamps-header-feature-gate-icon: 'feature-gate-icon.png';
$stamps-header-feature-gate-icon-hover: 'feature-gate-icon-hover.png';

// Layout
$stamps-top-panel-background-color: $adl-surface-container;
$stamps-top-panel-border: 1px solid $adl-border-light;
$stamps-collapsible-panel-header-width: 40px;
$stamps-collapsible-panel-collapse-expand-icon: 'collapsible-panel-toggle-icon.png';
$stamps-collapsible-panel-west-header-text-color: #fff;
$stamps-collapsible-panel-west-bg-color: $stamps-primary-color;
$stamps-collapsible-panel-west-border-color: transparent;
$stamps-collapsible-panel-west-border-width: 0px;
$stamps-collapsible-panel-east-header-text-color: #fff;
$stamps-collapsible-panel-east-bg-color: $stamps-primary-color;
$stamps-collapsible-panel-east-border-color : #e2e2e2;
$stamps-center-panel-background-color: #f6f7f7;
$stamps-collapsible-panel-west-toggle-padding-right: 12px;
$stamps-collapsible-panel-west-border-color: #e2e2e2;

// Menu
$stamps-menu-font-family: inherit;
$stamps-menu-badge-width: 32px;
$stamps-menu-badge-height: 20px;
$stamps-menu-badge-border-radius: 3px;
$stamps-menu-badge-font-size: 13px;
$stamps-menu-badge-text-color: #fff;
$stamps-menu-badge-background-color: darken($stamps-primary-color, 25%);
$stamps-menu-badge-item-font-size: 13px;
$stamps-menu-badge-item-highlight-border-color: $stamps-primary-color;
$stamps-menu-group-item-border-color: darken($stamps-primary-color, 5%);
$stamps-menu-right-group-item-border-color: #fff;;
$stamps-menu-group-item-highlight-border-color: $stamps-secondary-color;
$stamps-menu-group-item-highlight-border-width: 6px;
$stamps-menu-group-item-highlight-content-border-width: 0;
$stamps-menu-icon-color: $adl-stamps-darkindigo;
$stamps-menu-icon-caret-size: $adl-font-size-number-input-icon;
$stamps-menu-icon-margin-left: $adl-icon-margin-sm;
$stamps-menu-icon-plus-cog-size: inherit;
$stamps-menu-icon-plus-content: "\e90d";
$stamps-menu-icon-cog-content: "\e94a";
$stamps-menu-icon-trash-content: "\e93d";
$stamps-menu-icon-pencil-content: "\e942";
$stamps-menu-icon-background: none;
$stamps-menu-item-group-item-expanded-border-width: 1px;
$stamps-menu-icon-margin: 10px;
$stamps-menu-item-background-color: $stamps-primary-color;
$stamps-menu-item-highlight-background-color: darken($stamps-primary-color, 10%);
$stamps-menu-item-hover-background-color: $adl-dropdown-active-background-color;
$stamps-menu-item-padding: 12px;
$stamps-menu-item-border-color: darken($stamps-primary-color, 5%);
$stamps-menu-item-active-border: 5px solid $adl-orange-500;
$stamps-menu-item-active-font-weight: 600;
$stamps-menu-item-inactive-border: 5px solid transparent;
$stamps-menu-item-inactive-font-weight: $adl-font-weight-normal;
$stamps-menu-item-link-height: 45px;
$stamps-menu-item-height: auto;
$stamps-menu-item-margin-top: -10px;
$stamps-menu-item-right-border: 1px solid $adl-border-light;
$stamps-menu-item-right-link-height: 34px;
$stamps-menu-item-right-link-margin-top: 10px;
$stamps-menu-item-right-padding-right: 10px;
$stamps-menu-item-text-transform: none;
$stamps-menu-item-right-edges-background-color: transparent;
$stamps-menu-right-background-color: #fff;
$stamps-menu-right-padding: 4px;
$stamps-menu-search-text-color: #000;
$stamps-menu-search-text-font-size: $adl-font-size-sm;
$stamps-menu-search-icon-size: $adl-font-size-xs;
$stamps-menu-search-caret-size: $adl-icon-size-sm;
$stamps-menu-search-padding: 5px;
$stamps-menu-search-input-top-bottom-padding: $stamps-menu-search-padding;
$stamps-menu-search-input-left-right-padding: $stamps-menu-search-padding;
$stamps-menu-search-input-inner-padding: 0;
$stamps-menu-search-background-color: #fff;
$stamps-menu-search-border-color: #fff;
$stamps-menu-search-border-radius: $adl-border-radius-xs;
$stamps-menu-search-focus-border-color: $stamps-primary-color;
$stamps-menu-search-wrapper-border-color: #fff;
$stamps-menu-search-font-family: sans-serif;
$stamps-menu-search-line-height: $adl-line-height-sm;
$stamps-menu-text-color: #fff;
$stamps-menu-text-font-weight: 400;
$stamps-menu-text-font-size: $stamps-font-size;
$stamps-menu-text-line-height: 18px;
$stamps-menu-title-text-font-weight: 400;
$stamps-menu-tool-color: #fff;
$stamps-menu-tool-margin: 6px;
$stamps-menu-tool-size: $stamps-icon-font-size;
$stamps-menu-tag-width: 6px;
$stamps-menu-tag-height: 15px;
$stamps-menu-tag-border-radius: 0;
$stamps-menu-toggle-icon-size: 18px;
$stamps-menu-x-icon-size: $stamps-icon-font-size;
$stamps-menu-x-icon-color: inherit;

// Modal
$stamps-modal-header-font-weight: 400;
$stamps-modal-header-text-font-size: 16px;
$stamps-modal-header-text-color: #fff;
$stamps-modal-header-icon-color: $stamps-modal-header-text-color;
$stamps-modal-header-icon-hover-color: #aaa;
$stamps-modal-header-background-color: #565e64;
$stamps-modal-header-padding: 13px 15px;
$stamps-modal-header-border: 1px solid #ccc;
$stamps-modal-header-border-bottom: 1px solid #ccc;
$stamps-modal-header-cell-background-color: $adl-grey-050;
$stamps-modal-header-cell-color: $adl-grey-900;
$stamps-modal-body-text-font-size: 14px;
$stamps-modal-body-text-color: #0a0a0a;
$stamps-modal-body-cost-text-color: #0a0a0a;
$stamps-modal-body-background-color: #fff;
$stamps-modal-body-padding: 15px;
$stamps-modal-body-border: 1px solid #ccc;
$stamps-modal-body-hr-border: 1px solid #ccc;
$stamps-modal-body-bottom-border: 0;
$stamps-modal-footer-text-color: #0a0a0a;
$stamps-modal-footer-background-color: #fff;
$stamps-modal-footer-padding: 15px;
$stamps-modal-footer-border: 1px solid #ccc;
$stamps-modal-footer-border-top: 1px solid #ccc;
$stamps-modal-error-icon: 'modal-error-icon.png';
$stamps-modal-border-radius: 8px;
$stamps-modal-border-color: $adl-grey-100;
$stamps-modal-warning-icon: 'exclamation-icon.png';
$stamps-modal-total-cost-font-size: 14px;
$stamps-tutorial-modal-max-width: 480px;
$stamps-tutorial-modal-width: 100%;
$stamps-tutorial-modal-min-height: 240px;
$stamps-tutorial-modal-height: auto;
$stamps-tutorial-modal-footer-gap: $adl-padding-sm;

// Print
$stamps-print-labelpreview-border: 1px;
$stamps-print-labelpreview-background-color: #fff;
$stamps-print-labelpreview-border-color: #a2a2a2;
$stamps-print-labelpreview-border-hover-color: #1c9eff;
$stamps-print-labelpreview-printed-thumbnail-color: #d3d3d3;
$stamps-print-labelpreview-thumbnail-container-color: #fff;
$stamps-print-labelpreview-printed-thumbnail-gap: 0px;
$stamps-print-labelpreview-printed-padding: 0px;
$stamps-print-labelpreview-box-shadow: none;
$stamps-print-labelpreview-box-radius: 0px;

// PrimeIcons
$stamps-pi-times-circle-color: #bcbcbc;
$stamps-pi-times-circle-hover-color: #a0a3a5;
$stamps-pi-menu-font-size: 6px;

// Rateadvisor
$stamps-rate-advisor-validation-error: #565e64;
$stamps-rate-advisor-border-color: #eef2f8;
$stamps-rate-advisor-accordion-hover-color: #f9f9f9;
$stamps-rate-advisor-weight-icon: 'rateadvisor/sdc_icon_weight.png';
$stamps-rate-advisor-weight-hover-icon: 'rateadvisor/sdc_icon_weight_hover.png';
$stamps-rate-advisor-dimensions-icon: 'rateadvisor/sdc_icon_dimensions.png';
$stamps-rate-advisor-dimensions-hover-icon: 'rateadvisor/sdc_icon_dimensions_hover.png';
$stamps-rate-advisor-dimensions-info-icon: 'rateadvisor/sdc_info_icon.png';
$stamps-rate-advisor-origin-location-icon: 'rateadvisor/sdc_icon_origin.png';
$stamps-rate-advisor-origin-location-hover-icon: 'rateadvisor/sdc_icon_origin_hover.png';
$stamps-rate-advisor-destination-location-icon: 'rateadvisor/sdc_icon_destination.png';
$stamps-rate-advisor-destination-location-hover-icon: 'rateadvisor/sdc_icon_destination_hover.png';
$stamps-rate-advisor-show-options-button-color: #023a89;
$stamps-rate-advisor-show-options-button-hover-color: #1c9eff;
$stamps-rate-advisor-services-active-tab-shadow-color: #023a89;
$stamps-rate-advisor-services-active-tab-bg-color: #f9f9f9;
$stamps-rate-advisor-services-tab-bg-color: #f9f9f9;
$stamps-rate-advisor-package-filter-checkbox-color: #1c9eff;
$stamps-rate-advisor-package-filter-radiobutton-color: #1c9eff;
$stamps-rate-advisor-font-color: #0F0F10;

// Account Verification
$stamps-verify-email-me-icon: 'email-icon.svg';
$stamps-verify-email-me-hover-icon: 'email-hover.svg';
$stamps-verify-text-me-icon: 'text.svg';
$stamps-verify-text-me-hover-icon: 'text-hover.svg';
$stamps-verify-call-me-icon: 'call.svg';
$stamps-verify-call-me-hover-icon: 'call-hover.svg';
$stamps-verify-button-active-color: #fff;
$stamps-verify-button-border-color: #2159a8;
$stamps-verify-button-hover-color: #fff;
$stamps-verify-hover-color: #2159a8;
$stamps-verify-modal-padding: 0;
$stamps-verify-phone-icon: 'phone-icon.svg';
$stamps-verify-text-color: #2159a8;

// Settings
$stamps-settings-footer-border-top: none;
$stamps-settings-header-font-size: 15px;
$stamps-settings-header-font-weight: bold;
$stamps-settings-header-line-height: 20px;
$stamps-settings-header-padding-bottom: 0;
$stamps-settings-header-text-color: #0F0F10;
$stamps-settings-label-text-color: #0F0F10;
$stamps-settings-label-messages-choice-row-gap: 5px;
$stamps-settings-content-body-width: 100%;

$stamps-settings-leftmenu-background: #f7f7f7;
$stamps-settings-leftmenu-text-color: #525a5f;
$stamps-settings-leftmenu-border-color: #c8c8c8;
$stamps-settings-leftmenu-caret-icon-color: #565e64;
$stamps-settings-leftmenu-active-border-color: $stamps-primary-color;
$stamps-settings-general-dropdown-width: 110px;
$stamps-settings-label-customization-image: 'label-customization-sample-envelopes.png';
$stamps-settings-manage-carrier-toolbar-background-color: #e9e9e9;
$stamps-settings-manage-carrier-grid-row-pad-left: 45px;
$stamps-settings-manage-carrier-grid-logo-bg-pos: 4px;
$stamps-settings-manage-carrier-grid-logo-bg-size: 30px;
$stamps-settings-manage-carrier-grid-logo-size-sm: 12px;
$stamps-settings-manage-carrier-grid-logo-size-med: 16px;
$stamps-settings-manage-carrier-grid-logo-ups-bg-pos: 4px;
$stamps-settings-manage-carrier-grid-logo-usps-bg-pos: 4px;
$stamps-settings-manage-carrier-grid-logo-dhl-bg-pos: 4px;
$stamps-settings-search-icon-size: 20px;
$stamps-settings-section-margin: 0;
$stamps-settings-section-padding: 0;
$stamps-settings-splitter-color: #999;
$stamps-settings-upload-image-background: 'selectafile.png';
$stamps-settings-upload-image-background-position: center;
$stamps-settings-upload-image-border-hover-color: #0095cb;
$stamps-settings-upload-image-border-radius: 6px;
$stamps-settings-upload-image-text-display: none;
$stamps-settings-manage-carrier-add-carrier-header-font-size: 16px;
$stamps-settings-manage-carrier-add-carrier-header-font-weight: normal;
$stamps-settings-manage-carrier-add-carrier-height: 100%;
$stamps-settings-manage-carrier-add-carrier-button-icon-margin: auto;
$stamps-settings-manage-carrier-add-carrier-section-width: auto;
$stamps-settings-manage-carrier-add-carrier-btn-grid-border: 1px solid #8D9196;
$stamps-settings-manage-carrier-add-carrier-btn-grid-border-radius: 0;
$stamps-settings-manage-carrier-add-carrier-grid-item-container-width: 100%;
$stamps-settings-manage-carrier-add-carrier-grid-item-container-height: auto;
$stamps-settings-manage-carrier-add-carrier-grid-item-container-gap: 0;
$stamps-settings-manage-carrier-add-carrier-grid-item-container-border: 3px solid #e5e5e5;
$stamps-settings-manage-carrier-add-carrier-grid-item-container-border-radius: 5px;
$stamps-settings-manage-carrier-add-carrier-grid-item-container-bg-color: transparent;
$stamps-settings-manage-carrier-add-carrier-grid-item-width: 25%;
$stamps-settings-manage-carrier-add-carrier-logo-width: 110px;
$stamps-settings-manage-carrier-add-carrier-logo-height: 100px;
$stamps-settings-manage-carrier-add-carrier-ups-logo: 'ups-logo.png';
$stamps-settings-manage-carrier-add-carrier-ups-logo-width: 100px;
$stamps-settings-manage-carrier-add-carrier-ups-logo-height: 150px;
$stamps-settings-manage-carrier-add-carrier-dhl-logo: 'dhlx-logo.png';
$stamps-settings-manage-carrier-add-carrier-dhl-logo-width: 100px;
$stamps-settings-manage-carrier-add-carrier-dhl-logo-height: 150px;
$stamps-settings-manage-carrier-add-carrier-fedex-logo: 'fedex-logo.png';
$stamps-settings-manage-carrier-add-carrier-fedex-logo-width: 100px;
$stamps-settings-manage-carrier-add-carrier-fedex-logo-height: 150px;
$stamps-settings-manage-carrier-add-carrier-grid-item-hover: 3px solid #2159a8;
$stamps-settings-manage-carrier-add-carrier-grid-item-active: 3px solid #2159a8;
$stamps-settings-manage-carrier-add-carrier-footer-button-border: 1px solid #e9e9e9;
$stamps-settings-manage-carrier-add-carrier-header-ups-bg-size: contain;
$stamps-settings-manage-carrier-add-carrier-header-dhl-logo-margin-top: 15px;
$stamps-settings-manage-carrier-add-carrier-grid-margin-right: -0.5em;
$stamps-settings-manage-carrier-add-carrier-grid-margin-left: -0.5em;
$stamps-settings-manage-carrier-add-carrier-grid-margin-top: -0.5em;
$stamps-settings-manage-carrier-add-carrier-footer-button-hover-bg-color: #c9c9c9;
$stamps-settings-manage-carrier-add-carrier-footer-button-active-bg-color: #c9c9c9;

$settings-menu-text-color: #444;
$settings-menu-item-background-color: #f7f7f7;
$settings-menu-badge-active-border-left: 6px solid #2158aa;
$settings-menu-badge-active-icon-display: none;
$settings-menu-badge-border-left: none;
$settings-menu-border-bottom: 1px solid #ccc;
$settings-menu-border-left: none;
$settings-menu-padding-left: 0;
$stamps-settings-service-options-row-padding-left: 40px;
$stamps-settings-service-options-postcard-icon: './service/service_postcard.png';
$stamps-settings-service-options-letter-icon: './service/service_letter.png';
$stamps-settings-service-options-envelope-icon: './service/service_envelope.png';
$stamps-settings-service-options-large-envelope-icon: './service/service_large_envelope.png';
$stamps-settings-service-options-package-icon: './service/service_package.png';
$stamps-settings-service-options-large-package-icon: './service/service_large_package.png';

$stamps-settings-service-options-usps-small-flat-rate-box-icon: './service/service_usps_small_flat_rate_box.png';
$stamps-settings-service-options-usps-medium-flat-rate-box-icon: './service/service_usps_medium_flat_rate_box.png';
$stamps-settings-service-options-usps-medium-flat-rate-box-pme-icon: './service/service_usps_medium_flat_rate_box_pme.png';
$stamps-settings-service-options-usps-large-flat-rate-box-icon: './service/service_usps_large_flat_rate_box.png';
$stamps-settings-service-options-usps-flat-rate-envelope-icon: './service/service_usps_flat_rate_envelope.png';
$stamps-settings-service-options-usps-flat-rate-envelope-pme-icon: './service/service_usps_flat_rate_envelope_pme.png';
$stamps-settings-service-options-usps-padded-flat-rate-envelope-icon: './service/service_usps_padded_flat_rate_envelope.png';
$stamps-settings-service-options-usps-padded-flat-rate-envelope-pme-icon: './service/service_usps_padded_flat_rate_envelope_pme.png';
$stamps-settings-service-options-usps-legal-flat-rate-envelope-icon: './service/service_usps_legal_flat_rate_envelope.png';
$stamps-settings-service-options-usps-legal-flat-rate-envelope-pme-icon: './service/service_usps_legal_flat_rate_envelope_pme.png';
$stamps-settings-service-options-ups-letter-icon: './service/service_ups_letter.png';
$stamps-settings-service-options-ups-pak-icon: './service/service_ups_pak.png';
$stamps-settings-service-options-ups-tube-icon: './service/service_ups_tube.png';
$stamps-settings-service-options-ups-express-box-small-icon: './service/service_ups_express_box_small.png';
$stamps-settings-service-options-ups-express-box-medium-icon: './service/service_ups_express_box_medium.png';
$stamps-settings-service-options-ups-express-box-large-icon: './service/service_ups_express_box_large.png';
$stamps-settings-service-options-fedex-envelope-icon: './service/service_fedex_envelope.png';
$stamps-settings-service-options-fedex-pak-icon: './service/service_fedex_pak.png';
$stamps-settings-service-options-fedex-tube-icon: './service/service_fedex_tube.png';
$stamps-settings-service-options-fedex-one-rate-envelope-icon: './service/service_fedex_one_rate_envelope.png';
$stamps-settings-service-options-fedex-one-rate-pak-icon: './service/service_fedex_one_rate_pak.png';
$stamps-settings-service-options-fedex-one-rate-tube-icon: './service/service_fedex_one_rate_tube.png';
$stamps-settings-service-options-fedex-one-rate-small-box-icon: './service/service_fedex_one_rate_small_box.png';
$stamps-settings-service-options-fedex-one-rate-medium-box-icon: './service/service_fedex_one_rate_medium_box.png';
$stamps-settings-service-options-fedex-one-rate-large-box-icon: './service/service_fedex_one_rate_large_box.png';
$stamps-settings-service-options-fedex-one-rate-extra-large-box-icon: './service/service_fedex_one_rate_extra_large_box.png';
$stamps-settings-service-options-fedex-10kg-box-icon: './service/service_fedex_10kg_box.png';
$stamps-settings-service-options-fedex-25kg-box-icon: './service/service_fedex_25kg_box.png';
$stamps-settings-service-options-express-envelope-icon: './service/service_dhl_express_express_envelope.png';
$stamps-settings-print-on-disabled-color: #8D9196;

$stamps-branded-tracking-header-padding: 15px;
$stamps-branded-tracking-header-margin-bottom: 15px;
$stamps-branded-tracking-theme-picker-padding: 10px;
$stamps-branded-tracking-theme-picker-header-margin: 20px;
$stamps-branded-tracking-file-upload-header-margin: 20px;
$stamps-branded-tracking-stores-checkbox-margin-top: 20px;
$stamps-branded-tracking-section-margin-bottom: 20px;
$stamps-branded-tracking-tab-button-active-border-color: #2159a8;
$stamps-branded-tracking-tab-button-active-background-color: #F0F0FF;

$settings-mail-search-icon-color: #888;

$settings-order-international-note-border: none;
$settings-order-international-note-background-color: none;
$settings-order-international-note-padding: 0;

// Sign In
$stamps-signin-background-color: inherit;
$stamps-signin-background-image: 'stamps-signin-background.jpg';
$stamps-signin-brand-logo: '';
$stamps-signin-indigo-brand-logo: '';
$stamps-signin-button-width: 168px;
$stamps-signin-container-height: 100%;
$stamps-signin-container-left: 0;
$stamps-signin-container-margin: 0;
$stamps-signin-container-padding: 0;
$stamps-signin-container-position: default;
$stamps-signin-container-top: 0;
$stamps-signin-container-transform: none;
$stamps-signin-container-width: auto;
$stamps-signin-element-display: flex;
$stamps-signin-footer-background-color: #000;
$stamps-signin-footer-color: #fff;
$stamps-signin-footer-height: 40px;
$stamps-signin-footer-line-height: 24px;
$stamps-signin-footer-margin-top: 0;
$stamps-signin-forgot-link-color: #096ca7;
$stamps-signin-forgot-link-padding: 5px 0 0 20px;
$stamps-signin-forgot-link-text-decoration: none;
$stamps-signin-forgot-link-white-space: nowrap;
$stamps-signin-form-border: none;
$stamps-signin-form-border-radius: 5px;
$stamps-signin-form-checkbox-label-line-height: 20px;
$stamps-signin-form-font-size: $stamps-font-size;
$stamps-signin-form-margin: -20px 0 0 0;
$stamps-signin-form-max-width: inherit;
$stamps-signin-form-min-height: inherit;
$stamps-signin-form-padding: 15px 25px;
$stamps-signin-form-width: 513px;
$stamps-signin-header-font-size: 28px;
$stamps-signin-header-font-weight: inherit;
$stamps-signin-header-padding: 10px 0 20px;
$stamps-signin-header-text-align: left;
$stamps-signin-hide-password-icon: 'password_hide.png';
$stamps-signin-hide-password-icon-margin-top: -32px;
$stamps-signin-layout-background-color: #fff;
$stamps-signin-link-color: $stamps-link-color;
$stamps-signin-link-font-size: $stamps-font-size;
$stamps-signin-link-separator-visibility: visible;
$stamps-signin-logo: 'stamps-signin-logo.svg';
$stamps-signin-logo-display: block;
$stamps-signin-logo-mobile: 'stamps-signin-logo-mobile.svg';
$stamps-signin-primary-button-text-color: #fff;
$stamps-signin-primary-button-bg-color: $stamps-primary-color;
$stamps-signin-primary-button-hover-bg-color: darken($stamps-primary-color, 10%);
$stamps-signin-primary-button-active-bg-color: darken($stamps-primary-color, 20%);
$stamps-signin-secondary-button-text-color: #333;
$stamps-signin-secondary-button-bg-color: $stamps-secondary-color;
$stamps-signin-secondary-button-border-color: $stamps-secondary-button-bg-color;
$stamps-signin-secondary-button-hover-bg-color: darken($stamps-secondary-color, 10%);
$stamps-signin-secondary-button-hover-border-color: darken($stamps-secondary-color, 10%);
$stamps-signin-secondary-button-active-bg-color: darken($stamps-secondary-color, 20%);
$stamps-signin-secondary-button-active-border-color: darken($stamps-secondary-color, 20%);
$stamps-signin-reset-links-font-size: $stamps-font-size;
$stamps-signin-row-container-margin-bottom: 0;
$stamps-signin-row-container-forgot-flows-margin-bottom: 8px;
$stamps-signin-row-container-padding: 0 0 20px;
$stamps-signin-show-password-icon: 'password_show.png';

// using adl variables here since endicia also uses the same styles
$stamps-signin-reset-validation-form-border: 1px solid $adl-grey-400;
$stamps-signin-reset-validation-form-margin-top: $adl-padding-xs;
$stamps-signin-reset-validation-form-padding: $adl-padding-md;
$stamps-signin-reset-validation-form-list-padding: $adl-padding-sm;
$stamps-signin-form-border-radius: $adl-border-radius-xs;

$stamps-signin-form-padding-mobile: 15px 10px;
$stamps-signin-form-mobile-hr-margin: 12px 0;
$stamps-signin-mobile-form-wrapper-margin-top: 24px;
$stamps-signin-footer-mobile-padding: 15px;
$stamps-signin-footer-mobile-background-color: #fff;
$stamps-signin-privacy-links-margin-top: 0;
$stamps-signin-privacy-links-mobile-border-top: none;

// Zorbit
$stamps-inbound-details-header-text-color: rgb(51, 94, 51);
$stamps-inbound-details-sub-header-text-color: #023a89;
$stamps-inbound-details-conditional-heading-text-color: #023a89;
$stamps-inbound-details-border: #babec3;
$stamps-details-background-color: #eef2f8;
$stamps-boxes-background-image: 'boxes.svg';
$stamps-pallet-background-image: 'pallet.svg';
$stamps-inbound-add-icon: 'zorbit-add-icon.svg';
$stamps-inbound-details-transparent-btn-color: #023a89;

// UI
$stamps-ui-accordion-bg-color: #f6f7f7;
$stamps-ui-accordion-header-bg-color: #f2f2f2;
$stamps-ui-accordion-header-text-color: #333;
$stamps-ui-accordion-header-hover-text-color: #2159a8;
$stamps-ui-accordion-header-padding: 10px;
$stamps-ui-accordion-toggle-icon-color: #848484;
$stamps-ui-accordion-toggle-icon-font-size: 16px;
$stamps-ui-accordion-tab-border-color: #ddd;
$stamps-ui-input-label-font-weight: 400;
$stamps-ui-input-label-spacing: 0;
$stamps-ui-input-padding: 8px 6px;
$stamps-ui-input-background-color: #fff;
$stamps-ui-input-border-color: #a0a0a0;
$stamps-ui-input-border-focus-color: $stamps-primary-color;
$stamps-ui-input-border-invalid-color: #c30;
$stamps-ui-input-placeholder-color: #666;
$stamps-ui-input-placeholder-font-style: normal;
$stamps-ui-input-border-radius: 3px;
$stamps-ui-input-hover-foreground-color: #fff;
$stamps-ui-input-hover-border-color: $stamps-primary-color;
$stamps-ui-input-number-icon-color: $stamps-secondary-button-text-color;
$stamps-ui-input-number-icon-weight: 400;
$stamps-ui-input-number-icon-font-size: 9px;
$stamps-ui-calendar-date-trigger-icon: 'date-trigger.png';
$stamps-ui-checkbox-box-checked-background-color: #fff;
$stamps-ui-checkbox-box-checked-border-color: $stamps-ui-input-border-color;
$stamps-ui-checkbox-box-checked-color: $stamps-primary-color;
$stamps-ui-checkbox-box-checked-hover-border-color: $stamps-primary-color;
$stamps-ui-checkbox-box-disabled-background-color: none;
$stamps-ui-checkbox-box-disabled-border-color: none;
$stamps-ui-checkbox-box-focus-outline-border: none;
$stamps-ui-checkbox-box-size: 20px;
$stamps-ui-checkbox-check-size: 16px;
$stamps-ui-checkbox-label-margin: 0 0 0 .5em;
$stamps-ui-dropdown-icon: "\e902";
$stamps-ui-dropdown-icon-color: inherit;
$stamps-ui-dropdown-icon-size: 12px;
$stamps-ui-dropdown-item-active-font-weight: 400;
$stamps-ui-dropdown-item-highlight-bg-color: #eef2f8;
$stamps-ui-dropdown-item-highlight-text-color: #000;
$stamps-ui-dropdown-item-line-height: unset;
$stamps-ui-dropdown-font-color: #333;
$stamps-ui-dropdown-list-active-hover-border-radius: 0;
$stamps-ui-dropdown-list-border-color: $stamps-ui-input-border-color;
$stamps-ui-dropdown-list-disabled-font-color: $stamps-ui-dropdown-font-color;
$stamps-ui-dropdown-list-margin-top: 0;
$stamps-ui-grouped-dropdown-item-group-background: inherit;
$stamps-ui-grouped-dropdown-item-group-margin: 0;
$stamps-ui-grouped-dropdown-item-group-margin-top: 0;
$stamps-ui-element-wrapper-error-icon: 'error.png';
$stamps-icon-check-mark: 'stamps-icon-check-mark.svg';
$stamps-ui-drag-drop-upload-background-position: center;
$stamps-ui-drag-drop-upload-border: $stamps-primary-color;
$stamps-ui-drag-drop-upload-border-radius: 10px;
$stamps-ui-drag-drop-upload-icon: 'drag-drop-upload-cloud.svg';
$stamps-ui-drag-drop-upload-icon-background-size: auto;
$stamps-ui-drag-drop-upload-icon-size: 100px;
$stamps-ui-drag-drop-upload-success-icon: 'success-icon.svg';
$stamps-ui-drag-drop-upload-text-color: $stamps-primary-color;
$stamps-ui-element-wrapper-invalid-text-color: #cf4c35;
$stamps-ui-input-icon-size: 20px;
$stamps-ui-multiselect-selected-item-background: #f6f7f7;
$stamps-ui-popover-border: 1px solid #c8c8c8;
$stamps-ui-popover-box-shadow: 0 8px 4px 0 rgba(39, 41, 42, .16);
$stamps-ui-popover-line-height: 20px;
$stamps-ui-radiobutton-disabled-background-color: #fff;
$stamps-ui-radiobutton-disabled-icon-color: $stamps-primary-color;
$stamps-ui-radiobutton-label-margin: 0 0 0 .5em;
$stamps-ui-radiobutton-selected-border-color: #a0a0a0;
$stamps-ui-toolbar-badge-icon-top: 9px;
$stamps-ui-toolbar-bg-color: #e9e9e9;
$stamps-ui-toolbar-border: 0;
$stamps-ui-toolbar-button-padding: $stamps-default-button-padding;
$stamps-ui-toolbar-height: unset;
$stamps-ui-toolbar-item-hover-bg-color: #f6f7f7;
$stamps-ui-toolbar-item-hover-text-color: $stamps-primary-color;
$stamps-ui-toolbar-button-height: 42px;
$stamps-ui-toolbar-icon-button-height: 42px;
$stamps-ui-toolbar-button-margin: 0 5px;
$stamps-ui-toolbar-list-padding: 0 5px;
$stamps-ui-toolbar-padding: 0;
$stamps-ui-toolbar-search-padding: 5px 5px;
$stamps-ui-toolbar-icon-button-padding: $stamps-default-button-padding;
$stamps-ui-toolbar-plus-gray-icon: 'plus-gray.svg';
$stamps-ui-toolbar-plus-blue-icon: 'plus-blue.svg';
$stamps-ui-toolbar-import-icon: 'import-icon.png';
$stamps-ui-toolbar-import-hover-icon: 'import-hover-icon.png';
$stamps-ui-toolbar-edit-icon: 'edit-icon.png';
$stamps-ui-toolbar-edit-icon-display: inherit;
$stamps-ui-toolbar-edit-hover-icon: 'edit-hover-icon.png';
$stamps-ui-toolbar-export-icon: 'export-icon.png';
$stamps-ui-toolbar-export-hover-icon: 'export-hover-icon.png';
$stamps-ui-toolbar-feedback-icon: 'feedback-icon.png';
$stamps-ui-toolbar-feedback-hover-icon: 'feedback-hover-icon.png';
$stamps-ui-tooltip-bg-color: #eef2f8;
$stamps-ui-tooltip-text-color: #0a0a0a;
$stamps-ui-tooltip-shadow: none;
$stamps-ui-tooltip-border-radius: 4px;
$stamps-ui-tooltip-min-height: 32px;
$stamps-ui-tooltip-max-height: 160px;
$stamps-ui-tooltip-max-width: 240px;
$stamps-ui-tooltip-padding: 6px 8px;
$stamps-ui-tooltip-padding-gap: 8px;
$stamps-ui-tooltip-text-max-width: 224px;
$stamps-ui-tooltip-text-color: #0F0F10;
$stamps-ui-tooltip-text-font-size: 12px;
$stamps-ui-tooltip-text-font-style: normal;
$stamps-ui-tooltip-text-font-weight: 400;
$stamps-ui-tooltip-text-line-height: 16px;
$stamps-ui-tooltip-border-color: #e5e5e5;
$stamps-ui-tooltip-invalid-border-color: #c30;
$stamps-ui-tooltip-max-width: 240px;
$stamps-ui-save-confirmation-error-color: #cf4c35;
$stamps-ui-save-confirmation-success-color: #00ad11;
$stamps-ui-spinner-bg-color: #e5e5e5;
$stamps-ui-spinner-border: 0;
$stamps-ui-spinner-border-radius: 3px;
$stamps-ui-spinner-circle-color: #666;
$stamps-ui-spinner-font-weight: 400;
$stamps-ui-spinner-mask-background-color: rgba(255, 255, 255, .7);
$stamps-ui-spinner-padding: 8px;
$stamps-ui-spinner-size: 30px;
$stamps-ui-spinner-text-color: #666;
$stamps-ui-spinner-width: unset;
$stamps-ui-spinner-image-margin-bottom: 12px;
$stamps-ui-slider-range-color:#535353;
$stamps-ui-slider-handle-color:#535353;
$stamps-ui-steppers-bg-color: $stamps-primary-color;
$stamps-ui-steppers-color: $stamps-primary-color;
$stamps-ui-steppers-title-color: #525a5d;
$stamps-ui-steppers-title-bottom-margin: 5px;
$stamps-ui-steppers-text-color: #848889;
$stamps-ui-tabview-active-background-color: #dbdbdb;
$stamps-ui-tabview-active-border-color: #fff;
$stamps-ui-tabview-active-text-color: #fff;
$stamps-ui-tabview-hover-border-color: #adb5bd;
$stamps-ui-tabview-hover-font-weight: 400;
$stamps-ui-tabview-text-color: $stamps-modal-header-text-color;
$stamps-ui-tabview-transition: 500ms cubic-bezier(.35, 0, .25, 1);
$stamps-ui-disabled-opacity: .5;
$stamps-ui-question-mark-icon: 'question_mark_static.svg';
$stamps-ui-question-mark-icon-hover: 'question_mark_active.svg';

// Icons
$stamps-ui-iconset-image-extension: 'png';
$stamps-ui-iconset-history-background-size: 48px 192px;
$stamps-ui-iconset-history-package-position: 0 0;
$stamps-ui-iconset-history-netstamps-position: 0 -48px;
$stamps-ui-iconset-history-envelope-position: 0 -24px;
$stamps-ui-iconset-history-refund-position: -1px -143px;
$stamps-ui-iconset-history-refund-hover-position: -24.3px -143px !important;
$stamps-ui-iconset-history-address-position: -1px -74px;
$stamps-ui-iconset-history-address-hover-position: -25px -74px;
$stamps-ui-iconset-history-reference-position: 2px -120px;
$stamps-ui-iconset-history-reference-hover-position: -22px -120px;
$stamps-ui-iconset-history-services-position: -2px -94px;
$stamps-ui-iconset-history-services-hover-position: -26px -94px;
$stamps-ui-iconset-history-parcelguard-position: 0 -144px;
$stamps-ui-iconset-history-parcelguard-hover-position: -24px -144px;
$stamps-ui-iconset-history-gap-coverage-position: 0 -144px;
$stamps-ui-iconset-history-gap-coverage-hover-position: -24px -144px;
$stamps-ui-iconset-history-customs-position: 0 -168px;
$stamps-ui-iconset-history-customs-hover-position: -24px -168px;

// Fineprint
$stamps-ui-fineprint-gap: $adl-space-var-sm;
$stamps-ui-fineprint-default-icon-color: $adl-text-primary-dark;
$stamps-ui-fineprint-information-icon-color: $adl-blue-700;
$stamps-ui-fineprint-font-size: $adl-font-size-xs;
$stamps-ui-fineprint-font-weight: $adl-font-weight-normal;
$stamps-ui-fineprint-line-height: $adl-line-height-xs;

// Badge
$stamps-ui-badge-background-color: $adl-purple-950;
$stamps-ui-badge-padding-x: $adl-padding-xs;
$stamps-ui-badge-padding-y: $adl-padding-sm;
$stamps-ui-badge-border-radius: $adl-border-radius-md;
$stamps-ui-badge-text-color: $adl-grey-white;
$stamps-ui-badge-complete-background-color: $adl-purple-500;

// Contacts
$stamps-contacts-import-contacts-header-padding: 10px 0 20px;
$stamps-contacts-details-header-image-url: 'user-icon.png';
$stamps-details-header-padding: 15px;
$stamps-details-header-header-text-margin: 0 10px;
$stamps-details-body-padding: 15px;
$stamps-contacts-import-modal-padding: 15px;
$stamps-contacts-import-template-line-height: 14px;
$stamps-contacts-import-template-margin-top: 24px;
$stamps-contacts-import-mapping-header-padding-bottom: 16px;
$stamps-contacts-import-mapping-text-padding: 16px;
$stamps-contacts-import-mapping-right-panel-padding: 0 20px 6px 0;
$stamps-contacts-import-mapping-right-panel-label-padding: 50px;
$stamps-contacts-import-mapping-right-panel-checkbox-margin: 0 0 0 75px;
$stamps-contacts-import-mapping-arrow-left: 'left-arrow.svg';
$stamps-contacts-import-mapping-arrow-right: 'right-arrow.svg';
$stamps-contacts-import-mapping-arrow-margin-left: 5px;
$stamps-contacts-import-mapping-arrow-size: 10px;
$stamps-contacts-import-mapping-dropdown-border: 4px solid #3aa2da;
$stamps-contacts-import-mapping-scroll-panel-height: 70%;

// History Details Panel
$stamps-ui-tracking-general-color: black;

$stamps-ui-details-header-padding: 5px 10px 5px 6px;
$stamps-ui-details-header-title-line-height: 20px;
$stamps-ui-details-header-title-font-weight: bold;
$stamps-ui-details-header-title-font-size: 14px;
$stamps-ui-details-header-title-color: black;
$stamps-ui-details-header-hover-color: inherit;

$stamps-ui-details-accordion-content-margin: 15px;
$stamps-ui-details-accordion-header-font-weight: bold;;
$stamps-ui-details-accordion-header-font-size: 100%;
$stamps-ui-details-accordion-header-line-height: 14px;
$stamps-ui-details-accordion-header-link-padding: 10px;
$stamps-ui-details-display-label-padding-right: 10px;
$stamps-details-steppers-padding: 10px;
$stamps-ui-details-button-label-padding: 4px 10px;
$stamps-ui-details-line-height: 25px;
$stamps-ui-details-display-detail-padding: 7px;

$stamps-ui-data-display-label-container-margin-right: 10px;

$stamps-ui-tracking-detail-panel-padding: 20px 10px 0px;

$stamps-ui-tracking-status-font-weight: bold;
$stamps-ui-tracking-status-font-size: 22px;
$stamps-ui-tracking-status-line-height: 24px;

$stamps-ui-tracking-last-event-padding: inherit;
$stamps-ui-tracking-last-event-font-size: inherit;
$stamps-ui-tracking-last-event-line-height: 14px;
$stamps-ui-tracking-number-details-font-weight: bold;
$stamps-ui-email-tracking-info-container-margin: 8px 0;
$stamps-ui-email-tracking-info-line-height: 20px;

$stamps-ui-list-gap: $adl-padding-lg;
$stamps-ui-list-item-gap: $adl-gap;
$stamps-ui-list-affirm-icon: 'icons/affirm.svg';
$stamps-ui-list-icon: 'icons/bullet.svg';
$stamps-ui-list-check-icon: 'icons/check.svg';
$stamps-ui-list-item-gap: $adl-gap-md;
$stamps-ui-list-icon: 'icons/bullet.svg';
$stamps-ui-list-icon-top: 2px; // Arbitrary value to align the icon with the text
$stamps-ui-list-icon-width: 16px;
$stamps-ui-list-icon-height: 16px;

// History Details Panel - Services
$stamps-ui-details-services-fee-items-container-gap: 4px;
$stamps-ui-details-services-fee-items-icon-width: 16px;
$stamps-ui-details-services-fee-items-icon-height: 16px;

// Products
$stamps-products-manage-categories-padding: 0;
$stamps-products-manage-categories-border: none;
$stamps-products-manage-categories-border-radius: 0;
$stamps-products-manage-categories-toolbar-height: unset;
$stamps-products-sprites-image: 'products-sprites.png';
$stamps-products-sprites-image-size: 48px;
$stamps-products-toolbar-color: $stamps-ui-toolbar-bg-color;
$stamps-details-accordion-header-text-margin: 8px 24px;
$stamps-products-accordion-content-background-color: #f6f7f7;

// Branded Tracking Preview Background Image URLs
$stamps-branded-tracking-iphonex-frame: 'branded-tracking/stamps-branded-tracking-iPhoneXFrame.png';
$stamps-branded-tracking-tablet-frame: 'branded-tracking/stamps-branded-tracking-tabletFrame.png';
$stamps-branded-tracking-browser-frame: 'branded-tracking/stamps-branded-tracking-browserFrame.png';

// Pagination
$stamps-pagination-bar-border: none;
$stamps-pagination-bar-padding: 5px 10px;
$stamps-pagination-input-padding: 2px 0;

// Payment
$stamps-payment-button-text-color: $stamps-primary-color;
$stamps-payment-button-margin: 0;
$stamps-payment-link-margin: 8px 0 0 11px;
$stamps-payment-link-color: $stamps-primary-color;
$stamps-payment-link-text-decoration: none;
$stamps-payment-info-banner-space: 0 0 $adl-padding-md 0;

/*
* 3rd-party Ag-Grid SASS variables - $ag-grid-xoxo, in which
* xoxo are ag-grid variables. See their definitions in the link below.
* https://www.ag-grid.com/javascript-grid-themes-provided/#customizing-sass-variables
*/

$ag-grid-foreground-opacity: .87 !default;
$ag-grid-secondary-foreground-color-opacity: .54 !default;
$ag-grid-disabled-foreground-color-opacity: .38 !default;

// theme shortcuts
$ag-grid-default-background: white !default;
$ag-grid-chrome-background: lighten(#ecf0f1, 3) !default;
$ag-grid-active: #0091ea !default;
$ag-grid-alt-background: #ecf0f1 !default;
$ag-grid-odd-row-background-color: rgb(252, 253, 254) !default;
$ag-grid-header-cell-moving-background-color: $ag-grid-default-background !default;

// Colors
$ag-grid-border-color: #bdc3c7 !default;
$ag-grid-background-color: $ag-grid-default-background !default;
$ag-grid-foreground-color: rgba(#000, $ag-grid-foreground-opacity) !default; // foreground
$ag-grid-header-background-color: $ag-grid-chrome-background !default;
$ag-grid-header-foreground-color: rgba($ag-grid-foreground-color, $ag-grid-secondary-foreground-color-opacity) !default; // foreground-secondary
$ag-grid-icon-color: #7f8c8d !default;
$ag-grid-icon-checked-background-color: $ag-grid-default-background;
$ag-grid-icon-checked-color: $ag-grid-active;
$ag-grid-alt-icon-color: $ag-grid-default-background !default;
$ag-grid-accent-color: $ag-grid-active !default;
$ag-grid-range-selection-background-color: transparentize($ag-grid-active, .8) !default;
$ag-grid-secondary-foreground-color: rgba($ag-grid-foreground-color, $ag-grid-secondary-foreground-color-opacity) !default; // foreground-secondary
$ag-grid-disabled-foreground-color: rgba($ag-grid-foreground-color, $ag-grid-disabled-foreground-color-opacity) !default; // foreground-disabled
$ag-grid-order-setting-background-color: #eef2f8;
$ag-grid-order-setting-border-color: none;

$ag-grid-primary-color: $ag-grid-active !default;
$ag-grid-range-selected-color-1: opacify($ag-grid-range-selection-background-color, .1) !default;
$ag-grid-range-selected-color-2: opacify($ag-grid-range-selection-background-color, .2) !default;
$ag-grid-range-selected-color-3: opacify($ag-grid-range-selection-background-color, .3) !default;
$ag-grid-range-selected-color-4: opacify($ag-grid-range-selection-background-color, .4) !default;
$ag-grid-range-selection-highlight-color: $ag-grid-active !default;
$ag-grid-selected-color: lighten($ag-grid-active, 40) !default;

$ag-grid-editor-background-color: $ag-grid-chrome-background !default;
$ag-grid-panel-background-color: $ag-grid-chrome-background !default;
$ag-grid-header-cell-hover-background-color: $ag-grid-header-background-color !default;

$ag-grid-hover-color: $ag-grid-alt-background !default;
$ag-grid-chip-background-color: darken($ag-grid-alt-background, 5) !default;

$ag-grid-row-stub-background-color: inherit !default;
$ag-grid-row-floating-background-color: inherit !default;

$ag-grid-icon-tick: '\f106';
$ag-grid-icon-minus: '\f107';
$ag-grid-indeterminate-icon-padding: 0;
$ag-grid-indeterminate-icon-width: 16px;
$ag-grid-indeterminate-icon-height: 16px;
$ag-grid-indeterminate-icon-position: relative;
$ag-grid-indeterminate-icon-content-position: relative;
$ag-grid-indeterminate-icon-content-left: 0;
$ag-grid-indeterminate-icon-content-top: 0;

// Sizing
$ag-grid-grid-size: 4px !default;
$ag-grid-icon-size: 16px !default;
$ag-grid-header-height: $ag-grid-grid-size * 8 !default;
$ag-grid-row-height: 42px !default; // needs to match grid's rowHeight (see GridPanel.component.js)
$ag-grid-rich-select-item-height: $ag-grid-row-height !default;
$ag-grid-cell-horizontal-padding: $ag-grid-grid-size * 3 !default;
$ag-grid-virtual-item-height: $ag-grid-grid-size * 7 !default;
$ag-grid-header-icon-size: 14px !default;

// Fonts
$ag-grid-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default;
$ag-grid-font-size: 12px !default;
$ag-grid-font-weight: 400 !default;

$ag-grid-secondary-font-family: $ag-grid-font-family !default;
$ag-grid-secondary-font-size: 12px !default;
$ag-grid-secondary-font-weight: 600 !default;

// Tooltip
$ag-grid-border-radius: 2px !default;
$ag-grid-tooltip-background-color: lighten(#bdc3c7, 5%) !default;
$ag-grid-tooltip-foreground-color: $ag-grid-foreground-color !default;
$ag-grid-tooltip-border-radius: $ag-grid-border-radius !default;
$ag-grid-tooltip-padding: 5px !default;
$ag-grid-tooltip-border-width: 1px !default;
$ag-grid-tooltip-border-style: solid !default;
$ag-grid-tooltip-border-color: $ag-grid-tooltip-background-color !default;

// Column Options
$ag-grid-column-options-icon-tick: "\f132";
$ag-grid-column-options-icon-color: #7f8c8d;
$ag-grid-column-options-icon-pin-rotate: 0deg;
$ag-grid-column-options-menu-option-height: 28px;
$ag-grid-column-options-menu-border-radius: 0;
$ag-grid-column-options-menu-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
$ag-grid-column-options-tab-border-bottom-color: transparent;
$ag-grid-column-options-tab-border-radius: 0px;
$ag-grid-column-options-tab-margin-bottom: -2px;
$ag-grid-column-options-text-align: center;
$ag-grid-column-options-tool-panel-item-padding: 0;
$ag-grid-column-options-tool-panel-height: 30px;
$ag-grid-column-options-search-border-top: 1px solid #E4E5E7;
$ag-grid-column-options-search-border-radius: 0;
$ag-grid-column-options-search-height: 42px;
$ag-grid-column-options-search-padding: 0;
$ag-grid-column-options-separator-display: table-row;

// Misc
$ag-grid-card-shadow: none !default;
$ag-grid-card-radius: 2px !default;
$ag-grid-row-border-width: 1px !default;
$ag-grid-transition-speed: 120ms !default;
$ag-grid-cell-horizontal-border: 1px solid transparent !default;

$ag-grid-toolpanel-indent-size: $ag-grid-grid-size + $ag-grid-icon-size !default;
$ag-grid-row-group-indent-size: $ag-grid-grid-size * 3 + $ag-grid-icon-size !default;

$ag-grid-input-disabled-background-color: #ebebeb !default;
$ag-grid-input-border-color: #95a5a6 !default;
$ag-grid-scroll-spacer-border: 1px solid if($ag-grid-border-color, $ag-grid-border-color, lightgrey) !default;

$ag-grid-dialog-background-color: $ag-grid-background-color !default;
$ag-grid-dialog-border-size: 1px !default;
$ag-grid-dialog-border-style: solid !default;
$ag-grid-dialog-border-color: $ag-grid-border-color !default;

$ag-grid-dialog-title-background-color: $ag-grid-header-background-color !default;
$ag-grid-dialog-title-foreground-color: $ag-grid-header-foreground-color !default;
$ag-grid-dialog-title-height: $ag-grid-header-height !default;
$ag-grid-dialog-title-font-family: $ag-grid-secondary-font-family !default;
$ag-grid-dialog-title-font-size: $ag-grid-secondary-font-size !default;
$ag-grid-dialog-title-font-weight: $ag-grid-secondary-font-weight !default;
$ag-grid-dialog-title-padding: 5px 10px !default;
$ag-grid-dialog-title-icon-size: max(20px, $ag-grid-icon-size) !default;

$ag-grid-tool-panel-background-color: $ag-grid-chrome-background !default;
$ag-grid-group-component-background-color: lighten($ag-grid-tool-panel-background-color, 1%) !default;
$ag-grid-group-component-border-color: lighten($ag-grid-border-color, 5%) !default;
$ag-grid-group-component-title-background-color: darken($ag-grid-group-component-background-color, 5%) !default;

$ag-grid-range-selection-chart-background-color: rgba(0, 88, 255, .1) !default;
$ag-grid-range-selection-chart-category-background-color: rgba(0, 255, 132, .1) !default;

// delta changes
$ag-grid-cell-data-changed-color: #fce4ec !default;
$ag-grid-value-change-delta-up-color: #43a047 !default;
$ag-grid-value-change-delta-down-color: #e53935 !default;
$ag-grid-value-change-value-highlight-background-color: transparentize(#16a085, .5) !default;

/*
* 3rd-party Primereact designer SASS variables. See their definitions in the link below.
* https://www.primefaces.org/designer-react/#/documentation
*/

// main color scheme
$primereact-primaryColor: #007ad9;
$primereact-primaryDarkColor: #116fbf;
$primereact-primaryDarkerColor: #005b9f;
$primereact-primaryTextColor: #fff;

// Global
$primereact-fontFamily: $stamps-font-family;
$primereact-fontSize: $stamps-font-size;
$primereact-textColor: #333;
$primereact-textSecondaryColor: #848484;
$primereact-borderRadius: $stamps-ui-input-border-radius;
$primereact-transitionDuration: .2s;
$primereact-primeIconFontSize: $stamps-icon-font-size;

// anchors
$primereact-linkColor: $primereact-primaryColor;
$primereact-linkHoverColor: $primereact-primaryDarkColor;
$primereact-linkActiveColor: $primereact-primaryDarkerColor;

// highlight
$primereact-highlightBgColor: $primereact-primaryColor;
$primereact-highlightColorText: $primereact-primaryTextColor;

// focus
$primereact-focusOutline: 0 none;
$primereact-focusOutlineOffset: 0;
$primereact-focusShadow: none;

// input field (e.g. inputtext, spinner, inputmask)
$primereact-inputPadding: $stamps-ui-input-padding;
$primereact-inputBgColor: #fff;
$primereact-inputBorder: 1px solid $stamps-ui-input-border-color;
$primereact-inputHoverBorderColor: $primereact-primaryColor;
$primereact-inputHoverForegroundColor: $stamps-ui-input-hover-foreground-color;
$primereact-inputFocusBorderColor: $primereact-primaryColor;
$primereact-inputErrorBorder: 1px solid #a80000;
$primereact-inputPlaceholderTextColor: $stamps-ui-input-placeholder-color;
$primereact-inputTransition: border-color $primereact-transitionDuration, box-shadow $primereact-transitionDuration;
$primereact-inputDisabledBgColor: inherit;
$primereact-inputDisabledTextColor: inherit;

// input groups
$primereact-inputGroupBorderColor: #e7d8d8;
$primereact-inputGroupBgColor: #eef2f8;
$primereact-inputGroupTextColor: $primereact-textSecondaryColor;

// input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$primereact-inputListMinWidth: 12em;
$primereact-inputListBgColor: #fff;
$primereact-inputListPadding: 0;
$primereact-inputListItemPadding: 7px;
$primereact-inputListItemBgColor: transparent;
$primereact-inputListItemTextColor: $stamps-ui-dropdown-font-color;
$primereact-inputListItemHoverBgColor: #eef2f8;
$primereact-inputListItemHoverTextColor: #333;
$primereact-inputListItemHighlightBgColor: $primereact-highlightBgColor;
$primereact-inputListItemHighlightTextColor: $stamps-ui-dropdown-item-highlight-text-color;
$primereact-inputListItemBorder: 0 none;
$primereact-inputListItemMargin: 0;
$primereact-inputListItemFocusShadow: inset 0 0 0 .2em lighten($primereact-primaryColor, 35%);
$primereact-inputListItemTransition: background-color $primereact-transitionDuration, box-shadow $primereact-transitionDuration;
$primereact-inputListItemDividerColor: #d8dae2;
$primereact-inputListHeaderPaddingTop: .429em;
$primereact-inputListHeaderPaddingLeft: .857em;
$primereact-inputListHeaderPaddingRight: .857em;
$primereact-inputListHeaderPaddingBottom: .429em;
$primereact-inputListHeaderMargin: 0;
$primereact-inputListHeaderBgColor: #fff;
$primereact-inputListHeaderTextColor: $primereact-textColor;
$primereact-inputListHeaderBorder: 1px solid #eef2f8;
$primereact-inputListHeaderSearchIconColor: $primereact-primaryColor;
$primereact-inputListHeaderCloseIconColor: $primereact-textSecondaryColor;
$primereact-inputListHeaderCloseIconHoverColor: $primereact-textColor;
$primereact-inputListHeaderCloseIconTransition: box-shadow $primereact-transitionDuration;

// inputs with panels (e.g. password)
$primereact-inputContentPanelPadding: 12px;
$primereact-inputContentPanelBgColor: #fff;
$primereact-inputContentPanelTextColor: $primereact-textColor;

// static positioned input lists like listbox
$primereact-inputListBorder: 1px solid #a6a6a6;

// inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$primereact-inputOverlayBorder: 1px solid $stamps-ui-input-border-color;
$primereact-inputOverlayShadow: 0 3px 6px 0 rgba(0, 0, 0, .16);

// input dropdowns (e.g. multiselect, dropdown)
$primereact-inputDropdownIconColor: $primereact-textSecondaryColor;

// button
$primereact-buttonTextOnlyPadding: $stamps-default-button-padding;
$primereact-buttonWithLeftIconPadding: $stamps-icon-button-padding $stamps-icon-button-padding $stamps-icon-button-padding $stamps-icon-button-padding * 2 + $stamps-icon-button-icon-size;
$primereact-buttonWithRightIconPadding: $stamps-icon-button-padding $stamps-icon-button-padding * 2 + $stamps-icon-button-icon-size $stamps-icon-button-padding $stamps-icon-button-padding;
$primereact-buttonIconOnlyPadding: $stamps-default-button-padding; //.49em
$primereact-buttonIconOnlyWidth: $stamps-icon-button-icon-size;
$primereact-buttonIconOnlyHeight: $stamps-icon-button-icon-size;
$primereact-buttonBgColor: $stamps-primary-button-bg-color;
$primereact-buttonBorder: 1px solid $stamps-primary-button-bg-color;
$primereact-buttonTextColor: $stamps-primary-button-text-color;
$primereact-buttonHoverBgColor: $stamps-primary-button-hover-bg-color;
$primereact-buttonHoverTextColor: $stamps-primary-button-text-color;
$primereact-buttonHoverBorderColor: $stamps-primary-button-hover-bg-color;
$primereact-buttonActiveBgColor: $stamps-primary-button-active-bg-color;
$primereact-buttonActiveTextColor: $stamps-primary-button-text-color;
$primereact-buttonActiveBorderColor: $stamps-primary-button-active-bg-color;
$primereact-buttonTransition: background-color $primereact-transitionDuration,box-shadow $primereact-transitionDuration;
$primereact-raisedButtonShadow: 0 2px 3px 0 rgba(0, 0, 0, .15);
$primereact-roundedButtonBorderRadius: 15px;

$primereact-secondaryButtonBgColor: $stamps-secondary-button-bg-color;
$primereact-secondaryButtonBorder: 1px solid $stamps-secondary-button-bg-color;
$primereact-secondaryButtonTextColor: $stamps-secondary-button-text-color;
$primereact-secondaryButtonHoverBgColor: $stamps-secondary-button-hover-bg-color;
$primereact-secondaryButtonHoverTextColor: $stamps-secondary-button-text-color;
$primereact-secondaryButtonHoverBorderColor: $stamps-secondary-button-hover-bg-color;
$primereact-secondaryButtonActiveBgColor: $stamps-secondary-button-active-bg-color;
$primereact-secondaryButtonActiveTextColor: $stamps-secondary-button-text-color;
$primereact-secondaryButtonActiveBorderColor: $stamps-secondary-button-active-bg-color;
$primereact-secondaryButtonFocusShadow: 0 0 0 .2em lighten($primereact-primaryColor, 35%);

$primereact-infoButtonBgColor: #007ad9;
$primereact-infoButtonBorder: 1px solid #007ad9;
$primereact-infoButtonTextColor: #fff;
$primereact-infoButtonHoverBgColor: #116fbf;
$primereact-infoButtonHoverTextColor: #fff;
$primereact-infoButtonHoverBorderColor: #116fbf;
$primereact-infoButtonActiveBgColor: #005b9f;
$primereact-infoButtonActiveTextColor: #fff;
$primereact-infoButtonActiveBorderColor: #005b9f;
$primereact-infoButtonFocusShadow: 0 0 0 .2em lighten($primereact-infoButtonBgColor, 35%);

$primereact-successButtonBgColor: #34a835;
$primereact-successButtonBorder: 1px solid #34a835;
$primereact-successButtonTextColor: #fff;
$primereact-successButtonHoverBgColor: #107d11;
$primereact-successButtonHoverTextColor: #fff;
$primereact-successButtonHoverBorderColor: #107d11;
$primereact-successButtonActiveBgColor: #0c6b0d;
$primereact-successButtonActiveTextColor: #fff;
$primereact-successButtonActiveBorderColor: #0c6b0d;
$primereact-successButtonFocusShadow: 0 0 0 .2em lighten($primereact-successButtonBgColor, 35%);

$primereact-warningButtonBgColor: #ffba01;
$primereact-warningButtonBorder: 1px solid #ffba01;
$primereact-warningButtonTextColor: #333;
$primereact-warningButtonHoverBgColor: #ed990b;
$primereact-warningButtonHoverTextColor: #333;
$primereact-warningButtonHoverBorderColor: #ed990b;
$primereact-warningButtonActiveBgColor: #d38b10;
$primereact-warningButtonActiveTextColor: #333;
$primereact-warningButtonActiveBorderColor: #d38b10;
$primereact-warningButtonFocusShadow: 0 0 0 .2em lighten($primereact-warningButtonBgColor, 35%);

$primereact-dangerButtonBgColor: #e91224;
$primereact-dangerButtonBorder: 1px solid #e91224;
$primereact-dangerButtonTextColor: #fff;
$primereact-dangerButtonHoverBgColor: #c01120;
$primereact-dangerButtonHoverTextColor: #fff;
$primereact-dangerButtonHoverBorderColor: #c01120;
$primereact-dangerButtonActiveBgColor: #a90000;
$primereact-dangerButtonActiveTextColor: #fff;
$primereact-dangerButtonActiveBorderColor: #a90000;
$primereact-dangerButtonFocusShadow: 0 0 0 .2em lighten($primereact-dangerButtonBgColor, 35%);

// checkbox
$primereact-checkboxWidth: 20px;
$primereact-checkboxHeight: 20px;
$primereact-checkboxActiveBorderColor: $primereact-primaryColor;
$primereact-checkboxActiveBgColor: $primereact-primaryColor;
$primereact-checkboxActiveTextColor: $primereact-primaryTextColor;
$primereact-checkboxActiveHoverBgColor: $primereact-primaryDarkerColor;
$primereact-checkboxActiveHoverTextColor: $primereact-primaryTextColor;
$primereact-checkboxActiveHoverBorderColor: $primereact-primaryDarkerColor;
$primereact-checkboxTransition: background-color $primereact-transitionDuration, border-color $primereact-transitionDuration, box-shadow $primereact-transitionDuration;

// radiobutton
$primereact-radiobuttonWidth: 20px;
$primereact-radiobuttonHeight: 20px;
$primereact-radiobuttonActiveBorderColor: $primereact-primaryColor;
$primereact-radiobuttonActiveBgColor: $primereact-primaryColor;
$primereact-radiobuttonActiveTextColor: $primereact-primaryTextColor;
$primereact-radiobuttonActiveHoverBgColor: $primereact-primaryDarkerColor;
$primereact-radiobuttonActiveHoverTextColor: $primereact-primaryTextColor;
$primereact-radiobuttonActiveHoverBorderColor: $primereact-primaryDarkerColor;
$primereact-radiobuttonTransition: background-color $primereact-transitionDuration, border-color $primereact-transitionDuration, box-shadow $primereact-transitionDuration;

// togglebutton
$primereact-toggleButtonBgColor: #dadada;
$primereact-toggleButtonBorder: 1px solid #dadada;
$primereact-toggleButtonTextColor: #333;
$primereact-toggleButtonIconColor: #666;
$primereact-toggleButtonHoverBgColor: #c8c8c8;
$primereact-toggleButtonHoverBorderColor: #c8c8c8;
$primereact-toggleButtonHoverTextColor: #333;
$primereact-toggleButtonHoverIconColor: #212121;
$primereact-toggleButtonActiveBgColor: $primereact-primaryColor;
$primereact-toggleButtonActiveBorderColor: $primereact-primaryColor;
$primereact-toggleButtonActiveTextColor: $primereact-primaryTextColor;
$primereact-toggleButtonActiveIconColor: $primereact-primaryTextColor;
$primereact-toggleButtonActiveHoverBgColor: $primereact-primaryDarkColor;
$primereact-toggleButtonActiveHoverBorderColor: $primereact-primaryDarkColor;
$primereact-toggleButtonActiveHoverTextColor: $primereact-primaryTextColor;
$primereact-toggleButtonActiveHoverIconColor: $primereact-primaryTextColor;

// inplace
$primereact-inplacePadding: $primereact-inputPadding;
$primereact-inplaceHoverBgColor: #eef2f8;
$primereact-inplaceHoverTextColor: $primereact-textColor;
$primereact-inplaceTransition: background-color $primereact-transitionDuration;

// rating
$primereact-ratingTransition: color $primereact-transitionDuration, box-shadow $primereact-transitionDuration;
$primereact-ratingCancelIconColor: #e4018d;
$primereact-ratingCancelHoverIconColor: #b5019f;
$primereact-ratingIconFontSize: 20px;
$primereact-ratingStarIconColor: $primereact-textColor;
$primereact-ratingStarIconHoverColor: $primereact-primaryColor;

// slider
$primereact-sliderBgColor: #c8c8c8;
$primereact-sliderBorder: 0 none;
$primereact-sliderHeight: .286em;
$primereact-sliderWidth: .286em;
$primereact-sliderHandleWidth: 1.143em;
$primereact-sliderHandleHeight: 1.143em;
$primereact-sliderHandleBgColor: #fff;
$primereact-sliderHandleBorder: 2px solid #666;
$primereact-sliderHandleBorderRadius: 100%;
$primereact-sliderHandleHoverBorder: 2px solid $primereact-primaryColor;
$primereact-sliderHandleHoverBgColor: 2px solid #666;
$primereact-sliderHandleTransition: box-shadow $primereact-transitionDuration;
$primereact-sliderRangeBgColor: $primereact-primaryColor;

// calendar
$primereact-calendarWidth: 20em;
$primereact-calendarNavIconBgColor: #f0f0f0;
$primereact-calendarNavIconColor: #a6a6a6;
$primereact-calendarNavIconHoverColor: $primereact-primaryColor;
$primereact-calendarNavIconTransition: color $primereact-transitionDuration, box-shadow $primereact-transitionDuration;
$primereact-calendarPadding: .857em;
$primereact-calendarTableMargin: .857em 0 0 0;
$primereact-calendarHeaderCellPadding: .5em;
$primereact-calendarHeaderTextColor: #333;
$primereact-calendarCellDatePadding: .5em;
$primereact-calendarCellDateHoverBgColor: #eef2f8;
$primereact-calendarCellDateBorderRadius: $primereact-borderRadius;
$primereact-calendarCellDateSelectedBgColor: $primereact-primaryColor;
$primereact-calendarCellDateSelectedContainerBgColor: inherit;
$primereact-calendarCellDateSelectedTextColor: $primereact-primaryTextColor;
$primereact-calendarCellDateTextColor: $primereact-textColor;
$primereact-calendarCellDateTodayBgColor: #d0d0d0;
$primereact-calendarCellDateTodayTextColor: $primereact-textColor;
$primereact-calendarCellDateTodayBorder: none;
$primereact-calendarCellDateTodayBorderHover: none;
$primereact-calendarCellDateTodayBorderRadius: inherit;
$primereact-calendarCellDisabledBackgroundColor: inherit;
$primereact-calendarCellDisabledTextColor: inherit;
$primereact-calendarFontWeight: inherit;
$primereact-calendarTimePickerDivider:  1px solid #d8dae2;
$primereact-calendarTimePickerPadding: .857em;
$primereact-calendarTimePickerIconColor: #a6a6a6;
$primereact-calendarTimePickerIconFontSize: 1.286em;
$primereact-calendarTimePickerTimeFontSize: 1.286em;
$primereact-calendarTimePickerIconHoverColor: $primereact-primaryColor;
$primereact-calendarTitleFontSize: 14px;
$primereact-calendarButtonBarDivider:  1px solid #d8dae2;
$primereact-calendarButtonTextColor: inherit;
$primereact-calendarButtonBgColor: inherit;
$primereact-calendarMultipleMonthDivider:  1px solid #d8dae2;

// spinner
$primereact-spinnerButtonWidth: 28px;

// inline spinner
$stamps-ui-inline-spinner-margin: 0;
$stamps-ui-inline-spinner-color: #11003A;

// input switch
$primereact-inputSwitchWidth: 3em;
$primereact-inputSwitchHeight: 1.75em;
$primereact-inputSwitchBorderRadius: 30px;
$primereact-inputSwitchTransition: background-color $primereact-transitionDuration, box-shadow $primereact-transitionDuration;
$primereact-inputSwitchSliderOffBgColor: #ccc;
$primereact-inputSwitchHandleOffBgColor: #fff;
$primereact-inputSwitchSliderOffHoverBgColor: #b7b7b7;
$primereact-inputSwitchSliderOnBgColor: $primereact-primaryColor;
$primereact-inputSwitchSliderOnHoverBgColor: $primereact-primaryDarkColor;
$primereact-inputSwitchHandleOnBgColor: #ccc;

// panel common (e.g. accordion, panel, tabview)
$primereact-panelHeaderBorder: 0;
$primereact-panelHeaderBgColor: $stamps-modal-header-background-color;
$primereact-panelHeaderTextColor: $stamps-modal-header-text-color;
$primereact-panelHeaderIconColor: #848484;
$primereact-panelHeaderIconHoverColor: #333;
$primereact-panelHeaderIconTransition: color $primereact-transitionDuration, box-shadow $primereact-transitionDuration;
$primereact-panelHeaderFontWeight: normal;
$primereact-panelHeaderPadding: 15px 30px 10px 30px;
$primereact-panelContentBorder: 0;
$primereact-panelContentBgColor: $stamps-modal-body-background-color;
$primereact-panelContentTextColor: $stamps-modal-body-text-color;
$primereact-panelContentPadding: .571em 1em;
$primereact-panelFooterBorder: 1px solid #c8c8c8;
$primereact-panelFooterBgColor: $stamps-modal-footer-background-color;
$primereact-panelFooterTextColor: $stamps-modal-footer-text-color;
$primereact-panelFooterPadding: $stamps-modal-footer-padding;
$primereact-panelHeaderHoverBgColor: #dbdbdb;
$primereact-panelHeaderHoverBorder: 0;
$primereact-panelHeaderHoverTextColor: #333;
$primereact-panelHeaderHoverIconColor: #333;
$primereact-panelHeaderActiveBgColor: $primereact-primaryColor;
$primereact-panelHeaderActiveBorder: 0;
$primereact-panelHeaderActiveTextColor: #fff;
$primereact-panelHeaderActiveIconColor: #fff;
$primereact-panelHeaderActiveHoverBgColor: $primereact-primaryDarkerColor;
$primereact-panelHeaderActiveHoverBorder: 0;
$primereact-panelHeaderActiveHoverTextColor: #fff;
$primereact-panelHeaderActiveHoverIconColor: #fff;
$primereact-panelHeaderTransition: background-color $primereact-transitionDuration, box-shadow $primereact-transitionDuration;

// accordion
$primereact-accordionSpacing: 2px;

// tabview
$primereact-tabsNavBorder: 0 none;
$primereact-tabsNavBgColor: transparent;
$primereact-tabHeaderSpacing:  2px;

// scrollpanel
$primereact-scrollPanelHandleBgColor: #dadada;
$primereact-scrollPanelTrackBorder: 0 none;
$primereact-scrollPanelTrackBgColor: #f8f8f8;

// card
$primereact-cardShadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);

// paginator
$primereact-paginatorBgColor: #f4f4f4;
$primereact-paginatorBorder: 1px solid #c8c8c8;
$primereact-paginatorPadding: 0;
$primereact-paginatorIconColor: $primereact-textSecondaryColor;
$primereact-paginatorElementWidth: 2.286em;
$primereact-paginatorElementHeight: 2.286em;
$primereact-paginatorElementHoverBgColor: #e0e0e0;
$primereact-paginatorElementHoverIconColor: #333;
$primereact-paginatorElementBorderRadius: 0;
$primereact-paginatorElementMargin: 0;
$primereact-paginatorElementPadding: 0;
$primereact-paginatorElementBorder: 0 none;

// table
$primereact-tableHeaderCellPadding: .571em .857em;
$primereact-tableHeaderCellBgColor: #f4f4f4;
$primereact-tableHeaderCellTextColor: $primereact-textColor;
$primereact-tableHeaderCellFontWeight: 700;
$primereact-tableHeaderCellBorder: 1px solid #c8c8c8;
$primereact-tableHeaderCellHoverBgColor: #e0e0e0;
$primereact-tableHeaderCellHoverTextColor: #333;
$primereact-tableHeaderCellIconColor: $primereact-textSecondaryColor;
$primereact-tableHeaderCellHoverIconColor: #333;
$primereact-tableBodyRowBgColor: #fff;
$primereact-tableBodyRowTextColor: $primereact-textColor;
$primereact-tableBodyRowEvenBgColor: #f9f9f9;
$primereact-tableBodyRowHoverBgColor: #eef2f8;
$primereact-tableBodyRowHoverTextColor: #333;
$primereact-tableBodyCellBorder: 1px solid #c8c8c8;
$primereact-tableBodyCellPadding: .429em .857em;
$primereact-tableFooterCellPadding: .571em .857em;
$primereact-tableFooterCellBgColor: #f4f4f4;
$primereact-tableFooterCellTextColor: $primereact-textColor;
$primereact-tableFooterCellFontWeight: 700;
$primereact-tableFooterCellBorder: 1px solid #c8c8c8;
$primereact-tableResizerHelperBgColor: $primereact-primaryColor;

// schedule
$primereact-scheduleEventBgColor: $primereact-primaryDarkColor;
$primereact-scheduleEventBorder: 1px solid $primereact-primaryDarkColor;
$primereact-scheduleEventTextColor: $primereact-primaryTextColor;

// tree
$primereact-treeContainerPadding: .286em;
$primereact-treeNodePadding: .143em 0;
$primereact-treeNodeLabelPadding: .286em 0;
$primereact-treeNodeContentSpacing: .286em;

// lightbox
$primereact-lightBoxNavIconFontSize: 3em;
$primereact-lightBoxNavIconColor: #fff;

// org chart
$primereact-organizationChartConnectorColor: #c8c8c8;

// messages
$primereact-messagesMargin: 1em 0;
$primereact-messagesPadding: 1em;
$primereact-messagesIconFontSize: 2em;
$primereact-messageCloseIconFontSize: 1.25em;

// message
$primereact-messagePadding: .429em;
$primereact-messageMargin:  0;
$primereact-messageIconFontSize:  1.25em;
$primereact-messageTextFontSize:  1em;

// toast
$primereact-toastShadow:  0 3px 6px 0 rgba(0, 0, 0, .16);
$primereact-toastMessageMargin: 0 0 1em 0;

// severities
$primereact-infoMessageBgColor: #7fbcec;
$primereact-infoMessageBorder: 0 none;
$primereact-infoMessageTextColor: #212121;
$primereact-infoMessageIconColor: #212121;
$primereact-successMessageBgColor: #b7d8b7;
$primereact-successMessageBorder: 0 none;
$primereact-successMessageTextColor: #212121;
$primereact-successMessageIconColor: #212121;
$primereact-warnMessageBgColor: #ffe399;
$primereact-warnMessageBorder: 0 none;
$primereact-warnMessageTextColor: #212121;
$primereact-warnMessageIconColor: #212121;
$primereact-errorMessageBgColor: #f8b7bd;
$primereact-errorMessageBorder: 0 none;
$primereact-errorMessageTextColor: #212121;
$primereact-errorMessageIconColor: #212121;

// growl
$primereact-growlTopLocation: 70px;
$primereact-growlIconFontSize: 2.571em;
$primereact-growlMessageTextMargin:  0 0 0 4em;
$primereact-growlMargin: 0 0 1em 0;
$primereact-growlPadding: 1em;
$primereact-growlShadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
$primereact-growlOpacity: .9;

// overlays
$primereact-overlayContentBorderColor: #c8c8c8;
$primereact-overlayContentBorder: 1px solid $primereact-overlayContentBorderColor;
$primereact-overlayContainerShadow: 0 8px 4px 0 rgba(39, 41, 42, .16);
$primereact-overlayContainerRadius: $stamps-modal-border-radius;

// dialog
$primereact-dialogHeaderPadding: $stamps-modal-header-padding;
$primereact-dialogContentPadding: $stamps-modal-body-padding;
$primereact-confirmDialogPadding: $stamps-modal-body-padding;

// overlay panel
$primereact-overlayPanelCloseIconBgColor: $primereact-primaryColor;
$primereact-overlayPanelCloseIconColor: #fff;
$primereact-overlayPanelCloseIconHoverBgColor: $primereact-primaryDarkerColor;
$primereact-overlayPanelCloseIconHoverColor: #fff;

// tooltip
$primereact-tooltipBgColor: $stamps-ui-tooltip-bg-color;
$primereact-tooltipTextColor: $stamps-ui-tooltip-text-color;
$primereact-tooltipPadding: $primereact-inputPadding;

// steps
$primereact-stepsItemBgColor: #fff;
$primereact-stepsItemBorder: 1px solid #c8c8c8;
$primereact-stepsItemNumberColor: $primereact-textColor;
$primereact-stepsItemTextColor: $primereact-textSecondaryColor;
$primereact-stepsItemWidth: 28px;
$primereact-stepsItemHeight: 28px;

// progressbar
$primereact-progressBarHeight: 24px;
$primereact-progressBarBorder: 0 none;
$primereact-progressBarBgColor: #eef2f8;
$primereact-progressBarValueBgColor: $primereact-primaryColor;

// menu (e.g. menu, menubar, tieredmenu)
$primereact-menuBgColor: #fff;
$primereact-menuBorder: 0;
$primereact-menuPadding: 0;
$primereact-menuTextColor: $primereact-textColor;
$primereact-menuitemPadding: .714em .857em;
$primereact-menuitemMargin: 0;
$primereact-menuitemTextColor: $stamps-header-menu-item-text-color;
$primereact-menuitemIconColor: $stamps-header-menu-item-text-color;
$primereact-menuitemHoverTextColor: $stamps-header-menu-item-active-text-color;
$primereact-menuitemHoverIconColor: $stamps-header-menu-item-active-text-color;
$primereact-menuitemHoverBgColor: $stamps-header-menu-item-active-bg-color;
$primereact-menuitemActiveTextColor: $stamps-header-menu-item-active-text-color;
$primereact-menuitemActiveIconColor: $stamps-header-menu-item-active-text-color;
$primereact-menuitemActiveBgColor: $stamps-header-menu-item-active-bg-color;
$primereact-submenuHeaderMargin:  0;
$primereact-submenuPadding:  0;
$primereact-overlayMenuBorder: 1px solid $adl-grey-100;
$primereact-overlayMenuBorderRadius: $adl-border-radius-xs;
$primereact-overlayMenuMarginTop: 4px;
$primereact-overlayMenuPadding: $adl-padding-xs;
$primereact-overlayMenuShadow: 0 0 6px 0 rgba(0, 0, 0, .16);

// misc
$primereact-maskBgColor: rgba(0, 0, 0, .4);                    //dialog mask
$primereact-inlineSpacing: .5em;                                 //spacing between inline elements
$primereact-chipsItemMargin: 0 .286em 0 0;                       //autocomplete and chips token spacing
$primereact-dataIconColor: $primereact-textSecondaryColor;       //icon color of a data such as treetoggler, table expander
$primereact-dataIconHoverColor: $primereact-textColor;           //hover icon color of a data such as treetoggler, table expander

// general
$primereact-disabledOpacity: $stamps-ui-disabled-opacity;        //opacity of a disabled item

// info banner
$stamps-ui-infobanner-padding: $adl-padding-md $adl-padding-md;
$stamps-ui-infobanner-font-size: $adl-font-size-sm;
$stamps-ui-infobanner-warning-bg: $adl-yellow-050;
$stamps-ui-infobanner-line-height: $adl-line-height-sm;
$stamps-ui-infobanner-warning-border-color: 1px solid $adl-yellow-500;
$stamps-ui-infobanner-warning-padding: $adl-padding-md;
$stamps-ui-infobanner-text-padding: $adl-padding-lg;
$stamps-ui-infobanner-warning-light-border: 1px solid $adl-yellow-200;
$stamps-ui-infobanner-affirm-border-color: 1px solid $adl-green-700;
$stamps-ui-infobanner-affirm-background: $adl-green-050;
$stamps-ui-infobanner-border-radius: $adl-border-radius-xs;
$stamps-ui-infobanner-inform-border-color: 1px solid $adl-blue-700;
$stamps-ui-infobanner-inform-background: $adl-blue-050;
$stamps-ui-infobanner-error-border-color: 1px solid $adl-red-600;
$stamps-ui-infobanner-error-background: $adl-red-050;

// collapsible panel
$stamps-ui-collapsible-card-border-color: $adl-grey-100;
$stamps-ui-collapsible-card-border-radius: $adl-border-radius-sm;
$stamps-ui-collapsible-card-padding: $adl-space-var-md;
$stamps-ui-collapsible-card-header-font-weight: $adl-font-weight-bold;
$stamps-ui-collapsible-card-secondary-text-color: $adl-grey-600;
$stamps-ui-collapsible-card-header-icon-color: $adl-grey-900;

// Winclient home page
// using adl variables here since there is no endicia version of this.
$stamps-winclienthome-bg-color: $adl-surface-default;
$stamps-winclient-container-gap: $adl-margin-sm;
$stamps-winclient-container-padding: $adl-padding-sm;

// comparison table
$stamps-ui-inline-table-padding: $adl-padding-lg;
$stamps-ui-inline-table-border-width: $adl-border-width;
$stamps-ui-inline-table-border-radius: $adl-border-radius-xs;
$stamps-ui-inline-table-min-height: $adl-line-height-xl;
$stamps-ui-inline-table-border-color: $adl-grey-100;
$stamps-ui-inline-table-background-color: $adl-grey-white;
$stamps-ui-inline-table-row-cell-line-height: $adl-line-height-xl;
$stamps-ui-inline-table-row-cell-font-weight: $adl-font-weight-normal;

$stamps-ui-inline-table-header-cell-color: $adl-grey-900;
$stamps-ui-inline-table-header-cell-background-color: $adl-grey-050;

$upgrade-plan-comparison-gap: $adl-space-var-lg;
